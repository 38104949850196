import { BsChevronUp } from "react-icons/bs";
import { BsChevronDown } from "react-icons/bs";

const RoleListPagenation=()=>{
    return(
        <div className="col-12">
             <div className="card">
             <div className="table-responsive card">
          <table className="table  table-hover">
            <thead className="border-top">
              <tr>
              <th >
                  <span className="position-relative top-4">USER</span>
                  <div className="d-inline-block item-center">
                    <span className="d-flex flex-column ps-1">
                      <BsChevronUp
                        fontSize={12}
                        // onClick={() => aTozSorting("firstname")}
                      />
                      <BsChevronDown
                        fontSize={12}
                        // onClick={() => zToaSorting("firstname")}
                      />
                    </span>
                  </div>
                </th>
               
                <th>
                  <span className="position-relative top-4">ROLE</span>
                  <div className="d-inline-block item-center">
                    <span className="d-flex flex-column ps-1">
                      <BsChevronUp
                        fontSize={12}
                      />
                      <BsChevronDown
                        fontSize={12}
                      />
                    </span>
                  </div>
                </th>
                <th>
                  <span className="position-relative top-4">PLAN</span>
                  <div className="d-inline-block item-center">
                    <span className="d-flex flex-column ps-1">
                      <BsChevronUp
                        fontSize={12}

                      />
                      <BsChevronDown
                        fontSize={12}
                      />
                    </span>
                  </div>
                </th>
                <th>
                  <span className="position-relative top-4"> BILLING</span>
                  <div className="d-inline-block item-center">
                    <span className="d-flex flex-column ps-1">
                      <BsChevronUp
                        fontSize={12}
                      />
                      <BsChevronDown
                        fontSize={12}
                      />
                    </span>
                  </div>
                </th>
                <th>
                  <span className="position-relative top-4">STATUS</span>
                  <div className="d-inline-block item-center">
                    <span className="d-flex flex-column ps-1">
                      <BsChevronUp
                        fontSize={12}
                      />
                      <BsChevronDown
                        fontSize={12}
                      />
                    </span>
                  </div>
                </th>
                <th>
                 ACTION
                </th>
                
              </tr>
            </thead>
            <tbody>
                    <tr >
                      <td>
                    <div className="d-flex align-items-center">
                      <span className="mx-2" ><img src="../assets/img/avatars/2.png" alt=""/></span>
                       <span ><div className="name">Jamal Kerrod</div> <div className="email">Marge.Jacobson@gmail.com</div>  </span>  
                       </div>
                      </td>


                      <td className="text-nowrap">
                       
                   <div className="d-flex align-items-center">
                   <span class="badge badge-center rounded-pill bg-label-primary me-3 w-px-30 h-px-30"><i class="ti ti-chart-pie-2 ti-sm"></i></span>
                        <span> Maintainer</span></div>
                      </td>
                      <td className="text-nowrap name">
                      Enterprise
                      </td>

                      
                     
                      <td>Auto Debit</td> 
                     
                      <td className="text-nowrap"><span class="btn btn-label-success waves-effect py-1">Active</span></td>
                     
                      <td>
                        <i
                          className="menu-icon tf-icons ti ti-edit"
                          type="button"
                        />

                        <i
                          onClick={() => {
                          }}
                          className="menu-icon tf-icons  ti ti-sm ti-trash"
                        />

                       <i class="ti ti-dots-vertical ti-sm lh-1"></i>
                      </td>
                    </tr>
                    <tr >
                      <td>
                    <div className="d-flex align-items-center">
                      <span className="mx-2" ><img src="../assets/img/avatars/1.png" alt=""/></span>
                       <span ><div className="name">Shamus Tuttle</div> <div  className="email">Nicklaus.Balistreri24@hotmail.com</div>  </span>  
                       </div>
                      </td>

                      <td className="text-nowrap">
                       
                        <div className="d-flex align-items-center">
                        <span class="badge badge-center rounded-pill bg-label-warning  me-3 w-px-30 h-px-30"><i class="ti ti-circle-check ti-sm"></i>
                        </span>
                        <span> Subscriber</span></div>
                      </td>
                      <td className="text-nowrap name">
                      Basic
                      </td>
                      <td>Auto Debit</td> 
                     
                      <td className="text-nowrap"><span class="btn btn-label-secondary waves-effect py-1">Inactive</span></td>
                     
                      <td>
                        <i
                          className="menu-icon tf-icons ti ti-edit"
                          type="button"
                        />

                        <i
                          onClick={() => {
                          }}
                          className="menu-icon tf-icons  ti ti-sm ti-trash"
                        />

                       <i class="ti ti-dots-vertical ti-sm lh-1"></i>
                      </td>
                    </tr>

                    <tr >
                      <td>
                    <div className="d-flex align-items-center">
                      <span className="mx-2" ><img src="../assets/img/avatars/3.png" alt=""/></span>
                       <span ><div className="name">Devonne Wallbridge</div> <div  className="email">Michale_Goldner@gmail.com</div>  </span>  
                       </div>
                      </td>
                      <td className="text-nowrap">
                        <div className="d-flex align-items-center">
                        <span class="badge badge-center rounded-pill bg-label-info  me-3 w-px-30 h-px-30"><i class="menu-icon tf-icons ti ti-edit ti-sm"></i>
                        </span>
                        <span> Editor</span></div>
                      </td>
                      <td className="text-nowrap name">
                      Team
                      </td>
                      <td>Manual - PayPal</td> 
                     
                      <td className="text-nowrap"><span class="btn btn-label-success waves-effect py-1">Active</span></td>
                     
                      <td>
                        <i
                          
                          className="menu-icon tf-icons ti ti-edit"
                          type="button"
                        />

                        <i
                          onClick={() => {
                           
                          }}
                          className="menu-icon tf-icons  ti ti-sm ti-trash"
                        />

                       <i class="ti ti-dots-vertical ti-sm lh-1"></i>
                      </td>
                    </tr>
         
                    <tr >
                      <td>
                    <div className="d-flex align-items-center">
                      <span className="mx-2" ><img src="../assets/img/avatars/4.png" alt=""/></span>
                       <span ><div className="name">Ariella Filippyev</div> <div  className="email">Gus43@yahoo.com</div>  </span>  
                       </div>
                      </td>
                      <td className="text-nowrap">
                       
                        <div className="d-flex align-items-center">
                        <span class="badge badge-center rounded-pill bg-label-success me-3 w-px-30 h-px-30"><i class="ti ti-user ti-sm"></i></span>
                        
                        <span> Author</span></div>
                      </td>
                      <td className="text-nowrap name">
                      Team
                      </td>
                      <td>Manual - Cash</td> 
                     
                      <td className="text-nowrap"><span class="btn btn-label-warning waves-effect py-1">Pending</span></td>
                     
                      <td>
                        <i
                          
                          className="menu-icon tf-icons ti ti-edit"
                          type="button"
                        />

                        <i
                          onClick={() => {
                            
                          }}
                          className="menu-icon tf-icons  ti ti-sm ti-trash"
                        />

                       <i class="ti ti-dots-vertical ti-sm lh-1"></i>
                      </td>
                    </tr>
                    

                    <tr >
                      <td>
                    <div className="d-flex align-items-center">
                      <span className="mx-2" ><img src="../assets/img/avatars/5.png" alt=""/></span>
                       <span ><div className="name">Lutero Aloshechkin</div> <div  className="email">Alaina.Dicki@yahoo.com</div>  </span>  
                       </div>
                      </td>

                      

                      <td className="text-nowrap">
                       
                        <div className="d-flex align-items-center">
                        <span class="badge badge-center rounded-pill bg-label-warning  me-3 w-px-30 h-px-30"><i class="ti ti-circle-check ti-sm"></i>
                        </span>
                        <span> Subscriber</span></div>
                      </td>
                      <td className="text-nowrap name">
                      Enterprise
                      </td>
                      <td>Auto Debit</td> 
                     
                      <td className="text-nowrap"><span class="btn btn-label-secondary waves-effect py-1">Inactive</span></td>
                     
                      <td>
                        <i
                          
                          className="menu-icon tf-icons ti ti-edit"
                          type="button"
                        />

                        <i
                          onClick={() => {
                            
                          }}
                          className="menu-icon tf-icons  ti ti-sm ti-trash"
                        />

                       <i class="ti ti-dots-vertical ti-sm lh-1"></i>
                      </td>
                    </tr>
                  

                    <tr >
                      <td>
                    <div className="d-flex align-items-center">
                      <span className="mx-2" ><img src="../assets/img/avatars/6.png" alt=""/></span>
                       <span ><div className="name">Raynell Clendennen</div> <div className="email">Koby.Kohler13@gmail.com</div>  </span>  
                       </div>
                      </td>


                      <td className="text-nowrap">
                       
                   <div className="d-flex align-items-center">
                   <span class="badge badge-center rounded-pill bg-label-primary me-3 w-px-30 h-px-30"><i class="ti ti-chart-pie-2 ti-sm"></i></span>
                        <span> Maintainer</span></div>
                      </td>
                      <td className="text-nowrap name">
                      Basic
                      </td>
                      <td>Manual - PayPal</td> 
                     
                      <td className="text-nowrap"><span class="btn btn-label-warning waves-effect py-1">Pending</span></td>
                     
                      <td>
                        <i
                          className="menu-icon tf-icons ti ti-edit"
                          type="button"
                        />
                        <i
                          onClick={() => {
                          }}
                          className="menu-icon tf-icons  ti ti-sm ti-trash"
                        />
                       <i class="ti ti-dots-vertical ti-sm lh-1"></i>
                      </td>
                    </tr>
                    <tr >
                      <td>
                    <div className="d-flex align-items-center">
                      <span className="mx-2" ><img src="../assets/img/avatars/7.png" alt=""/></span>
                       <span ><div className="name">Lorine Hischke</div> <div className="email">Savanna46@yahoo.com</div>  </span>  
                       </div>
                      </td>
                      <td className="text-nowrap">
                       
                   <div className="d-flex align-items-center">
                   <span class="badge badge-center rounded-pill bg-label-secondary w-px-30 h-px-30 me-2"><i class="ti ti-device-laptop ti-sm"></i></span>
                        <span> Admin</span></div>
                      </td>
                      <td className="text-nowrap name">
                      Company
                      </td>
                      <td>Manual - Cash</td> 
                      <td className="text-nowrap"><span class="btn btn-label-success waves-effect py-1">Active</span></td>
                      <td>
                        <i
                          className="menu-icon tf-icons ti ti-edit"
                          type="button"
                        />
                        <i
                          onClick={() => {
                          }}
                          className="menu-icon tf-icons  ti ti-sm ti-trash"
                        />

                       <i class="ti ti-dots-vertical ti-sm lh-1"></i>
                      </td>
                    </tr>
            </tbody>
          </table>
        </div>
             </div>
        </div>
    )
}
export default RoleListPagenation;