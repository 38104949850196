import { toast } from "react-toastify";
import { createSlice } from "@reduxjs/toolkit";
import { userList, userPasswordUpdate, userInfoUpdate } from "../services/user";

const userSlice = createSlice({
    name: "userSlice",
    initialState: {
        userData: [],
        loading: false,
    },
    reducers: {
        loading: (state, action) => {
            state.loading = action?.payload ?? true;
        },
        getUserList: (state, action) => {
            state.userData = action.payload;
            state.loading = false
        },
        updateUserList: (state, action) => {
            state.loading = false
        }
    },
});

export const {
    loading,
    getUserList,
    updateUserList
} = userSlice.actions;
export default userSlice.reducer;

// api call
export const fetchUser = (id) => async (dispatch) => {
    try {
        dispatch(loading());
        const response = await userList(id);
        dispatch(getUserList(response?.data));
    } catch (error) {
        console.error("Error fetchInvoice:", error);
        dispatch(loading(false));
    }
};

export const changeUserPassword = (params) => async (dispatch) => {
    try {
        dispatch(loading());
        const response = await userPasswordUpdate(params);
        toast.success(response?.msg, {
            position: toast.POSITION.TOP_RIGHT,
        });
        dispatch(updateUserList(response?.data));
    } catch (error) {
        console.error("Error fetchInvoice:", error);
        dispatch(loading(false));

    }
};


export const updateUserDetails = (id, params) => async (dispatch) => {
    try {
        dispatch(loading());
        const response = await userInfoUpdate(id, params);
        toast.success(response?.msg, {
            position: toast.POSITION.TOP_RIGHT,
        });
        dispatch(getUserList(response?.data));
    } catch (error) {
        console.error("Error fetchInvoice:", error);
        dispatch(loading(false));

    }
};

