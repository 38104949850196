/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteReservation,
  getCustomer,
} from "../../services/client/addclientApi";
import {
  addReservation,
  getReservationById,
  updateResrvation,
} from "../../services/Calendar/reservation";
import { reservationList } from "../../Redux/calanderSlice";
import { fetchcompanyDropdown } from "../../Redux/commonSlice";
import { useNavigate } from "react-router-dom";
import { fetchCustomerData, getCustomerData } from "../../Redux/clientsSlice";
import { getSubscription } from "../../services/Company/addSubscriptionApi";
import { fetchSingleVehicleList } from "../../Redux/vehicleSlice";
import ConnectedCust from "./ConnectedCust";
import RealativeClient from "./RealativeClient";
import { Autocomplete } from "@react-google-maps/api";
import { schengenCountries } from "../Common/AddressAutocompleteInput";

const AddReservation = (props) => {
  const { show, onHide, res_id, setId, name } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companyDropdown } = useSelector((state) => state?.commonSlice);
  const { vehicleList } = useSelector((state) => state?.vehicleSlice);
  const [isLoding, setLoding] = useState(null);
  const [isCustomerList, setCustomerList] = useState(null);
  const [isVehicleDropdown, setVehicleDropdown] = useState([]);
  const [isSingleData, setSingleData] = useState([]);
  const currentDate = new Date().toISOString().split("T")[0]; // Get current date in YYYY-MM-DD format
  const [isStartDate, setStartDate] = useState("");
  const [isReturnDate, setIsReturnData] = useState("");
  const [isSingleCompany, setSingleCompany] = useState({});
  const [subscriptionValue, setSubscriptionValue] = useState("");
  const [isPrice, setPrice] = useState("");
  const [autocomplete, setAutocomplete] = useState("");
  const { customerData } = useSelector((state) => state?.clientsSlice);
  const { singlevehicleData } = useSelector((state) => state?.vehicleSlice);
  const Status = [
    { label: "Non Confirmé", value: "Not Confirmed" },
    { label: "Confirmé", value: "Confirmed" },
    { label: "Maintenance", value: "Maintenance" },
  ];

  // single Data start
  const singleData = async () => {
    if (res_id) {
      const data = await getReservationById(res_id);
      setSingleData(data?.data);
    }
  };

  useEffect(() => {
    setSingleData("");
    singleData();
  }, [res_id]);
  // single Data end

  const formik = useFormik({
    initialValues: {
      company_id: isSingleCompany || "",
      general_id:
        {
          name: `${isSingleData?.general_id?.manufacturer?.name} ${isSingleData?.general_id?.model?.name} ${isSingleData?.general_id?.general_info?.license_plate}`,
          _id: isSingleData?.general_id?._id,
        } || "",
      customer_id:
        {
          name: `${isSingleData?.customer_id?.firstname} ${isSingleData?.customer_id?.lastname}`,
          _id: isSingleData?.customer_id?._id,
        } || "",
      status: isSingleData?.status || "",
      extra_km: isSingleData?.extra_km || "",
      start_date: isStartDate || "",
      dept_loc: isSingleData?.dept_loc || "",
      return_date: isReturnDate || "",
      return_loc: isSingleData?.return_loc || "",
      start_loc: isSingleData?.start_loc || "",
      end_loc: isSingleData?.end_loc || "",
      type_voiture: isSingleData?.vahical_type || "",
    },

    onSubmit: async (values, { resetForm }) => {
      const body = { ...values };
      body.company_id = values?.company_id?._id;
      body.general_id = values?.general_id?._id;
      body.vahical_type = values?.type_voiture;
      if (
        values?.status === "Not Confirmed" ||
        values?.status === "Confirmed"
      ) {
        body.customer_id = values?.customer_id?._id
          ? values?.customer_id?._id
          : "";
      } else {
        delete body.customer_id;
      }
      setLoding(true);
      if (isSingleData?._id) {
        await updateResrvation(isSingleData?._id, body);
        onHide();
        setId(null);
      } else {
        const data = await addReservation(body);
        if (data) {
          onHide();
          resetForm();
        }
      }
      dispatch(reservationList());
      setLoding(false);
    },

    validateOnChange: false,
    enableReinitialize: true,
    validate: (values) => {
      const errors = {};
      if (!values.company_id?._id?.toString().trim()) {
        errors.company_id = "This field is required.";
      }
      // if (values.start_date?.toString().trim() < currentDate) {
      //   errors.start_date = "Invalide date.";
      // }
      if (
        values.return_date?.toString().trim() <
        values.start_date?.toString().trim()
      ) {
        errors.return_date = "Invalide date.";
      }
      return errors;
    },
  });

  const deleteData = async () => {
    const data = await deleteReservation(isSingleData?._id);
    if (data) {
      onHide();
      dispatch(reservationList());
    }
  };

  const customerDropdown = async () => {
    try {
      const dropdownData = await getCustomer();
      // filter base on rol
      const filterData = await dropdownData?.data?.filter((item) =>
        item.role?.includes("MAIN")
      );

      // client dropdown vlaues
      const formattedOptions = filterData?.map((customer) => ({
        name: `${customer?.firstname} ${customer?.lastname}`,
        _id: customer?._id,
      }));
      setCustomerList(formattedOptions);
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };

  useEffect(() => {
    setSingleCompany(isSingleData?.company_id);
    setStartDate(
      isSingleData?.start_date
        ? new Date(isSingleData?.start_date).toLocaleDateString("en-CA")
        : ""
    );
    setIsReturnData(
      isSingleData?.return_date
        ? new Date(isSingleData?.return_date).toLocaleDateString("en-CA")
        : ""
    );
  }, [vehicleList, isSingleData, res_id]);

  useEffect(() => {
    setPrice("");
    customerDropdown();
    dispatch(fetchcompanyDropdown());
  }, []);

  const handleCancel = () => {
    onHide(); // Close the Offcanvas
    formik.resetForm(); // Reset the Formik form
  };

  const handleNavigate = (role, id) => {
    if (role?.includes?.("CLIENT")) {
      navigate(`/client/profile/${id}`);
    } else {
      navigate(`/driver/profile/${id}`);
    }
  };

  useEffect(() => {
    const data = vehicleList
      ?.filter(
        (vehicle) => vehicle.active === "Active" && vehicle.status === "A louer"
      )
      .map((item) => ({
        name: `${item.manufacturer} ${item.model} ${item.license_plate}`,
        _id: item?._id,
      }));
    setVehicleDropdown(data);
  }, []);

  // subscriptionDropdownValue start
  const subscriptionDropdownValue = async (id) => {
    const subscriptionValue = await getSubscription(id);

    if (subscriptionValue !== undefined) {
      setSubscriptionValue(subscriptionValue?.data);
    } else {
      setSubscriptionValue([]);
    }
  };
  // subscriptionDropdownValue end

  // single vehile data
  const singleCarData = vehicleList?.filter(
    (item) => item?._id === formik?.values?.general_id?._id
  );

  useEffect(() => {
    if (singleCarData?.[0]?.general_id)
      dispatch(fetchSingleVehicleList(singleCarData?.[0]?.general_id));
  }, [formik?.values?.general_id?._id]);

  useEffect(() => {
    if (customerData?.customer?.sub_id?._id) {
      subscriptionDropdownValue(customerData?.customer?.sub_id?._id);
    }
  }, [customerData]);

  useEffect(() => {
    dispatch(fetchCustomerData(formik?.values?.customer_id?._id));
  }, [formik?.values?.customer_id]);

  useEffect(() => {
    if (
      formik?.values?.status === "Maintenance" ||
      formik?.values?.status === "Repair" ||
      formik?.values?.status === "Transit"
    )
      dispatch(getCustomerData({}));
  }, [formik?.values?.status]);

  useEffect(() => {
    setPrice("");
    const categoryMap = {
      1: "category1",
      2: "category2",
      3: "category3",
      4: "category4",
      5: "category5",
      6: "category6",
    };
    const categoryKey =
      categoryMap[singlevehicleData?.General?.vehicle_category?.name];
    const price = subscriptionValue?.subscription?.bail[categoryKey];
    setPrice(price);
  }, [singlevehicleData, customerData, subscriptionValue]);

  //  google setAutocomplete start
  const handleLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const handlePlaceChanged = (name) => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      let address = "";

      const addressComponents = place?.address_components;
      if (addressComponents) {
        const uniqueComponents = new Set(
          addressComponents
            .filter((component) => !component.types.includes("street_number"))
            .map((component) => component.long_name)
        );

        // Join the unique components to form the full address
        address = Array.from(uniqueComponents).join(", ");
        const fullAddress = address?.includes(place?.name)
          ? address
          : place?.name + ", " + address;
        formik.setFieldValue(name, fullAddress);
      }
    }
  };
  // google setAutocomplete end

  const a = Status?.find((item) => item.value === isSingleData?.status);
  return (
    <>
      {show && <div className="fade c-offcanvas-backdrop show"></div>}
      <div
        className={`c-offcanvas offcanvas-end ${show ? "c-show" : ""}`}
        tabIndex={-1}
        id="offcanvasEnd"
        aria-labelledby="offcanvasEndLabel"
        aria-modal="true"
        role="dialog"
      >
        <div className="c-offcanvas-header">
          <h5 id="offcanvasEndLabel" className="offcanvas-title">
            {name}
          </h5>
          <button
            onClick={() => onHide()}
            type="button"
            className="btn-close text-reset"
            aria-label="Close"
          />
        </div>
        <div className="c-offcanvas-body my-auto mx-0 flex-grow-0">
          <form
            className="add-new-user pt-0"
            id="addNewUserForm"
            onSubmit={formik.handleSubmit}
          >
            {/* relavtive driver start  */}
            <ConnectedCust
              isSingleData={isSingleData}
              customerData={customerData}
              handleNavigate={handleNavigate}
              isPrice={isPrice}
            />
            {/* relavtive client  */}

            {customerData?.relatedCustomer && (
              <RealativeClient
                clientData={customerData?.relatedCustomer}
                handleNavigate={handleNavigate}
                isPrice={isPrice}
                customerData={customerData}
                isSingleData={isSingleData}
              />
            )}

            {/* relavtive driver end */}
            <div className="mb-3">
              <label className="form-label" htmlFor="add-user-email">
                Société
              </label>
              <Dropdown
                options={companyDropdown}
                placeholder="Société"
                className={`w-full ${
                  formik.errors.company_id ? "dropdown-invalid" : ""
                }`}
                name="company_id"
                optionLabel="name"
                value={formik.values.company_id}
                onChange={formik.handleChange}
              />
              {formik.errors.company_id && (
                <div className="invalid-feedback">
                  {formik.errors.company_id}
                </div>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="add-user-email">
                Status
              </label>
              <Dropdown
                options={Status}
                placeholder="Status"
                className={`w-full ${
                  formik.errors?.firstName ? "dropdown-invalid" : ""
                }`}
                name="status"
                value={formik.values.status}
                onChange={formik.handleChange}
              />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="add-user-email">
                Véhicule
              </label>
              <Dropdown
                filter
                options={isVehicleDropdown}
                placeholder="Véhicule"
                className="w-full"
                optionLabel="name"
                name="general_id"
                value={formik.values.general_id}
                onChange={formik.handleChange}
              />
              {formik.errors.general_id && (
                <div className="invalid-feedback">
                  {formik.errors.general_id}
                </div>
              )}
            </div>
            {formik.values?.status === "Maintenance" ||
            formik.values?.status === "Repair" ||
            formik.values?.status === "Transit" ? null : (
              <>
                <div className="mb-3">
                  <label className="form-label" htmlFor="add-user-email">
                    Client
                  </label>
                  <Dropdown
                    filter
                    options={isCustomerList}
                    placeholder="Client"
                    className="w-full"
                    optionLabel="name"
                    name="customer_id"
                    value={formik.values.customer_id}
                    onChange={formik.handleChange}
                  />
                </div>
              </>
            )}
            <div className="mb-3">
              <label className="form-label" htmlFor="add-user-contact">
                Date de Début
              </label>

              <input
                type="date"
                id="add-user-contact"
                className={`form-control phone-mask ${
                  formik.errors.start_date ? "is-invalid-input" : ""
                }`}
                placeholder="Start date"
                name="start_date"
                value={formik.values.start_date}
                onChange={formik.handleChange}
              />
              {formik.errors.start_date && (
                <span className="invalid-feedback">
                  {formik.errors.start_date}
                </span>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">Lieux de Départ</label>
              <Autocomplete
                onLoad={handleLoad}
                onPlaceChanged={() => handlePlaceChanged("dept_loc")}
                options={{
                  componentRestrictions: { country: schengenCountries },
                }}
              >
                <input
                  type="text"
                  className="form-control mt-0"
                  placeholder="Lieux"
                  name="dept_loc"
                  autoComplete="off"
                  onChange={formik.handleChange}
                  Value={formik.values.dept_loc}
                  style={{ width: "100%" }}
                />
              </Autocomplete>
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="add-user-contact">
                Date de Retour
              </label>
              <input
                type="date"
                id="add-user-contact"
                className={`form-control phone-mask ${
                  formik.errors.return_date ? "is-invalid-input" : ""
                }`}
                placeholder="Return date"
                name="return_date"
                value={formik.values.return_date}
                onChange={formik.handleChange}
              />
              {formik.errors.return_date && (
                <span className="invalid-feedback">
                  {formik.errors.return_date}
                </span>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="add-user-contact">
                Lieux de Retour
              </label>
              <Autocomplete
                onLoad={handleLoad}
                onPlaceChanged={() => handlePlaceChanged("return_loc")}
                options={{
                  componentRestrictions: { country: schengenCountries },
                }}
              >
                <input
                  min={currentDate}
                  type="text"
                  className="form-control mt-0"
                  placeholder="Lieux"
                  name="return_loc"
                  autoComplete="off"
                  onChange={formik.handleChange}
                  Value={formik.values.return_loc}
                  style={{ width: "100%" }}
                />
              </Autocomplete>
            </div>
            <div className="mb-4">
              <button
                type="submit"
                className="btn btn-primary me-sm-3 me-1 data-submit "
              >
                {isLoding && (
                  <span
                    className="spinner-border text-white me-2"
                    role="status"
                  ></span>
                )}
                {isSingleData ? "mise à jour" : "Ajouter"}
              </button>
              <button
                type="reset"
                className="btn btn-label-secondary me-sm-3 me-1"
                onClick={handleCancel}
              >
                Annuler
              </button>
              <button
                type="reset"
                className="btn btn-label-danger "
                onClick={() => deleteData()}
              >
                Delete
              </button>
            </div>
          </form>
        </div>
      </div>
      {show && (
        <div
          className={`c-offcanvas-backdrop fade show ${show ? "c-width" : ""}`}
          onClick={() => onHide()}
        ></div>
      )}
    </>
  );
};

export default AddReservation;
