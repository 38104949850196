import { toast } from "react-toastify";
import axiosInstanceAuthFormData from "../../apiInstances/axiosInstanceAuthFormData";
import axiosInstanceAuth from "../../apiInstances/axiosInstanceAuth";

export const addReservation = async (body) => {
  try {
    const response = await axiosInstanceAuthFormData.post(
      `reservation/add`,
      body
    );
    toast.success(response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const getVehicleDropDown = async (id) => {
  const response = await axiosInstanceAuth.get(
    `/vehicle/vehiclelist/dropdown/${id}`
  );
  return response.data;
};

export const updateResrvation = async (id, body) => {
  try {
    const response = await axiosInstanceAuthFormData.patch(
      `/reservation/${id}`,
      body
    );
    toast.success(response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
export const updateResrvationStatus = async (body) => {
  try {
    const response = await axiosInstanceAuthFormData.put(
      `reservation/updateReservationStatus`,
      body
    );
    toast.success(response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const getReservationList = async () => {
  try {
    const response = await axiosInstanceAuth.get(`/reservation`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const getReservationById = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(`reservation/${id}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
