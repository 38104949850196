// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#reactSchedulerOutsideWrapper {
  /* width: 500px; */
  font-family: Inter, sans-serif;
  box-sizing: border-box;
  line-height: 1.15;
  text-size-adjust: 100%;
  margin: 0px;
  position: relative;
  border-radius: 12px;
}


`, "",{"version":3,"sources":["webpack://./src/css/calander.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,8BAA8B;EAC9B,sBAAsB;EACtB,iBAAiB;EACjB,sBAAsB;EACtB,WAAW;EACX,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":["#reactSchedulerOutsideWrapper {\n  /* width: 500px; */\n  font-family: Inter, sans-serif;\n  box-sizing: border-box;\n  line-height: 1.15;\n  text-size-adjust: 100%;\n  margin: 0px;\n  position: relative;\n  border-radius: 12px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
