import { toast } from "react-toastify";
import axiosInstanceAuth from "../../apiInstances/axiosInstanceAuth";
import axiosInstanceAuthFormData from "../../apiInstances/axiosInstanceAuthFormData";

export const invoiceList = async () => {
  try {
    const response = await axiosInstanceAuth.get(`/invoice`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const invoiceNumber = async () => {
  try {
    const response = await axiosInstanceAuth.get(`/invoice/invoicenumber`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const addInvoice = async (body) => {
  try {
    const response = await axiosInstanceAuth.post("/invoice/add", body);
    toast.success(response?.data?.msg, { position: toast.POSITION.TOP_RIGHT });
    return response.data;
  } catch (error) {
    toast.warn(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const sendInvoice = async (body) => {
  try {
    const response = await axiosInstanceAuthFormData.post(
      "/email/sendinvoice",
      body
    );
    toast.success("successfully sended", {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response.data;
  } catch (error) {
    toast.warn(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const updateInvoice = async (id, body) => {
  try {
    const response = await axiosInstanceAuth.put(`/invoice/${id} `, body);
    toast.success("updated successfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response.data;
  } catch (error) {
    toast.warn("please fill-up all the compulsory fields", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const invoiceById = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(`/invoice/${id}`);
    return response.data;
  } catch (error) {
    console.log("🚀 ~ file: index.js:43 ~ invoiceById ~ error:", error);
  }
};

export const addPayment = async (body) => {
  try {
    const response = await axiosInstanceAuthFormData.post("/payment/add", body);

    toast.success(response?.data?.msg, { position: toast.POSITION.TOP_RIGHT });
    return response.data;
  } catch (error) {
    toast.warn(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
export const invoiceListApi = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(
      `/invoice/invoicecustomer/${id}`
    );
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
