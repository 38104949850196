import React from "react";

export const roleClasses = {
  MAIN: "rounded bg-success c-badge px-2 text-white fw-600 fs-13",
  AUX: "bg-info rounded  c-badge px-2 text-white fw-600 fs-13",
  BASIC: "bg-secondary rounded c-badge px-2 text-white fw-600 fs-13",
  CLIENT: "bg-primary rounded c-badge px-2 text-white fw-600 fs-13",
};

export const roleClassesSecound = {
  MAIN: "btn btn-label-primary waves-effect py-1 px-2",
  AUX: "bg-info rounded badge",
  BASIC: "bg-secondary rounded badge",
  CLIENT: "btn btn-label-info waves-effect py-1 px-2",
};

export const statusClass = {
  MAIN: "btn btn-label-success waves-effect py-1",
  AUX: "btn btn-label-primary waves-effect py-1",
  CLIENT: "btn btn-label-primary waves-effect py-1",
  BASIC: "bg-secondary rounded badge",
};

export const subscriptionStatusClass = {
  One: "btn btn-success c-badge px-2 text-white fw-600 fs-13",
  Plus: "btn btn-label-success c-badge px-2 fw-600 fs-13",
};
export const mainAndClientRoleClass = {
  CLIENT: "px-custom bg-primary rounded c-badge text-white fw-600 fs-13",
  MAIN: "btn-label-success rounded c-badge px-custom fw-600 fs-13",
  AUX: "bg-info rounded  c-badge px-2 text-white fw-600 fs-13",
};

export const statusClassSecound = {
  MAIN: "btn rounded badge bg-info",
  CLIENT: "btn rounded badge bg-info",
};

export const RoleLabel = ({ userrols }) => {
  return (
    <>
      <div className="d-inline-block">
        {userrols?.length > 0 &&
          userrols?.map((role, i) => {
            return (
              <React.Fragment key={i}>
                {role !== "MAIN" && role !== "AUX" ? (
                  <span className={`${mainAndClientRoleClass[role]}`}>
                    {role?.charAt(0).toUpperCase()}
                  </span>
                ) : (
                  <>
                    {role !== "MAIN" && (
                      <span className={`${mainAndClientRoleClass[role]}`}>
                        {role}
                      </span>
                    )}
                  </>
                )}
              </React.Fragment>
            );
          })}
      </div>
      {/* {userrols?.length > 1 ? (
        userrols?.map((item) => (
          <span
            className={`me-1 btn waves-effect py-1 ${
              item?.charAt(0).toUpperCase() === "C"
                ? "btn-label-primary"
                : "btn-label-success"
            }`}
          >
            {item?.charAt(0).toUpperCase()}
          </span>
        ))
      ) : (
        <span className={`me-1 py-2 ${roleClasses[userrols?.[0]]}`}>
          {userrols?.[0]}
        </span>
      )} */}
    </>
  );
};
