import React from "react";
import {
  Document,
  Page,
  StyleSheet,
  View,
  Text,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";
import { formatDate } from "../../Common/FormatDate";
import { numberWithEuroFormat } from "../../Common/Currency";

const styles = StyleSheet.create({
  page: { backgroundColor: "white", color: "black", width: "100%" },
  section: {
    margin: "40px 12px",
  },
  invoiceHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (min-width: 768px)": {
      flexDirection: "column",
    },
    width: "100%",
  },
  image: { width: "100px", height: "auto" },
  invoiceInfo: {
    display: "flex",
    flexDirection: "row",
    "@media (min-width: 768px)": {
      flexDirection: "column",
    },
    width: "100%",
    marginTop: "28px",
    marginBottom: "28px",
  },
  inputControlStyle: {
    display: "block",
    width: "100%",
    padding: "7px 14px",
    appearance: "none",
    backgroundClip: "padding-box",
    border: "1px solid #dbdade",
    borderRadius: "4px",
  },
  table: {
    width: "100%",
    marginTop: "8px",
    marginBottom: "8px",
    // marginLeft:"13px"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #dbdade",
    paddingTop: 8,
    paddingBottom: 8,
  },
  header: {
    borderTop: "none",
    fontSize: "11px",
  },
  row1: {
    width: "15.66%",
    marginLeft:"13px"
  },
  row2: {
    width: "15.66%",
  },
  row3: {
    width: "15.66%",
  },
  row4: {
    width: "15.66%",
  },
  row5: {
    width: "15.66%",
  },
  row6: {
    width: "15.66%",
    marginLeft:"20px"
  },
  textColorP: {
    color: "#5d596c",
  },
  textColorS: {
    color: "#6f6b7d",
  },
  fontSize11: {
    fontSize: "10px",
  },
});

export const MyInvoicePDFDoc = (props) => {
  const { isSingleInvoice, singleCompanyData, total } = props;
  return (
    <Document>
      <Page size="A4" orientation="portrait" wrap={true} style={styles.page}>
        <View style={styles.section}>
          <View style={styles.invoiceHeader}>
            <Image style={styles.image} src={"https://iili.io/Jjw7SHX.png"} />
            <Text
              style={{
                fontSize: "24px",
              }}
            >
              {isSingleInvoice?.invoice?.proformate
                ? "Facture Pro-format"
                : "Facture"}
            </Text>
          </View>
          <View style={styles.invoiceInfo}>
            <View style={{ flex: 1 }}>
              <Text
                style={{
                  marginBottom: "8px",
                  fontWeight: "extrabold",
                  fontSize: "14px",
                }}
              >
                {singleCompanyData?.company?.name}
              </Text>
              <Text
                style={{
                  marginBottom: "8px",
                  fontSize: "12px",
                  color: "#6f6b7d",
                }}
              >
                {singleCompanyData?.billing?.number}
                {singleCompanyData?.billing?.number && ","}
                {singleCompanyData?.billing?.street}
              </Text>
              <Text
                style={{
                  marginBottom: "8px",
                  fontSize: "12px",
                  color: "#6f6b7d",
                }}
              >
                {singleCompanyData?.billing?.province}
                {singleCompanyData?.billing?.province && ","}
                {singleCompanyData?.billing?.postal}
                {singleCompanyData?.billing?.postal && ","}
                {isSingleInvoice?.invoice.customer_id?.city}
              </Text>
              <Text
                style={{
                  marginBottom: "8px",
                  fontSize: "12px",
                  color: "#6f6b7d",
                }}
              >
                {isSingleInvoice?.invoice.customer_id?.country}
              </Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text
                style={{
                  marginBottom: "8px",
                  fontWeight: "extrabold",
                  fontSize: "14px",
                }}
              >
                {isSingleInvoice?.invoice?.customer_id?.firstname}&nbsp;
                {isSingleInvoice?.invoice?.customer_id?.lastname}
              </Text>
              <Text
                style={{
                  marginBottom: "8px",
                  fontSize: "12px",
                  color: "#6f6b7d",
                }}
              >
                {isSingleInvoice?.invoice.customer_id?.address}
              </Text>
              <Text
                style={{
                  marginBottom: "8px",
                  fontSize: "12px",
                  color: "#6f6b7d",
                }}
              >
                {singleCompanyData?.billing?.city}
                {singleCompanyData?.billing?.city && ","}
                {singleCompanyData?.billing?.country}
              </Text>
              <Text
                style={{
                  marginBottom: "8px",
                  fontSize: "12px",
                  color: "#6f6b7d",
                }}
              >
                {isSingleInvoice?.invoice?.customer_id?.email}
                {isSingleInvoice?.invoice?.customer_id?.email && ","}
                {isSingleInvoice?.invoice.customer_id?.contact}
              </Text>
            </View>
          </View>
          <View
            style={{ borderTop: "1px solid #dbdade", padding: "16px 0px" }}
          ></View>
          <View style={{ border: "1px solid #dbdade", padding: "32px 12px" }}>
            <View
              style={{
                display: "flex",
                fontSize: "11px",
                gap: "8px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  gap: "8px",
                }}
              >
                <View
                  style={{
                    flexGrow: 1,
                  }}
                >
                  <Text style={{ marginBottom: "8px" }}>Numéro de Facture</Text>
                  <Text style={styles.inputControlStyle}>
                    {isSingleInvoice?.invoice?.invoice_id}20
                  </Text>
                </View>
                <View
                  style={{
                    flexGrow: 1,
                  }}
                >
                  <Text style={{ marginBottom: "8px" }}>
                    Date de Facturation
                  </Text>
                  <Text style={styles.inputControlStyle}>
                    {formatDate(isSingleInvoice?.invoice?.billing_date)}
                  </Text>
                </View>
                <View
                  style={{
                    flexGrow: 1,
                  }}
                >
                  <Text style={{ marginBottom: "8px" }}>Date d'Échéance</Text>
                  <Text style={styles.inputControlStyle}>
                    {formatDate(isSingleInvoice?.invoice?.due_date)}
                  </Text>
                </View>
                <View
                  style={{
                    flexGrow: 1,
                  }}
                >
                  <Text style={{ marginBottom: "8px" }}>Numéro Client</Text>
                  <Text style={styles.inputControlStyle}>
                    {isSingleInvoice?.invoice?.customer_id?.c_id}20
                  </Text>
                </View>
                <View
                  style={{
                    flexGrow: 1,
                  }}
                >
                  <Text style={{ marginBottom: "8px" }}>Numéro TVA</Text>
                  <Text style={styles.inputControlStyle}>
                    {singleCompanyData?.company?.vatno}20
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.table}>
            <View style={[styles.row, styles.header, styles.textColorP]} >
              <Text style={styles.row1}>ITEM</Text>
              <Text style={styles.row2}>COST</Text>
              <Text style={styles.row3}>QTY</Text>
              <Text style={styles.row4}>VAT</Text>
              <Text style={styles.row5}>PRICE</Text>
              <Text style={styles.row6}>SELECT</Text>
            </View>
            {isSingleInvoice?.productDetails?.map((row, i) => (
              <View
                key={i}
                style={[styles.row, styles.fontSize11]}
                wrap={false}
              >
                <Text style={styles.row1}>{row.item_name}</Text>
                <Text style={styles.row2}>{row.price}</Text>
                <Text style={styles.row3}>{row.qty}</Text>
                <Text style={styles.row4}>{row.vat}</Text>
                <Text style={styles.row5}>
                  {numberWithEuroFormat(row.total_ex_vat)}
                </Text>
                <Text style={styles.row6}>{row.period}</Text>
              </View>
            ))}
          </View>
          <View
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              gap: "8px",
              padding: "16px 0px",
              justifyContent: "space-between",
            }}
          >
            <View
              style={{
                border: "1px solid #dbdade",
                padding: "20px 12px",
                borderRadius: "4px",
                width: "35%",
              }}
            >
              <View
                style={{
                  margin: "0px 0px 24px 0px",
                  fontSize: "14px",
                  ...styles.textColorP,
                }}
              >
                <Text>Salesperson :</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "4px",
                  marginBottom: "8px",
                  fontSize: "9.5px",
                }}
              >
                <Text style={{ paddingRight: "16px" }}>Total Due:</Text>
                <Text>
                  {numberWithEuroFormat(
                    isSingleInvoice?.invoice?.total_pay?.toFixed(2)
                  )}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "4px",
                  marginBottom: "8px",
                  fontSize: "9.5px",
                }}
              >
                <Text style={{ paddingRight: "16px" }}>Bank name:</Text>
                <Text>{singleCompanyData?.banking?.bankname}</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "4px",
                  marginBottom: "8px",
                  fontSize: "9.5px",
                }}
              >
                <Text style={{ paddingRight: "16px" }}>Country:</Text>
                <Text>{singleCompanyData?.billing?.country}</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "4px",
                  marginBottom: "8px",
                  fontSize: "9.5px",
                }}
              >
                <Text style={{ paddingRight: "16px" }}>IBAN:</Text>
                <Text>{singleCompanyData?.banking?.vatno}</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "4px",
                  fontSize: "9.5px",
                }}
              >
                <Text style={{ paddingRight: "16px" }}>BIC code:</Text>
                <Text>{singleCompanyData?.banking?.bic}</Text>
              </View>
            </View>

            <View
              style={{
                fontSize: "9.5px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "4px",
                  marginBottom: "8px",
                }}
              >
                <Text style={{ width: "180px" }}>Subtotal excl. VAT:</Text>
                <Text>
                  {numberWithEuroFormat(
                    isSingleInvoice?.invoice?.subtotal_ex_vat
                  )}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "4px",
                  marginBottom: "8px",
                }}
              >
                <Text style={{ width: "180px" }}>VAT:</Text>
                <Text>
                  {numberWithEuroFormat(
                    isSingleInvoice?.invoice?.total_vat?.toFixed(2)
                  )}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "4px",
                  marginBottom: "8px",
                }}
              >
                <Text style={{ width: "180px" }}>Total Incl. VAT:</Text>
                <Text>{numberWithEuroFormat(total)}</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "4px",
                  marginBottom: "8px",
                }}
              >
                <Text style={{ width: "180px" }}>Paid:</Text>
                <Text>€{isSingleInvoice?.invoice?.paid?.toFixed(2)}</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "4px",
                  marginBottom: "12px",
                }}
              >
                <Text style={{ width: "180px" }}>Discount:</Text>
                <Text>
                  {numberWithEuroFormat(isSingleInvoice?.invoice?.discount)}
                </Text>
              </View>
              <View
                style={{
                  borderTop: "1px solid #dbdade",
                  padding: "0px 0px 12px 0px",
                }}
              ></View>
              <View
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "4px",
                  marginBottom: "8px",
                }}
              >
                <Text style={{ width: "180px" }}>Total To Pay:</Text>
                <Text> {numberWithEuroFormat(total)}</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "4px",
                }}
              >
                <Text style={{ width: "184px" }}>Remaining To Pay:</Text>
                <Text>
                  {numberWithEuroFormat(
                    (total - isSingleInvoice?.invoice?.paid)?.toFixed(2)
                  )}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              borderTop: "1px solid #dbdade",
              padding: "0px 0px 12px 0px",
            }}
          ></View>
          <View>
            <Text
              style={{
                fontSize: "14px",
                ...styles.textColorP,
              }}
            >
              Note : {isSingleInvoice?.invoice?.notes}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const InvoicePDF = () => {
  return (
    <>
      <div className="content-wrapper">
        <PDFViewer style={{ width: "100%", height: "100%" }}>
          <MyInvoicePDFDoc />
        </PDFViewer>
      </div>
    </>
  );
};

export default InvoicePDF;
