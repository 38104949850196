// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vehicles-progress-labels .vehicles-progress-label{position:relative;padding-bottom:15px}.vehicles-progress-labels .vehicles-progress-label::after{content:"";position:absolute;left:0;bottom:0;display:inline-block;height:10px;width:2px}[dir=rtl] .vehicles-progress-labels .vehicles-progress-label::after{left:unset;right:0}.vehicles-overview-progress .bg-gray-900{color:#fff}#shipmentStatisticsChart .apexcharts-legend-series,#carrierPerformance .apexcharts-legend-series{padding:5px 15px;border-radius:.375rem;height:83%}.light-style .vehicles-progress-labels .vehicles-progress-label::after{background-color:#dbdade}.light-style .vehicles-overview-progress .bg-gray-900{background-color:#5d596c}.light-style #shipmentStatisticsChart .apexcharts-legend-series,.light-style #carrierPerformance .apexcharts-legend-series{border:1px solid #dbdade}.dark-style .vehicles-progress-labels .vehicles-progress-label::after{background-color:#434968}.dark-style .vehicles-overview-progress .bg-gray-900{background-color:#4a5072}.dark-style #shipmentStatisticsChart .apexcharts-legend-series,.dark-style #carrierPerformance .apexcharts-legend-series{border:1px solid #434968}
`, "",{"version":3,"sources":["webpack://./src/assets/vendor/css/pages/app-logistics-dashboard.css"],"names":[],"mappings":"AAAA,mDAAmD,iBAAiB,CAAC,mBAAmB,CAAC,0DAA0D,UAAU,CAAC,iBAAiB,CAAC,MAAM,CAAC,QAAQ,CAAC,oBAAoB,CAAC,WAAW,CAAC,SAAS,CAAC,oEAAoE,UAAU,CAAC,OAAO,CAAC,yCAAyC,UAAU,CAAC,iGAAiG,gBAAgB,CAAC,qBAAqB,CAAC,UAAU,CAAC,uEAAuE,wBAAwB,CAAC,sDAAsD,wBAAwB,CAAC,2HAA2H,wBAAwB,CAAC,sEAAsE,wBAAwB,CAAC,qDAAqD,wBAAwB,CAAC,yHAAyH,wBAAwB","sourcesContent":[".vehicles-progress-labels .vehicles-progress-label{position:relative;padding-bottom:15px}.vehicles-progress-labels .vehicles-progress-label::after{content:\"\";position:absolute;left:0;bottom:0;display:inline-block;height:10px;width:2px}[dir=rtl] .vehicles-progress-labels .vehicles-progress-label::after{left:unset;right:0}.vehicles-overview-progress .bg-gray-900{color:#fff}#shipmentStatisticsChart .apexcharts-legend-series,#carrierPerformance .apexcharts-legend-series{padding:5px 15px;border-radius:.375rem;height:83%}.light-style .vehicles-progress-labels .vehicles-progress-label::after{background-color:#dbdade}.light-style .vehicles-overview-progress .bg-gray-900{background-color:#5d596c}.light-style #shipmentStatisticsChart .apexcharts-legend-series,.light-style #carrierPerformance .apexcharts-legend-series{border:1px solid #dbdade}.dark-style .vehicles-progress-labels .vehicles-progress-label::after{background-color:#434968}.dark-style .vehicles-overview-progress .bg-gray-900{background-color:#4a5072}.dark-style #shipmentStatisticsChart .apexcharts-legend-series,.dark-style #carrierPerformance .apexcharts-legend-series{border:1px solid #434968}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
