import React from "react";
import { RoleLabel, statusClass } from "../Common/roles";
import PriceSection from "./PriceSection";

const ConnectedCust = ({
  isSingleData,
  customerData,
  handleNavigate,
  isPrice,
}) => {
  return (
    <div>
      {(isSingleData?.connectedCustomers?.length > 0 ||
        customerData?.connectedCustomers) && (
        <div className="mb-3">Client Name</div>
      )}

      {isSingleData?.connectedCustomers
        ? isSingleData?.connectedCustomers?.map((item) => {
            return (
              <>
                {item?.MAIN !== null && (
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h6
                      className="m-0 cursor-pointer"
                      onClick={() =>
                        handleNavigate(item?.MAIN?.role, item?.MAIN?._id)
                      }
                    >
                      {item?.MAIN?.firstname} {item?.MAIN?.lastname}
                    </h6>
                    <div>
                      <div
                        className={`mx-1  ${
                          statusClass[
                            item?.MAIN?.role?.includes("MAIN")
                              ? "CLIENT"
                              : item?.MAIN?.role
                          ]
                        }`}
                      >
                        <div className="mx-1">
                          {item?.MAIN?.sub_id?.subscription_name}
                        </div>
                      </div>
                      <RoleLabel userrols={item?.MAIN?.role} />
                    </div>
                  </div>
                )}
                {item?.AUX?.map((aux) => (
                  <>
                    {aux !== null && (
                      <div className="d-flex justify-content-between align-items-center mb-3 cursor-pointer">
                        <h6
                          className="m-0"
                          onClick={() => handleNavigate(aux?.role, aux?._id)}
                        >
                          {aux?.firstname} {aux?.lastname}
                        </h6>
                        <div>
                          {aux?.sub_id?.subscription_name && (
                            <div
                              className={`mx-1  ${
                                statusClass[
                                  aux?.role?.includes("MAIN")
                                    ? "CLIENT"
                                    : aux?.role
                                ]
                              }`}
                            >
                              <div className="mx-1">
                                {aux?.sub_id?.subscription_name}
                              </div>
                            </div>
                          )}
                          <RoleLabel userrols={aux?.role} />
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </>
            );
          })
        : customerData?.connectedCustomers?.map((item) => {
            return (
              <>
                {item?.MAIN !== null && (
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h6
                      className="m-0 cursor-pointer"
                      onClick={() =>
                        handleNavigate(item?.MAIN?.role, item?.MAIN?._id)
                      }
                    >
                      {item?.MAIN?.firstname} {item?.MAIN?.lastname}
                    </h6>
                    <div>
                      <div
                        className={`mx-1  ${
                          statusClass[
                            item?.MAIN?.role?.includes("MAIN")
                              ? "CLIENT"
                              : item?.MAIN?.role
                          ]
                        }`}
                      >
                        <div className="mx-1">
                          {item?.MAIN?.sub_id?.subscription_name}
                        </div>
                      </div>
                      <RoleLabel userrols={item?.MAIN?.role} />
                    </div>
                  </div>
                )}
                {item?.AUX?.map((aux) => (
                  <>
                    {aux !== null && (
                      <div className="d-flex justify-content-between align-items-center mb-3 cursor-pointer">
                        <h6
                          className="m-0"
                          onClick={() => handleNavigate(aux?.role, aux?._id)}
                        >
                          {aux?.firstname} {aux?.lastname}
                        </h6>
                        <div>
                          {aux?.sub_id?.subscription_name && (
                            <div
                              className={`mx-1  ${
                                statusClass[
                                  aux?.role?.includes("MAIN")
                                    ? "CLIENT"
                                    : aux?.role
                                ]
                              }`}
                            >
                              <div className="mx-1">
                                {aux?.sub_id?.subscription_name}
                              </div>
                            </div>
                          )}
                          <RoleLabel userrols={aux?.role} />
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </>
            );
          })}

      {/* price section  */}
      {(isSingleData?.connectedCustomers?.length > 0 ||
        customerData?.connectedCustomers) && (
        <PriceSection
          isSingleData={isSingleData}
          customerData={customerData}
          isPrice={isPrice}
        />
      )}
    </div>
  );
};

export default ConnectedCust;
