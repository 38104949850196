import { ErrorMessage, Field } from "formik";
import { Type, demo2 } from "../../../Common/DropdownValue";
import { Dropdown } from "primereact/dropdown";
import { useSelector } from "react-redux";
import React from "react";

const GeneralInformation = React.forwardRef(
  ({ values, handleChange, setTags, errors }, ref) => {
    const { fuleDropdown } = useSelector((state) => state.vehicleSlice);

    return (
      <div className="card mb-4">
        <div className="card-header">
          <h5 className="card-tile mb-0">Informations Générale</h5>
        </div>
        <div className="card-body">
          <div className="row mb-3">
            <div className="col">
              <label className="form-label mb-1">
                Type de Carburant Primaire
              </label>
              <Dropdown
                value={values?.generalInfo?.main_fuel}
                onChange={handleChange}
                options={fuleDropdown}
                name="generalInfo.main_fuel"
                optionLabel="name"
                placeholder="Type"
                className={`w-full ${
                  errors?.generalInfo?.main_fuel && "dropdown-invalid"
                }`}
              />
              <ErrorMessage
                name="generalInfo.main_fuel"
                component="div"
                className="text-danger ps-2"
              />
            </div>
            <div className="col">
              <label className="form-label mb-1">
                Type de Carburant Secondaire
              </label>
              <Dropdown
                value={values?.generalInfo?.second_fuel}
                onChange={handleChange}
                options={fuleDropdown}
                name="generalInfo.second_fuel"
                optionLabel="name"
                placeholder="Type"
                className="w-full"
              />
              {/* <ErrorMessage name="generalInfo.second_fuel" component="div" className="text-danger ps-2" /> */}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <label className="form-label mb-1">Numéro de chassis</label>
              <Field
                name="generalInfo.chassis_number"
                type="text"
                className={`form-control ${
                  errors?.generalInfo?.chassis_number && "is-invalid"
                }`}
                placeholder="numéro"
                aria-controls="DataTables_Table_0"
              />
              <ErrorMessage
                name="generalInfo.chassis_number"
                component="div"
                className="text-danger ps-2"
              />
            </div>
            <div className="col">
              <label className="form-label mb-1">
                Plaque d'immatriculation
              </label>
              <Field
                name="generalInfo.license_plate"
                type="text"
                className={`form-control ${
                  errors?.generalInfo?.license_plate && "is-invalid"
                }`}
                placeholder="LU0001"
                aria-controls="DataTables_Table_0"
              />
              <ErrorMessage
                name="generalInfo.license_plate"
                component="div"
                className="text-danger ps-2"
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <label className="form-label mb-1">Émissions CO2</label>
              <Field
                name="generalInfo.co2_emissions"
                type="number"
                className={`form-control ${
                  errors?.generalInfo?.co2_emissions && "is-invalid"
                }`}
                placeholder="CO2"
                aria-controls="DataTables_Table_0"
              />
              <ErrorMessage
                name="generalInfo.co2_emissions"
                component="div"
                className="text-danger ps-2"
              />
            </div>
            <div className="col">
              <label className="form-label mb-1">K.W.</label>
              <Field
                name="generalInfo.k_w"
                type="number"
                className="form-control"
                placeholder="K.W."
                aria-controls="DataTables_Table_0"
              />
            </div>
            <div className="col">
              <label className="form-label mb-1">Puissance (Ch)</label>
              {/* <label className="form-label mb-1">Chevaux fiscaux</label> */}
              <Field
                name="generalInfo.resume"
                type="number"
                className="form-control"
                placeholder="C.V"
                // placeholder="Chevaux fiscaux"
                aria-controls="DataTables_Table_0"
              />
            </div>
            <div className="col">
              <label className="form-label mb-1">Cylindrée</label>
              <Field
                name="generalInfo.displacement"
                type="number"
                className="form-control"
                placeholder="cylindré"
                aria-controls="DataTables_Table_0"
              />
            </div>
            <div className="col">
              <label className="form-label mb-1">MMA</label>
              <Field
                name="generalInfo.good"
                type="text"
                className="form-control"
                placeholder="MMA"
                aria-controls="DataTables_Table_0"
              />
            </div>
            <div className="col">
              <label className="form-label mb-1">Taille du Réservoir</label>
              <Field
                name="generalInfo.tank_size"
                type="number"
                className="form-control"
                placeholder="L"
                aria-controls="DataTables_Table_0"
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              {/* <label className="form-label mb-1">Puissance Fiscale</label> old */}
              <label className="form-label mb-1">Chevaux fiscaux</label>
              {/* <label className="form-label mb-1">Puissance (CH)</label> */}
              <Field
                name="generalInfo.tax_power"
                type="number"
                // className="form-control"
                className={`form-control ${
                  errors?.generalInfo?.tax_power && "is-invalid"
                }`}
                placeholder="Ch"
                aria-controls="DataTables_Table_0"
              />
              <ErrorMessage
                name="generalInfo.tax_power"
                component="div"
                className="text-danger ps-2"
              />
            </div>
            <div className="col">
              <label className="form-label mb-1">Transmission</label>
              <Dropdown
                value={values.generalInfo.transmission}
                onChange={handleChange}
                name="generalInfo.transmission"
                options={demo2}
                placeholder="Manuelle"
                className="w-full"
              />
            </div>
            <div className="col">
              <label className="form-label mb-1">Nombre de Sièges</label>
              <Field
                name="generalInfo.seat_name"
                type="text"
                className="form-control"
                placeholder="0"
                aria-controls="DataTables_Table_0"
              />
            </div>
            <div className="col">
              {/* <label className="form-label mb-1">Nombre de Portes</label> */}
              <label className="form-label mb-1">Numéro de porte</label>
              <Field
                name="generalInfo.no_doors"
                type="number"
                className="form-control"
                placeholder="0"
                aria-controls="DataTables_Table_0"
              />
            </div>
          </div>
          <div className="row mb-3">
            <div
              className={`col ${
                errors?.generalInfo?.tags && "is-invalid-tage"
              } `}
            >
              <input
                type="text"
                placeholder="Tags"
                value={values?.generalInfo?.tags?.name}
                className="form-control"
                name="generalInfo.tags"
                onChange={(e) => setTags(e.target.value)}
                aria-controls="DataTables_Table_0"
                ref={ref}
              />
              {errors?.generalInfo?.tags && (
                <div className="text-danger ps-2">{"required *"}</div>
              )}
            </div>
            <div className="col-3">
              <Dropdown
                value={values?.generalInfo?.vi_types}
                onChange={handleChange}
                name="generalInfo.vi_types"
                options={Type}
                placeholder="Type"
                className="w-full"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default GeneralInformation;
