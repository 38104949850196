import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Dropdown } from "primereact/dropdown";
import Webcam from 'react-webcam';
import { BsApp } from "react-icons/bs";
import { useFormik } from "formik";
import { useNavigate, useParams } from 'react-router-dom';
import { createDamgeImage, getDamageById, repairDamageById } from '../../services/check';
import { useDispatch, useSelector } from "react-redux";
import { fetchDamageListById } from '../../Redux/checkSlice';
import camera from '../../assets/img/camera.png'

function DamageReportModal() {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [images, setImages] = useState([null, null]); // Two images for two upload icons
    const [previewImage, setPreviewImage] = useState(null);
    const webcamRef = useRef(null);
    const [screenshot, setScreenshot] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const currentIndexRef = useRef(currentIndex);
    const navigate = useNavigate();
    const { id } = useParams();
    // const { damagelistSingle, loading } = useSelector((state) => state?.checkSlice);
    const [showRepairedCheckbox, setShowRepairedCheckbox] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    let [imageRepaired, setImageRepaired] = useState(null);
    const [fromRepair, setFromRepair] = useState(false);
    let baseImgPath = process.env.REACT_APP_IMG_URL;
    const [damagelistSingle, setdamagelistSingle] = useState(null);

    const newExteriorParts = [
        { value: 'Rayures', label: 'Rayures' },
        { value: 'Renfoncement', label: 'Renfoncement' },
        { value: 'Cassure', label: 'Cassure' },
        { value: 'Éclat/bris', label: 'Éclat/bris' },
        { value: 'Pièces manquantes', label: 'Pièces manquantes' },
        { value: 'Autres', label: 'Autres' },
    ];
    
    useEffect(() => {
        const getDamageDetails = async () => {
            let responseData = await getDamageById(id);
            setdamagelistSingle(responseData && responseData.data ? responseData.data : null);
        }
        getDamageDetails();
    }, [id]);

    useEffect(() => {
        if (damagelistSingle && damagelistSingle._id && !damagelistSingle.is_repaired_yn) {
            setShowRepairedCheckbox(true);
        }

        if (damagelistSingle && damagelistSingle.damage_images) {
            const imagePaths = damagelistSingle.damage_images.map(image => (image.image_path && baseImgPath + '/' + image.image_path));
            setImages(imagePaths);
            setPreviewImage(imagePaths[0]);
        }
    }, [damagelistSingle]);

    const handleImageUpload = (index) => {
        setFromRepair(false);
        setShow(true);
        setCurrentIndex(index);
        currentIndexRef.current = index;
    };

    const removeImage = (index) => {
        const newImages = images.map((img, i) => (i === index ? null : img));
        const imgNotRemoved = (index === 1) ? newImages[0] : newImages[1];
        setImages(newImages);
        setPreviewImage(imgNotRemoved);
    };

    const removeRepaired = () => {
        setImageRepaired(null);
        setPreviewImage(null);
    }

    const handleRepairedImageUpload = () => {
        setFromRepair(true);
        setShow(true);
    };

    const plusIconStyle = {
        width: '60px',
        height: '60px',
        border: '2px solid black',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '24px',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        margin: '5px'
    };

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot({ width: 800, height: 570 });
        if (!fromRepair) {
            const newImages = [...images];
            newImages[currentIndexRef.current] = imageSrc;
            setImages(newImages);
        } else {
            setImageRepaired(imageSrc)
        }
        setPreviewImage(imageSrc);
        setShow(false)
    }, [webcamRef, images, fromRepair]);

    const formik = useFormik({
        initialValues: {
            damage_type: damagelistSingle && damagelistSingle.damage_type ? damagelistSingle.damage_type : '',
            comments: damagelistSingle && damagelistSingle.comments ? damagelistSingle.comments : '',
            damageAssumer: damagelistSingle && damagelistSingle.demage_assumer ? damagelistSingle.demage_assumer : '',
            isRepaired: false
        },
        onSubmit: async values => {
            let imagesRepaired = [];
            let damageId = damagelistSingle && damagelistSingle._id
            let filteredImages = images.filter(image => image !== null);
            if (imageRepaired) {
                imagesRepaired.push(imageRepaired)
            }
            let payload = {
                annotation_id: id,
                comments: values.comments,
                image_file: filteredImages,
                demage_assumer: values.damageAssumer,
                damage_type: values.damage_type
            }
            let repairPayload = {
                is_repaired_yn: true,
                image_file: imagesRepaired,
                annotation_id: id
            }
            let res = showRepairedCheckbox ? await repairDamageById(damageId, repairPayload) : await createDamgeImage(payload)
            if (res) {
                navigate(-1);
            }
        }
    })

    const handleDamageAssumerChange = (assumer) => {
        formik.setFieldValue('damageAssumer', assumer);
    };


    const videoConstraints = {
        facingMode: { exact: "environment" } // for back cam
        // facingMode: 'user' // for front cam
    };

    const handleRepairedChange = (checked) => {
        if (checked) {
            setShowConfirmation(true);
        } else {
            formik.setFieldValue('isRepaired', false);
        }
    };

    const handleConfirmation = (confirm) => {
        if (confirm) {
            formik.setFieldValue('isRepaired', true);
        }
        setShowConfirmation(false);
    };

    return (
        ((!damagelistSingle) || (damagelistSingle && damagelistSingle._id && !damagelistSingle.is_repaired_yn)) ? (<>
            <div className='card mb-3'>
                {/* <Modal.Header closeButton>
                <Modal.Title>Damage Report Form</Modal.Title>
            </Modal.Header> */}
                <div className='card-body'>
                    {
                        show ?
                            <>
                                <Webcam
                                    audio={false}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    className="webcam"
                                    height={570}
                                    screenshotQuality={1}
                                    style={{
                                        width: "100%",
                                        objectFit: 'cover'
                                    }}
                                    videoConstraints={videoConstraints}
                                />
                            </> :
                            <>
                                {
                                    previewImage ? (
                                    <div className='w-100' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '15px' }}>
                                        <img
                                            src={previewImage}
                                            style={{ maxWidth: '100%', maxHeighth: '100%' }}
                                            alt=''
                                        />
                                    </div> 
                                    ) : 
                                    <div className='w-100 h-25 d-flex justify-content-center align-content-center'>
                                        <img
                                            src={camera}
                                            style={{ maxWidth: '100%', maxHeighth: '100%' }}
                                            alt=''
                                        />
                                    </div>
                                }
                            </>
                    }
                    <div className="d-flex justify-content-center">
                        {show && <><BsApp size={'40'} onClick={capture} /> </>}
                    </div>


                    <Form onSubmit={formik.handleSubmit}>
                        {
                            showRepairedCheckbox ? <>
                                <div>
                                    <p className='fw-bold'>Type de Degat - {damagelistSingle && damagelistSingle.damage_type}</p>
                                </div>
                                <div className='d-flex-column'>
                                    <p className='fw-bold'>
                                        Commentaire
                                    </p>
                                    <p>
                                        {damagelistSingle && damagelistSingle.comments}
                                    </p>
                                </div>
                            </>
                                :
                                <>
                                    <Dropdown
                                        options={newExteriorParts}
                                        placeholder="Sélectionner la partie endommagé"
                                        className="w-full mb-3"
                                        value={formik.values.damage_type}
                                        onChange={formik.handleChange}
                                        name="damage_type"
                                    />

                                    <Form.Group controlId="commentaire" className="mb-3">
                                        <Form.Label>Commentaire</Form.Label>
                                        <Form.Control as="textarea" rows={3} placeholder="Commentaire" name="comments" value={formik.values.comments} onChange={formik.handleChange} />
                                    </Form.Group>
                                </>
                        }



                        <div className='d-flex w-100'>
                            <div className="d-flex w-50">
                                {images.map((image, index) => (
                                    <div key={index} style={{ position: 'relative', display: 'inline-block', marginRight: '5px' }}>
                                        {image ? (
                                            <>
                                                <img
                                                    src={image}
                                                    alt={`uploaded-${index}`}
                                                    style={{ width: '60px', height: '60px', objectFit: 'cover', borderRadius: '8px' }}
                                                    onClick={() => setPreviewImage(image)}
                                                />
                                                {/* <button
                                                    type="button"
                                                    onClick={() => removeImage(index)}
                                                    style={{
                                                        position: 'absolute',
                                                        top: '0',
                                                        right: '0',
                                                        backgroundColor: 'red',
                                                        color: 'white',
                                                        border: 'none',
                                                        borderRadius: '50%',
                                                        width: '20px',
                                                        height: '20px',
                                                        cursor: 'pointer',
                                                    }}
                                                >×</button> */}
                                            </>
                                        ) : (
                                            <>
                                                <label
                                                    htmlFor={`upload-button-${index}`}
                                                    className="d-flex justify-content-center align-items-center"
                                                    style={plusIconStyle}
                                                    onClick={() => handleImageUpload(index)}
                                                >
                                                    +
                                                </label>
                                            </>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <Form.Group className="w-50 d-flex justify-content-end">
                                {!showRepairedCheckbox && <>

                                    <div className='w-30' style={{
                                        marginRight: '7px'
                                    }}>
                                        <Form.Label style={{
                                            marginBottom: 'unset'
                                        }}>Turismo</Form.Label>
                                        <Form.Check type="checkbox" checked={formik.values.damageAssumer === 'Turismo'}
                                            onChange={() => handleDamageAssumerChange('Turismo')}
                                            name="damageAssumer"
                                            style={{
                                                fontSize: '35px',
                                                marginTop: 'unset'
                                            }} />
                                    </div>
                                    <div className='w-30'>
                                        <Form.Label style={{
                                            marginBottom: 'unset'
                                        }}>Client</Form.Label>
                                        <Form.Check type="checkbox" checked={formik.values.damageAssumer === 'Client'}
                                            onChange={() => handleDamageAssumerChange('Client')}
                                            name="damageAssumer"
                                            style={{
                                                fontSize: '35px',
                                                marginTop: 'unset'
                                            }} />
                                    </div>
                                </>}
                                <div style={{ position: 'relative', display: 'inline-block', marginRight: '5px' }}>
                                    {imageRepaired && formik.values.isRepaired ? (
                                        <>
                                            <img
                                                src={imageRepaired}
                                                alt={`uploaded`}
                                                style={{ width: '60px', height: '60px', objectFit: 'cover', borderRadius: '8px' }}
                                                onClick={() => setPreviewImage(imageRepaired)}
                                            />
                                            {/* <button
                                                type="button"
                                                onClick={() => removeRepaired()}
                                                style={{
                                                    position: 'absolute',
                                                    top: '0',
                                                    right: '0',
                                                    backgroundColor: 'red',
                                                    color: 'white',
                                                    border: 'none',
                                                    borderRadius: '50%',
                                                    width: '20px',
                                                    height: '20px',
                                                    cursor: 'pointer',
                                                }}
                                            >×</button> */}
                                        </>
                                    ) : (
                                        formik.values.isRepaired && <>
                                            <label
                                                htmlFor={`upload-button`}
                                                className="d-flex justify-content-center align-items-center"
                                                style={plusIconStyle}
                                                onClick={() => handleRepairedImageUpload()}
                                            >
                                                +
                                            </label>
                                        </>
                                    )}
                                </div>

                                {showRepairedCheckbox && (

                                    <div className='w-30'>
                                        <Form.Label style={{ marginBottom: 'unset' }}>Réparé</Form.Label>
                                        <Form.Check
                                            type="checkbox"
                                            checked={formik.values.isRepaired === true}
                                            onChange={(e) => handleRepairedChange(e.target.checked)}
                                            name="isRepaired"
                                            style={{ fontSize: '35px', marginTop: 'unset' }}
                                        />
                                    </div>
                                )}

                            </Form.Group>
                        </div>

                        <Button variant="primary" type="submit" className="w-100">
                            Valider
                        </Button>
                    </Form>

                    {showConfirmation && (
                        <Modal show={showConfirmation} onHide={() => handleConfirmation(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className='d-flex justify-content-center'>Etes vous sur que le dommage est réparé ?</p>

                                <div className='d-flex justify-content-center m-3'>
                                    <Button variant="primary" className='w-20 me-2' onClick={() => handleConfirmation(true)}>
                                        Oui
                                    </Button>
                                    <Button variant="outline-secondary" type="button" className="w-20" onClick={() => handleConfirmation(false)}>
                                        Non
                                    </Button>

                                </div>
                            </Modal.Body>

                        </Modal>
                    )}

                </div>
            </div>
        </>) : (
            <>
                <ul className="list-group d-flex">
                    <li className="list-group-item d-flex gap-2">
                        <img className='img-fluid imgInImageUploadComp' src={damagelistSingle && damagelistSingle.damage_images && damagelistSingle.damage_images.map((item) => item.image_path && (baseImgPath + '/' + item.image_path))} alt="..." />
                        <div className="mx-3">
                            <span className="badge text-bg-danger p-2">1 Rayure ajoute</span>
                            <dt class="col-sm-4 mt-3">Commentaire</dt>
                            <dd class="col-sm-8 mt-2 text-black-50">{damagelistSingle && damagelistSingle.comments}</dd>
                            {(!damagelistSingle) || (damagelistSingle && damagelistSingle._id && !damagelistSingle.is_repaired_yn)}
                        </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between gap-2">
                        <img className='imgInImageUploadComp border border-3 border-danger img-fluid' src={damagelistSingle && damagelistSingle.damage_images && damagelistSingle.damage_images.map((item) => item.image_path && (baseImgPath + '/' + item.image_path))} alt="..." />
                        <img className="imgInImageUploadComp img-fluid" src={damagelistSingle && damagelistSingle.damage_images && damagelistSingle.repaired_images.map((item) => item.image_path && (baseImgPath + '/' + item.image_path))} alt="..." style={{ border: '3px solid rgb(19, 235, 3)' }} />
                    </li>
                </ul>
                <button type="button" className={`btn btn-primary mt-5 w-100`}>Suivant</button>
            </>
        )


    );
}

export default DamageReportModal;
