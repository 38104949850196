/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Sidebar from "./Layout/Sidebar";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Home from "./Pages/Home";
import Navbar from "./Layout/Navbar";
import Settings from "./Pages/Settings";
import Login from "./authentication/Login";
import { ToastContainer } from "react-toastify";
import Fleet from "./Pages/Fleet";
import AddCompany from "./components/Companies/AddCompany";
import AddNewSubscription from "./components/Companies/AddNewSubscription";
import AccountSettings from "./components/Profile/AccountSettings";
import Calendar from "./Pages/Calendar";
import Invoice from "./Pages/Invoice";
import AddInvoice from "./components/Invoice/AddInvoice/AddInvoice";
import { InvoiceContextProvider } from "./components/Invoice/invoiceContext";
import EditeInvoice from "./components/Invoice/EditeInvoice/EditeInvoice";
import ReviewInvoice from "./components/Invoice/ReviewInvoice/ReviewInvoice";
import Checks from "./Pages/Checks";
import Deliveries from "./Pages/Deliveries";
import DeliveryIn from "./components/Delivery/DeliveryIn/DeliveryIn";
import DeliveryOut from "./components/Delivery/DeliveryOut/DeliveryOut";
import DeliveryOutList from "./components/Delivery/DeliveryOut/DeliveryOutList";
import DeliveryInList from "./components/Delivery/DeliveryIn/DeliveryInList";
import CheackIn from "./components/Checks/CheackIn";
import CheackOut from "./components/Checks/CheackOut";
import CheckList from "./components/Checks/CheckList";
import RoleList from "./Pages/RoleList";
import PermissionList from "./Pages/PermissionsList";
import Prospect from "./Pages/Prospect";
import Driver from "./Pages/Driver";
import DriverInfo from "./components/Driver/DriverInfo";
import DriverProfile from "./components/Driver/Pages/DriverProfile";
import DriverInvoices from "./components/Driver/Pages/DriverInvoices";
import Client from "./Pages/Client";
import ClientInfo from "./components/Client/ClientInfo";
import ClientProfile from "./components/Client/Pages/ClientProfile";
import ClientDocument from "./components/Client/Pages/ClientDocument";
import ClientInvoice from "./components/Client/Pages/ClientInvoice";
import ProspectInfo from "./components/Prospect/ProspectInfo";
import ProspectProfile from "./components/Prospect/Pages/ProspectProfile";
import FleetGeneral from "./components/Fleet/Editvehicle/Pages/FleetGeneral";
import FleetInfo from "./components/Fleet/Editvehicle/Pages/FleetInfo";
import FleetVisibilite from "./components/Fleet/Editvehicle/Pages/FleetVisibilite";
import FleetDocument from "./components/Fleet/Editvehicle/Pages/FleetDocument";
import DriverDocuments from "./components/Driver/Pages/DriverDocuments";
import Addvehicle from "./components/Fleet/AddFleet/Addvehicle";
import EditFleet from "./components/Fleet/Editvehicle/EditFleet";
import Team from "./Pages/teamProfile";
import TeamProfile from "./components/Profile/Team/Profile";
import CheckFinal from "./components/Checks/CheckFinal";
import AnnotateImage from "./components/Checks/AnnotateImage";
import DamageReportModal from "./components/Checks/ImageUpload";
import VehicleList from "./components/Prospect/Pages/VehicleList";
import TimeSlot from "./components/Prospect/Pages/TimeSlot";

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [istoggle, setToggle] = useState(false);
  const [isHover, setHover] = useState(false);
  const [iscollapsed, setcollapsed] = useState(true);
  const hidenav = ["checks/annotate"];
  const navCondition = location.pathname.includes(hidenav);
  const authToken = localStorage.getItem("auth_token");
  const valueSide = localStorage.getItem("sidebarfixed");

  useEffect(() => {
    const pathName = location.pathname.substring(1);
    const firstName = pathName.split(/[\/-]/)[0];
    document.title = `Turismo ${
      firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase()
    }`;
  }, [location]);

  const handleCollapsed = (boolean) => {
    setcollapsed(!iscollapsed);
    localStorage.setItem("sidebarfixed", !iscollapsed);
  };

  // authToken Start
  useEffect(() => {
    if (!authToken) {
      navigate("/login");
    }
  }, [authToken, navigate]);
  // authToken end

  useEffect(() => {
    if (valueSide === "true") {
      setcollapsed(true);
    } else {
      setcollapsed(false);
    }
  }, []);

  return (
    <div
      className={`light-style layout-navbar-fixed layout-menu-fixed layout-compact
       ${
         istoggle
           ? "layout-menu-expanded"
           : iscollapsed
           ? "layout-menu-collapsed"
           : ""
       }
       ${isHover ? "layout-menu-hover" : ""}`}
    >
      <div
        className={`layout-content-navbar ${
          location.pathname !== "/login" && "layout-wrapper"
        }`}
      >
        <div
          className={`${location.pathname !== "/login" && "layout-container"}`}
        >
          {location.pathname !== "/login" && (
            <Sidebar
              iscollapsed={iscollapsed}
              handleCollapsed={handleCollapsed}
              isHover={isHover}
              setToggle={setToggle}
              setHover={setHover}
            />
          )}
          <div
            className={`${
              location.pathname !== "/login"
                ? navCondition
                  ? "layout-page annotate-img"
                  : "layout-page"
                : ""
            }`}
          >
            {location.pathname !== "/login" && !navCondition && (
              <Navbar setToggle={setToggle} />
            )}
            <div
              className={`content-wrapper ${
                location.pathname === "/login" && "bg-white"
              }`}
            >
              <ToastContainer />

              <Routes>
                {/* Login page */}
                <Route path="/login" element={<Login />} />
                {/* Login page */}

                {/* pages start */}
                <Route path="/" element={<Home />} />

                {/* driver pages start  */}
                <Route path="/driver" element={<Driver />} />
                <Route path="/driver" element={<DriverInfo />}>
                  <Route path="profile/:id" element={<DriverProfile />} />
                  <Route path="document/:id" element={<DriverDocuments />} />
                  <Route path="invice/:id" element={<DriverInvoices />} />
                </Route>
                {/* driver pages end  */}

                {/* Settings page start  */}
                <Route path="/settings" element={<Settings />}>
                  <Route path="company" element={<AddCompany />} />
                  <Route path="subscription" element={<AddNewSubscription />} />
                </Route>
                {/* Settings page end */}
                {/* permissions pages start  */}
                {/* <Route path="/permissions" element={<Permissions />} >
                  <Route path="profile" element={<TeamProfile />} />
                </Route> */}
                {/* permissions page end */}

                {/* Profile pages start  */}
                <Route path="/team" element={<Team />}>
                  <Route path="profile" element={<TeamProfile />} />
                </Route>
                {/* Profile page end */}

                {/* fleet pages start  */}
                <Route path="/add-vehicle" element={<Addvehicle />} />
                <Route path="/fleet" element={<Fleet />} />
                <Route path="/fleet" element={<EditFleet />}>
                  <Route path="general/:id" element={<FleetGeneral />} />
                  <Route path="info/:id" element={<FleetInfo />} />
                  <Route path="visibilite/:id" element={<FleetVisibilite />} />
                  <Route path="document/:id" element={<FleetDocument />} />
                </Route>
                {/* fleet pages end  */}

                {/* fleet pages start  */}
                <Route path="/prospect" element={<Prospect />} />
                <Route path="/prospect" element={<ProspectInfo />}>
                  <Route path="document/:id" element={<ProspectProfile />} />
                  <Route path="vehicle/:id" element={<VehicleList />} />
                  <Route path="timeslot/:id" element={<TimeSlot />} />
                </Route>
                {/* fleet pages end  */}

                <Route path="/client" element={<Client />} />
                <Route path="/client" element={<ClientInfo />}>
                  <Route path="profile/:id" element={<ClientProfile />} />
                  <Route path="document/:id" element={<ClientDocument />} />
                  <Route path="invoice/:id" element={<ClientInvoice />} />
                </Route>

                <Route path="/rolelist" element={<RoleList />} />
                <Route path="/permissionlist" element={<PermissionList />} />
                <Route path="/account-settings" element={<AccountSettings />} />

                <Route path="/calendar" element={<Calendar />} />
                <Route path="/invoice" element={<Invoice />} />
                <Route path="/review-invoice/:id" element={<ReviewInvoice />} />
                <Route
                  path="/invoice-update/:id"
                  element={
                    <InvoiceContextProvider>
                      <EditeInvoice />
                    </InvoiceContextProvider>
                  }
                />
                <Route
                  path="/add-invoice"
                  element={
                    <InvoiceContextProvider>
                      <AddInvoice />
                    </InvoiceContextProvider>
                  }
                />
                <Route path="/checks" element={<Checks />}>
                  <Route path="checkList" element={<CheckList />} />
                  <Route
                    path="check-final/:id/:generalId/:resvStatus"
                    element={<CheckFinal />}
                  />
                  <Route
                    path="check-out/:id/:generalId"
                    element={<CheackOut />}
                  />
                  <Route path="check-in" element={<CheackIn />} />
                  <Route
                    path="annotate/:id/:generalId/:blocknumber/:blockName/:from"
                    element={<AnnotateImage />}
                  />
                  <Route
                    path="damage-report/:id"
                    element={<DamageReportModal />}
                  />
                </Route>

                <Route path="/deliveries/" element={<Deliveries />}>
                  <Route path="delivery-out" element={<DeliveryOutList />} />
                  <Route path="delivery-in" element={<DeliveryInList />} />
                </Route>
                <Route path="/edit-delivery-in/:id" element={<DeliveryIn />} />
                <Route
                  path="/edit-delivery-out/:id"
                  element={<DeliveryOut />}
                />

                {/* pages end */}
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
