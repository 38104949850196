/* eslint-disable react-hooks/exhaustive-deps */
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchCustomerData } from "../../../Redux/clientsSlice";
import pencilIcon from "../../../assets/icons/pencil.png";
import { updataClientApi } from "../../../services/client/addclientApi";
import { SmallLoader } from "../../Common/Loader";
import PhoneInput from "react-phone-input-2";
import { roleClasses } from "../../Common/roles";
import CustomerActivationModale from "../../Common/CustomertActivationModale";
import ValidateClientModal from "../Modals/ValidateClientModal";
import { Dropdown } from "primereact/dropdown";
import AddressAutocompleteInput from "../../Common/AddressAutocompleteInput";
import AutoResizingTextArea from "../../Common/AutoResizingTextArea";

const PrivateDetails = ({
  customerData,
  setLoding,
  id,
  singleClientValue,
  setEdit,
  isEdit,
  isLoding,
}) => {
  const dispatch = useDispatch();
  const [isModelShow, setModelShow] = useState(false);
  const [validModalShow, setValidModalShow] = useState(false);
  const {
    houseno,
    address,
    contact,
    invoice_email,
    email,
    lastname,
    firstname,
    gender,
    language,
  } = customerData?.customer || {};

  const handleModal = () => {
    setValidModalShow(true);
  };

  return (
    <div className="card mb-4">
      <div className="card-body">
        <Formik
          initialValues={{
            firstname: firstname || "",
            lastname: lastname || "",
            email: email || "",
            buildingNumber: houseno || "",
            address: `${address}` || "",
            language: language || "",
            gender: gender || "",
            contact: `+${contact || ""}`,
            invoice_email: invoice_email || "",
          }}
          enableReinitialize={true}
          onSubmit={async (values) => {
            setLoding(true);
            const formData = new FormData();
            formData.append("firstname", values.firstname);
            formData.append("lastname", values.lastname);
            formData.append("email", values.email);
            formData.append("houseno", values.buildingNumber);
            formData.append("address", values.address);
            formData.append("contact", values.contact);
            formData.append("language", values.language);
            formData.append("gender", values.gender);
            formData.append("invoice_email", values.invoice_email);
            const data = await updataClientApi(id, formData);

            if (data) {
              setEdit(true);
              setLoding(false);
              dispatch(fetchCustomerData(id));
            }
          }}
        >
          {({ values, setFieldValue, handleChange, handleSubmit }) => (
            <Form>
              <div className="d-flex justify-content-between position-relative">
                <div className="position-absolute end-0 top-0">
                  {isEdit && (
                    <button
                      className="btn waves-effect waves-light p-0 align-items-start"
                      onClick={() => {
                        setEdit(false);
                      }}
                    >
                      <img src={pencilIcon} alt="pencil-icon" />
                    </button>
                  )}
                </div>
                <div className="mb-4">
                  <div className="d-flex gap-2">
                    {!isEdit ? (
                      <>
                        <h4>
                          <Field
                            readOnly={isEdit}
                            name="firstname"
                            placeholder="-"
                            className={` ${
                              isEdit ? "hide-input" : "form-control"
                            }`}
                          />
                        </h4>
                        <h4>
                          <Field
                            readOnly={isEdit}
                            name="lastname"
                            placeholder="-"
                            className={` ${
                              isEdit ? "hide-input" : "form-control"
                            }`}
                          />
                        </h4>

                        <div>
                          {!isEdit && (
                            <button
                              type="button"
                              onClick={handleSubmit}
                              className="btn btn-primary waves-effect waves-light ms-3"
                            >
                              Save
                              {isLoding && <SmallLoader />}
                            </button>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <h6 className="card-text my-auto">
                          {customerData?.customer?.firstname}{" "}
                          {customerData?.customer?.lastname}{" "}
                        </h6>

                        <div className="d-flex justify-content-center align-items-center gap-2">
                          <td className="text-nowrap">
                            {customerData?.customer?.role?.length > 1 ? (
                              customerData?.customer?.role?.map((item) => (
                                <span
                                  className={`me-1 btn waves-effect py-1 ${
                                    item?.charAt(0).toUpperCase() === "C"
                                      ? "btn-label-primary"
                                      : "btn-label-success"
                                  }`}
                                >
                                  {item?.charAt(0).toUpperCase()}
                                </span>
                              ))
                            ) : (
                              <span
                                className={`${
                                  roleClasses[customerData?.customer?.role?.[0]]
                                }`}
                              >
                                {customerData?.customer?.role?.[0]}
                              </span>
                            )}
                          </td>
                          {customerData?.customer?.role?.length === 1 && (
                            <div
                              className="bg-success rounded badge py-2 fs-7 cursor-pointer "
                              onClick={() => handleModal()}
                            >
                              Validate
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <small className="card-text text-uppercase">CLIENT DETAIL</small>

              <ul className="list-unstyled mb-0 mt-2">
                <li className="d-flex align-items-center mb-3 mt-2">
                  <span className="fw-medium mx-2 text-nowrap">Email:</span>
                  <Field
                    readOnly
                    type="text"
                    name="email"
                    className={` ${isEdit ? "hide-input" : "form-control"}`}
                    placeholder="email"
                  />

                  {customerData?.customer?.active === false && (
                    <btn
                      className="text-primary text-decoration-underline cursor-pointer"
                      onClick={(e) => {
                        setModelShow(true);
                      }}
                    >
                      Activate
                    </btn>
                  )}
                </li>

                <li className="d-flex align-items-center mb-3 mt-2">
                  <span className="fw-medium mx-2 text-nowrap">Contact:</span>
                  <PhoneInput
                    disabled={isEdit}
                    country={"Luxembourg"}
                    enableSearch={true}
                    className={`${
                      !isEdit ? "form-control flex-grow-1 " : "hide-input"
                    }`}
                    placeholder="+352 369 852 472"
                    value={values?.contact || "+352"}
                    onChange={(value) => setFieldValue("contact", value)}
                  />
                </li>
                <hr />
                {isEdit ? (
                  <li className="d-flex align-items-start mb-3">
                    <span className="fw-medium mx-2 text-heading text-nowrap">
                      Address:
                    </span>

                    <AutoResizingTextArea
                      value={values.address}
                      name="address"
                      id="address"
                      className="w-100 hide-input resize-none"
                    />
                  </li>
                ) : (
                  <li className="d-flex align-items-center mb-3">
                    <span className="fw-medium mx-2 text-heading text-nowrap">
                      Address:
                    </span>
                    <AddressAutocompleteInput
                      name="address"
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </li>
                )}
                <li className="d-flex align-items-center mb-3">
                  <span className="fw-medium mx-2 text-nowrap  text-nowrap">
                    Street/Building No:
                  </span>
                  <input
                    type="text"
                    id="add-user-contact"
                    autoComplete="off"
                    className={`${isEdit ? "hide-input" : "form-control"}`}
                    placeholder="Street/Building Number"
                    name="buildingNumber"
                    value={values.buildingNumber}
                    onChange={handleChange}
                  />
                </li>
                <hr />
                <small className="card-text text-uppercase">CONTACTS</small>
                <li className="d-flex align-items-center mb-3 mt-2">
                  <span className="fw-medium mx-2 text-nowrap">Languages:</span>
                  <Field
                    readOnly={isEdit}
                    type="text"
                    name="language"
                    className={`${isEdit ? "hide-input" : "form-control"}`}
                    placeholder="-"
                  />
                </li>
                <li className="d-flex align-items-center mb-3 mt-2">
                  <span className="fw-medium mx-2 text-nowrap">Gender:</span>
                  <Dropdown
                    disabled={isEdit}
                    options={["Male", "Female", "Other"]}
                    placeholder="Gender"
                    name="gender"
                    value={values.gender}
                    className={`w-full ${
                      isEdit ? "p-dropdown-hide" : "p-dropdown"
                    }`}
                    onChange={handleChange}
                  />
                </li>
                <li className="d-flex align-items-center mb-3 mt-2">
                  <span className="fw-medium mx-2 text-nowrap">
                    Invoice Email:
                  </span>
                  <Field
                    readOnly={isEdit}
                    type="text"
                    name="invoice_email"
                    className={` ${isEdit ? "hide-input" : "form-control"}`}
                    placeholder="-"
                  />
                </li>
              </ul>

              {isModelShow && (
                <CustomerActivationModale
                  show={isModelShow}
                  onHide={() => setModelShow(false)}
                  title={"are you sure you want ot send active link"}
                  id={id}
                />
              )}
              {validModalShow && (
                <ValidateClientModal
                  customerData={customerData}
                  show={validModalShow}
                  id={id}
                  onHide={() => setValidModalShow(false)}
                />
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PrivateDetails;
