import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { addClientApi } from "../../services/client/addclientApi";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { Offcanvas } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { fetchAllUserList } from "../../Redux/commonSlice";

const AddProspect = (props) => {
  const { show, handleClose, onHide } = props;
  const [isLoding, setLoding] = useState(null);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastname: "",
      email: "",
      contact: "",
      clientType: "BASIC",
      customerType: "",
      vatNumber: "",
      address: "",
      comp_id: "",
      subscription: "",
    },
    onSubmit: async (values, { resetForm }) => {
      setLoding(true);
      const formData = new FormData();
      if (values.firstName) {
        formData.append("firstname", values.firstName);
      }
      if (values.lastname) {
        formData.append("lastname", values.lastname);
      }
      if (values.email) {
        formData.append("email", values.email);
      }
      if (values.contact) {
        formData.append("contact", values.contact);
      }
      const tempData = await addClientApi(formData);

      if (tempData?.status) {
        resetForm();
        onHide();
      }
      setLoding(false);
      dispatch(fetchAllUserList());
    },

    validate: (values) => {
      const errors = {};
      // Add validation rules here
      if (!values.firstName.trim()) {
        errors.firstName = "This field is required.";
      }
      if (!values.lastname.trim()) {
        errors.lastname = "This field is required.";
      }
      if (!values.email.trim()) {
        errors.email = "This field is required.";
      }
      if (!values.contact?.toString().trim()) {
        errors.contact = "This field is required.";
      }

      // Add validation for other fields
      return errors;
    },
  });

  useEffect(() => {
    formik.resetForm();
  }, [show]);

  return (
    <>
      <Offcanvas show={show} onHide={handleClose} placement="end" {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Ajouter un Prospect</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="offcanvas-body mx-0 flex-grow-0 pt-0 h-100">
          <form
            className="add-new-user pt-0"
            id="addNewUserForm"
            onSubmit={formik.handleSubmit}
          >
            <div className="mb-3 d-flex gap-3">
              <div>
                <label className="form-label" htmlFor="add-user-fullname">
                  Prénom
                </label>
                <input
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  type="text"
                  className={`form-control ${
                    formik.errors.firstName ? "form-control-invalid" : ""
                  }`}
                  id="add-user-fullname"
                  placeholder="Prénom"
                  name="firstName"
                  aria-label="John"
                />
                {formik.errors.firstName && (
                  <div className="invalid-feedback">
                    {formik.errors.firstName}
                  </div>
                )}
              </div>
              <div className="">
                <label className="form-label" htmlFor="add-user-email">
                  Nom de famille
                </label>
                <input
                  value={formik.values.lastname}
                  onChange={formik.handleChange}
                  type="text"
                  placeholder="Nom de famille"
                  aria-label="doe"
                  name="lastname"
                  className={`form-control ${
                    formik.errors.lastname ? "form-control-invalid" : ""
                  }`}
                  id="add-user-lastname"
                />
                {formik.errors.lastname && (
                  <div className="invalid-feedback">
                    {formik.errors.lastname}
                  </div>
                )}
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label" htmlFor="add-user-email">
                E-mail
              </label>
              <input
                type="email"
                placeholder="@turismo.com"
                name="email"
                value={formik.values.email}
                onChange={(e) => {
                  const lowerCaseEmail = e.target.value.toLowerCase();
                  formik.setFieldValue("email", lowerCaseEmail);
                }}
                className={`form-control ${
                  formik.errors.email ? "form-control-invalid" : ""
                }`}
              />
              {formik.errors.email && (
                <div className="invalid-feedback">{formik.errors.email}</div>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="add-user-email">
                Contact
              </label>
              <PhoneInput
                country={"Luxembourg"}
                enableSearch={true}
                className={`form-control ${
                  formik.errors.contact ? "form-control-invalid" : ""
                }`}
                placeholder="+352 369 852 472"
                value={"+352" || formik.values.contact}
                onChange={(value) => formik.setFieldValue("contact", value)}
              />

              {formik.errors.contact && (
                <div className="invalid-feedback">{formik.errors.contact}</div>
              )}
            </div>

            <button
              type="submit"
              className="btn btn-primary me-sm-3 me-1 data-submit"
            >
              {isLoding && (
                <span
                  class="spinner-border text-white me-2"
                  role="status"
                ></span>
              )}
              Ajouter
            </button>
            <button
              type="reset"
              className="btn btn-label-secondary"
              onClick={() => onHide()}
            >
              Annuler
            </button>
          </form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default AddProspect;
