/* eslint-disable react-hooks/exhaustive-deps */
import { ErrorMessage, Field } from "formik";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import {
  Active,
  License,
  LocationTypeDropdown,
  Status,
  Year,
  Color,
} from "../../../Common/DropdownValue";
import AddManufacturerModal from "../../Modal/AddManufacturerModal";
import { useDispatch, useSelector } from "react-redux";
import AddModel from "../../Modal/AddModel";
import {
  fetchManufacturerDropdown,
  fetchModalAllValue,
  fetchModalDropdown,
} from "../../../../Redux/vehicleSlice";
import { colorTemplate } from "../../../Common/colorTemplate";
import {
  deleteManufacturer,
  deleteModel,
} from "../../../../services/AddVehicle";
import DeleteConfirmationModal from "../../../Common/DeleteConfirmationModal";
import EditModel from "../../Editvehicle/Modal/EditModel";
import EditManufacturer from "../../Editvehicle/Modal/EditManufacturer";

const General = ({ handleChange, values, errors }) => {
  const dispatch = useDispatch();
  const [isId, setId] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [modulEdit, setModuleEdit] = useState(false);
  const [isManufacturerId, setManufacturerId] = useState("");
  const [modelName, setModelName] = useState("");
  const [ismanufacturerId, setmanufacturerId] = useState("");
  const [ismanufacturerEdit, setManufacturerEdit] = useState(false);
  const [manufacturerModal, setManufacturerModal] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const { companyDropdown } = useSelector((state) => state?.commonSlice);
  const { manufacturersDropdown, modalDropdown } = useSelector(
    (state) => state.vehicleSlice
  );
  const { vehicleCategoryDropdownList } = useSelector(
    (state) => state.commonSlice
  );

  useEffect(() => {
    if (values.general?.manufacturer?._id) {
      dispatch(fetchModalDropdown(values.general?.manufacturer?._id));
    }
  }, [values.general?.manufacturer?._id]);

  useEffect(() => {
    dispatch(fetchModalAllValue());
  }, []);

  const deleteModule = async (id) => {
    await deleteModel(id);
    setModalShow(false);
    if (values.general?.manufacturer?._id) {
      dispatch(fetchModalDropdown(values.general?.manufacturer?._id));
      console.log("single");
    } else {
      dispatch(fetchModalAllValue());
      console.log("multiple");
    }
  };

  const deleteManufacturerdata = async (id) => {
    await deleteManufacturer(id);
    setModalShow(false);
    dispatch(fetchManufacturerDropdown());
  };

  const modelTemplete = (option) => {
    return (
      <div className="color-option d-flex justify-content-between w-100">
        <div className="color-label">{option.name}</div>

        <div className="d-flex position-absolute" style={{ right: "0" }}>
          <i
            className="mx-2 ti ti-edit ti-sm "
            onClick={(e) => {
              setId(option._id);
              setModuleEdit(true);
              setManufacturerId(option.manufacturer);
              setModelName(option.name);
            }}
          ></i>

          <i
            className="mx-2 ti ti-sm ti-trash"
            onClick={(e) => {
              setModalShow(true);
              setId(option._id);
            }}
          ></i>
        </div>
      </div>
    );
  };

  const manufacturerTemplete = (option) => {
    return (
      <div className="color-option d-flex justify-content-between">
        <div className="color-label w-100">{option.name}</div>

        <div className="d-flex position-absolute" style={{ right: "0" }}>
          <i
            className="mx-2 ti ti-edit ti-sm "
            onClick={(e) => {
              setmanufacturerId(option._id);
              setManufacturerEdit(true);
              setModelName(option.name);
            }}
          ></i>

          <i
            className="mx-2 ti ti-sm ti-trash"
            onClick={(e) => {
              setModalShow(true);
              setmanufacturerId(option._id);
            }}
          ></i>
        </div>
      </div>
    );
  };

  return (
    <div className="card mb-4">
      <div className="card-header">
        <h5 className="card-tile mb-0">Général</h5>
      </div>
      <div className="card-body">
        <div className="col mb-3">
          <label className="form-label mb-1">Société</label>
          <Dropdown
            value={values?.general?.company}
            onChange={handleChange}
            options={companyDropdown}
            optionLabel="name"
            name="general.company"
            placeholder="Sélectionner"
            className={`w-full ${
              errors?.general?.company && "dropdown-invalid"
            }`}
          />
          <ErrorMessage
            name="general.company"
            component="div"
            className="text-danger ps-2"
          />
        </div>
        <div className="col mb-3">
          <label
            className="form-label mb-1 d-flex justify-content-between align-items-center"
            htmlFor="category-org"
          >
            <span>Fabriquant </span>
            <span
              className="fw-medium text-primary cursor-pointer"
              onClick={() => setManufacturerModal(true)}
            >
              Ajouter un Fabricant
            </span>
          </label>
          <Dropdown
            value={values?.general?.manufacturer}
            onChange={handleChange}
            // options={manufacturersDropdown}
            options={manufacturersDropdown?.map((item) => ({
              name: item?.name,
              _id: item?._id,
            }))}
            itemTemplate={manufacturerTemplete}
            name="general.manufacturer"
            optionLabel="name"
            placeholder="Sélectionner"
            className={`w-full ${
              errors?.general?.manufacturer && "dropdown-invalid"
            }`}
          />
          <ErrorMessage
            name="general.manufacturer"
            component="div"
            className="text-danger ps-2"
          />
        </div>
        <div className="col mb-3">
          <label
            className="form-label mb-1 d-flex justify-content-between align-items-center"
            htmlFor="category-org"
          >
            <span>Modéle</span>
            <span
              className="fw-medium text-primary cursor-pointer"
              onClick={() => setAddModel(true)}
            >
              Ajouter un Modéle
            </span>
          </label>
          <Dropdown
            value={values?.general?.model}
            onChange={handleChange}
            // options={modalDropdown}
            options={modalDropdown?.map((item) => ({
              name: item?.name,
              manufacturer: item?.manufacturer,
              _id: item?._id,
            }))}
            itemTemplate={modelTemplete}
            name="general.model"
            optionLabel="name"
            placeholder="Sélectionner"
            className={`w-full ${errors?.general?.model && "dropdown-invalid"}`}
          />
          <ErrorMessage
            name="general.model"
            component="div"
            className="text-danger ps-2"
          />
        </div>
        <div className="row mb-3">
          <div className="col">
            <label
              className="form-label mb-1 d-flex justify-content-between align-items-center"
              htmlFor="category-org"
            >
              Catégorie du véhicule
            </label>
            <Dropdown
              value={values.general?.vehicle_category}
              onChange={handleChange}
              name="general.vehicle_category"
              options={vehicleCategoryDropdownList}
              optionLabel="name"
              placeholder="Sélectionner"
              // className="w-full"
              className={`w-full ${
                errors?.general?.vehicle_category && "dropdown-invalid"
              }`}
            />
            <ErrorMessage
              name="general.vehicle_category"
              component="div"
              className="text-danger ps-2"
            />
          </div>
          <div className="col">
            <label
              className="form-label mb-1 d-flex justify-content-between align-items-center"
              htmlFor="category-org"
            >
              Déclinaison
            </label>

            <div className="col">
              <Field
                value={values.general?.declination}
                name="general.declination"
                type="text"
                className="form-control"
                placeholder="Déclinaison"
                aria-controls="DataTables_Table_0"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="col mb-3">
          <label
            className="form-label mb-1 d-flex justify-content-between align-items-center"
            htmlFor="category-org"
          >
            Localisation du véhicule
          </label>
          <Dropdown
            value={values?.general?.vehicle_location}
            options={LocationTypeDropdown}
            placeholder="Localisation"
            className={`w-full ${
              errors?.general?.vehicle_location && "dropdown-invalid"
            }`}
            name="general.vehicle_location"
            onChange={handleChange}
          />
          <ErrorMessage
            name="general.vehicle_location"
            component="div"
            className="text-danger ps-2"
          />
        </div>

        <div className="row mb-3">
          <div className="col">
            <label
              className="form-label mb-1 d-flex justify-content-between align-items-center"
              htmlFor="category-org"
            >
              Année
            </label>
            <Dropdown
              value={values.general.year}
              onChange={handleChange}
              options={Year}
              name="general.year"
              filter
              placeholder="Année"
              className="w-full"
            />
          </div>
          <div className="col">
            <label
              className="form-label mb-1 d-flex justify-content-between align-items-center"
              htmlFor="category-org"
            >
              Couleur
            </label>
            <Dropdown
              placeholder="Blanc"
              valueTemplate={colorTemplate(values.general.color)}
              onChange={handleChange}
              options={Color.map((color) => ({
                name: color.name,
                image: color.image,
              }))}
              itemTemplate={colorTemplate}
              optionLabel="name"
              name="general.color"
              filter
              className="w-full"
            />
          </div>
        </div>
        <div className="col mb-3">
          <label
            className="form-label mb-1 d-flex justify-content-between align-items-center"
            htmlFor="category-org"
          >
            Status
          </label>
          <Dropdown
            value={values.general.status}
            onChange={handleChange}
            options={Status}
            name="general.status"
            placeholder="Sélectionner"
            className="w-full"
          />
        </div>
        <div className="col mb-3">
          <label
            className="form-label mb-1 d-flex justify-content-between align-items-center"
            htmlFor="category-org"
          >
            Activité
          </label>
          <Dropdown
            value={values.general.active}
            onChange={handleChange}
            options={Active}
            name="general.active"
            placeholder="Sélectionner"
            className="w-full"
          />
        </div>
        <div className="row mb-3">
          <div className="col">
            <label
              className="form-label mb-1 d-flex justify-content-between align-items-center"
              htmlFor="Age min du Conducteur"
            >
              Age min du Conducteur
            </label>
            <Field
              name="general.min_age_driver"
              type="number"
              className="form-control"
              placeholder="Age"
              aria-controls="DataTables_Table_0"
            />
          </div>
          <div className="col">
            <label
              className="form-label mb-1 d-flex justify-content-between align-items-center"
              htmlFor="Classe du Permis"
            >
              Classe du Permis
            </label>
            <Dropdown
              value={values.general.license_class}
              onChange={handleChange}
              options={License}
              name="general.license_class"
              placeholder="B"
              className="w-full"
            />
          </div>
        </div>
        {/* AddManufacturerModal*/}
        <AddManufacturerModal
          show={manufacturerModal}
          onHide={() => setManufacturerModal(false)}
        />
        {/* AddModel  */}
        <AddModel show={addModel} onHide={() => setAddModel(false)} />

        {isId && (
          <DeleteConfirmationModal
            title={"Supprimer le modèle"}
            description={"Es-tu sûr de supprimer ce modèle ?"}
            // show={isId}
            show={modalShow}
            // onHide={() => setId("")}
            onHide={() => setModalShow(false)}
            deleteData={() => deleteModule(isId)}
          />
        )}

        {modulEdit && (
          <EditModel
            id={isId}
            modelName={modelName}
            isManufacturerId={isManufacturerId}
            show={modulEdit}
            onHide={() => setModuleEdit(false)}
            values={values}
          />
        )}

        {ismanufacturerId && (
          <DeleteConfirmationModal
            title={"Supprimer le fabricant"}
            description={"Êtes-vous sûr de supprimer ce fabricant ?"}
            // show={isId}
            show={modalShow}
            // onHide={() => setId("")}
            onHide={() => setModalShow(false)}
            deleteData={() => deleteManufacturerdata(ismanufacturerId)}
          />
        )}
        {ismanufacturerEdit && (
          <EditManufacturer
            id={ismanufacturerId}
            modelName={modelName}
            show={ismanufacturerEdit}
            onHide={() => setManufacturerEdit(false)}
          />
        )}
      </div>
    </div>
  );
};

export default General;
