import React, { useEffect } from "react";
import { useState } from "react";
import { ListGroup } from "react-bootstrap";
import { getVehicleDeliveryInfo } from "../../../../services/Deliveries";

const VehicleInformation = ({ stepDetail, selectedResv, getVehicleData, setValue, value, setNewKmValue, newKmValue, setIsclinet, setSignatureValue }) => {

  const manufacturer = selectedResv?.manufacturer;
  const model = selectedResv?.model;
  const license_plate = selectedResv?.license_plate;
  let id = selectedResv && selectedResv._id ? selectedResv._id : null;
  let generalId = selectedResv && selectedResv.generalId ? selectedResv.generalId : null;
  let ststus = selectedResv && selectedResv.reservation_status ? selectedResv.reservation_status : null;

  let [oldKmValue, setOldKmValue] = useState(null);
  let [fuelOld, setFuelold] = useState(null);

  const handleSliderChange = (event) => {
    setValue(event.target.value);
  };


  const handleKmChange = (event) => {
    setNewKmValue(event.target.value);
  };

  useEffect(() => {
    getDeliveryInfo();
  }, [selectedResv])

  getVehicleData(manufacturer, model)

  const getDeliveryInfo = async () => {
    let type = ststus && (ststus === 'ADDRESSVALID' || ststus === 'DELIVERYOUT' || ststus ===  'ADDRESSNOTVALID') ? 'IN' : 'OUT'
    let payload = {
      // 'reservation_id': id,
      'general_id': generalId,
      'delivery_type': type,
      'is_latest_yn': true
    }
    let res = await getVehicleDeliveryInfo(payload);
    if (res && res.data) {
      let oldValue = res.data && res.data.deliveryInfo && res.data.deliveryInfo.length && res.data.deliveryInfo[0] ? res.data.deliveryInfo[0] : null;
      setOldKmValue(oldValue.kilometer ? oldValue.kilometer : null);
      setFuelold(oldValue.fuel_level ? oldValue.fuel_level : 0);
      setSignatureValue(oldValue.signature ? oldValue.signature : "");
      setIsclinet(oldValue.is_client_present_yn ? oldValue.is_client_present_yn : false)
    }
  }

  return (
    <ListGroup>
      <ListGroup.Item className="text-center py-1">
        <p className="fw-semibold fs-6 mb-0">Vehicule</p>
        <p className="mb-0 text-wrap">
          {manufacturer && manufacturer} {model}
        </p>
        <p className="mb-0 text-wrap">{license_plate}</p>
      </ListGroup.Item>
      <ListGroup.Item className="text-center py-1">
        <p className="fw-semibold fs-6 mb-0 ">Kilometrage</p>
        <p className="mb-0 text-wrap fw-semibold">
          Ancien : <span className="fw-normal">{oldKmValue ? oldKmValue + ' km' : '-'}</span>
        </p>
        <p className="mb-0 text-wrap fw-semibold">
          Actuel :
          <input type="text" id="new-value"
            name="newValue" style={{
              border: 'none',
              borderBottom: '1px solid black',
              outline: 'none',
              borderRadius: 'unset'
            }}
            value={newKmValue}
            onChange={handleKmChange}
          />

        </p>
      </ListGroup.Item>
      <ListGroup.Item className="text-center py-1">
        <p className="fw-semibold fs-6 mb-0 ">Niveau d’essence</p>
        <p className="mb-0 text-wrap fw-semibold">
          Ancien : <span className="fw-normal">{fuelOld ? fuelOld : '-'}</span>
        </p>
        <p className="mb-0 text-wrap fw-semibold">
          Nouveau :{" "}
          <span
            className="border-0"
            style={{
              background: "#4B465C29",
              padding: "0.1rem 0.2rem",
              borderRadius: "4px",
            }}
          >
            {value}%
          </span>
        </p>

        <div className="">
          <div className="wrapper">
            <div className="input-wrapper">
              <input
                className="inputRangeForFuel"
                type="range"
                min="1"
                max="100"
                value={value}
                id="myRange"
                onChange={handleSliderChange}
              />

              <div className="dataListContainer">
                <datalist className="datalistDiv">
                  <option>0</option>
                  <option>10</option>
                  <option>20</option>
                  <option>30</option>
                  <option>40</option>
                  <option>50</option>
                  <option>60</option>
                  <option>70</option>
                  <option>80</option>
                  <option>90</option>
                  <option>100</option>
                </datalist>
              </div>
            </div>
          </div>
        </div>
      </ListGroup.Item>
    </ListGroup>
  );
};

export default VehicleInformation;
