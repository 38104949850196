/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { fetchSingleVisibilite } from "../../../../Redux/vehicleSlice";
import { useDispatch } from "react-redux";
import VisibiliteInformation from "../Components/Visibilite/VisibiliteInformation";
import OptionList from "../Components/Visibilite/OptionList";
import VisualSupport from "../Components/Visibilite/VisualSupport";
import { useParams } from "react-router-dom";

const FleetVisibilite = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSingleVisibilite(id));
  }, [id]);
  return (
    <div>
      <VisibiliteInformation />
      <OptionList />
      <VisualSupport />
    </div>
  );
};

export default FleetVisibilite;
