import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addOptionlist } from "../../../../../services/AddVehicle";
import { SmallLoader } from "../../../../Common/Loader";
import { fetchSingleVisibilite } from "../../../../../Redux/vehicleSlice";
import { useParams } from "react-router-dom";

const OptionList = () => {
  const { id } = useParams();
  const [editOption, setEditOption] = useState(true);
  const [isLoding, setLoding] = useState(false);
  const [checked, setLChecked] = useState(false);
  const { singleVisibiliteData } = useSelector((state) => state.vehicleSlice);
  const dispatch = useDispatch();
  const handleChecked = () => {
    setLChecked((prev) => !prev);
  };

  const [optionListValues, setOptionListValues] = useState({
    Option_Interior: "",
    Option_Exterieur: "",
    Option_Confort: "",
    description: "",
  });

  useEffect(() => {
    setOptionListValues({
      Option_Interior: checked
        ? singleVisibiliteData?.optionListData?.Option_Interior?.EN
          ? singleVisibiliteData?.optionListData?.Option_Interior?.EN
          : ""
        : singleVisibiliteData?.optionListData?.Option_Interior?.FR
        ? singleVisibiliteData?.optionListData?.Option_Interior?.FR
        : "",
      description: checked
        ? singleVisibiliteData?.optionListData?.description?.EN
          ? singleVisibiliteData?.optionListData?.description?.EN
          : ""
        : singleVisibiliteData?.optionListData?.description?.FR
        ? singleVisibiliteData?.optionListData?.description?.FR
        : "",
      Option_Confort: checked
        ? singleVisibiliteData?.optionListData?.Option_Confort?.EN
          ? singleVisibiliteData?.optionListData?.Option_Confort?.EN
          : ""
        : singleVisibiliteData?.optionListData?.Option_Confort?.FR
        ? singleVisibiliteData?.optionListData?.Option_Confort?.FR
        : "",
      Option_Exterieur: checked
        ? singleVisibiliteData?.optionListData?.Option_Exterieur?.EN
          ? singleVisibiliteData?.optionListData?.Option_Exterieur?.EN
          : ""
        : singleVisibiliteData?.optionListData?.Option_Exterieur?.FR
        ? singleVisibiliteData?.optionListData?.Option_Exterieur?.FR
        : "",
    });
  }, [singleVisibiliteData?.optionListData, checked]);

  return (
    <Formik
      initialValues={optionListValues}
      enableReinitialize={true}
      onChange={(values) => console.log(values, "values")}
      onSubmit={async (values) => {
        setLoding(true);
        const tempData = {
          ...singleVisibiliteData,
          optionListData: {
            general_info: id,
            ...values,
            lang: checked ? "EN" : "FR",
          },
        };
        const data = await addOptionlist(tempData);
        if (data) {
          setEditOption(true);
          setLoding(false);
          dispatch(fetchSingleVisibilite(id));
        }
      }}
    >
      {({ values, handleChange }) => (
        <Form className="card info mb-4">
          <div className="card-header row">
            <h5 className="col-md-6 card-tile mb-0">Liste d'options</h5>
            <div className="col-md-6 d-flex justify-content-end align-items-center">
              <label
                className="switch switch-lg"
                style={{ marginRight: "5rem" }}
              >
                <input
                  readOnly
                  checked={checked}
                  type="checkbox"
                  className="switch-input"
                  onChange={() => handleChecked()}
                  name="lang"
                  disabled={!editOption}
                />
                <span className="switch-toggle-slider">
                  <span className="switch-on">
                    <div className="en-btn">En</div>
                  </span>
                  <span className="switch-off">
                    <div className="fr-btn">Fr</div>
                  </span>
                </span>
              </label>
              {editOption ? (
                <div
                  onClick={() => setEditOption(false)}
                  className="btn btn-primary waves-effect waves-light"
                >
                  Modifier {isLoding && <SmallLoader />}
                </div>
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary waves-effect waves-light"
                >
                  Sauvegarder {isLoding && <SmallLoader />}
                </button>
              )}
            </div>
          </div>

          <div className="card-body">
            <h6>Description</h6>
            <textarea
              rows={3}
              readOnly={editOption}
              value={values.description}
              onChange={handleChange}
              name="description"
              placeholder="Description"
              className={`mb-4 ${
                editOption ? "hide-input text-lighter " : "form-control"
              }`}
            />
            <h6>Options intérieures</h6>
            <textarea
              rows={3}
              readOnly={editOption}
              value={values.Option_Interior}
              onChange={handleChange}
              name="Option_Interior"
              placeholder="Option Interior"
              className={`mb-4 ${
                editOption ? "hide-input text-lighter " : "form-control"
              }`}
            />
            <h6>Options extérieurs</h6>
            <textarea
              rows={3}
              readOnly={editOption}
              value={values.Option_Exterieur}
              onChange={handleChange}
              name="Option_Exterieur"
              placeholder="Option Interior"
              className={`mb-4 ${
                editOption ? "hide-input text-lighter" : "form-control"
              }`}
            />
            <h6>Options confort</h6>
            <textarea
              rows={3}
              readOnly={editOption}
              value={values.Option_Confort}
              onChange={handleChange}
              name="Option_Confort"
              placeholder="Option Interior"
              className={`mb-4 ${
                editOption ? "hide-input text-lighter" : "form-control"
              }`}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default OptionList;
