// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.log-in .car-container {
  background-color: #f8f7fa;
  height: calc(100vh - 4rem);
}
.login-container {
  padding: 0 3rem;
}
.log-in .box {
  max-width: 400px;
}
.login-input {
  border: 0;
}
.login-input:focus-visible {
  outline: 0;
}
@media only screen and (max-width: 760px) {
  .login-container {
    padding: 0;
  }
}
@media only screen and (min-width: 1880px) {
  .login-m-auto {
    margin: auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/css/log-in.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,0BAA0B;AAC5B;AACA;EACE,eAAe;AACjB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,SAAS;AACX;AACA;EACE,UAAU;AACZ;AACA;EACE;IACE,UAAU;EACZ;AACF;AACA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":[".log-in .car-container {\n  background-color: #f8f7fa;\n  height: calc(100vh - 4rem);\n}\n.login-container {\n  padding: 0 3rem;\n}\n.log-in .box {\n  max-width: 400px;\n}\n.login-input {\n  border: 0;\n}\n.login-input:focus-visible {\n  outline: 0;\n}\n@media only screen and (max-width: 760px) {\n  .login-container {\n    padding: 0;\n  }\n}\n@media only screen and (min-width: 1880px) {\n  .login-m-auto {\n    margin: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
