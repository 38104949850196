/* eslint-disable react-hooks/exhaustive-deps */
import { Dropdown } from "primereact/dropdown";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomer } from "../../../services/client/addclientApi";
import {
  fetchSingleCompanyData,
  fetchSingleclientData,
} from "../../../Redux/invoiceSlice";
import { InvoiceContext } from "../invoiceContext";
import { updateInvoice } from "../../../services/Invoice";
import { useNavigate } from "react-router-dom";
import { fetchcompanyDropdown } from "../../../Redux/commonSlice";
import { getVehicleDropDown } from "../../../services/Calendar/reservation";
import InviceDiscount from "../AddInvoice/InviceDiscount";
import AddPayment from "../ReviewInvoice/AddPayment";

const EditeInvoiceActions = ({ id, isSingleInvoice, singelInvoice }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [invoiceDiscount, setInvoiceDiscount] = useState(false);
  const [paymentShow, setPaymentShow] = useState(false);
  const [isCustomerList, setCustomerList] = useState();
  const [isProformate, setProformate] = useState(true);
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [setCustomerRequire] = useState(false);
  const { isValue, setValue } = useContext(InvoiceContext);
  const [setSelectedCustomerRequire] = useState(false);
  const [isVehicleDropdown, setVehicleDropdown] = useState([]);
  const { companyDropdown } = useSelector((state) => state?.commonSlice);

  const customerDropdown = async () => {
    try {
      const dropdownData = await getCustomer();
      const formattedOptions = dropdownData?.data.map((customer) => ({
        label: `${customer?.firstname} ${customer?.lastname}`,
        _id: customer?._id,
      }));
      setCustomerList(formattedOptions);
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };

  useEffect(() => {
    const updatedValue = {
      ...isValue,
      company_id: selectedCompany,
      customer_id: selectedCustomer,
      proformate: isProformate,
      general_id: selectedVehicle?._id,
    };
    setValue(updatedValue);
    customerDropdown();
  }, [selectedCompany, selectedCustomer, selectedVehicle?._id, isProformate]);

  useEffect(() => {
    if (isSingleInvoice) {
      setSelectedCompany({
        _id: isSingleInvoice?.invoice?.company_id?._id,
        name: isSingleInvoice?.invoice?.company_id?.name,
      });
      setSelectedCustomer({
        label: `${isSingleInvoice?.invoice?.customer_id?.firstname} ${isSingleInvoice?.invoice?.customer_id?.lastname}`,
        _id: isSingleInvoice?.invoice?.customer_id?._id,
      });
      setSelectedVehicle({
        name: `${isSingleInvoice?.invoice?.general_id?.manufacturer?.name} ${isSingleInvoice?.invoice?.general_id?.model.name}: ${isSingleInvoice?.invoice?.general_id?.general_info?.license_plate}`,
        _id: isSingleInvoice?.invoice?.general_id?._id,
        general_info: isSingleInvoice?.invoice?.general_id?.general_info?._id,
      });
      setProformate(isSingleInvoice?.invoice?.proformate);
    }
  }, [isSingleInvoice]);

  // addInvoice start
  const toSafeguards = async () => {
    if (selectedCustomer && selectedCompany) {
      const data = await updateInvoice(id, isValue ? isValue : isSingleInvoice);
      if (data) {
        navigate("/invoice");
      }
    }
    if (!selectedCustomer) {
      setCustomerRequire(true);
    }
    if (!selectedCompany) {
      setSelectedCustomerRequire(true);
    }
  };
  // addInvoice end
  useEffect(() => {
    if (isSingleInvoice) {
      dispatch(fetchcompanyDropdown());
      dispatch(
        fetchSingleclientData(isSingleInvoice?.invoice?.customer_id?._id)
      );
    }
  }, [isSingleInvoice]);

  const vehicleDropdown = async (id) => {
    try {
      const formattedOptions = await getVehicleDropDown(id);

      setVehicleDropdown(formattedOptions.data);
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };
  useEffect(() => {
    if (isSingleInvoice?.invoice?.company_id?._id) {
      vehicleDropdown(isSingleInvoice?.invoice?.company_id?._id);
    }
  }, [isSingleInvoice]);

  useEffect(() => {
    if (selectedCompany) {
      vehicleDropdown(selectedCompany?._id);
    }
  }, [selectedCompany]);
  return (
    <div className="col-lg-3 col-12 invoice-actions">
      <div className="card mb-4">
        <div className="card-body">
          <button
            className="btn btn-primary d-grid w-100 mb-2 waves-effect waves-light"
            value=""
            type="button"
            onClick={() => setInvoiceDiscount(true)}
          >
            Discount
          </button>
          {/* <button
            className="btn btn-primary d-grid w-100 mb-2 waves-effect waves-light"
            value=""
            type="button"
            disabled
          >
            <span className="d-flex align-items-center justify-content-center text-nowrap">
              <i className="ti ti-send tiw2-xs me-2" />
              Send Invoice
            </span>
          </button> */}
          <div className="d-flex gap-3 mb-2">
            <button
              className="btn btn-label-secondary w-100 waves-effect"
              type="submit"
            >
              Review
            </button>
            <button
              className="btn btn-label-secondary w-100 waves-effect"
              value=""
              type="submit"
              onClick={toSafeguards}
            >
              save
            </button>
          </div>

          <button
            onClick={() => setPaymentShow(true)}
            className="btn btn-primary d-grid w-100 mb-2 waves-effect waves-light"
            type="button"
          >
            <span className="d-flex align-items-center justify-content-center text-nowrap">
              <i className="ti ti-currency-dollar ti-xs me-2" />
              Add Payment
            </span>
          </button>
        </div>
      </div>
      <div>
        <p className="mb-1">Company</p>
        <Dropdown
          value={selectedCompany}
          onChange={(e) => {
            setSelectedCompany(e.value);
            dispatch(fetchSingleCompanyData(e.value?._id));
          }}
          options={companyDropdown}
          optionLabel="name"
          filter
          placeholder="Company"
          className="w-full mb-3 "
        />
        <p className="mb-1">Customer</p>
        <Dropdown
          value={selectedCustomer}
          onChange={(e) => {
            setSelectedCustomer(e.value);
            dispatch(fetchSingleclientData(e.value?._id));
          }}
          options={isCustomerList}
          filter
          placeholder="Customer"
          className="w-full mb-3"
        />
        <label className="mb-1">Vehicle</label>
        <Dropdown
          value={selectedVehicle}
          onChange={(e) => {
            setSelectedVehicle(e.value);
          }}
          options={isVehicleDropdown}
          optionLabel="name"
          filter
          placeholder="Vehicle"
          className="w-full mb-3 "
        />
        <div className="d-flex justify-content-between mb-2">
          <label htmlFor="payment-terms" className="mb-0">
            Pro-Format
          </label>
          <label className="switch switch-primary me-0">
            <input
              onChange={(e) => setProformate(e.target.checked)}
              type="checkbox"
              className="switch-input"
              id="ProFormat"
              name="ProFormat"
              checked={isProformate}
            />
            <span className="switch-toggle-slider">
              <span className="switch-on"></span>
              <span className="switch-off"></span>
            </span>
            <span className="switch-label"></span>
          </label>
        </div>
      </div>
      <InviceDiscount
        isSingleInvoice={isSingleInvoice}
        invoiceDiscount={invoiceDiscount}
        handleClose={() => setInvoiceDiscount(false)}
      />
      <AddPayment
        singelInvoice={singelInvoice}
        id={id}
        paymentShow={paymentShow}
        handleClose={() => setPaymentShow(false)}
      />
    </div>
  );
};

export default EditeInvoiceActions;
