import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { addManufacturer } from "../../../services/AddVehicle";
import { fetchManufacturerDropdown } from "../../../Redux/vehicleSlice";
import { useDispatch } from "react-redux";

const AddManufacturerModal = (props) => {
  const [isManufacturer, setManufacturer] = useState("");
  const dispatch = useDispatch();
  const onAddManufacturer = async (e) => {
    await addManufacturer({ name: isManufacturer });
    dispatch(fetchManufacturerDropdown());
    setManufacturer("");
    props.onHide();
  };

  return (
    <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Ajouter un fabricant
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col">
          <label className="form-label mb-1">Nom</label>
          <input
            value={isManufacturer}
            onChange={(e) => setManufacturer(e.target.value)}
            type="text"
            className="form-control"
            placeholder="Entrez un fabricant"
            aria-controls="DataTables_Table_0"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-label-secondary waves-effect"
          onClick={props.onHide}
        >
          Fermer
        </button>
        <button
          className="btn btn-primary waves-effect waves-light"
          onClick={onAddManufacturer}
        >
          Sauvegarder
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddManufacturerModal;
