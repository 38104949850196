import { Field, Form, Formik } from "formik";
import { Dropdown } from "primereact/dropdown";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { demo2 } from "../../../../Common/DropdownValue";
import { updateVehicle } from "../../../../../services/AddVehicle";
import * as Yup from "yup";
import { SmallLoader } from "../../../../Common/Loader";
import { fetchSingleVehicleList } from "../../../../../Redux/vehicleSlice";
import { useParams } from "react-router-dom";
const GeneralInformation = () => {
  const [isEdit, setEdit] = useState(true);
  const [isLoding, setLoding] = useState(false);
  const dispatch = useDispatch();
  const { fuleDropdown, singlevehicleData } = useSelector(
    (state) => state.vehicleSlice
  );

  const { id } = useParams();
  const validationSchema = Yup.object().shape({
    main_fuel: Yup.mixed().required("required *"),
    chassis_number: Yup.mixed().required("required *"),
    co2_emissions: Yup.string().required("required *"),
    tax_power: Yup.number().required("required *"),
    license_plate: Yup.string().required("required *"),
  });

  const generalInfo = {
    main_fuel: singlevehicleData?.GeneralInfo?.main_fuel || "",
    second_fuel: singlevehicleData?.GeneralInfo?.second_fuel || "",
    chassis_number: singlevehicleData?.GeneralInfo?.chassis_number || "",
    license_plate: singlevehicleData?.GeneralInfo?.license_plate || "",
    co2_emissions: singlevehicleData?.GeneralInfo?.co2_emissions || "",
    k_w: singlevehicleData?.GeneralInfo?.k_w || "",
    resume: singlevehicleData?.GeneralInfo?.resume || "",
    displacement: singlevehicleData?.GeneralInfo?.displacement || "",
    good: singlevehicleData?.GeneralInfo?.good || "",
    tank_size: singlevehicleData?.GeneralInfo?.tank_size || "",
    tax_power: singlevehicleData?.GeneralInfo?.tax_power || "",
    transmission: singlevehicleData?.GeneralInfo?.transmission || "",
    seat_name: singlevehicleData?.GeneralInfo?.seat_name || "",
    no_doors: singlevehicleData?.GeneralInfo?.no_doors || "",
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={generalInfo}
      enableReinitialize={true}
      onSubmit={async (values) => {
        setLoding(true);
        const tempData = { ...singlevehicleData, GeneralInfo: values };
        const data = await updateVehicle(id, tempData);
        if (data) {
          setEdit(true);
          setLoding(false);
          dispatch(fetchSingleVehicleList(id));
        }
      }}
    >
      {({ values, errors, handleChange, handleSubmit }) => (
        <Form className="card mb-4">
          <div className="card-header d-flex justify-content-between">
            <h5 className="card-tile mb-0">General Information</h5>
            {isEdit ? (
              <div
                onClick={() => setEdit(false)}
                className="btn btn-primary waves-effect waves-light"
              >
                Modifier
              </div>
            ) : (
              <button
                type="submit"
                className="btn btn-primary waves-effect waves-light"
              >
                Save
                {isLoding && <SmallLoader />}
              </button>
            )}
          </div>
          {console.log(errors, "valuesvaluesvaluesvaluesvaluesvalues")}
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-4">
                  <h6>Carburant primaire</h6>
                  <Dropdown
                    disabled={isEdit}
                    value={values?.main_fuel}
                    onChange={handleChange}
                    options={fuleDropdown}
                    name="main_fuel"
                    optionLabel="name"
                    placeholder="Type"
                    className={`w-full ${
                      isEdit ? "p-dropdown-hide" : ".p-dropdown"
                    } ${errors?.main_fuel && "dropdown-invalid"}`}
                  />
                </div>
                <div className="mb-4">
                  <h6>Numéro de châssis</h6>
                  <Field
                    readOnly={isEdit}
                    name="chassis_number"
                    value={values.chassis_number}
                    placeholder="Type"
                    className={` ${isEdit ? "hide-input" : "form-control"} ${
                      errors.chassis_number && "dropdown-invalid"
                    }`}
                  />
                </div>
                <div className="row mb-4">
                  <div className="col-md-4">
                    <h6>CO2</h6>
                    <Field
                      readOnly={isEdit}
                      name="co2_emissions"
                      value={values.co2_emissions}
                      placeholder="CO2"
                      className={` ${isEdit ? "hide-input" : "form-control"} ${
                        errors.co2_emissions && "dropdown-invalid"
                      }`}
                    />
                  </div>
                  <div className="col-md-4">
                    <h6>K.W.</h6>
                    <Field
                      type="number"
                      readOnly={isEdit}
                      name="k_w"
                      value={values.k_w}
                      placeholder="Type"
                      className={` ${isEdit ? "hide-input" : "form-control"} ${
                        errors.k_w && "dropdown-invalid"
                      }`}
                    />
                  </div>
                  <div className="col-md-4">
                    <h6>Puissance (Ch)</h6>
                    <Field
                      type="number"
                      readOnly={isEdit}
                      name="resume"
                      value={values.resume}
                      placeholder="Type"
                      className={` ${isEdit ? "hide-input" : "form-control"} ${
                        errors.resume && "dropdown-invalid"
                      }`}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h6>Chevaux fiscaux</h6>
                    <Field
                      type="number"
                      readOnly={isEdit}
                      name="tax_power"
                      value={values.tax_power}
                      placeholder="Type"
                      className={` ${isEdit ? "hide-input" : "form-control"} ${
                        errors.tax_power && "dropdown-invalid"
                      }`}
                    />
                  </div>
                  <div className="col">
                    <h6>Transmission</h6>
                    <Dropdown
                      disabled={isEdit}
                      value={values?.transmission}
                      onChange={handleChange}
                      name="transmission"
                      options={demo2}
                      placeholder="Électrique"
                      className={`w-full ${
                        isEdit ? "p-dropdown-hide" : "p-dropdown"
                      } ${errors?.transmission && "dropdown-invalid"}`}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-4">
                  <h6>Carburant secondaire</h6>
                  <Dropdown
                    disabled={isEdit}
                    value={values?.second_fuel}
                    onChange={handleChange}
                    options={fuleDropdown}
                    name="second_fuel"
                    optionLabel="name"
                    placeholder="Type"
                    className={`w-full ${
                      isEdit ? "p-dropdown-hide" : "p-dropdown"
                    } ${errors?.second_fuel && "dropdown-invalid"}`}
                  />
                </div>
                <div className="mb-4">
                  <h6>Plaque d’immatriculation</h6>
                  <Field
                    value={values.license_plate}
                    readOnly={isEdit}
                    name="license_plate"
                    type="text"
                    className={` ${isEdit ? "hide-input" : "form-control"} ${
                      errors.license_plate && "dropdown-invalid"
                    }`}
                    placeholder="LU0001"
                  />
                </div>
                <div className="row mb-4">
                  <div className="col-md-4">
                    <h6>Cylindrée</h6>
                    <Field
                      readOnly={isEdit}
                      value={values.displacement}
                      name="displacement"
                      type="number"
                      className={` ${isEdit ? "hide-input" : "form-control"} ${
                        errors.displacement && "dropdown-invalid"
                      }`}
                      placeholder="cylindré"
                      aria-controls="DataTables_Table_0"
                    />
                  </div>
                  <div className="col-md-4">
                    <h6>MMA</h6>
                    <Field
                      readOnly={isEdit}
                      value={values.good}
                      name="good"
                      type="text"
                      className={` ${isEdit ? "hide-input " : "form-control"} ${
                        errors.good && "dropdown-invalid"
                      }`}
                      placeholder="MMA"
                      aria-controls="DataTables_Table_0"
                    />
                  </div>
                  <div className="col-md-4">
                    <h6>Réservoir</h6>
                    <Field
                      readOnly={isEdit}
                      value={values.tank_size}
                      name="tank_size"
                      type="number"
                      className={` ${isEdit ? "hide-input" : "form-control"} ${
                        errors.tank_size && "dropdown-invalid"
                      }`}
                      placeholder="L"
                      aria-controls="DataTables_Table_0"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h6>nombres de sièges</h6>
                    <Field
                      readOnly={isEdit}
                      value={values.seat_name}
                      name="seat_name"
                      type="text"
                      className={` ${isEdit ? "hide-input" : "form-control"} ${
                        errors.seat_name && "dropdown-invalid"
                      }`}
                      placeholder="0"
                      aria-controls="DataTables_Table_0"
                    />
                  </div>
                  <div className="col">
                    <h6>Nombre de portes</h6>
                    {/* <input
                      type="text"
                      name=""
                      defaultValue={"5"}
                      className="hide-input"
                      readOnly
                    /> */}
                    <Field
                      readOnly={isEdit}
                      value={values.no_doors}
                      name="no_doors"
                      type="number"
                      className={` ${isEdit ? "hide-input" : "form-control"} ${
                        errors.no_doors && "dropdown-invalid"
                      }`}
                      placeholder="0"
                      aria-controls="DataTables_Table_0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default GeneralInformation;
