// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-profile-header-banner img{width:100%;object-fit:cover;height:250px}.user-profile-header{margin-top:-2rem}.user-profile-header .user-profile-img{border:5px solid;width:120px}.light-style .user-profile-header .user-profile-img{border-color:#fff}.dark-style .user-profile-header .user-profile-img{border-color:#2f3349}.dataTables_wrapper .card-header .dataTables_filter label{margin-top:0 !important;margin-bottom:0 !important}@media(max-width: 767.98px){.user-profile-header-banner img{height:150px}.user-profile-header .user-profile-img{width:100px}}
`, "",{"version":3,"sources":["webpack://./src/assets/vendor/css/pages/page-profile.css"],"names":[],"mappings":"AAAA,gCAAgC,UAAU,CAAC,gBAAgB,CAAC,YAAY,CAAC,qBAAqB,gBAAgB,CAAC,uCAAuC,gBAAgB,CAAC,WAAW,CAAC,oDAAoD,iBAAiB,CAAC,mDAAmD,oBAAoB,CAAC,0DAA0D,uBAAuB,CAAC,0BAA0B,CAAC,4BAA4B,gCAAgC,YAAY,CAAC,uCAAuC,WAAW,CAAC","sourcesContent":[".user-profile-header-banner img{width:100%;object-fit:cover;height:250px}.user-profile-header{margin-top:-2rem}.user-profile-header .user-profile-img{border:5px solid;width:120px}.light-style .user-profile-header .user-profile-img{border-color:#fff}.dark-style .user-profile-header .user-profile-img{border-color:#2f3349}.dataTables_wrapper .card-header .dataTables_filter label{margin-top:0 !important;margin-bottom:0 !important}@media(max-width: 767.98px){.user-profile-header-banner img{height:150px}.user-profile-header .user-profile-img{width:100px}}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
