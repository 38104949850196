/* eslint-disable react-hooks/exhaustive-deps */
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";

import { Dropdown } from "primereact/dropdown";

import EditModel from "./EditModel";

import EditManufacturer from "./EditManufacturer";
import {
  deleteManufacturer,
  deleteModel,
  updateVehicle,
} from "../../../../services/AddVehicle";
import {
  fetchManufacturerDropdown,
  fetchModalDropdown,
  fetchSingleVehicleList,
} from "../../../../Redux/vehicleSlice";
import {
  Active,
  License,
  Status,
  Type,
  Year,
} from "../../../Common/DropdownValue";
import {
  fetchVehicleCategoryDropdown,
  fetchcompanyDropdown,
} from "../../../../Redux/commonSlice";
import AddManufacturerModal from "../../Modal/AddManufacturerModal";
import AddModel from "../../Modal/AddModel";
import DeleteConfirmationModal from "../../../Common/DeleteConfirmationModal";
import { useParams } from "react-router-dom";

function GeneralModal(props) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [modulEdit, setModuleEdit] = useState(false);
  const [modelName, setModelName] = useState("");
  const [manufacturerModal, setManufacturerModal] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [isId, setId] = useState("");
  const [ismanufacturerEdit, setManufacturerEdit] = useState(false);
  const [isupdateManufacturer, setupdateManufacturer] = useState(false);
  const [ismanufacturerId, setmanufacturerId] = useState("");
  const [isManufacturerId, setManufacturerId] = useState("");
  const { vehicleCategoryDropdownList } = useSelector(
    (state) => state.commonSlice
  );
  const { singlevehicleData, manufacturersDropdown, modalDropdown } =
    useSelector((state) => state.vehicleSlice);
  const { companyDropdown } = useSelector((state) => state.commonSlice);

  const initialValues = {
    company: singlevehicleData?.General?.company || "",
    manufacturer:
      {
        name: singlevehicleData?.General?.manufacturer.name,
        _id: singlevehicleData?.General?.manufacturer._id,
      } || "",
    model:
      {
        manufacturer: singlevehicleData?.General?.model?.manufacturer?._id,
        name: singlevehicleData?.General?.model?.name,
        _id: singlevehicleData?.General?.model?._id,
      } || "",
    vehicle_category: singlevehicleData?.General?.vehicle_category || "",
    vehicle_location: singlevehicleData?.General?.vehicle_location || "",
    year: singlevehicleData?.General?.year || "",
    color: singlevehicleData?.General?.color || "",
    status: singlevehicleData?.General?.status || "",
    active: singlevehicleData?.General?.active || "",
    min_age_driver: singlevehicleData?.General?.min_age_driver || "",
    license_class: singlevehicleData?.General?.license_class || "",
    vi_types: singlevehicleData?.GeneralInfo?.vi_types || "",
    declination: singlevehicleData?.General?.declination || "",
  };

  const deleteModule = async (id) => {
    await deleteModel(id);
    setModalShow(false);
    if (isManufacturerId) {
      dispatch(fetchModalDropdown(isManufacturerId));
    }
  };

  const deleteManufacturerdata = async (id) => {
    await deleteManufacturer(id);
    setModalShow(false);
    setupdateManufacturer(true);
  };

  const modelTemplete = (option) => {
    return (
      <div className="color-option d-flex justify-content-between w-100">
        <div className="color-label">{option.name}</div>

        <div className="d-flex position-absolute" style={{ right: "0" }}>
          <i
            className="mx-2 ti ti-edit ti-sm "
            onClick={(e) => {
              setId(option._id);
              setModuleEdit(true);
              setManufacturerId(option.manufacturer);
              setModelName(option.name);
            }}
          ></i>

          <i
            className="mx-2 ti ti-sm ti-trash"
            onClick={(e) => {
              setModalShow(true);
              setId(option._id);
            }}
          ></i>
        </div>
      </div>
    );
  };

  const manufacturerTemplete = (option) => {
    return (
      <div className="color-option d-flex justify-content-between">
        <div className="color-label w-100">{option.name}</div>

        <div className="d-flex position-absolute" style={{ right: "0" }}>
          <i
            className="mx-2 ti ti-edit ti-sm "
            onClick={(e) => {
              setmanufacturerId(option._id);
              setManufacturerEdit(true);
              setModelName(option.name);
            }}
          ></i>

          <i
            className="mx-2 ti ti-sm ti-trash"
            onClick={(e) => {
              setModalShow(true);
              setmanufacturerId(option._id);
            }}
          ></i>
        </div>
      </div>
    );
  };

  useEffect(() => {
    dispatch(fetchVehicleCategoryDropdown());
    dispatch(fetchManufacturerDropdown());
    dispatch(fetchcompanyDropdown());
    dispatch(fetchModalDropdown(singlevehicleData?.General?.manufacturer?._id));
  }, []);

  useEffect(() => {
    dispatch(fetchManufacturerDropdown());
  }, [isupdateManufacturer]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">General</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validate={(values) => {
            if (values.manufacturer?._id) {
              dispatch(fetchModalDropdown(values.manufacturer?._id));
            }
          }}
          onSubmit={async (values) => {
            console.log("jksnjnjsdfnozxkcmzx", values);
            const tempData = {
              ...singlevehicleData,
              General: values,
              GeneralInfo: {
                ...singlevehicleData.GeneralInfo,
                vi_types: values.vi_types,
              },
            };
            const data = await updateVehicle(id, tempData);
            if (data) {
              props.onHide();
              dispatch(fetchSingleVehicleList(id));
            }
          }}
        >
          {({ values, handleChange, errors }) => (
            <Form>
              <div className="col mb-3">
                <label className="form-label mb-1">Société</label>
                <Dropdown
                  value={values.company}
                  onChange={handleChange}
                  options={companyDropdown}
                  optionLabel="name"
                  name="company"
                  placeholder="Sélectionner"
                  className={`w-full ${errors.company && "dropdown-invalid"}`}
                />

                <ErrorMessage
                  name="company"
                  component="div"
                  className="text-danger ps-2"
                />
              </div>
              <div className="col mb-3">
                <label
                  className="form-label mb-1 d-flex justify-content-between align-items-center"
                  htmlFor="category-org"
                >
                  <span>Fabriquant</span>
                  <span
                    onClick={() => setManufacturerModal(true)}
                    className="fw-medium text-primary cursor-pointer"
                  >
                    Ajouter un Fabricant
                  </span>
                </label>
                <Dropdown
                  value={values.manufacturer}
                  onChange={handleChange}
                  options={manufacturersDropdown?.map((item) => ({
                    name: item?.name,

                    _id: item?._id,
                  }))}
                  itemTemplate={manufacturerTemplete}
                  name="manufacturer"
                  optionLabel="name"
                  placeholder="Sélectionner"
                  className={`w-full ${
                    errors.manufacturer && "dropdown-invalid"
                  }`}
                />
                <ErrorMessage
                  name="manufacturer"
                  component="div"
                  className="text-danger ps-2"
                />
              </div>
              <div className="col mb-3">
                <label
                  className="form-label mb-1 d-flex justify-content-between align-items-center"
                  htmlFor="category-org"
                >
                  <span>Modéle</span>
                  <span
                    className="fw-medium text-primary cursor-pointer"
                    onClick={() => setAddModel(true)}
                  >
                    Ajouter un Modéle
                  </span>
                </label>

                <Dropdown
                  placeholder="Sélectionner"
                  value={values?.model}
                  onChange={handleChange}
                  options={modalDropdown?.map((item) => ({
                    name: item?.name,
                    manufacturer: item?.manufacturer,
                    _id: item?._id,
                  }))}
                  itemTemplate={modelTemplete}
                  optionLabel="name"
                  name="model"
                  className={`w-full ${errors.model && "dropdown-invalid"}`}
                />

                <ErrorMessage
                  name="model"
                  component="div"
                  className="text-danger ps-2"
                />
              </div>

              <div className="row mb-3">
                <div className="col">
                  <label
                    className="form-label mb-1 d-flex justify-content-between align-items-center"
                    htmlFor="category-org"
                  >
                    Déclinaison
                  </label>
                  <Field
                    value={values.declination}
                    name="declination"
                    className="form-control"
                    placeholder="Déclinaison"
                    aria-controls="DataTables_Table_0"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col">
                  <label
                    className="form-label mb-1 d-flex justify-content-between align-items-center"
                    htmlFor="category-org"
                  >
                    Année
                  </label>
                  <Dropdown
                    value={values.year}
                    onChange={handleChange}
                    options={Year}
                    name="year"
                    filter
                    placeholder="Année"
                    className="w-full"
                  />
                </div>
              </div>
              <div className="col mb-3">
                <label
                  className="form-label mb-1 d-flex justify-content-between align-items-center"
                  htmlFor="category-org"
                >
                  Status
                </label>
                <Dropdown
                  value={values.status}
                  onChange={handleChange}
                  options={Status}
                  name="status"
                  placeholder="Sélectionner"
                  className="w-full"
                />
              </div>
              <div className="col mb-3">
                <label
                  className="form-label mb-1 d-flex justify-content-between align-items-center"
                  htmlFor="category-org"
                >
                  Active
                </label>
                <Dropdown
                  value={values.active}
                  onChange={handleChange}
                  options={Active}
                  name="active"
                  placeholder="Active"
                  className="w-full"
                />
              </div>
              <div className="col mb-3">
                <label
                  className="form-label mb-1 d-flex justify-content-between align-items-center"
                  htmlFor="category-org"
                >
                  Catégorie du véhicule
                </label>
                <Dropdown
                  value={values.vehicle_category}
                  onChange={handleChange}
                  options={vehicleCategoryDropdownList}
                  name="vehicle_category"
                  optionLabel="name"
                  placeholder="Sélectionner"
                  className="w-full"
                />
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label
                    className="form-label mb-1 d-flex justify-content-between align-items-center"
                    htmlFor="category-org"
                  >
                    Age min du Conducteur
                  </label>
                  <Field
                    name="min_age_driver"
                    type="number"
                    className="form-control"
                    placeholder="Age"
                    aria-controls="DataTables_Table_0"
                  />
                  <ErrorMessage
                    name="min_age_driver"
                    component="div"
                    className="text-danger ps-2"
                  />
                </div>
                <div className="col">
                  <label
                    className="form-label mb-1 d-flex justify-content-between align-items-center"
                    htmlFor="category-org"
                  >
                    Classe du Permis
                  </label>
                  <Dropdown
                    value={values.license_class}
                    onChange={handleChange}
                    options={License}
                    name="license_class"
                    placeholder="B"
                    className="w-full"
                  />
                </div>
              </div>
              <div className="col">
                <label
                  className="form-label mb-1 d-flex justify-content-between align-items-center"
                  htmlFor="category-org"
                >
                  Types
                </label>
                <Dropdown
                  value={values?.vi_types}
                  onChange={handleChange}
                  name="vi_types"
                  options={Type}
                  placeholder="Type"
                  className="w-full"
                />
              </div>
              <div className="d-flex justify-content-end gap-3 mt-4">
                <button
                  type="submit"
                  className="btn btn-label-danger waves-effect"
                  onClick={props.onHide}
                >
                  Annuler
                </button>
                <button type="submit" className="btn btn-primary">
                  Mise à jour
                </button>
              </div>
            </Form>
          )}
        </Formik>

        {manufacturerModal && (
          <AddManufacturerModal
            show={manufacturerModal}
            onHide={() => setManufacturerModal(false)}
          />
        )}
        {isId && (
          <DeleteConfirmationModal
            title={"Supprimer le modèle"}
            description={"Es-tu sûr de supprimer ce modèle ?"}
            // show={isId}
            show={modalShow}
            // onHide={() => setId("")}
            onHide={() => setModalShow(false)}
            deleteData={() => deleteModule(isId)}
          />
        )}

        {ismanufacturerId && (
          <DeleteConfirmationModal
            title={"Supprimer le fabricant"}
            description={"Êtes-vous sûr de supprimer ce fabricant ?"}
            // show={isId}
            show={modalShow}
            // onHide={() => setId("")}
            onHide={() => setModalShow(false)}
            deleteData={() => deleteManufacturerdata(ismanufacturerId)}
          />
        )}

        {addModel && (
          <AddModel show={addModel} onHide={() => setAddModel(false)} />
        )}

        {modulEdit && (
          <EditModel
            id={isId}
            modelName={modelName}
            isManufacturerId={isManufacturerId}
            show={modulEdit}
            onHide={() => setModuleEdit(false)}
          />
        )}

        {ismanufacturerEdit && (
          <EditManufacturer
            id={ismanufacturerId}
            modelName={modelName}
            show={ismanufacturerEdit}
            onHide={() => setManufacturerEdit(false)}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}
export default GeneralModal;
