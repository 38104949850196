import React, { useState } from 'react';
import { Card, Row, Col, Form } from 'react-bootstrap';

const ConnectedAccounts = () => {
  const [googleConnected, setGoogleConnected] = useState(true);

  const handleToggle = () => {
    setGoogleConnected(!googleConnected);
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title>Comptes Connectés</Card.Title>
        <Row className="align-items-center">
          <Col xs="auto">
            <img
              src="https://www.google.com/images/branding/product/1x/gsa_64dp.png"
              alt="Google"
              width="60"
              height="60"
            />
          </Col>
          <Col>
            <h5 className=" mb-0">Google</h5>
            <p className="text-muted mb-0">Calendrier, contacts et mails</p>
          </Col>
          <Col xs="auto">
            <Form>
              <Form.Check
                type="switch"
                id="google-switch"
                label=""
                checked={googleConnected}
                onChange={handleToggle}
              />
            </Form>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ConnectedAccounts;
