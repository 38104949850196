/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchInvoiceList,
  searchInvoice,
} from "../../../Redux/profileInviceSlice";
import { useNavigate, useParams } from "react-router-dom";
import { pagination } from "../../Common/Pagination";
import { options } from "../../Common/DropdownValue";
import moment from "moment/moment";
import payment from "../../../assets/icons/payment.png";
import { addPayment } from "../../../services/Invoice";
import AddPayment from "../../Invoice/ReviewInvoice/AddPayment";
import { invoiceUpdate } from "../../../services/client/addclientApi";
import { InvoiceStatus } from "../../Common/InvoiceStatus";
import { toast } from "react-toastify";

const DriverInvoices = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setitemsPerPage] = useState("25");
  const [isSingleInvoiceID, setSingleInvoiceId] = useState("");
  const [paymentShow, setPaymentShow] = useState(false);
  const { invoiceList } = useSelector((state) => state?.profileInviceSlice);
  // pagination start
  const userinvoiceList = pagination(invoiceList, currentPage, itemsPerPage);
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  // pagination end

  const [selectStatus, setStatus] = useState("Select Status");
  const optionForStatus = [{ label: "Select Status", value: "Select Status" }];

  const handleSelectChange = (e) => {
    setitemsPerPage(e.value);
  };

  const singelInvoice = async () => {
    dispatch(fetchInvoiceList(id));
  };

  const fullPayment = async (item) => {
    const body = {
      invoice_id: item?._id,
      amount: item?.total_pay - (item?.paid || 0),
      pay_date: moment(),
      status: 1,
      pay_method: "Cash",
    };

    await addPayment(body);
    dispatch(fetchInvoiceList(id));
  };

  const setPayment = async (item) => {
    if (item.paid === item.total_pay) {
      toast.info("You have already paid the full amount");
    } else {
      const body = {
        due_date: moment().add(7, "days"),
        status: 3,
      };
      await invoiceUpdate(item?._id, body);
      dispatch(fetchInvoiceList(id));
    }
  };

  function generateRandomThreeDigitNumber() {
    return Math.floor(Math.random() * 900) + 100;
  }

  useEffect(() => {
    const fetchClientData = async () => {
      if (id) {
        dispatch(fetchInvoiceList(id));
      }
    };
    fetchClientData();
  }, [id, dispatch]);

  function convertDateFormat(dateStr) {
    // Parse the ISO 8601 date string using moment
    let date = moment(dateStr);
    // Format the date to DD-MM-YYYY
    return date.format("DD-MM-YYYY");
  }

  function convertAndIncreaseDate(dateStr) {
    // Parse the ISO 8601 date string using moment
    let date = moment(dateStr);
    // Add 7 days to the date
    date.add(7, "days");
    // Format the date to DD-MM-YYYY
    return date.format("DD-MM-YYYY");
  }
  return (
    <>
      <div className="row mt-2">
        <div className="col-xl-12 col-lg-8 col-md-8">
          <div className="card">
            <div className="card-header">
              <div className="d-flex align-items-center gap-3">
                <div className="flex-grow-1">
                  <div className="col-12 col-md-3 col-lg-2 d-none d-lg-inline">
                    <Dropdown
                      value={itemsPerPage}
                      onChange={handleSelectChange}
                      options={options}
                      placeholder="Select an option"
                      className=""
                    />
                    <button
                      onClick={() => navigate("/add-invoice")}
                      className="btn btn-primary mb-2 text-nowrap add-new-role waves-effect waves-light mx-4"
                    >
                      <i className="ti ti-plus me-md-1"></i>
                      Create Invoice
                    </button>
                  </div>
                </div>

                <div className="col-12 col-md-3 col-lg-2 d-none d-lg-inline">
                  <input
                    onChange={(e) => {
                      dispatch(searchInvoice(e.target.value));
                    }}
                    className="form-control"
                    placeholder="Search Invoice"
                  />
                </div>
                <div className="d-flex align-items-center flex-grow-0">
                  <Dropdown
                    value={selectStatus}
                    onChange={handleSelectChange}
                    options={optionForStatus}
                    placeholder="Select an option"
                    className=""
                  />
                </div>
              </div>
            </div>
            <div className="card-body table-responsive">
              <table className="table  table-hover">
                <thead className="border-top">
                  <tr>
                    <th>
                      <span className="position-relative top-4">ID</span>
                      <div className="d-inline-block item-center">
                        <span className="d-flex flex-column ps-1">
                          <BsChevronUp fontSize={12} />
                          <BsChevronDown fontSize={12} />
                        </span>
                      </div>
                    </th>
                    <th>
                      <span className="position-relative top-4">
                        <span className="position-relative top-4">Abonnée</span>
                      </span>
                      <div className="d-inline-block item-center">
                        <span className="d-flex flex-column ps-1">
                          <BsChevronUp fontSize={12} />
                          <BsChevronDown fontSize={12} />
                        </span>
                      </div>
                    </th>
                    <th>
                      <span className="position-relative top-4">Date</span>
                      <div className="d-inline-block item-center">
                        <span className="d-flex flex-column ps-1">
                          <BsChevronUp fontSize={12} />
                          <BsChevronDown fontSize={12} />
                        </span>
                      </div>
                    </th>
                    <th>
                      <span className="position-relative top-4">Item Type</span>
                      <div className="d-inline-block item-center">
                        <span className="d-flex flex-column ps-1">
                          <BsChevronUp fontSize={12} />
                          <BsChevronDown fontSize={12} />
                        </span>
                      </div>
                    </th>
                    <th>
                      <span className="position-relative top-4">Price</span>
                      <div className="d-inline-block item-center">
                        <span className="d-flex flex-column ps-1">
                          <BsChevronUp fontSize={12} />
                          <BsChevronDown fontSize={12} />
                        </span>
                      </div>
                    </th>
                    <th>
                      <span className="position-relative top-4">Status</span>
                      <div className="d-inline-block item-center">
                        <span className="d-flex flex-column ps-1">
                          <BsChevronUp fontSize={12} />
                          <BsChevronDown fontSize={12} />
                        </span>
                      </div>
                    </th>
                    <th className="text-center">ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceList.length > 0 &&
                    invoiceList?.map((mainInvoice, mainIndex) => {
                      return (
                        <Fragment key={mainIndex}>
                          {mainInvoice?.periods?.map((item, index) => (
                            <tr key={index}>
                              <td className="text-primary">
                                {mainInvoice?.invoice_id}
                              </td>
                              <td className="text-nowrap">
                                <span className="pe-1"></span>
                                {mainInvoice?.vehicle_name}
                              </td>
                              <td>
                                {item?.startdate} / {item?.retrundate}
                              </td>
                              <td className="text-nowrap">{item?.item_name}</td>
                              <td> €{item?.price}</td>
                              <td className="text-nowrap">
                                <InvoiceStatus item={item} />
                              </td>
                              <td className="text-center cursor-pointer ">
                                <div className="d-flex gap-2 justify-content-center align-items-center">
                                  <div className="fs-5">€</div>

                                  <img
                                    src={payment}
                                    style={{ width: "30px", height: "30px" }}
                                    alt="payment"
                                    className="img-fluid"
                                  />
                                  <i
                                    className="ti ti-send  ti-sm"
                                    type="button"
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}

                          {mainInvoice?.productDetails?.[0]?.price !== 0 && (
                            <tr>
                              <td className="text-primary">
                                {mainInvoice?.invoice_id}
                              </td>
                              <td className="text-nowrap">
                                <span className="pe-1"></span>
                                {mainInvoice?.vehicle_name}
                              </td>
                              <td>
                                {convertDateFormat(mainInvoice?.billing_date)} /{" "}
                                {convertAndIncreaseDate(
                                  mainInvoice?.billing_date
                                )}
                              </td>
                              <td className="text-nowrap">
                                {mainInvoice?.productDetails?.[0]?.item_name}
                              </td>
                              <td>
                                {" "}
                                €{mainInvoice?.productDetails?.[0]?.price}
                              </td>
                              <td className="text-nowrap">
                                <InvoiceStatus item={{ status: 0 }} />
                              </td>
                              <td className="text-center cursor-pointer ">
                                <div className="d-flex gap-2 justify-content-center align-items-center">
                                  <div
                                    className="fs-5"
                                    // onClick={() => fullPayment(item)}
                                  >
                                    €
                                  </div>

                                  <img
                                    // onClick={() => {
                                    //   setPaymentShow(true);
                                    //   setSingleInvoiceId(item?._id);
                                    // }}
                                    src={payment}
                                    style={{ width: "30px", height: "30px" }}
                                    alt="payment"
                                    className="img-fluid"
                                  />
                                  <i
                                    // onClick={() => setPayment(item)}
                                    className="ti ti-send  ti-sm"
                                    type="button"
                                  />
                                </div>
                              </td>
                            </tr>
                          )}
                        </Fragment>
                      );
                    })}
                </tbody>
              </table>
            </div>
            {userinvoiceList.length > itemsPerPage && (
              <ReactPaginate
                pageCount={Math.ceil(userinvoiceList.length / itemsPerPage)}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            )}
          </div>
        </div>
      </div>

      <AddPayment
        id={isSingleInvoiceID}
        paymentShow={paymentShow}
        singelInvoice={singelInvoice}
        handleClose={() => setPaymentShow(false)}
      />
    </>
  );
};
export default DriverInvoices;
