/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import { Dropdown } from "primereact/dropdown";
import {
  deleteClient,
  getSubscriptionByCompanylist,
} from "../services/client/addclientApi";
import BreadCrumbs from "../components/Common/BreadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import {
  alphabeticalClient,
  fetchAllUserList,
  fetchcompanyDropdown,
  searchClientUserList,
} from "../Redux/commonSlice";
import { Loader } from "../components/Common/Loader";
import { BsChevronUp } from "react-icons/bs";
import { BsChevronDown } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../components/Common/DeleteModal";
import AddClient from "../components/Client/AddClient";
import { pagination } from "../components/Common/Pagination";
import TableFooter from "../components/Common/TableFooter";
import {
  mainAndClientRoleClass,
  roleClasses,
  statusClass,
  subscriptionStatusClass,
} from "../components/Common/roles";

const Client = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addShow, setAddShow] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [clientId, setclientId] = useState(null);
  const [isSubscriptionValue, setSubscriptionValue] = useState([]);
  const [itemsPerPage, setitemsPerPage] = useState("25");
  const [currentPage, setCurrentPage] = useState(0);
  const [initialValue, setInitialValue] = useState({
    search: "",
    company: "",
    subscription: "",
  });
  const { companyDropdown, clientList, tempClientList, loading } = useSelector(
    (state) => state?.commonSlice
  );

  // pagination start
  const clientListData = pagination(clientList, currentPage, itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  // pagination end

  // subscriptionDropdownValue start
  const subscriptionDropdownValue = async (id) => {
    const subscriptionValue = await getSubscriptionByCompanylist(id);

    if (subscriptionValue !== undefined) {
      setSubscriptionValue(subscriptionValue?.data);
    } else {
      setSubscriptionValue([]);
    }
  };
  // subscriptionDropdownValue end

  const aTozSorting = (value) => {
    const tempData = tempClientList.map((item) => ({
      ...item,
      member: item?.comp_id?.name,
    }));
    const data = tempData
      ?.slice()
      .sort((a, b) => a?.[value]?.localeCompare(b?.[value]));
    dispatch(alphabeticalClient(data));
  };

  const zToaSorting = (value) => {
    const tempData = tempClientList.map((item) => ({
      ...item,
      member: item?.comp_id?.name,
    }));
    const data = tempData
      ?.slice()
      .sort((a, b) => b?.[value]?.localeCompare(a?.[value]));
    dispatch(alphabeticalClient(data));
  };

  const handleEditClick = (id) => {
    navigate(`/client/profile/${id}`);
  };

  const handleSelectChange = (e) => {
    setitemsPerPage(e.value);
  };

  const handleDelete = async () => {
    const form = new FormData();
    form.append("role", "CLIENT");
    await deleteClient(clientId);
    dispatch(searchClientUserList(form));
    setModalShow(false);
  };

  const addMainDriverCouting = (data) => {
    const counting = data?.map((item) => item?.MAIN);
    return `${counting?.length > 1 ? "2" : "0"}+`;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentPage(0);
    setInitialValue((prev) => {
      const updatedFilters = { ...prev, [name]: value };
      return updatedFilters;
    });
  };

  useEffect(() => {
    dispatch(fetchcompanyDropdown());
  }, []);

  // filter data search
  useEffect(() => {
    const form = new FormData();
    if (initialValue?.search) form.append("searchname", initialValue?.search);
    if (initialValue?.company?._id)
      form.append("comp_id", initialValue?.company?._id);
    if (initialValue?.subscription?._id)
      form.append("sub_id", initialValue?.subscription?._id);
    form.append("role", "CLIENT");
    const debounceTimeout = setTimeout(() => {
      dispatch(searchClientUserList(form));
    }, 300);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [initialValue]);

  return (
    <div className="container-fluid flex-grow-1 container-p-y client">
      <BreadCrumbs title={"Financier"} subTitle={"Clients"} />
      <div className="card">
        <div className="card-header">
          <div className="d-flex align-items-center gap-3">
            <div className="flex-grow-1">
              <input
                type="search"
                className="form-control"
                placeholder="Rechercher.."
                name="search"
                onChange={handleChange}
                value={initialValue?.search}
              />
            </div>
            <div className="col-12 col-md-3 col-lg-2 d-none d-lg-inline">
              <Dropdown
                value={initialValue?.company}
                options={companyDropdown}
                optionLabel="name"
                filter
                name="company"
                placeholder="Société"
                className="w-full"
                onChange={(e) => {
                  handleChange(e);
                  subscriptionDropdownValue(e.target.value?._id);
                }}
                {...(initialValue?.company && { showClear: true })}
              />
            </div>
            <div className="col-12 col-md-3 col-lg-2 d-none d-lg-inline">
              <Dropdown
                value={initialValue?.subscription}
                onChange={handleChange}
                options={isSubscriptionValue}
                optionLabel="subscription_name"
                placeholder="Abonnement"
                className="w-full"
                name="subscription"
                {...(initialValue?.subscription && { showClear: true })}
              />
            </div>
            <div className="d-flex align-items-center flex-grow-0">
              <button
                className="dt-button add-new btn btn-primary waves-effect waves-light"
                onClick={() => setAddShow(true)}
                type="button"
              >
                <span>
                  <i className="ti ti-plus me-0 me-sm-1 ti-xs" />
                  <span className="d-none d-sm-inline-block">
                    Ajouter un Client
                  </span>
                </span>
              </button>
            </div>
          </div>
        </div>
        {/* table start  */}
        <div className=" table-responsive">
          <table className="table  table-hover">
            <thead className="border-top">
              <tr>
                <th># </th>
                <th>
                  <span className="position-relative top-4">STATUS</span>
                  <div className="d-inline-block item-center">
                    <span
                      className="d-flex flex-column ps-1"
                      style={{ opacity: "0" }}
                    >
                      <BsChevronUp fontSize={12} />
                      <BsChevronDown fontSize={12} />
                    </span>
                  </div>
                </th>

                <th>
                  <span className="position-relative top-4">NOM</span>
                  <div className="d-inline-block item-center">
                    <span className="d-flex flex-column ps-1">
                      <BsChevronUp
                        fontSize={12}
                        onClick={() => aTozSorting("firstname")}
                      />
                      <BsChevronDown
                        fontSize={12}
                        onClick={() => zToaSorting("firstname")}
                      />
                    </span>
                  </div>
                </th>
                <th>
                  <span className="position-relative top-4">
                    Type de client
                  </span>
                  <div className="d-inline-block item-center">
                    <span className="d-flex flex-column ps-1">
                      <BsChevronUp
                        fontSize={12}
                        onClick={() => aTozSorting("cust_type")}
                      />
                      <BsChevronDown
                        fontSize={12}
                        onClick={() => zToaSorting("cust_type")}
                      />
                    </span>
                  </div>
                </th>
                <th>
                  <span className="position-relative top-4"> E-MAIL</span>
                  <div className="d-inline-block item-center">
                    <span className="d-flex flex-column ps-1">
                      <BsChevronUp
                        fontSize={12}
                        onClick={() => aTozSorting("email")}
                      />
                      <BsChevronDown
                        fontSize={12}
                        onClick={() => zToaSorting("email")}
                      />
                    </span>
                  </div>
                </th>

                <th>
                  <span className="position-relative top-4">MEMBRE</span>
                  <div className="d-inline-block item-center">
                    <span className="d-flex flex-column ps-1">
                      <BsChevronUp
                        fontSize={12}
                        onClick={() => aTozSorting("member")}
                      />
                      <BsChevronDown
                        fontSize={12}
                        onClick={() => zToaSorting("member")}
                      />
                    </span>
                  </div>
                </th>
                <th>ACTIONS </th>
              </tr>
            </thead>
            <tbody>
              {clientListData?.length > 0 &&
                clientListData?.map((customer) => {
                  return (
                    <tr
                      className="cursor-pointer"
                      key={customer?.index}
                      onClick={() => {
                        handleEditClick(customer._id);
                      }}
                    >
                      <td>{customer?.index}</td>
                      <td>
                        <div className="text-nowrap d-flex gap-2">
                          {customer?.role?.length > 1 ? (
                            customer?.role?.map((item) => {
                              return (
                                <>
                                  {item !== "MAIN" && (
                                    <span
                                      className={`${mainAndClientRoleClass[item]}`}
                                    >
                                      {item?.charAt(0).toUpperCase()}
                                    </span>
                                  )}
                                </>
                              );
                            })
                          ) : (
                            <div className="d-inline-block">
                              <span
                                className={`me-1 ${
                                  roleClasses[customer?.role?.[0]]
                                }`}
                              >
                                {customer?.role?.[0]}
                              </span>
                            </div>
                          )}

                          {customer?.sub_id?.subscription_name && (
                            <>
                              {customer?.connectedCustomers?.length > 1 ? (
                                <span className="btn btn-label-primary waves-effect p-1">
                                  {addMainDriverCouting(
                                    customer?.connectedCustomers
                                  )}
                                </span>
                              ) : (
                                <span
                                  className={
                                    subscriptionStatusClass[
                                      customer?.sub_id?.subscription_name
                                    ]
                                  }
                                >
                                  {customer?.sub_id?.subscription_name}
                                </span>
                              )}

                              {customer?.connectedCustomers?.length > 0 &&
                                customer?.connectedCustomers?.map((item) => (
                                  <>
                                    {item?.sub_id?.subscription_name && (
                                      <span
                                        className={`ms-1 ${
                                          statusClass[customer?.role?.[0]]
                                        }`}
                                      >
                                        {item?.sub_id?.subscription_name}
                                      </span>
                                    )}
                                  </>
                                ))}
                            </>
                          )}
                        </div>
                      </td>
                      <td className="text-nowrap cursor-pointer">
                        {customer?.cust_type === "Entreprise"
                          ? customer?.business
                          : `${customer?.firstname} ${customer?.lastname}`}
                      </td>
                      <td className="text-nowrap">{customer?.cust_type}</td>
                      <td>{customer?.email}</td>
                      <td className="text-nowrap">{customer?.comp_id?.name}</td>

                      <td>
                        <i
                          onClick={() => {
                            handleEditClick(customer._id);
                          }}
                          className="menu-icon tf-icons ti ti-edit"
                          type="button"
                        />
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            setclientId(customer?._id);
                            setModalShow(true);
                          }}
                          className="menu-icon tf-icons  ti ti-sm ti-trash"
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        {clientListData.length > 0 && (
          <TableFooter
            itemsPerPage={itemsPerPage}
            handleSelectChange={handleSelectChange}
            listData={clientList}
            handlePageChange={handlePageChange}
          />
        )}

        {/* add client start  */}
        <AddClient
          show={addShow}
          onHide={() => setAddShow(false)}
          setclientId={setclientId}
        />
        {/* add client end  */}

        {/* modal start */}
        <DeleteModal
          title={"Supprimer Cette Client"}
          description={
            "Etes-vous sur de vouloir supprimer ce client et tout les conducteurs liés?"
          }
          show={modalShow}
          onHide={() => setModalShow(false)}
          deleteData={() => handleDelete(false)}
        />
        {/* modal end */}

        {!loading && (clientList?.length === 0 || !clientList) && (
          <div className="text-center my-4 mx-auto">No Data Found</div>
        )}

        {loading && (
          <div className="card-body">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default Client;
