import React, { useState } from "react";
import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import DeleteModal from "../../Common/DeleteModal";
import { deleteClient } from "../../../services/client/addclientApi";
import { fetchAllUserList } from "../../../Redux/commonSlice";
import { useDispatch } from "react-redux";
import { Loader } from "../../Common/Loader";

const ClientNavbar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [modalShow, setModalShow] = useState(false);
  const [isLoding, setLoding] = useState(false);

  const handleDelete = async () => {
    const data = await deleteClient(id);
    if (data?.status) {
      dispatch(fetchAllUserList());
      setModalShow(false);
      navigate("/client");
    }
  };

  const handleNavigate = (pathname) => {
    setLoding(true);
    navigate(`${pathname}/${id}`);
    const timer = setTimeout(() => {
      setLoding(false);
    }, 500);

    // Return a cleanup function to clear the timeout
    return () => clearTimeout(timer);
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-4 align-items-center">
        <ul className="nav nav-pills flex-column flex-sm-row align-items-center">
          <li
            className={`${
              location.pathname?.includes("profile") ? "active" : ""
            }`}
          >
            <div
              className="nav-link cursor-pointer"
              onClick={() => handleNavigate("profile")}
            >
              <i className="ti-xs ti ti-user-check me-1 text-c-white" />
              <span className="span">Profile</span>
            </div>
          </li>

          <li
            className={`${
              location.pathname?.includes("document") ? "active" : ""
            }`}
          >
            <div
              className="nav-link cursor-pointer"
              onClick={() => handleNavigate("document")}
            >
              <i className="ti-xs ti ti-files me-1 text-c-white" />
              <span className="span">Documents</span>
            </div>
          </li>

          <li
            className={`${
              location.pathname?.includes("invoice") ? "active" : ""
            }`}
          >
            <div
              className="nav-link cursor-pointer"
              onClick={() => handleNavigate("invoice")}
            >
              <i className="ti-xs ti ti-file-euro me-1 text-c-white" />
              <span className="span">Invoices</span>
            </div>
          </li>

          <li
            className={`nav-item ${location.pathname === "" ? "active" : ""}`}
          >
            <div className="nav-link cursor-pointer">
              <i className="ti ti-tool me-1 text-c-white" />
              <span className="span">Dammages</span>
            </div>
          </li>
        </ul>

        <div
          onClick={() => setModalShow(true)}
          className="btn btn-label-danger waves-effect"
        >
          <span className="span">Delete</span>
        </div>
      </div>
      {!isLoding ? (
        <Outlet />
      ) : (
        <div
          style={{
            height: "50vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      )}

      {/* modal start */}
      <DeleteModal
        title={"Supprimer Cette véhicule"}
        description={
          "Etes-vous sur de vouloir supprimer ce client et tout les conducteurs liés?"
        }
        show={modalShow}
        onHide={() => setModalShow(false)}
        deleteData={() => handleDelete(false)}
      />
      {/* modal end */}
    </>
  );
};

export default ClientNavbar;
