// MyContext.js
import React, { createContext, useState } from "react";

// Create a context with a default value (optional)
const InvoiceContext = createContext();

// Create a provider component
const InvoiceContextProvider = ({ children }) => {
  const [isValue, setValue] = useState({
    company_id: "",
    customer_id: "",
    general_id: "",
    invoice_id: "",
    billing_date: "",
    due_date: "",
    subtotal_ex_vat: "",
    total_vat: "",
    total_in_vat: "",
    paid: "",
    refunded: "",
    total_pay: "",
    notes: "",
    isdiscount: "",
    discount_per:"",
    discount_amount:"",
    productDetails: [],
  });

  return (
    <InvoiceContext.Provider value={{ isValue, setValue }}>
      {children}
    </InvoiceContext.Provider>
  );
};

export { InvoiceContext, InvoiceContextProvider };
