import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    generalInfo: Yup.object().shape({
      main_fuel: Yup.mixed().required('required *'),
      chassis_number: Yup.mixed().required('required *'),
      co2_emissions:Yup.number().required('required *'),
      tax_power:Yup.number().required('required *'),
      license_plate:Yup.string().required('required *'),

    }),
    insuranceInfo:Yup.object().shape({
      date_issue:Yup.date().required('required *'),
      issuer:Yup.string().required('required *'),
    }),
    general:Yup.object().shape({
      vehicle_category:Yup.mixed().required('required *'),
      model:Yup.mixed().required('required *'),
      company:Yup.mixed().required('required *'),
      manufacturer:Yup.mixed().required('required *'),
      vehicle_location:Yup.string().required('required *'),
    })

  });