/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import BreadCrumbs from "../components/Common/BreadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { fetchUser } from "../Redux/userSlice";
import { Loader } from "../components/Common/Loader";


const Checks = () => {
    const dispatch = useDispatch();
    const userInfo = JSON.parse(localStorage.getItem("userinfo"));
    const { loading } = useSelector((state) => state?.userSlice);

    useEffect(() => {
        dispatch(fetchUser(userInfo?._id));
    }, []);

    return (
        <>
            <div className="container-fluid flex-grow-1 client">
                <div className="content-wrapper delivers">
                    {/* Users list */}
                    <div className=" flex-grow-1 container-p-y">
                        <BreadCrumbs title={"Team"} subTitle={"Profile"} />
                    </div>
                    {loading && (
                        <duv className="card-body">
                            <Loader />
                        </duv>
                    )}

                    {!loading && <Outlet />}
                </div>
            </div>
        </>
    );
};

export default Checks;
