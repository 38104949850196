import { toast } from "react-toastify";
import axiosInstanceAuth from "../../apiInstances/axiosInstanceAuth";
import axiosInstanceAuthFormData from "../../apiInstances/axiosInstanceAuthFormData";
import axiosInstanceAuthGoogleAddValidate from "../../apiInstances/axiosInstanceAuthGoogleAddValidate";
import axios from "axios";

export const getDeliveryList = async () => {
  try {
    const response = await axiosInstanceAuth.get(`delivery/deliveryout`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const getDeliveryListData = async () => {
  try {
    const response = await axiosInstanceAuth.get(
      `/reservation/filter/reservationByDeliveryStatus`
    );
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const getDeliveryListIn = async () => {
  try {
    const response = await axiosInstanceAuth.get(`delivery/deliveryin`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const getSingleDeliveryOut = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(`delivery/deliveryout/${id}`);
    return response.data;
  } catch (error) {
    console.log("Error in getSingleCheck", error?.response?.data?.msg);
  }
};
export const getSingleDeliveryIn = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(`delivery/deliveryin/${id}`);
    return response.data;
  } catch (error) {
    console.log("Error in getSingleCheck", error?.response?.data?.msg);
  }
};

export const addDeliveryOut = async (body) => {
  console.log("body", body);
  try {
    const response = await axiosInstanceAuthFormData.post(
      "delivery/deliveryout",
      body
    );
    toast.success(response?.data?.msg, { position: toast.POSITION.TOP_RIGHT });
    return response.data;
  } catch (error) {
    console.log("Error in getSingleDeliveryIn", error?.response?.data?.msg);
  }
};

export const addDeliveryIn = async (body) => {
  try {
    const response = await axiosInstanceAuth.post(`delivery/deliveryin`, body);
    toast.success(response?.data?.msg, { position: toast.POSITION.TOP_RIGHT });
    return response.data;
  } catch (error) {
    console.log("Error in addDeliveryOut", error?.response?.data?.msg);
  }
};

export const listdataExteriorParts = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(
      `check/listdata/exterior-parts`
    );
    return response.data;
  } catch (error) {
    console.log("Error in getSingleCheck", error?.response?.data?.msg);
  }
};

export const listDataExteriorStripe = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(
      `check/listdata/exterior-stripe`
    );
    return response.data;
  } catch (error) {
    console.log("Error in getSingleCheck", error?.response?.data?.msg);
  }
};
export const listDataExteriorElement = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(
      `check/listdata/exterior-element`
    );
    return response.data;
  } catch (error) {
    console.log("Error in getSingleCheck", error?.response?.data?.msg);
  }
};
export const listDataInteriorParts = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(
      `check/listdata/interior-parts`
    );
    return response.data;
  } catch (error) {
    console.log("Error in getSingleCheck", error?.response?.data?.msg);
  }
};
export const listDataInteriorStripe = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(
      `check/listdata/interior-stripe`
    );
    return response.data;
  } catch (error) {
    console.log("Error in getSingleCheck", error?.response?.data?.msg);
  }
};

const axiosInstanceAuthNgRok = axios.create({
  baseURL: "http://157.230.102.115:4003/api/v1/",
  Accept: "application/json",
  "Content-Type": "application/json",
});

axiosInstanceAuthNgRok.interceptors.request.use(
  async (config) => {
    const authToken = localStorage.getItem("auth_token");
    if (authToken) {
      config.headers = {
        Authorization: `Bearer ${authToken}`,
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstanceAuthNgRok.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

export const checkAdd = async (body) => {
  try {
    const response = await axiosInstanceAuth.post(`check/add`, body);
    toast.success(response?.data?.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response.data;
  } catch (error) {
    console.log("Error in getSingleCheck", error?.response?.data?.msg);
  }
};

export const getSingleResDelivery = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(
      `reservation/listReservation/${id}`
    );
    return response.data;
  } catch (error) {
    console.log("Error in getSingleCheck", error?.response?.data?.msg);
  }
};

export const getVehicleDeliveryInfo = async (body) => {
  try {
    const response = await axiosInstanceAuth.post(
      `delivery/listDeliveryInfo`,
      body
    );
    // toast.success(response?.data?.message, {
    //   position: toast.POSITION.TOP_RIGHT,
    // });
    return response.data;
  } catch (error) {
    console.log("Error in getSingleCheck", error?.response?.data?.msg);
  }
};

export const addVehicleDeliveryInfo = async (body) => {
  try {
    const response = await axiosInstanceAuth.post(
      `delivery/createDeliveryInfo`,
      body
    );
    // toast.success(response?.data?.message, {
    //   position: toast.POSITION.TOP_RIGHT,
    // });
    return response.data;
  } catch (error) {
    console.log("Error in getSingleCheck", error?.response?.data?.msg);
  }
};

export const validateAddress = async (body) => {
  try {
    const response = await axiosInstanceAuthGoogleAddValidate.post("", body);
    // toast.success(response?.data?.message, {
    //   position: toast.POSITION.TOP_RIGHT,
    // });
    return response.data;
  } catch (error) {
    console.log("Error in validation", error);
  }
};

export const deliveryDriverUpdate = async (body) => {
  try {
    const response = await axiosInstanceAuth.put(
      `reservation/updReservWithDriver`,
      body
    );
    return response.data;
  } catch (error) {
    console.log("checkOutAdd", error?.response?.data?.msg);
  }
};

export const updateDriverDoc = async (id, body) => {
  try {
    const response = await axiosInstanceAuthFormData.patch(
      `custdocument/updateCustDoc/${id}`,
      body
    );
    toast.success(response?.data?.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response.data;
  } catch (error) {
    console.log("checkOutAdd", error?.response?.data?.msg);
  }
};

export const getHistoryOfDeliveryList = async () => {
  try {
    const response = await axiosInstanceAuth.get(
      `reservation/filter/reserByChecksComplete`
    );
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
