import { createSlice } from "@reduxjs/toolkit";
import {
  getDeliveryList,
  getHistoryOfDeliveryList,
  getDeliveryListData,
  getDeliveryListIn,
  getSingleDeliveryIn,
  getSingleDeliveryOut,
} from "../services/Deliveries";

const deliverySlice = createSlice({
  name: "deliverySlice",
  initialState: {
    isFromAnnotatePage: false,
    show: false,
    showLocatiom: false,
    addressValidate: false,
    selectedResv: {},
    deliveryListData: [],
    deliveryOutListData: [],
    deliveriesListData: [],
    singleDeliveryOutData: [],
    singleDeliveryInData: [],
    deliveryListDataIn: [],
    loading: true,
  },
  reducers: {
    setAddressValidate: (state, action) => {
      state.addressValidate = action.payload;
    },
    setisFromAnnotatePage: (state, action) => {
      state.isFromAnnotatePage = action.payload;
    },
    setModalVerification: (state, action) => {
      state.show = action.payload;
    },
    setLocationModal: (state, action) => {
      state.showLocatiom = action.payload;
    },
    setselectedResv: (state, action) => {
      state.selectedResv = action.payload;
    },
    deliveryList: (state, action) => {
      state.deliveryListData = action.payload;
    },
    deliveryOutList: (state, action) => {
      state.deliveryOutListData = action.payload;
    },
    deliveryListNew: (state, action) => {
      state.deliveriesListData = action.payload;
    },
    singleDeliveryOut: (state, action) => {
      state.singleDeliveryOutData = action.payload;
    },
    singleDeliveryIn: (state, action) => {
      state.singleDeliveryInData = action.payload;
    },
    deliveryListDataIn: (state, action) => {
      state.deliveryListDataIn = action.payload;
    },
    loading: (state, action) => {
      state.loading = action.payload;
      
    },
  },
});

export const {
  loading,
  setLocationModal,
  showLocatiom,
  deliveryList,
  deliveryOutList,
  deliveryListNew,
  singleDeliveryOut,
  deliveryListDataIn,
  singleDeliveryIn,
  show,
  setModalVerification,
  setselectedResv,
  isFromAnnotatePage,
  setisFromAnnotatePage,
  addressValidate,
  setAddressValidate,
} = deliverySlice.actions;

export default deliverySlice.reducer;

export const fetchDelivery = () => async (dispatch) => {
  try {
    const tempData = await getDeliveryList();
    if (tempData) {
      dispatch(loading(false));
    }
    dispatch(deliveryList(tempData?.data));
  } catch (error) {
    console.log("Error fetchCheck", error);
  }
};

export const fetchDeliveryHistory = () => async (dispatch) => {
  try {
    const tempData = await getHistoryOfDeliveryList();
    const finalResponse = setDataFormatFun(tempData?.data);
    dispatch(deliveryOutList(finalResponse));
    dispatch(loading(false));
  } catch (error) {
    console.log("Error fetchCheck", error);
  }
};

const setDataFormatFun = (data) => {
  let temp = [];
  data &&
    data.map((res) => {
      try {
        let customer = res && res?.customer;
        let reservation = res && res?.reservation;

        // console.log("customer", customer);

        const today = new Date();
        const returnDays = new Date(reservation?.start_date);
        var timeDifference = returnDays?.getTime() - today.getTime();
        var dayDifference = timeDifference / (1000 * 3600 * 24);
        dayDifference = Math.round(dayDifference);

        let cusFirstname = customer?.firstname || "-";
        let cusLastname = customer?.lastname || "-";

        let li_plate = reservation.general_info?.license_plate || "-";
        let manufactname = reservation.general_info.manufacturer?.name || "-";
        let manufactmodel = reservation.general_info.model?.name || "-";
        let deliverydate = reservation?.start_date || "-";
        let subname =
          res &&
          res.customer &&
          res.customer?.connectedCustomers &&
          res.customer?.connectedCustomers.length > 1 &&
          res.customer?.connectedCustomers[1].MAIN &&
          res.customer?.connectedCustomers[1].MAIN.sub_id &&
          res.customer?.connectedCustomers[1].MAIN.sub_id.subscription_name
            ? res.customer?.connectedCustomers[1].MAIN.sub_id.subscription_name
            : null;
        let status = reservation?.reservation_status;
        let cusMainFirstname = customer?.connectedCustomers.map(
          (ele) => ele.MAIN?.firstname
        );
        let cusMainLastname = customer?.connectedCustomers.map(
          (ele) => ele.MAIN?.lastname
        );
        let cusAuxFirstname = customer?.connectedCustomers.map((ele) =>
          ele.AUX.map((ele) => ele?.firstname)
        );
        let cusAuxLastname = customer?.connectedCustomers.map((ele) =>
          ele.AUX.map((ele) => ele?.lastname)
        );

        let auxDrivers =
          res &&
          res.customer &&
          res.customer.connectedCustomers &&
          res.customer.connectedCustomers.length > 1 &&
          res.customer.connectedCustomers[1].AUX
            ? res.customer.connectedCustomers[1].AUX
            : [];

        let mainDirv =
          res &&
          res.customer &&
          res.customer.connectedCustomers &&
          res.customer.connectedCustomers.length > 1 &&
          res.customer.connectedCustomers[1].MAIN
            ? res.customer.connectedCustomers[1].MAIN
            : null;

        const categoryMap = {
          1: "category1",
          2: "category2",
          3: "category3",
          4: "category4",
          5: "category5",
          6: "category6",
        };
        const categoryKey =
          categoryMap[reservation?.general_info?.vehicle_category?.name];
        const price = customer?.sub_id.bail[categoryKey];

        let addressline1 = res?.customer?.address ?? "";
        let addressline2 = res?.customer?.address1 ?? "";
        let city = res?.customer?.city ?? "";
        let state = res?.customer?.state ?? "";
        let postal_code = res?.customer?.postal_code ?? "";
        let country = res?.customer?.country ?? "";

        temp.push({
          _id: reservation._id,
          clientName: cusFirstname + " " + cusLastname,

          addressline1: addressline1,
          addressline2: addressline2,
          city: city,
          state: state,
          postal_code: postal_code,
          country: country,
          address:
            addressline1 +
              (addressline1 && ", ") +
              addressline2 +
              (addressline2 && ", ") +
              city +
              (city && ", ") +
              state +
              (state && ", ") +
              postal_code +
              (postal_code && ", ") +
              country ?? "No Address Found",

          Maindrivername:
            mainDirv && mainDirv?.firstname + " " + mainDirv?.lastname,
          Auxdrivername: cusAuxFirstname + cusAuxLastname,
          leftDays: dayDifference,
          license_plate: li_plate,
          manufacturer: manufactname,
          model: manufactmodel,
          return_date: deliverydate,
          subscriptionname: subname,
          monthlyprice: price,
          reservation_status: status,
          auxDrivers: auxDrivers,
          generalId:
            reservation.general_info && reservation.general_info._id
              ? reservation.general_info._id
              : null,
          cusId: customer?._id,
          validationAddress:
            addressline1 +
              (addressline1 && ", ") +
              addressline2 +
              (addressline2 && ", ") +
              city +
              (city && ", ") +
              state +
              (state && " ") +
              postal_code ?? null,
        });

        // console.log(cusFirstname);
        // console.log(cusLastname);
      } catch (e) {
        console.log(e);
      }
    });
  return temp;
};

export const fetchDeliveryNew = () => async (dispatch) => {
  try {
    const tempData = await getDeliveryListData();
    const finalResponse = setDataFormatFun(tempData?.data);
    dispatch(deliveryOutList(finalResponse));
    dispatch(loading(false));
  } catch (error) {
    console.log("Error fetchCheck", error);
  }
};

export const fetchDeliveryIn = () => async (dispatch) => {
  try {
    dispatch(loading(true));
    const tempData = await getDeliveryListIn();
    if (tempData) {
      dispatch(loading(false));
    }
    dispatch(deliveryListDataIn(tempData?.data));
  } catch (error) {
    console.log("Error fetchCheck", error);
  }
};

export const fetchSingleDeliveryOut = (id) => async (dispatch) => {
  try {
    const tempData = await getSingleDeliveryOut(id);
    dispatch(singleDeliveryOut(tempData?.data));
  } catch (error) {
    console.error("Error listDataExteriorPartsDropdown:", error);
  }
};
export const fetchSingleDeliveryIn = (id) => async (dispatch) => {
  try {
    const clientData = await getSingleDeliveryIn(id);
    dispatch(singleDeliveryIn(clientData?.data));
  } catch (error) {
    console.error("Error listDataExteriorPartsDropdown:", error);
  }
};
