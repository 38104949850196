/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useFormik } from "formik";
import { Alert } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import { changeUserPassword } from "../../../Redux/userSlice";

const ChangePassword = () => {
    const [oldPasswordShow, setOldPasswordShow] = useState(false);
    const [newPasswordShow, setNewPasswordShow] = useState(false);
    const userInfo = JSON.parse(localStorage.getItem("userinfo"));
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            newPassword: "",
            oldPassword: "",
        },
        onSubmit: async (values) => {
            let payload = {
                userId: userInfo?._id,
                oldPassword: values.oldPassword,
                newPassword: values.newPassword,
                confirmPassword: values.newPassword,
            }
            dispatch(changeUserPassword(payload));
        },
        validate: (values) => {
            const errors = {};
            // Add validation rules here
            if (!values.newPassword.trim()) {
                errors.newPassword = "This field is required.";
            } else if (values.newPassword.length < 8) {
                errors.newPassword = "Password must be at least 8 characters.";
            }
            if (!values.oldPassword.trim()) {
                errors.oldPassword = "This field is required.";
            } else if (values.oldPassword.length < 8) {
                errors.oldPassword = "Password must be at least 8 characters.";
            }
            return errors;
        },
    });

    return (
        <div className="card">
            <div className="card-body">
                <div className="text-left d-flex justify-content-center gap-2 flex-column">
                    <h5>Changer le mot de passe</h5>
                </div>

                <Alert variant="warning">
                    Veiller à ce que ces exigences soient respectées<br />
                    Minimum de 8 caractères, majuscules et chiffres.
                </Alert>

                <form onSubmit={formik.handleSubmit} >
                    <div className="">
                        <div className="d-flex flex-row justify-content-between">
                            <div className="mb-3 custom-width-49" >
                                <label className="form-label" htmlFor="newPassword">
                                    Nouveau mot de passe
                                </label>
                                <div className={`form-control d-flex align-items-center ${formik.errors.newPassword ? "is-invalid" : ""}`}>
                                    <input
                                        type={newPasswordShow ? "text" : "password"}
                                        placeholder="Nouveau mot de passe"
                                        onChange={formik.handleChange}
                                        value={formik.values.newPassword}
                                        name="newPassword"
                                        className={`login-input ${!formik.errors.newPassword && "w-full"}`}
                                    />

                                    {!formik.errors.newPassword &&
                                        <div onClick={() => setNewPasswordShow(!newPasswordShow)} style={{ cursor: 'pointer' }}>
                                            {newPasswordShow ? (
                                                <i className="ti ti-eye"></i>
                                            ) : (
                                                <i className="ti ti-eye-off"></i>
                                            )}
                                        </div>}
                                </div>
                                {formik.errors.newPassword && (
                                    <div className="invalid-feedback">
                                        {formik.errors.newPassword}
                                    </div>
                                )}
                            </div>

                            <div className="mb-3 custom-width-49">
                                <label className="form-label" htmlFor="oldPassword">
                                    Confirmer le mot de passe
                                </label>
                                <div className={`form-control d-flex align-items-center ${formik.errors.oldPassword ? "is-invalid" : ""}`}>
                                    <input
                                        type={oldPasswordShow ? "text" : "password"}
                                        placeholder="Confirmer le mot de passe"
                                        onChange={formik.handleChange}
                                        value={formik.values.oldPassword}
                                        name="oldPassword"
                                        className={`login-input ${!formik.errors.oldPassword && "w-full"}`}
                                    />
                                    {!formik.errors.oldPassword &&
                                        <div onClick={() => setOldPasswordShow(!oldPasswordShow)} style={{ cursor: 'pointer' }}>
                                            {oldPasswordShow ? (
                                                <i className="ti ti-eye"></i>
                                            ) : (
                                                <i className="ti ti-eye-off"></i>
                                            )}
                                        </div>}
                                </div>
                                {formik.errors.oldPassword && (
                                    <div className="invalid-feedback">
                                        {formik.errors.oldPassword}
                                    </div>
                                )}
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary row-cols-auto" >
                            Valider
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ChangePassword;
