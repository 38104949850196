import React, { useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../../Common/FormatDate";
import { getCarHistory } from "../../../../Redux/clientsSlice";
import { Loader } from "../../../Common/Loader";
import { pagination } from "../../../Common/Pagination";
import TableFooter from "../../../Common/TableFooter";

const Historical = () => {
  const dispatch = useDispatch();
  const [itemsPerPage, setitemsPerPage] = useState("5");
  const [currentPage, setCurrentPage] = useState(0);
  const { historicalReservationsList, commonLoading } = useSelector(
    (state) => state?.clientsSlice
  );
  const clientListData = pagination(
    historicalReservationsList,
    currentPage,
    itemsPerPage
  );

  const aTozSorting = (value) => {
    const data = historicalReservationsList
      ?.slice()
      .sort((a, b) =>
        a?.general_id?.[value]?.name?.localeCompare(
          b?.general_id?.[value]?.name
        )
      );
    dispatch(getCarHistory(data));
  };

  const zToaSorting = (value) => {
    const data = historicalReservationsList
      ?.slice()
      .sort((a, b) =>
        b?.general_id?.[value]?.name?.localeCompare(
          a?.general_id?.[value]?.name
        )
      );
    dispatch(getCarHistory(data));
  };
  const handleSelectChange = (e) => {
    setitemsPerPage(e.value);
  };
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <>
      <div className="card-body table-responsive pb-0">
        <table className="table  table-hover">
          <thead className="border-top">
            <tr>
              <th>
                <span className="position-relative top-4">CAR/Date </span>
                <div className="d-inline-block item-center">
                  <span className="d-flex flex-column ps-1">
                    <BsChevronUp
                      fontSize={12}
                      onClick={() => aTozSorting("manufacturer")}
                    />
                    <BsChevronDown
                      fontSize={12}
                      onClick={() => zToaSorting("manufacturer")}
                    />
                  </span>
                </div>
              </th>
              <th>
                <span className="position-relative top-4">Damages </span>
                <div className="d-inline-block item-center">
                  <span className="d-flex flex-column ps-1">
                    <BsChevronUp fontSize={12} />
                    <BsChevronDown fontSize={12} />
                  </span>
                </div>
              </th>
              <th>
                <span className="position-relative top-4"> Km</span>
                <div className="d-inline-block item-center">
                  <span className="d-flex flex-column ps-1">
                    <BsChevronUp fontSize={12} />
                    <BsChevronDown fontSize={12} />
                  </span>
                </div>
              </th>
              <th>
                <span className="position-relative top-4">Invoices</span>
                <div className="d-inline-block item-center">
                  <span className="d-flex flex-column ps-1">
                    <BsChevronUp fontSize={12} />
                    <BsChevronDown fontSize={12} />
                  </span>
                </div>
              </th>
              <th>
                <i class="ti ti-dots-vertical ti-sm lh-1"></i>
              </th>
            </tr>
          </thead>
          {!commonLoading && (
            <tbody>
              {clientListData.length > 0 &&
                clientListData?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <div className="name">
                        {item?.general_id?.manufacturer?.name}
                        {item?.general_id?.model?.name}
                      </div>
                      <div className="email">
                        {formatDate(item?.start_date)}-
                        {formatDate(item?.return_date)}
                      </div>
                    </td>
                    <td className="text-nowrap">1 Major</td>
                    <td className="text-nowrap">0</td>
                    <td>
                      {item?.invoices === "ALL INVOICES PAID" ? (
                        <span className="btn btn-label-success waves-effect py-1 mx-1 my-1">
                          ALL INVOICES PAID
                        </span>
                      ) : (
                        <span className="btn btn-label-danger waves-effect py-1 mx-1 my-1 fs-7">
                          1 OVERDUE INVOICE
                        </span>
                      )}
                    </td>
                    <td>
                      <i class="ti ti-dots-vertical ti-sm lh-1"></i>
                    </td>
                  </tr>
                ))}
            </tbody>
          )}
        </table>
        {commonLoading && (
          <div className="card-body">
            <Loader />
          </div>
        )}
        {clientListData === false && !commonLoading && (
          <div className="text-center pt-4 pb-4 mb-2">No Data Found</div>
        )}
      </div>
      {clientListData.length > 0 && (
        <TableFooter
          itemsPerPage={itemsPerPage}
          handleSelectChange={handleSelectChange}
          listData={clientListData}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default Historical;
