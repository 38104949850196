import { createSlice } from "@reduxjs/toolkit";
import {
  getCheckFinal,
  getChecklist,
  getDamageById,
  getImageById,
  getListCheckin,
  getSingleCheckData,
  getnewChecklist,
  listDataExteriorElement,
  listDataExteriorStripe,
  listDataInteriorParts,
  listDataInteriorStripe,
  listdataExteriorParts,
} from "../services/check";

const checkSlice = createSlice({
  name: "checkSlice",
  initialState: {
    checkListData: [],
    checkListInData: [],

    exteriorPartsDropdownData: [],
    exteriorStripeDropdownData: [],
    exteriorElementDropdownData: [],
    interiorPartsDropdownData: [],
    interiorStripeDropdownData: [],
    checkListSingle: null,
    checkImageListByType: null,
    damagelistSingle: null,
    finalCheckList: null,
    loading: true,
  },
  reducers: {
    checkList: (state, action) => {
      state.checkListData = action.payload;
    },
    checkListImage: (state, action) => {
      state.checkListSingle = action.payload;
    },
    checkListImageByType: (state, action) => {
      state.checkImageListByType = action.payload;
    },
    listBydamageId: (state, action) => {
      state.damagelistSingle = action.payload;
    },
    listCheckFinal: (state, action) => {
      state.finalCheckList = action.payload;
    },
    checkInList: (state, action) => {
      const temData = action.payload.map((item) => ({
        ...item,
        status: 3,
      }));
      state.checkListInData = temData;
    },
    exteriorPartsDropdownList: (state, action) => {
      state.exteriorPartsDropdownData = action.payload;
    },
    exteriorStripeDropdownList: (state, action) => {
      state.exteriorStripeDropdownData = action.payload;
    },
    exteriorElementDropdownList: (state, action) => {
      state.exteriorElementDropdownData = action.payload;
    },
    InteriorPartsDropdownList: (state, action) => {
      state.interiorPartsDropdownData = action.payload;
    },
    InteriorStripeDropdownList: (state, action) => {
      state.interiorStripeDropdownData = action.payload;
    },
    loading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const {
  loading,
  checkInList,
  checkList,
  checkListImage,
  checkListImageByType,
  exteriorPartsDropdownList,
  exteriorStripeDropdownList,
  exteriorElementDropdownList,
  InteriorPartsDropdownList,
  InteriorStripeDropdownList,
  listBydamageId,
  listCheckFinal
} = checkSlice.actions;
export default checkSlice.reducer;


const setDataFormat = (data) => {
  let temp = [];
  data && data.map(res => {
    try {
      let customer = res && res?.customer;
      let reservation = res && res?.reservation;

      const today = new Date();
      const returnDays = new Date(reservation?.start_date);
      var timeDifference = returnDays?.getTime() - today.getTime();
      var dayDifference = timeDifference / (1000 * 3600 * 24);
      dayDifference = Math.round(dayDifference);



      let cusFirstname = customer?.firstname;
      let cusLastname = customer?.lastname;
      let li_plate = reservation.general_info?.license_plate;
      let manufactname = reservation.general_info.manufacturer?.name;
      let manufactmodel = reservation.general_info.model?.name;
      let deliverydate = reservation?.start_date;
      let subname = res && res.customer && res.customer.connectedCustomers && res.customer.connectedCustomers.length > 1 &&
        res.customer.connectedCustomers[1].MAIN && res.customer.connectedCustomers[1].MAIN.sub_id && res.customer.connectedCustomers[1].MAIN.sub_id.subscription_name
        ? res.customer.connectedCustomers[1].MAIN.sub_id.subscription_name : null
      let status = reservation?.reservation_status;
      let cusMainFirstname = customer.connectedCustomers.map(ele =>
        ele.MAIN?.firstname
      )
      let cusMainLastname = customer.connectedCustomers.map(ele =>
        ele.MAIN?.lastname
      )
      let cusAuxFirstname = customer.connectedCustomers.map(ele =>
        ele.AUX.map(ele => ele?.firstname)
      )
      let cusAuxLastname = customer.connectedCustomers.map(ele =>
        ele.AUX.map(ele => ele?.lastname)
      )

      let auxDrivers = res && res.customer && res.customer.connectedCustomers && res.customer.connectedCustomers.length > 1 &&
        res.customer.connectedCustomers[1].AUX ? res.customer.connectedCustomers[1].AUX : [];

      let mainDirv = res && res.customer && res.customer.connectedCustomers && res.customer.connectedCustomers.length > 1 &&
        res.customer.connectedCustomers[1].MAIN ? res.customer.connectedCustomers[1].MAIN : null;

      const categoryMap = {
        1: "category1",
        2: "category2",
        3: "category3",
        4: "category4",
        5: "category5",
        6: "category6",
      };
      const categoryKey = categoryMap[reservation?.general_info?.vehicle_category?.name];
      const price = customer.sub_id.bail[categoryKey];

      temp.push({
        _id: reservation._id,
        clientName: cusFirstname + ' ' + cusLastname,
        Maindrivername: mainDirv && mainDirv?.firstname + ' ' + mainDirv?.lastname,
        Auxdrivername: cusAuxFirstname + cusAuxLastname,
        leftDays: dayDifference,
        license_plate: li_plate,
        manufacturer: manufactname,
        model: manufactmodel,
        general_info: reservation.general_info,
        return_date: deliverydate,
        subscriptionname: subname,
        monthlyprice: price,
        reservation_status: status,
        auxDrivers: auxDrivers
      })

    } catch (e) {
      console.log(e);
    }
  })
  return temp;
}

// api call
export const fetchCheck = () => async (dispatch) => {
  try {
    const tempData = await getnewChecklist();
    const finalResponse = setDataFormat(tempData?.data)
    dispatch(checkList(finalResponse));
    dispatch(loading(false));
  } catch (error) {
    console.log("Error fetchCheck", error);
  }
};


export const fetchCheckin = () => async (dispatch) => {
  try {
    dispatch(loading(true));
    const tempData = await getListCheckin();
    dispatch(checkInList(tempData?.data));
    dispatch(loading(false));
  } catch (error) {
    console.log("Error fetchCheckIn", error);
  }
};

export const listDataExteriorPartsDropdown = () => async (dispatch) => {
  try {
    const clientData = await listdataExteriorParts();
    dispatch(exteriorPartsDropdownList(clientData?.data));
  } catch (error) {
    console.error("Error listDataExteriorPartsDropdown:", error);
  }
};

export const listDataExteriorStripeDropdown = () => async (dispatch) => {
  try {
    const clientData = await listDataExteriorStripe();
    dispatch(exteriorStripeDropdownList(clientData?.data));
  } catch (error) {
    console.error("Error listDataExteriorStripeDropdown:", error);
  }
};

export const listDataExteriorElementDropdown = () => async (dispatch) => {
  try {
    const clientData = await listDataExteriorElement();
    dispatch(exteriorElementDropdownList(clientData?.data));
  } catch (error) {
    console.error("Error listDataExteriorElementDropdown:", error);
  }
};

export const listDataInteriorPartsDropdown = () => async (dispatch) => {
  try {
    const clientData = await listDataInteriorParts();
    dispatch(InteriorPartsDropdownList(clientData?.data));
  } catch (error) {
    console.error("Error listDataInteriorPartsDropdown:", error);
  }
};

export const listDataInteriorStripeDropdown = () => async (dispatch) => {
  try {
    const clientData = await listDataInteriorStripe();
    dispatch(InteriorStripeDropdownList(clientData?.data));
  } catch (error) {
    console.error("Error listDataInteriorStripeDropdown:", error);
  }
};

export const fetchCheckSingle = (doby) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const tempData = await getSingleCheckData(doby);
    dispatch(checkListImage(tempData));
    dispatch(loading(false));
  } catch (error) {
    dispatch(checkListImage(null));
    console.log("Error fetchCheckIn", error);
  }
};

export const fetchCheckImageByTypeId = (doby) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const tempData = await getImageById(doby);
    dispatch(checkListImageByType(tempData));
    dispatch(loading(false));
    console.log('console.logconsole.log', tempData)
  } catch (error) {
    dispatch(checkListImageByType(null));
    console.log("Error fetchCheckIn", error);
  }
};

export const fetchDamageListById = (id) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const tempData = await getDamageById(id);
    dispatch(listBydamageId(tempData.data));
    dispatch(loading(false));
  } catch (error) {
    dispatch(listBydamageId(null));
    console.log("Error fetchCheckIn", error);
  }
};

export const fetchCheckFinalListById = (id) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const tempData = await getCheckFinal(id);
    dispatch(listCheckFinal(tempData?.data));
    dispatch(loading(false));
  } catch (error) {
    dispatch(listCheckFinal(null));
    console.log("Error fetchCheckIn", error);
  }
};