/* eslint-disable react-hooks/exhaustive-deps */
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { updataClientApi } from "../../../services/client/addclientApi";
import { fetchCustomerData } from "../../../Redux/clientsSlice";
import { SmallLoader } from "../../Common/Loader";
import { useDispatch, useSelector } from "react-redux";
import pencilIcon from "../../../assets/icons/pencil.png";
import { useParams } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import RelativeClient from "./RelativeClient";
import AddressAutocompleteInput from "../../Common/AddressAutocompleteInput";
import AutoResizingTextArea from "../../Common/AutoResizingTextArea";

const ClientDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isEdit, setEdit] = useState(true);
  const [isLoding, setLoding] = useState();
  const { customerData } = useSelector((state) => state?.clientsSlice);

  return (
    <>
      {/* CLIENT and MAIN With Entreprise */}
      {customerData?.customer.role?.includes("CLIENT") &&
        customerData?.customer.cust_type === "Entreprise" && (
          <div className="card mb-4">
            <div className="card-body">
              <Formik
                initialValues={{
                  business: customerData?.customer?.business || "",
                  vat_number: customerData?.customer?.vat_number || "",
                  invoice_email: customerData?.customer?.invoice_email || "",
                  address: `${
                    customerData?.customer?.company?.company_address || ""
                  }`,
                  buildingNumber:
                    customerData?.customer?.company?.houseno || "",
                }}
                enableReinitialize={true}
                onSubmit={async (values) => {
                  setLoding(true);
                  const formData = new FormData();
                  if (values.business)
                    formData.append("business", values.business);
                  if (values.vat_number)
                    formData.append("vat_number", values.vat_number);
                  if (values.buildingNumber)
                    formData.append("company[houseno]", values.buildingNumber);
                  if (values.address)
                    formData.append("company[company_address]", values.address);
                  if (values.invoice_email)
                    formData.append("invoice_email", values.invoice_email);
                  const data = await updataClientApi(id, formData);

                  if (data?.status) {
                    setEdit(true);
                    setLoding(false);
                    dispatch(fetchCustomerData(id));
                  }
                }}
              >
                {({ values, handleSubmit, setFieldValue }) => (
                  <Form>
                    <div className="d-flex justify-content-between position-relative mb-3">
                      {console.log("valuessfdfddf", values)}
                      <div className="position-absolute start-0 top-0">
                        {isEdit && (
                          <button
                            className="btn waves-effect waves-light p-0 align-items-start"
                            onClick={() => {
                              setEdit(false);
                            }}
                          >
                            <img src={pencilIcon} alt="pencil-icon" />
                          </button>
                        )}
                      </div>
                      <div className="mx-auto">
                        <div className="text-center d-flex justify-content-center gap-2">
                          {isEdit ? (
                            <>
                              <h4 className="mb-0">
                                {customerData?.customer?.business}{" "}
                              </h4>{" "}
                              <span className="bg-info rounded badge py-2">
                                {customerData?.customer?.comp_id?.name}
                              </span>
                            </>
                          ) : (
                            <div className="d-flex gap-2">
                              <div>
                                <button
                                  type="button"
                                  onClick={handleSubmit}
                                  className="btn btn-primary waves-effect waves-light "
                                >
                                  Save
                                  {isLoding && <SmallLoader />}
                                </button>
                              </div>
                              <Field
                                readOnly={isEdit}
                                name="business"
                                placeholder="-"
                                className={` ${
                                  isEdit ? "hide-input" : "form-control"
                                }`}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <small className="card-text text-uppercase text-muted">
                      CLIENT DETAIL
                    </small>

                    <ul className="list-unstyled mb-0 mt-2">
                      <li className="d-flex align-items-center mb-3">
                        <span className="fw-medium mx-2 text-nowrap">
                          VAT Number:
                        </span>
                        <Field
                          readOnly={isEdit}
                          type="text"
                          name="vat_number"
                          className={`${
                            isEdit ? "hide-input" : "form-control"
                          }`}
                          placeholder="-"
                        />
                      </li>

                      {isEdit ? (
                        <li className="d-flex align-items-start mb-3">
                          <span className="fw-medium mx-2 text-heading text-nowrap">
                            Address:
                          </span>

                          <AutoResizingTextArea
                            value={values.address}
                            name="address"
                            id="address"
                            className="w-100 hide-input resize-none"
                          />
                        </li>
                      ) : (
                        <li className="d-flex align-items-center mb-3">
                          <span className="fw-medium mx-2 text-heading text-nowrap">
                            Address:
                          </span>
                          <AddressAutocompleteInput
                            name="address"
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </li>
                      )}
                      <li className="d-flex align-items-center mb-3">
                        <span className="fw-medium mx-2 text-heading text-nowrap">
                          Street/Building No:
                        </span>
                        <Field
                          readOnly={isEdit}
                          name="buildingNumber"
                          placeholder=" "
                          className={`${
                            isEdit ? "hide-input" : "form-control"
                          }`}
                        />
                      </li>

                      <li className="d-flex  align-items-center mb-3 mt-2">
                        <span className="fw-medium mx-2 text-nowrap">
                          Invoice Email:
                        </span>
                        <Field
                          readOnly={isEdit}
                          type="text"
                          name="invoice_email"
                          className={` ${
                            isEdit ? "hide-input" : "form-control"
                          }`}
                          placeholder="-"
                        />
                      </li>
                    </ul>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}

      {/* AUX And MAIN With Entreprise */}
      {(customerData?.customer.role?.includes("MAIN") ||
        customerData?.customer.role?.includes("AUX")) &&
        customerData?.relatedCustomer &&
        customerData?.relatedCustomer.cust_type === "Entreprise" && (
          <div className="card mb-4">
            <div className="card-body">
              <Formik
                initialValues={{
                  business: customerData?.relatedCustomer?.business || "",
                  vat_number: customerData?.relatedCustomer?.vat_number || "",
                  invoice_email:
                    customerData?.relatedCustomer?.invoice_email || "",
                  //   ${
                  //   customerData?.relatedCustomer?.company?.houseno
                  //     ? customerData?.relatedCustomer?.company?.houseno + ", "
                  //     : ""
                  // }
                  address: `${
                    customerData?.relatedCustomer?.company?.company_address ||
                    ""
                  }`,
                  buildingNumber:
                    customerData?.customer?.company?.houseno || "",
                }}
                enableReinitialize={true}
                onSubmit={async (values) => {
                  setLoding(true);
                  const formData = new FormData();
                  if (values.business)
                    formData.append("business", values.business);
                  if (values.vat_number)
                    formData.append("vat_number", values.vat_number);
                  if (values.address)
                    formData.append("address", values.address);
                  if (values.invoice_email)
                    formData.append("invoice_email", values.invoice_email);
                  const data = await updataClientApi(id, formData);

                  if (data?.status) {
                    setEdit(true);
                    setLoding(false);
                    dispatch(fetchCustomerData(id));
                  }
                }}
              >
                {({ values, handleChange }) => (
                  <Form>
                    <div className="d-flex justify-content-between position-relative">
                      <div className="mx-auto ">
                        <div className="text-center d-flex justify-content-center gap-2">
                          {isEdit ? (
                            <>
                              <h4 className="mb-0">
                                {customerData?.relatedCustomer?.business}{" "}
                              </h4>{" "}
                              <span className="bg-info rounded badge py-2">
                                {customerData?.relatedCustomer?.comp_id?.name}
                              </span>
                            </>
                          ) : (
                            <div className="d-flex gap-2 justify-content-center">
                              <h4>
                                <Field
                                  readOnly={isEdit}
                                  name="business"
                                  placeholder="-"
                                  className={` ${
                                    isEdit ? "hide-input" : "form-control"
                                  }`}
                                />
                              </h4>

                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary waves-effect waves-light "
                                >
                                  Save
                                  {isLoding && <SmallLoader />}
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <small className="card-text text-uppercase text-muted">
                      CLIENT DETAIL
                    </small>

                    <ul className="list-unstyled mb-0 mt-2">
                      <li className="d-flex align-items-center mb-3">
                        <span className="fw-medium mx-2 text-nowrap">
                          VAT Number:
                        </span>
                        <Field
                          readOnly={isEdit}
                          type="text"
                          name="vat_number"
                          className={`${
                            isEdit ? "hide-input" : "form-control"
                          }`}
                          placeholder="-"
                        />
                      </li>

                      <li className="d-flex align-items-start mb-3">
                        <span className="fw-medium mx-2 text-heading text-nowrap">
                          Address:
                        </span>

                        <AutoResizingTextArea
                          value={values.address}
                          name="address"
                          id="address"
                          className="w-100 hide-input resize-none"
                        />
                      </li>
                      <li className="d-flex align-items-center mb-3">
                        <span className="fw-medium mx-2 text-heading text-nowrap">
                          Street/Building No:
                        </span>
                        <Field
                          readOnly={isEdit}
                          name="buildingNumber"
                          placeholder=" "
                          className={`${
                            isEdit ? "hide-input" : "form-control"
                          }`}
                        />
                      </li>
                      <small className="card-text text-uppercase text-muted">
                        CONTACTS
                      </small>

                      <li className="d-flex  align-items-center mb-3 mt-2">
                        <span className="fw-medium mx-2 text-nowrap">
                          Invoice Email:
                        </span>
                        <Field
                          readOnly={isEdit}
                          type="text"
                          name="invoice_email"
                          className={` ${
                            isEdit ? "hide-input" : "form-control"
                          }`}
                          placeholder="-"
                        />
                      </li>
                    </ul>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}

      {/* AUX And MAIN With Particulier */}
      {(customerData?.customer.role?.includes("MAIN") ||
        customerData?.customer.role?.includes("AUX")) &&
        customerData?.relatedCustomer &&
        customerData?.relatedCustomer.cust_type === "Particulier" && (
          <RelativeClient />
        )}
    </>
  );
};

export default ClientDetails;
