import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, Outlet, useLocation, useParams } from "react-router-dom";
import DeleteModal from "../../Driver/Modal/DeleteModal";
import ValidateProspectModal from "../Modals/ValidateProspectModal";

const ProspectNav = () => {
  const { id } = useParams();
  const location = useLocation();
  const [modalShow, setModalShow] = useState(false);
  const [validModalShow, setValidModalShow] = useState(false);
  const { customerData } = useSelector((state) => state?.clientsSlice);
  const singleUser = customerData?.customer;
  return (
    <div className="col-md-9">
      <div className="d-flex justify-content-between  mb-4">
        <ul className="nav nav-pills flex-column flex-sm-row">
          <li
            className={`${
              location.pathname === "/driver/document" ? "active" : ""
            }`}
          >
            <NavLink className="nav-link" to={`document/${id}`}>
              <i className="ti-xs ti ti-files me-1 text-c-white" />
              <span className="text-c-white">Documents</span>
            </NavLink>
          </li>

          {singleUser?.selected_vehicle?.length > 0 && (
            <li
              className={`${
                location.pathname === "/driver/vehicle" ? "active" : ""
              }`}
            >
              <NavLink className="nav-link" to={`vehicle/${id}`}>
                <i className="ti-xs ti ti-car me-1 text-c-white" />
                <span className="text-c-white">Vehicle</span>
              </NavLink>
            </li>
          )}
          {singleUser?.customer_support && (
            <li
              className={`${
                location.pathname === "/driver/timeslot" ? "active" : ""
              }`}
            >
              <NavLink className="nav-link" to={`timeslot/${id}`}>
                <i className="ti ti-time me-1 text-c-white" />
                <span className="text-c-white">Time Slot</span>
              </NavLink>
            </li>
          )}
        </ul>
        <div className="d-flex align-items-center gap-2">
          {customerData?.customer?.beclient === "true" && (
            <button
              onClick={() => setValidModalShow(true)}
              type="submite"
              className="btn btn-label-success waves-effect "
            >
              Accepter
            </button>
          )}

          <div
            onClick={() => setModalShow(true)}
            className="btn btn-label-danger waves-effect"
            to={"/prospect/detail"}
          >
            <span className="text-c-white">Refuser</span>
          </div>
        </div>
      </div>
      <Outlet />
      {modalShow && (
        <DeleteModal
          show={modalShow}
          clientId={id}
          onHide={() => setModalShow(false)}
        />
      )}
      <ValidateProspectModal
        show={validModalShow}
        comp_id={customerData?.customer?.comp_id?._id}
        id={id}
        onHide={() => setValidModalShow(false)}
      />
    </div>
  );
};

export default ProspectNav;
