import React from "react";
import { ListGroup } from "react-bootstrap";
import moment from "moment";
import "moment/locale/fr";

const ReservationDetails = ({ stepDetail }) => {

moment.locale("fr");
// moment.updateLocale("fr", {
//   months: [
//     "Janvier",
//     "Février",
//     "Mars",
//     "Avril",
//     "Mai",
//     "Juin",
//     "Juillet",
//     "Août",
//     "Septembre",
//     "Octobre",
//     "Novembre",
//     "Décembre",
//   ],
// });

  return (
    <>
      <ListGroup>
        <ListGroup.Item className="text-center py-1">
          <p className="fw-semibold fs-6 text-black mb-0">Date de Départ</p>
          <p className="mb-0 text-wrap">{moment(stepDetail?.reservation?.start_date).format("DD/MM/YYYY")}</p>
        </ListGroup.Item>

        <ListGroup.Item className="text-center py-1">
          <p className="fw-semibold fs-6 text-black mb-0">Lieu de Livraison</p>
          <p className="mb-0 text-wrap">{stepDetail?.reservation?.dept_loc}</p>
        </ListGroup.Item>

        <ListGroup.Item className="text-center py-1">
          <p className="fw-semibold fs-6 text-black mb-0">Date de Retour</p>
          <p className="mb-0 text-wrap">{moment(stepDetail?.reservation?.return_date).format("DD/MM/YYYY")}</p>
        </ListGroup.Item>

        <ListGroup.Item className="text-center py-1">
          <p className="fw-semibold fs-6 text-black mb-0">Lieu de Retour</p>
          <p className="mb-0 text-wrap">{stepDetail?.reservation?.return_loc}</p>
        </ListGroup.Item>
      </ListGroup>
    </>
  );
};

export default ReservationDetails;
