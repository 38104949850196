import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  clientvalidateAPi,
  getSubscriptionByCompanylist,
  updataClientApi,
} from "../../../services/client/addclientApi";
import { fetchCustomerData } from "../../../Redux/clientsSlice";
import { useDispatch } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { useFormik } from "formik";

function ValidateClientModal(props) {
  const { onHide, id, customerData } = props;
  const dispatch = useDispatch();
  const [isSubscriptionValue, setSubscriptionValue] = useState([]);

  const formik = useFormik({
    initialValues: {
      subscription: "",
    },

    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("sub_id", values?.subscription?._id);
      const data = await updataClientApi(id, formData);
      await clientvalidateAPi(id);
      if (data) {
        onHide();
        dispatch(fetchCustomerData(id));
      }
    },

    validate: (values) => {
      const errors = {};
      // Add validation rules here
      if (!values?.subscription) {
        errors.subscription = "This field is required.";
      }
      // Add validation for other fields

      return errors;
    },
  });

  // subscriptionDropdownValue start
  const subscriptionDropdownValue = async (id) => {
    const subscriptionValue = await getSubscriptionByCompanylist(id);

    if (subscriptionValue !== undefined) {
      setSubscriptionValue(subscriptionValue?.data);
    } else {
      setSubscriptionValue([]);
    }
  };
  // subscriptionDropdownValue end

  useEffect(() => {
    subscriptionDropdownValue(customerData?.customer?.comp_id?._id);
  }, [customerData]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Sélectionnez le forfait
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label htmlFor="Abonnement" className="mb-1">
              Abonnement
            </label>
            <div className="col-12 col-md-3 col-lg-2 d-none d-lg-inline">
              <Dropdown
                value={formik.values.subscription}
                onChange={formik.handleChange}
                options={isSubscriptionValue}
                optionLabel="subscription_name"
                placeholder="Abonnement"
                className={`w-full ${
                  formik.errors.subscription ? "dropdown-invalid" : ""
                }`}
                name="subscription"
                {...(formik.values.subscription && { showClear: true })}
              />
            </div>

            {formik.errors.subscription && (
              <div className="invalid-feedback">
                {formik.errors.subscription}
              </div>
            )}
          </div>
          {/* <p>Êtes-vous sûr de créer ce pilote principal ?</p> */}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            type="button"
            onClick={props.onHide}
          >
            Annuler
          </button>
          {/* <button className="btn btn-danger" onClick={() =>}> */}
          <button className="btn btn-success" type="submit">
            validate
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
export default ValidateClientModal;
