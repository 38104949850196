import React from "react";

const Navbar = ({ setToggle }) => {
  return (
    <nav
      className="d-xl-none layout-navbar container-fuild navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
      id="layout-navbar"
    >
      <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0   d-xl-none ">
        <div
          onClick={() => setToggle(true)}
          className="nav-item nav-link px-0 me-xl-4"
        >
          <i className="ti ti-menu-2 ti-sm" />
        </div>
      </div>
      {/* Search Small Screens */}
      <div className="navbar-search-wrapper search-input-wrapper  d-none">
        <input
          type="text"
          className="form-control search-input container-xxl border-0"
          placeholder="Search..."
          aria-label="Search..."
        />
        <i className="ti ti-x ti-sm search-toggler cursor-pointer" />
      </div>
    </nav>
  );
};

export default Navbar;
