import React, { useCallback, useEffect, useRef, useState } from "react";
import { ListGroup, Modal } from "react-bootstrap";
import { IoAdd } from "react-icons/io5";
import Webcam from "react-webcam";
import { updateDriverDoc } from "../../../../services/Deliveries";
let baseImgPath = process.env.REACT_APP_IMG_URL;

const CustomerDetails = ({
  stepDetail,
  selectedResv,
  setSelectedAuxIds,
  selectAuxIds,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [showAux, setShowAux] = useState(false);
  const [formattedDetail, setFormattedDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [auxIndex, setAuxIndex] = useState(0);
  let [selectedAuxDriver, setSelectedAuxDriver] = useState([]);

  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [image, setImage] = useState(null);
  const [imageDetail, setImageDetail] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const webcamRef = useRef(null);
  const [customerId, setCustomerId] = useState(null);

  const openModal = (ele, index, cusId) => {
    setModalIsOpen(true);
    setImage(baseImgPath + "/" + ele.url);
    // setImage(`http://157.230.102.115:4003/${ele.url}`);
    setImageDetail(ele);
    setCustomerId(cusId);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setIsCameraOpen(false);
  };

  const openCamera = () => {
    setIsCameraOpen(true);
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
    setIsCameraOpen(false);
    saveImage(imageSrc);
  }, [webcamRef, image, imageDetail, customerId]);

  const saveImage = async (imageSrc) => {
    let paylod = {
      img_id: imageDetail && imageDetail._id,
      document: imageSrc,
    };

    const formData = new FormData();
    formData.append("img_id", imageDetail && imageDetail._id);
    formData.append("document", imageSrc);

    let res = await updateDriverDoc(customerId, paylod);
    if (res && res.data && res.data.url) {
      const updatedUrl = res.data.url;

      setFormattedDetails((prevDetails) => {
        const updatedMainDriverDoc = prevDetails.mainDriverDoc.map((doc) =>
          doc._id === imageDetail._id ? { ...doc, url: updatedUrl } : doc
        );

        return {
          ...prevDetails,
          mainDriverDoc: updatedMainDriverDoc,
        };
      });

      setSelectedAuxDriver((prevDrivers) =>
        prevDrivers.map((driver) => {
          const updatedDocuments = driver.documents.map((doc) =>
            doc._id === imageDetail._id ? { ...doc, url: updatedUrl } : doc
          );

          return {
            ...driver,
            documents: updatedDocuments,
          };
        })
      );
    }
  };

  let handleAddDriver = () => {
    setModalShow(true);
  };

  let handleAuxBtn = (index, aux) => {
    setSelectedAuxDriver((prev) => {
      if (!prev.some((driver) => driver._id === aux._id)) {
        return [...prev, aux];
      }
      return prev;
    });

    setSelectedAuxIds((prev) => {
      if (!prev.includes(aux._id)) {
        return [...prev, aux._id];
      }
      return prev;
    });

    setModalShow(false);
    setShowAux(true);
    setAuxIndex(index);
  };

  useEffect(() => {
    setDataFormat();
  }, [stepDetail]);

  const setDataFormat = () => {
    try {
      const status =
        stepDetail &&
        stepDetail.reservation &&
        stepDetail.reservation.reservation_status
          ? stepDetail.reservation.reservation_status
          : null;
      const val =
        stepDetail &&
        stepDetail?.customer &&
        stepDetail?.customer?.connectedCustomers &&
        stepDetail?.customer?.connectedCustomers.length > 1 &&
        stepDetail?.customer?.connectedCustomers[0]?.MAIN;

      const main = stepDetail?.customer?.connectedCustomers[1]?.MAIN;

      let customerFName = stepDetail?.customer?.firstname;
      // stepDetail.customer.connectedCustomers[0].MAIN.firstname

      let customerLName = stepDetail?.customer?.lastname;

      let cusaddressline1 = stepDetail?.customer?.address || "";
      let cusaddressline2 = stepDetail?.customer?.address1 || "";
      let cuscity = stepDetail?.customer?.city || "";
      let cusstate = stepDetail?.customer?.state || "";
      let cuspostal_code = stepDetail?.customer?.postal_code || "";
      let cuscountry = stepDetail?.customer?.country || "";

      let driverfn = val && main?.firstname && main ? main.firstname : " - ";

      let driverln =
        val && main.lastname && main && main ? main?.lastname : " - ";

      let mainDriverAddress =
        val && main.address && main ? main?.address : " - ";

      let mainDriverPhone =
        val && main?.contact && main ? main?.contact : " - ";

      let mainDriverMail = val && main ? main?.email : null;

      let mainDriverDoc = val && main && main?.documents;

      // let auxDriverDocImage =
      //   stepDetail?.customer?.connectedCustomers[1]?.AUX[auxIndex]?.documents

      // let auxDriverDocImageArray = stepDetail?.customer?.connectedCustomers[1]?.AUX[auxIndex]?.documents.map((ele)=>ele?.url);
      // console.log('auxDriverDocImage', auxDriverDocImage);

      let aux = [];

      if (status && status != "DELIVERYIN") {
        aux =
          stepDetail &&
          stepDetail?.customer &&
          stepDetail?.customer?.connectedCustomers &&
          stepDetail?.customer?.connectedCustomers[1]?.AUX &&
          stepDetail?.customer?.connectedCustomers[1]?.AUX;
      } else if (status && status === "DELIVERYIN") {
        aux =
          stepDetail &&
          stepDetail.reservation &&
          stepDetail.reservation.linked_driver_details &&
          stepDetail.reservation.linked_driver_details.length
            ? stepDetail.reservation.linked_driver_details
            : [];
        setShowAux(true);
      }

      if (aux && aux.length > 0) {
        aux = aux?.map((auxDriver) => {
          let auxAddress =
            (auxDriver.address || "") +
            (auxDriver.address && auxDriver.address1 ? ", " : ", ") +
            (auxDriver.address1 || "") +
            (auxDriver.address1 && auxDriver.city ? ", " : "") +
            (auxDriver.city || "") +
            (auxDriver.city && auxDriver.state ? ", " : "") +
            (auxDriver.state || "") +
            (auxDriver.state && auxDriver.postal_code ? ", " : "") +
            (auxDriver.postal_code || "") +
            (auxDriver.postal_code && auxDriver.country ? ", " : "") +
            (auxDriver.country || "");

          return {
            ...auxDriver,
            formattedAddress: auxAddress || "No Address Found",
          };
        });
      }

      if (status && status === "DELIVERYIN" && aux && aux.length) {
        setSelectedAuxDriver(aux);
      }

      // if (aux && aux.length) {
      //   aux = aux?.map((auxDriver) => {
      //     let auxAddress =
      //       (auxDriver.address || "") +
      //       (auxDriver.address && auxDriver.address1 ? ", " : ", ") +
      //       (auxDriver.address1 || "") +
      //       (auxDriver.address1 && auxDriver.city ? ", " : "") +
      //       (auxDriver.city || "") +
      //       (auxDriver.city && auxDriver.state ? ", " : "") +
      //       (auxDriver.state || "") +
      //       (auxDriver.state && auxDriver.postal_code ? ", " : "") +
      //       (auxDriver.postal_code || "") +
      //       (auxDriver.postal_code && auxDriver.country ? ", " : "") +
      //       (auxDriver.country || "");

      //     return {
      //       ...auxDriver,
      //       formattedAddress: auxAddress || "No Address Found",
      //     };
      //   });
      // }

      let temp = {
        customerName: customerFName + (customerFName && " ") + customerLName,
        cusaddressline1: cusaddressline1,
        cusaddressline2: cusaddressline2,
        cuscity: cuscity,
        cusstate: cusstate,
        cuspostal_code: cuspostal_code,
        cuscountry: cuscountry,
        cusAddress:
          cusaddressline1 +
            (cusaddressline1 && ", ") +
            cusaddressline2 +
            (cusaddressline2 && ", ") +
            cuscity +
            (cuscity && ", ") +
            cusstate +
            (cusstate && ", ") +
            cuspostal_code ?? "No Address Found",

        mainDriver: (driverfn && driverfn) + (driverfn && " ") + driverln,
        auxDrivers: aux,
        mainDriverAddress: mainDriverAddress,
        mainDriverMail: mainDriverMail,
        mainDriverPhone: mainDriverPhone,
        reserStatus: status,
        mainDriverDoc: mainDriverDoc,
        mainDriverCusID: main && main._id ? main._id : null,
      };

      setFormattedDetails(temp);
      setLoading(false);
    } catch (error) {
      console.log("err in try", error);
    }

    // let driver = stepDetail && stepDetail.customer && stepDetail.customer.connectedCustomers && stepDetail.customer.connectedCustomers.length > 1 &&
    // stepDetail.customer.connectedCustomers[1].MAIN ? stepDetail.customer.connectedCustomers[1].MAIN : null;
  };
  // let index = 1;

  const videoConstraints = {
    facingMode: { exact: "environment" }, // for back cam
    // facingMode: "user", // for front cam
  };

  return (
    <>
      {!loading && (
        <>
          <ListGroup>
            <ListGroup.Item className="text-center py-1">
              <p className="fw-semibold text-black fs-6 mb-0 text-black">
                Detail du client
              </p>
              <p className="mb-0 text-wrap">{formattedDetail.customerName}</p>
            </ListGroup.Item>
            <ListGroup.Item className="text-center py-1">
              <p className="fw-semibold text-black fs-6 mb-0">
                Address du client
              </p>
              <p className="mb-0 text-wrap">{formattedDetail.cusAddress}</p>
            </ListGroup.Item>
            <ListGroup.Item className="text-center py-1">
              <p className="fw-semibold text-black fs-6 mb-0">
                Conductueur principale
              </p>
              <p className="mb-0 text-wrap">{formattedDetail?.mainDriver}</p>
            </ListGroup.Item>
            <ListGroup.Item className="text-center py-1">
              <p className="fw-semibold text-black fs-6 mb-0">
                Address du conducteur principale
              </p>
              <p className="mb-0 text-wrap">
                {formattedDetail?.mainDriverAddress}
              </p>
            </ListGroup.Item>
            <ListGroup.Item className="text-center py-1">
              <p className="fw-semibold text-black fs-6 mb-0">
                Moyens de contact du conducteur
              </p>
              <p className="mb-0 text-wrap">
                {formattedDetail?.mainDriverMail}
              </p>
              <p className="mb-0 text-wrap">
                {formattedDetail?.mainDriverPhone}
              </p>
            </ListGroup.Item>
            <ListGroup.Item className="">
              <p className="fw-semibold text-black fs-6 mb-1 text-center text-black">
                Documents
              </p>
              <div className="d-flex justify-content-center gap-2">
                {formattedDetail?.mainDriverDoc &&
                  formattedDetail?.mainDriverDoc.length > 0 &&
                  formattedDetail?.mainDriverDoc.map((ele, ind) => {
                    return (
                      <div className="">
                        <>
                          <p className="mb-2 text-center">
                            {/* {ele.firstname} {ele.lastname} */}
                          </p>
                          <img
                            onClick={() =>
                              openModal(
                                ele,
                                ind,
                                formattedDetail.mainDriverCusID
                              )
                            }
                            style={{ cursor: "pointer", height: "51px" }}
                            src={`${baseImgPath + "/"}${ele.url}`}
                            // src={`${"http://157.230.102.115:4003/"}${ele.url}`}
                            width="83"
                            height="51"
                            alt="No Documents Found"
                            className="rounded-0"
                          />
                        </>
                      </div>
                    );
                  })}
              </div>
            </ListGroup.Item>

            {showAux &&
              selectedAuxDriver.length > 0 &&
              selectedAuxDriver.map((aux, i) => (
                <>
                  <ListGroup.Item className="text-center py-1" key={i}>
                    <p className="fw-semibold text-black fs-6 mb-0">
                      Conducteur Auxiliaire
                    </p>
                    <p className="mb-0 text-wrap">
                      {aux?.firstname} {aux?.lastname}
                    </p>
                  </ListGroup.Item>
                  <ListGroup.Item className="text-center py-1" key={i}>
                    <p className="fw-semibold text-black fs-6 mb-0">
                      Address du conducteur principale
                    </p>
                    <p className="mb-0 text-wrap">{aux?.formattedAddress}</p>
                  </ListGroup.Item>
                  <ListGroup.Item className="text-center py-1" key={i}>
                    <p className="fw-semibold text-black fs-6 mb-0">
                      Moyens de contact du conducteur
                    </p>
                    <p className="mb-0 text-wrap">{aux?.email}</p>
                    <p className="mb-0 text-wrap">{aux?.contact}</p>
                  </ListGroup.Item>
                  <ListGroup.Item className="deliveryStepBorder-bottom" key={i}>
                    <p className="fw-semibold text-black fs-6 mb-1 text-center text-black">
                      Documents
                    </p>
                    <div className="d-flex justify-content-center gap-3">
                      <div className="d-flex">
                        {aux?.documents &&
                          aux?.documents.map((ele, ind, aux_id) => {
                            return (
                              <>
                                <p className="mb-2 text-center" key={ind}></p>
                                <img
                                  onClick={() => openModal(ele, ind, aux._id)}
                                  style={{ cursor: "pointer", height: "51px" }}
                                  src={`${baseImgPath + "/"}${ele.url}`}
                                  width="83"
                                  height="51"
                                  alt="No Documents Found"
                                  className="rounded-0"
                                />
                              </>
                            );
                          })}
                      </div>
                    </div>
                  </ListGroup.Item>
                </>
              ))}

            {formattedDetail &&
              formattedDetail.reserStatus &&
              formattedDetail.reserStatus != "DELIVERYIN" &&
              formattedDetail.auxDrivers &&
              formattedDetail.auxDrivers.length > 0 && (
                <>
                  <ListGroup.Item
                    className="text-center py-1 pb-3"
                    onClick={handleAddDriver}
                  >
                    <p className="fw-semibold text-black fs-6 mb-0">
                      Conduteur Auxiliaires
                    </p>
                    <IoAdd size={22} className="text-black" />
                  </ListGroup.Item>
                </>
              )}
          </ListGroup>
        </>
      )}

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="sm"
      >
        <Modal.Body className="text-center d-flex flex-column gap-2">
          {formattedDetail?.auxDrivers?.map((aux, index) => (
            <button
              key={index}
              className="border-0 bg-transparent"
              onClick={() => handleAuxBtn(index, aux)}
            >
              {aux?.firstname} {aux?.lastname}
            </button>
          ))}
        </Modal.Body>
      </Modal>

      <Modal
        show={modalIsOpen}
        onHide={closeModal}
        centered
        size="lg"
        style={{
          borderRadius: "19px !important",
          backgroundColor: "#00000099",
        }}
      >
        <div>
          {isCameraOpen ? (
            <div className=" d-flex flex-column">
              <Webcam
                screenshotQuality={1}
                videoConstraints={videoConstraints}
                audio={false}
                screenshotQuality={1}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                style={{ border: "5px solid white", borderRadius: "19px" }}
              />
              <svg
                onClick={capture}
                className="position-absolute mt-5 start-25"
                style={{ top: "100%", right: "45%" }}
                // width="54"
                height="54"
                viewBox="0 0 54 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="27"
                  cy="27"
                  r="27"
                  transform="rotate(90 27 27)"
                  fill="white"
                />
              </svg>
            </div>
          ) : (
            <div
              className="d-flex flex-column pt-4"
              style={{ background: "#252525D1" }}
            >
              <img src={image} alt="Preview" />
              <div className="" style={{ background: "#252525D1" }}>
                {formattedDetail &&
                  formattedDetail.reserStatus &&
                  formattedDetail.reserStatus != "DELIVERYIN" && (
                    <>
                      <button
                        style={{ background: "#252525D1" }}
                        className="p-2 border-1 border-end-2 border-top-0 border-start-0 border-bottom-0 w-50 text-white"
                        onClick={openCamera}
                      >
                        Modifier
                      </button>
                    </>
                  )}
                <button
                  style={{ background: "#252525D1" }}
                  className="p-2 border-0 w-50 text-white"
                  onClick={closeModal}
                >
                  Ok
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default CustomerDetails;
