export const InvoiceStatus = ({ item }) => {
  console.log("🚀 ~ file: InvoiceStatus.js:2 ~ InvoiceStatus ~ item:", item)
  if (item?.status === 0) {
    return <span className="rounded badge bg-primary">A Faire</span>;
  }
  if (item?.status === 1) {
    return <span className="bg-success rounded badge">Payé</span>;
  }
  if (item?.status === 2) {
    return <span className="rounded badge bg-secondary">Partially Paid</span>;
  }
  if (item?.status === 3) {
    return <span className="bg-info rounded badge">En Attente</span>;
  }
  if (item?.status === 4) {
    return <span className="rounded badge bg-danger">Impayé</span>;
  }
};

export const ReservationStatus = (status) => {
  const resStatus = {
    CONFIRMED: "Confirmé",
    "NOT CONFIRMED": "Brouillon",
    "WAIT CONFIRMED": "Non Confirmé",
  };
  return resStatus[status?.toUpperCase()];
};
