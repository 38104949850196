import { createSlice } from "@reduxjs/toolkit";
import { invoiceList } from "../services/Invoice";
import { getCompanyDetails } from "../services/Company/addCompanyApi";
import { clientById } from "../services/client/addclientApi";

const invoiceSlice = createSlice({
  name: "invoiceSlice",
  initialState: {
    invoiceData: [],
    singleCompanyData: [],
    singleClientData: [],
    invoiceDataList: [],
    loading: false,
  },
  reducers: {
    loading: (state) => {
      state.loading = true;
    },
    getInvoiceList: (state, action) => {
      state.invoiceData = action.payload;
      state.invoiceDataList = action.payload;
      state.loading = false
    },
    getSingleCompanyData: (state, action) => {
      state.singleCompanyData = action.payload;
    },
    getSingleClientData: (state, action) => {
      state.singleClientData = action.payload;
    },
    searchInvoiceList: (state, action) => {
      const { search } = action.payload;
      const fillterData = state.invoiceDataList.filter((item) => {
        const invoiceMatch = item?.company?.name
          ?.toLowerCase()
          .includes(search?.toLowerCase());
        return invoiceMatch;
      });

      state.invoiceData = fillterData;
    },
  },
});

export const {
  loading,
  getInvoiceList,
  getSingleCompanyData,
  getSingleClientData,
  searchInvoiceList,
} = invoiceSlice.actions;
export default invoiceSlice.reducer;

// api call
export const fetchInvoice = () => async (dispatch) => {
  try {
    dispatch(loading());
    const tempData = await invoiceList();
    dispatch(getInvoiceList(tempData?.data));
  } catch (error) {
    console.error("Error fetchInvoice:", error);
  }
};
export const fetchSingleCompanyData = (id) => async (dispatch) => {
  try {

    dispatch(loading());
    const tempData1 = await invoiceList();
    // console.log("hiiiiiiiii Nilesh", dispatch(getInvoiceList(tempData1?.data)));

    const tempData = await getCompanyDetails(id);
    dispatch(getSingleCompanyData(tempData?.data));
  } catch (error) {
    console.error("Error fetchSingleCompanyData:", error);
  }
};
export const fetchSingleclientData = (id) => async (dispatch) => {
  try {
    const tempData = await clientById(id);
    dispatch(getSingleClientData(tempData?.data));
  } catch (error) {
    console.error("Error fetchSingleclientData:", error);
  }
};
