/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import ActivityTimeline from "../Components/DriverProfile/ActivityTimeline";
import Historique from "../Components/DriverProfile/Historical";
import Future from "../Components/DriverProfile/Future";
import { searchCarHistory } from "../../../Redux/clientsSlice";

const DriverProfile = () => {
  const inputRef = useRef();
  const dispatch = useDispatch();
  const [isSearchValue, setSearchValue] = useState("");
  const [isTab, setTab] = useState("Future");
  const tab = ["Future", "Historique"];

  return (
    <div className="row">
      <div className="col-xl-12 col-lg-8 col-md-8">
        <div className="card">
          {/* Navbar Start  */}
          <div className="card-header">
            <div className="d-flex align-items-center gap-3">
              <div className="col-md">
                <div className="d-flex justify-content-between">
                  <ul className="nav nav-pills flex-column flex-sm-row">
                    {tab?.map((item) => (
                      <li
                        onClick={() => setTab(item)}
                        key={item}
                        className={`me-3 cursor-pointer ${
                          isTab === item && "sub-menu-active"
                        }`}
                      >
                        <div className="nav-link ">{item}</div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-3 col-lg-2 d-none d-lg-inline">
                <input
                  value={isSearchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    dispatch(searchCarHistory(e.target.value));
                  }}
                  className="form-control"
                  ref={inputRef}
                  placeholder="Search"
                />
              </div>
            </div>
          </div>
          {/* Navbar End  */}

          {isTab === "Future" && <Future />}
          {isTab === "Historique" && <Historique />}
        </div>
      </div>
      <ActivityTimeline />
      <Outlet />
    </div>
  );
};

export default DriverProfile;
