/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from "react-router-dom";
import BreadCrumbs from "../Common/BreadCrumbs";
import DriverDetails from "./Components/DriverDetails";
import ClientDetails from "./Components/ClientDetails";
import LinkedDriver from "./Components/LinkedDriver";
import { useDispatch, useSelector } from "react-redux";
import DriverNavbar from "./layout/DriverNavbar";
import { useEffect } from "react";
import { fetchCustomerData, loader } from "../../Redux/clientsSlice";
import { Loader } from "../Common/Loader";
import { useLoadScript } from "@react-google-maps/api";
const libraries = ["places"];

const DriverInfo = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { customerData, loading } = useSelector((state) => state?.clientsSlice);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCfC8Izlpa7eb4Rae3EtA5-WcmrHr8BHAA",
    libraries,
  });

  useEffect(() => {
    dispatch(loader(true));
    dispatch(fetchCustomerData(id));
  }, [id]);
  if (loadError) return <></>;
  if (!isLoaded) return <></>;
  return (
    <div className="content-wrapper">
      <div className="container-fluid flex-grow-1 container-p-y">
        <BreadCrumbs title={"Customer"} subTitle={"Driver Profile"} />

        {!loading ? (
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-4">
              <DriverDetails />
              <ClientDetails />
              {customerData?.customer?.role?.includes("MAIN") && (
                <LinkedDriver />
              )}
            </div>
            {/* Nav bar start */}
            <DriverNavbar />
            {/* Nav bar end */}
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "75vh" }}
          >
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};
export default DriverInfo;
