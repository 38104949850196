import React from "react";

export const Loader = () => {
  return (
    <div className="d-flex justify-content-center">
      <div className="sk-chase sk-primary">
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
      </div>
    </div>
  );
};

export const CenterLoader = () => {
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <div className="center-loader">
        <div className="sk-chase sk-primary">
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
        </div>
      </div>
    </div>
  );
};

export const SmallLoader = () => {
  return <span class="spinner-border text-white ms-2" role="status"></span>;
};

