// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-academy .app-academy-img-height{height:130px}@media(min-width: 768px){.app-academy .app-academy-md-25{width:25%}.app-academy .app-academy-md-50{width:50%}.app-academy .app-academy-md-80{width:80%}}@media(min-width: 576px){.app-academy .app-academy-sm-40{width:40% !important}.app-academy .app-academy-sm-60{width:60% !important}}@media(min-width: 1200px){.app-academy .app-academy-xl-100{width:100% !important}.app-academy .app-academy-xl-100{width:100% !important}}
`, "",{"version":3,"sources":["webpack://./src/assets/vendor/css/pages/app-academy.css"],"names":[],"mappings":"AAAA,qCAAqC,YAAY,CAAC,yBAAyB,gCAAgC,SAAS,CAAC,gCAAgC,SAAS,CAAC,gCAAgC,SAAS,CAAC,CAAC,yBAAyB,gCAAgC,oBAAoB,CAAC,gCAAgC,oBAAoB,CAAC,CAAC,0BAA0B,iCAAiC,qBAAqB,CAAC,iCAAiC,qBAAqB,CAAC","sourcesContent":[".app-academy .app-academy-img-height{height:130px}@media(min-width: 768px){.app-academy .app-academy-md-25{width:25%}.app-academy .app-academy-md-50{width:50%}.app-academy .app-academy-md-80{width:80%}}@media(min-width: 576px){.app-academy .app-academy-sm-40{width:40% !important}.app-academy .app-academy-sm-60{width:60% !important}}@media(min-width: 1200px){.app-academy .app-academy-xl-100{width:100% !important}.app-academy .app-academy-xl-100{width:100% !important}}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
