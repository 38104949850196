import React from "react";
import { RoleLabel, statusClass } from "../Common/roles";
import PriceSection from "./PriceSection";

const RealativeClient = ({
  clientData,
  handleNavigate,
  isSingleData,
  customerData,
  isPrice,
}) => {
  return (
    <div>
      <div>
        {clientData && <div className="mb-3">Client Name</div>}

        <div className="d-flex justify-content-between align-items-center mb-3 cursor-pointer">
          <h6
            className="m-0"
            onClick={() => handleNavigate(clientData?.role, clientData?._id)}
          >
            {clientData?.firstname} {clientData?.lastname}
          </h6>
          <div>
            {clientData?.sub_id?.subscription_name && (
              <div
                className={`mx-1  ${
                  statusClass[
                    clientData?.role?.includes("MAIN")
                      ? "CLIENT"
                      : clientData?.role
                  ]
                }`}
              >
                <div className="mx-1">
                  {clientData?.sub_id?.subscription_name}
                </div>
              </div>
            )}
            <RoleLabel userrols={clientData?.role} />
          </div>
        </div>

        {/* price section  */}
        <PriceSection
          isSingleData={isSingleData}
          customerData={customerData}
          isPrice={isPrice}
        />
      </div>
    </div>
  );
};

export default RealativeClient;
