import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchFuelDropdown } from "../../../../Redux/vehicleSlice";
import { fetchfinancetypeDropdown } from "../../../../Redux/commonSlice";
import GeneralInformation from "../Components/Info/GeneralInformation";
import InformationD from "../Components/Info/InformationD";
import Tires from "../Components/Info/Tires";
import FinancialInformation from "../Components/Info/FinancialInformation";
import DamageMaintenance from "../Components/Info/DamageMaintenance";
import DamagesPrices from "../Components/Info/DamagesPrices";

const FleetInfo = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFuelDropdown());
    dispatch(fetchfinancetypeDropdown());
  }, []);

  return (
    <div className="row info">
      <div className="col-12 col-xxl-8">
        <GeneralInformation />
        <InformationD />
        <Tires />
        <FinancialInformation />
      </div>
      <div className="col-12 col-xxl-4">
        <DamageMaintenance />
        <DamagesPrices />
      </div>
    </div>
  );
};

export default FleetInfo;
