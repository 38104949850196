// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 575.98px) {
  .invoice-edit .invoice-preview-card .invoice-calculations,
  .invoice-add .invoice-preview-card .invoice-calculations {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .invoice-edit .repeater-title,
  .invoice-add .repeater-title {
    position: absolute;
    top: -1.75rem;
  }
}
.invoice-edit .invoice-preview-card .repeater-wrapper:not(:last-child),
.invoice-add .invoice-preview-card .repeater-wrapper:not(:last-child) {
  margin-bottom: 1.5rem;
}
@media print {
  .invoice-edit hr,
  .invoice-add hr {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/vendor/css/pages/app-invoice.css"],"names":[],"mappings":"AAAA;EACE;;IAEE,WAAW;EACb;AACF;AACA;EACE;;IAEE,kBAAkB;IAClB,aAAa;EACf;AACF;AACA;;EAEE,qBAAqB;AACvB;AACA;EACE;;IAEE,2BAA2B;IAC3B,8BAA8B;EAChC;AACF","sourcesContent":["@media (max-width: 575.98px) {\n  .invoice-edit .invoice-preview-card .invoice-calculations,\n  .invoice-add .invoice-preview-card .invoice-calculations {\n    width: 100%;\n  }\n}\n@media (min-width: 768px) {\n  .invoice-edit .repeater-title,\n  .invoice-add .repeater-title {\n    position: absolute;\n    top: -1.75rem;\n  }\n}\n.invoice-edit .invoice-preview-card .repeater-wrapper:not(:last-child),\n.invoice-add .invoice-preview-card .repeater-wrapper:not(:last-child) {\n  margin-bottom: 1.5rem;\n}\n@media print {\n  .invoice-edit hr,\n  .invoice-add hr {\n    margin-top: 1rem !important;\n    margin-bottom: 1rem !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
