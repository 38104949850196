import { useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import ReactPaginate from "react-paginate";
const PermissionList=()=>{
    const inputRef = useRef();
   const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10; // Change this value based on your preference
  const permesstionList=[
    {
        name:"Management",
        assignedTo:["Administrator"],
        created:"14 Apr 2021, 8:43 PM",
    },
    {
        name:"Manage Billing & Roles",
        assignedTo:["Administrator"],
        created:"16 Sep 2021, 5:20 PM",
    },
    {
        name:"Add & Remove Users",
        assignedTo:["Administrator","Manager"],
        created:"14 Oct 2021, 10:20 AM",
    },
    {
        name:"Project Planning",
        assignedTo:["Administrator","Users","Support"],
        created:"14 May 2021, 12:10 PM	",
    },
    {
        name:"Manage Email Sequences",
        assignedTo:["Administrator","Users","Support"],
        created:"23 Aug 2021, 2:00 PM	",
    },
    {
        name:"Client Communication",
        assignedTo:["Administrator","Manager"],
        created:"15 Apr 2021, 11:30 AM",
    },
    {
        name:"Only View",
        assignedTo:["Administrator","Restricted User"],
        created:"04 Dec 2021, 8:15 PM",
    },
    {
        name:"Financial Management",
        assignedTo:["Administrator","Manager"],
        created:"25 Feb 2021, 10:30 AM",
    },
    {
        name:"Manage Others’ Tasks",
        assignedTo:["Administrator","Support"],
        created:"04 Nov 2021, 11:45 AM",
    }
  ]

   // pagination start
   const paginatedPermissionList = permesstionList.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
 // pagination end

  const [selectedOption, setSelectedOption] = useState("10"); // Default option is "10"
  const options = [
    { label: '10', value: '10' },
    { label: '20', value: '20' },
    { label: '30', value: '30' }
  ];

 const handleSelectChange = (e) => {
    setSelectedOption(e.value);
  };

 return(
        <div className="content-wrapper">
  {/* Content */}
  <div className="container-fluid flex-grow-1 container-p-y">
    <h4 className="mb-4">Permissions List</h4>
    <p className="mb-4">
    Each category (Basic, Professional, and Business) includes the four predefined roles shown below.
    </p>
    <div className="card">
        <div className="card-header">
          <div className="d-flex align-items-center gap-3">
            <div className="flex-grow-1">
              <div className="col-12 col-md-3 col-lg-2 d-none d-lg-inline">
              <Dropdown
                  value={selectedOption}
                  onChange={handleSelectChange}
                  options={options}
                  placeholder="Select an option"
                  className=""
                />
            </div>

            </div>
             <div className="col-12 col-md-3 col-lg-2 d-none d-lg-inline">
            </div>
            <div className="col-12 col-md-3 col-lg-2 d-none d-lg-inline">
            </div>
            <div className="col-12 col-md-3 col-lg-2 d-none d-lg-inline">
              <input
                onChange={(e) => {
                //   settags(e.target.value);
                }}
                className="form-control"
                ref={inputRef}
                placeholder="Search"
              />
            </div>
            <div className="d-flex align-items-center flex-grow-0">
              <button
                className="dt-button add-new btn btn-primary waves-effect waves-light"
                // onClick={() => setAddShow(true)}
                type="button"
              >
                <span>
                  <span className="d-none d-sm-inline-block">
                    Add Permission
                  </span>
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className=" table-responsive">
          <table className="table  table-hover">
            <thead className="border-top">
              <tr>
                {/* <th className="d-flex justify-content-between"> */}
                <th>
                  <span className="position-relative top-4">Name	</span>
                  <div className="d-inline-block item-center">
                    <span className="d-flex flex-column ps-1">
                      <BsChevronUp
                        fontSize={12}
                        // onClick={() => aTozSorting("role")}
                      />
                      <BsChevronDown
                        fontSize={12}
                        // onClick={() => zToaSorting("role")}
                      />
                    </span>
                  </div>
                </th>
                <th>ASSIGNED TO</th>
                <th>
                CREATED DATE
                </th>
                <th>ACTIONS </th>
              </tr>
            </thead>
            <tbody>
            {paginatedPermissionList.map((item, index) => (
                <tr key={index}>
                      <td>{item?.name}</td>
                      <td className="text-nowrap">
                     
                      {item?.assignedTo.map((items,index) => (
                    items === "Administrator" ? 
                    <span key={index} className="btn btn-label-primary waves-effect py-1 mx-1 my-1">Administrator</span> :
                    items === "Manager" ?
                    <span key={index} className="btn btn-label-warning waves-effect py-1 mx-1 my-1">Manager</span> :
                    items === "Users" ?
                    <span key={index} className="btn btn-label-success waves-effect py-1 mx-1 my-1">Users</span> :
                    items === "Support" ?
                    <span key={index} className="btn btn-label-info waves-effect py-1 mx-1 my-1">Support</span> :
                    items === "Restricted User" ?
                    <span key={index} className="btn btn-label-danger waves-effect py-1 mx-1 my-1">Restricted User</span> :
                    null
                ))}
                      </td>
                      <td className="text-nowrap">
                      {item?.created}
                      </td>
                     
                      <td>
                        <i
                          onClick={() => {
                           
                          }}
                          className="menu-icon tf-icons ti ti-edit"
                          type="button"
                        />

                        <i
                          onClick={() => {
                            
                          }}
                          className="menu-icon tf-icons  ti ti-sm ti-trash"
                        />
                      </td>
                    </tr>)
            )
                  
            }   
            </tbody>
          </table>
        </div>
       
          <div className="card-body">
            <div className="text-center"></div>
          </div>
       
          <div className="card-body">
           
          </div>
          {permesstionList.length > itemsPerPage && (
          <ReactPaginate
            pageCount={Math.ceil(permesstionList.length / itemsPerPage)}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        )}
      </div>
     
    </div>
   
    </div>
    )
}
export default PermissionList;