
import { Field, FieldArray, Form, Formik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { updateVehicle } from "../../../../../services/AddVehicle";
import { Dropdown } from "primereact/dropdown";
import { Damage } from "../../../../Common/DropdownValue";
import { SmallLoader } from "../../../../Common/Loader";
import { useParams } from "react-router-dom";

const DamagesPrices = () => {
  const { id } = useParams();
  const [isEdit, setEdit] = useState(true);
  const [isLoding, setLoding] = useState(false);
  const { singlevehicleData } = useSelector((state) => state.vehicleSlice);
  const initialValues = { Damages: singlevehicleData?.Damages } || [{}];

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={async (values) => {
        setLoding(true);
        const tempData = { ...singlevehicleData, Damages: values.Damages };
        const data = await updateVehicle(id, tempData);
        if (data) {
          setEdit(true);
          setLoding(false);
        }
      }}
    >
      {({ values, handleChange, errors }) => (
        <Form>
          <div className="card mb-4">
            <div className="card-header d-flex justify-content-between">
              <h5 className="card-title mb-0">Domages and Prix</h5>
              {isEdit ? (
                <div
                  onClick={() => setEdit(false)}
                  className="btn btn-primary waves-effect waves-light"
                >
                  Modifier
                </div>
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary waves-effect waves-light"
                >
                  Save {isLoding && <SmallLoader />}
                </button>
              )}
            </div>

            <div className="card-body">
              <FieldArray
                name="Damages"
                render={(arrayHelpers) => (
                  <div>
                    {values?.Damages?.length > 0 &&
                      values?.Damages.map((tire, index) => (
                        <div className="row mb-4" key={index}>
                          <div className="col">
                            <Dropdown
                              options={Damage}
                              value={values?.Damages?.[index].damage_type}
                              onChange={handleChange}
                              name={`Damages.${index}.damage_type`}
                              placeholder="Types"
                              className={`w-full ${
                                isEdit ? "p-dropdown-hide" : ".p-dropdown"
                              } ${errors?.second_fuel && "dropdown-invalid"}`}
                            />
                          </div>
                          <div className="col">
                            <Field
                              type="number"
                              name={`Damages.${index}.damage_cost`}
                              id={`${index}.damage_cost`}
                              placeholder="Mémo"
                              className={`col ${
                                isEdit ? "hide-input" : "form-control"
                              } ${
                                errors.franchise_crash && "dropdown-invalid"
                              }`}
                            />
                          </div>
                          {!isEdit && (
                            <div className="col flex-grow-0">
                              <button
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                                className="btn btn-danger"
                              >
                                x
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                    {!isEdit && (
                      <div
                        onClick={() =>
                          arrayHelpers.push({
                            wheel_type: "",
                            memo: "",
                            price: "",
                          })
                        }
                        className="btn btn-primary waves-effect waves-light"
                      >
                        Ajouter un Type
                      </div>
                    )}
                  </div>
                )}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DamagesPrices;
