import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import React, { useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { sendInvoice } from "../../../services/Invoice";
import { NavLink } from "react-router-dom";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const SendInvoice = (props) => {
  const { invoiceShow, handleClose, istHTMl, pdfRef } = props;
  const [isAttached, setAttached] = useState();
  const fileInputRef = useRef();

  const uploadImge = (e) => {
    const selectedFile = e.target.files[0];
    setAttached(selectedFile);
  };

  const generateImage = async () => {
    const canvas = await html2canvas(pdfRef.current);

    // Convert the canvas to a data URL
    const imageDataUrl = canvas.toDataURL("image/jpeg", 0.8); // Adjust quality here

    // Return the image data URL
    return imageDataUrl;
  };

  // const input = pdfRef.current;
  // const generatePdf = async () => {
  //   const canvas = await html2canvas(pdfRef.current);
  //   const imgData = canvas.toDataURL("image/png" ,  0.2);
  //   const pdf = new jsPDF("p", "mm", "a4");

  //   const pageWidth = pdf.internal.pageSize.getWidth();
  //   const pageHeight = pdf.internal.pageSize.getHeight();
  //   const widthRatio = pageWidth / canvas.width;
  //   const heightRatio = pageHeight / canvas.height;
  //   const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

  //   const canvasWidth = canvas.width * ratio;
  //   const canvasHeight = canvas.height * ratio;

  //   const marginX = (pageWidth - canvasWidth) / 2;
  //   const marginY = 0;
  //   pdf.addImage(imgData, "PNG", marginX, marginY, canvasWidth, canvasHeight);
  //   // pdf.save("invoice.pdf");
  //   // Return the PDF data
  //   return pdf.output("datauristring");
  // };

  const openFileInput = () => {
    fileInputRef.current.click();
  };

  const From = ["usemail", "generalInvoice"];
  const formik = useFormik({
    initialValues: {
      from: "",
      to: "",
      subject: "",
      templateType: "",
      invoiceHTML: "",
    },
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("email", values?.to);
      formData.append("subject", values?.subject);
      // formData.append("invoiceHTML", JSON.stringify(istHTMl || ""));
      // formData.append("invoiceHTML" , istHTMl);
      if (formik.values.templateType === "usemail") {
        formData.append("from", values?.from);
      }
      formData.append("templateType", values?.templateType);
      formData.append("attachments", isAttached);

      // const pdfData = await generateImage();
      // // formData.append("invoiceHTML", pdfData);
      // const byteCharacters = atob(pdfData.split(",")[1]);
      // const byteNumbers = new Array(byteCharacters.length);
      // for (let i = 0; i < byteCharacters.length; i++) {
      //   byteNumbers[i] = byteCharacters.charCodeAt(i);
      // }
      // const byteArray = new Uint8Array(byteNumbers);
      // const pdfBlob = new Blob([byteArray], { type: "image/jpeg" });

      // const pdfFile = new File([pdfBlob], "image.jpg", {
      //   type: "image/jpeg",
      //   lastModified: new Date().getTime(),
      // });

      // // Add PDF as attachment to FormData
      // // formData.append("invoiceHTML", pdfBlob, "invoice.pdf");
      // // formData.append("invoiceHTML", pdfFile);
      // formData.append("attachments", pdfFile);

      const data = await sendInvoice(formData);
      if (data) {
        handleClose();
      }
    },
  });
  return (
    <>
      <Offcanvas
        show={invoiceShow}
        onHide={handleClose}
        placement="end"
        {...props}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Send Invoice</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="offcanvas-body mx-0 flex-grow-0 pt-0 h-100">
          <form
            className="add-new-user pt-0"
            id="addNewUserForm"
            onSubmit={formik.handleSubmit}
          >
            <div className="mb-3">
              <label className="form-label" htmlFor="add-user-email">
                Member
              </label>
              <Dropdown
                filter
                options={From}
                placeholder="company"
                className={`w-full`}
                name="templateType"
                value={formik.values.templateType}
                onChange={formik.handleChange}
              />
            </div>
            {formik.values.templateType === "usemail" && (
              <div className="mb-3">
                <label className="form-label" htmlFor="add-user-email">
                  from
                </label>
                <input
                  type="email"
                  placeholder="example@email.com"
                  className="form-control"
                  name="from"
                  value={formik.values.from}
                  onChange={formik.handleChange}
                />
              </div>
            )}
            <div className="mb-3">
              <label className="form-label" htmlFor="add-user-email">
                To
              </label>
              <input
                type="email"
                placeholder="cilent@email.com"
                className="form-control"
                name="to"
                value={formik.values.to}
                onChange={formik.handleChange}
              />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="add-user-email">
                Subject
              </label>
              <input
                type="text"
                placeholder="Invoice of"
                className="form-control"
                name="subject"
                value={formik.values.subject}
                onChange={formik.handleChange}
              />
            </div>
            <input
              hidden
              type="file"
              onChange={uploadImge}
              ref={fileInputRef}
            />
            <div class="mb-3">
              <span class="badge bg-label-primary" onClick={openFileInput}>
                <i class="ti ti-link ti-xs"></i>
                <span class="align-middle">Invoice Attached</span>
              </span>
            </div>
            <div class="mb-3 d-flex flex-wrap">
              <button class="btn btn-primary me-3" type="submit">
                Send
              </button>
              <button
                class="btn btn-label-secondary"
                type="button"
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
          </form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default SendInvoice;
