import { Autocomplete } from "@react-google-maps/api";
import { useRef } from "react";
export const schengenCountries = [
  "FR",
  "LU",
  "AT",
  "BE",
  "CZ",
  "DK",
  "EE",
  "FI",
  "DE",
  "GR",
  "HU",
  "IS",
  "IT",
  "LV",
  "LI",
  "LT",
  "MT",
  "NL",
  "NO",
  "PL",
  "PT",
  "SK",
  "SI",
  "ES",
  "SE",
  "CH",
];

const AddressAutocompleteInput = ({ name, values, setFieldValue }) => {
  const autocompleteRef = useRef(null);

  const handlePlaceChanged = () => {
    const autocomplete = autocompleteRef.current;

    if (autocomplete) {
      const place = autocomplete.getPlace();
      let address = "";

      const addressComponents = place?.address_components;
      if (addressComponents) {
        const uniqueComponents = new Set(
          addressComponents
            .filter((component) => !component.types.includes("street_number"))
            .map((component) => component.long_name)
        );

        // Join the unique components to form the full address
        address = Array.from(uniqueComponents).join(", ");
        const fullAddress = address?.includes(place?.name)
          ? address
          : place?.name + ", " + address;
        setFieldValue("address", fullAddress);
      }
    }
  };

  return (
    <Autocomplete
      onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
      onPlaceChanged={handlePlaceChanged}
      className="w-100"
      options={{
        componentRestrictions: { country: schengenCountries },
      }}
    >
      <input
        type="text"
        className="form-control mt-0"
        placeholder="Address"
        name={name}
        defaultValue={values.address}
        autoComplete="off"
      />
    </Autocomplete>
  );
};
export default AddressAutocompleteInput;
