import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import {
  addSubscription,
  companyDropdownList,
  deleteSubscription,
  updataSubscriptionValue,
} from "../../services/Company/addSubscriptionApi";
import { useOutletContext } from "react-router-dom";
import DeleteModal from "../Common/DeleteModal";

const AddNewSubscription = () => {
  const [isDropdownValue, setDropdownValue] = useState();
  console.log(
    "🚀 ~ file: AddNewSubscription.js:15 ~ AddNewSubscription ~ isDropdownValue:",
    isDropdownValue
  );
  const [modalShow, setModalShow] = React.useState(false);
  const [isloding, setloding] = useState(false);
  const allProps = useOutletContext();
  const getSubscription = allProps[3];
  console.log(
    "🚀 ~ file: AddNewSubscription.js:19 ~ AddNewSubscription ~ getSubscription:",
    getSubscription
  );
  const setFetchCompanylist = allProps[4];
  const isSubscriptioneBtn = allProps[5];
  const setSubscriptionDetails = allProps[6];

  // fromInitialValues starta
  const isInitialValues = {
    comp_id: getSubscription?.subscription?.comp_id || "",
    subscription_name: getSubscription?.subscription?.subscription_name || "",
    cpr: getSubscription?.subscription?.cpr || "",
    base_cost: {
      category1: getSubscription?.subscription?.base_cost?.category1 || "",
      category2: getSubscription?.subscription?.base_cost?.category2 || "",
      category3: getSubscription?.subscription?.base_cost?.category3 || "",
      category4: getSubscription?.subscription?.base_cost?.category4 || "",
      category5: getSubscription?.subscription?.base_cost?.category5 || "",
      category6: getSubscription?.subscription?.base_cost?.category6 || "",
    },
    cost_per_adk: {
      category1: getSubscription?.subscription?.cost_per_adk?.category1 || "",
      category2: getSubscription?.subscription?.cost_per_adk?.category2 || "",
      category3: getSubscription?.subscription?.cost_per_adk?.category3 || "",
      category4: getSubscription?.subscription?.cost_per_adk?.category4 || "",
      category5: getSubscription?.subscription?.cost_per_adk?.category5 || "",
      category6: getSubscription?.subscription?.cost_per_adk?.category6 || "",
    },
    total_loss_theft_access: {
      category1:
        getSubscription?.subscription?.total_loss_theft_access?.category1 || "",
      category2:
        getSubscription?.subscription?.total_loss_theft_access?.category2 || "",
      category3:
        getSubscription?.subscription?.total_loss_theft_access?.category3 || "",
      category4:
        getSubscription?.subscription?.total_loss_theft_access?.category4 || "",
      category5:
        getSubscription?.subscription?.total_loss_theft_access?.category5 || "",
      category6:
        getSubscription?.subscription?.total_loss_theft_access?.category6 || "",
    },
    bail: {
      category1: getSubscription?.subscription?.bail?.category1 || "",
      category2: getSubscription?.subscription?.bail?.category2 || "",
      category3: getSubscription?.subscription?.bail?.category3 || "",
      category4: getSubscription?.subscription?.bail?.category4 || "",
      category5: getSubscription?.subscription?.bail?.category5 || "",
      category6: getSubscription?.subscription?.bail?.category6 || "",
    },
    exte_cleaning: {
      category1: getSubscription?.subscription?.exte_cleaning?.category1 || "",
      category2: getSubscription?.subscription?.exte_cleaning?.category2 || "",
      category3: getSubscription?.subscription?.exte_cleaning?.category3 || "",
      category4: getSubscription?.subscription?.exte_cleaning?.category4 || "",
      category5: getSubscription?.subscription?.exte_cleaning?.category5 || "",
      category6: getSubscription?.subscription?.exte_cleaning?.category6 || "",
    },
    inte_cleaning: {
      category1: getSubscription?.subscription?.inte_cleaning?.category1 || "",
      category2: getSubscription?.subscription?.inte_cleaning?.category2 || "",
      category3: getSubscription?.subscription?.inte_cleaning?.category3 || "",
      category4: getSubscription?.subscription?.inte_cleaning?.category4 || "",
      category5: getSubscription?.subscription?.inte_cleaning?.category5 || "",
      category6: getSubscription?.subscription?.inte_cleaning?.category6 || "",
    },
    vehicle_immobilization_cost: {
      category1:
        getSubscription?.subscription?.vehicle_immobilization_cost?.category1 ||
        "",
      category2:
        getSubscription?.subscription?.vehicle_immobilization_cost?.category2 ||
        "",
      category3:
        getSubscription?.subscription?.vehicle_immobilization_cost?.category3 ||
        "",
      category4:
        getSubscription?.subscription?.vehicle_immobilization_cost?.category4 ||
        "",
      category5:
        getSubscription?.subscription?.vehicle_immobilization_cost?.category5 ||
        "",
      category6:
        getSubscription?.subscription?.vehicle_immobilization_cost?.category6 ||
        "",
    },
    miscSetting: {
      admin_cost: getSubscription?.miscSetting?.admin_cost || "",
      add_cost: getSubscription?.miscSetting?.add_cost || "",
      svarnish: getSubscription?.miscSetting?.svarnish || "",
      lvarnish: getSubscription?.miscSetting?.lvarnish || "",
      redowheel: getSubscription?.miscSetting?.redowheel || "",
    },
  };
  // fromInitialValues end

  // dropdownValue Start
  const dropdownValue = async () => {
    const dropdownItems = await companyDropdownList();
    setDropdownValue(dropdownItems?.data);
  };
  useEffect(() => {
    dropdownValue();
  }, []);
  // dropdownValue end

  const formik = useFormik({
    initialValues: isInitialValues,
    enableReinitialize: true,

    onSubmit: async (values) => {
      const formData = { ...values, comp_id: values?.comp_id?._id };
      if (getSubscription) {
        setloding(true);
        await updataSubscriptionValue(
          getSubscription?.subscription?._id,
          formData
        );
        setFetchCompanylist(getSubscription?._id, formData); //callCompanyList
        setloding(false);
      } else {
        setloding(true);
        await addSubscription(formData);
        setFetchCompanylist(values?.subscription_name); //callCompanyList
        setloding(false);
      }
    },
    // Add validation rules here
    validate: (values) => {
      console.log("🚀 ~ AddNewSubscription ~ values:", values);
      const errors = {};
      console.log("🚀 ~ AddNewSubscription ~ errors:", errors);
      if (!values.comp_id) {
        errors.comp_id = "This field is required.";
      }
      if (!values.subscription_name.trim()) {
        errors.subscription_name = "This field is required.";
      }
      if (!values.cpr.toString().trim()) {
        errors.cpr = "This field is required.";
      }
      if (!values?.base_cost?.category1?.toString()?.trim()) {
        errors.base_cost = "This field is required.";
      }
      if (!values.cost_per_adk?.category1?.toString()?.trim()) {
        errors.cost_per_adk = "This field is required.";
      }
      if (!values.total_loss_theft_access?.category1?.toString()?.trim()) {
        errors.total_loss_theft_access = "This field is required.";
      }
      if (!values.bail?.category1?.toString()?.trim()) {
        errors.bail = "This field is required.";
      }
      if (!values.exte_cleaning?.category1?.toString()?.trim()) {
        errors.exte_cleaning = "This field is required.";
      }
      if (!values.inte_cleaning?.category1?.toString()?.trim()) {
        errors.inte_cleaning = "This field is required.";
      }
      if (!values.vehicle_immobilization_cost?.category1?.toString()?.trim()) {
        errors.vehicle_immobilization_cost = "This field is required.";
      }
      return errors;
      // Add validation for other fields
    },
  });

  // deleteData start
  const deleteData = async (id) => {
    await deleteSubscription(id);
    setFetchCompanylist(id);
    setModalShow(false);
  };
  // deleteData end
  return (
    <form onSubmit={formik.handleSubmit}>
      <div
        id="account-details-modern-vertical"
        className={`content dstepper-block  active`}
      >
        <section className="card mb-4">
          <div className="card-header">
            <h4 className=" text-center text-primary fw-normal">
              Ajouter un Abonnement
            </h4>
            <h5 className="card-title m-0">Général</h5>
          </div>
          <div className="card-body row g-3">
            <div className="col-sm-4">
              <div class="">
                <label htmlFor="defaultSelect" class="form-label">
                  Société
                </label>
                {console.log(
                  "sdhdjnjdcjdncjdncjndjcnjdcnjdc",
                  formik.values.comp_id
                )}
                <Dropdown
                  name="comp_id"
                  value={formik.values.comp_id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  options={isDropdownValue}
                  optionLabel="name"
                  placeholder="Société"
                  className={`w-full ${
                    formik.errors.comp_id ? "dropdown-invalid" : ""
                  }`}
                />
                {formik.errors.comp_id && (
                  <div className="invalid-feedback">
                    {formik.errors.comp_id}
                  </div>
                )}
              </div>
            </div>
            <div className="col-sm-4">
              <label className="form-label" htmlFor="username-modern-vertical">
                Nom de L'Abonnement
              </label>
              <input
                type="text"
                className={`form-control ${
                  formik.errors.subscription_name ? "is-invalid" : ""
                }`}
                placeholder="Nom"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.subscription_name}
                name="subscription_name"
              />
              {formik.errors.subscription_name && (
                <div className="invalid-feedback">
                  {formik.errors.subscription_name}
                </div>
              )}
            </div>
            <div className="col-sm-4">
              <label className="form-label" htmlFor="username-modern-vertical">
                Coût Annuel
              </label>
              <input
                type="number"
                className={`form-control ${
                  formik.errors.cpr ? "is-invalid" : ""
                }`}
                placeholder="Coût"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.cpr}
                name="cpr"
              />
              {formik.errors.cpr && (
                <div className="invalid-feedback">{formik.errors.cpr}</div>
              )}
            </div>
          </div>
        </section>
        <section className="card mb-4">
          <div className="card-header">
            <h5 className="card-title m-0 ">Paramètres Divers</h5>
          </div>
          <div className="card-body row g-3">
            <div className="col-sm-6">
              <label className="form-label" htmlFor="username-modern-vertical">
                Coût Administratif
              </label>
              <input
                type="number"
                id="username-modern-vertical"
                className="form-control"
                placeholder="Coût"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.miscSetting.admin_cost}
                name="miscSetting.admin_cost"
              />
            </div>
            <div className="col-sm-6">
              <label className="form-label" htmlFor="username-modern-vertical">
                Coût Supplémentaire pour le plein d'essence
              </label>
              <input
                type="number"
                id="username-modern-vertical"
                className="form-control"
                placeholder="Coût"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.miscSetting.add_cost}
                name="miscSetting.add_cost"
              />
            </div>

            <div className="col-sm-6 form-password-toggle">
              <label className="form-label" htmlFor="password-modern-vertical">
                Petit Polissage
              </label>
              <div className="input-group input-group-merge">
                <input
                  type="number"
                  id="password-modern-vertical"
                  className="form-control"
                  placeholder="Petit vernis"
                  aria-describedby="password2-modern-vertical"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.miscSetting.svarnish}
                  name="miscSetting.svarnish"
                />
              </div>
            </div>
            <div className="col-sm-6 form-password-toggle">
              <label
                className="form-label"
                htmlFor="confirm-password-modern-vertical"
              >
                Gros Polissage
              </label>
              <div className="input-group input-group-merge">
                <input
                  type="number"
                  id="confirm-password-modern-vertical"
                  className="form-control"
                  placeholder="Coût"
                  aria-describedby="confirm-password-modern-vertical2"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.miscSetting.lvarnish}
                  name="miscSetting.lvarnish"
                />
              </div>
            </div>
            <div className="col-sm-6 form-password-toggle">
              <label
                className="form-label"
                htmlFor="confirm-password-modern-vertical"
              >
                "Refaissage" de jante (text Modifier)
              </label>
              <div className="input-group input-group-merge">
                <input
                  type="number"
                  id="confirm-password-modern-vertical"
                  className="form-control"
                  placeholder="Coût"
                  aria-describedby="confirm-password-modern-vertical2"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.miscSetting.redowheel}
                  name="miscSetting.redowheel"
                />
              </div>
            </div>
          </div>
        </section>
        <div className="card mt-4 pb-3">
          <h5 className="card-header">Catégories des Prix</h5>
          <div className=" table-responsive text-nowrap">
            <table className="table">
              <thead className="thead">
                <tr className="text-nowrap px-3 text-center">
                  <th>Categorie 1</th>
                  <th>Categorie 2</th>
                  <th>Categorie 3</th>
                  <th>Categorie 4</th>
                  <th>Categorie 5</th>
                  <th>Categorie 6</th>
                </tr>
              </thead>
              <tbody className="table-border-bottom-0">
                <div className="px-4 pb-0 mt-3">Mensualité</div>
                <tr>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        placeholder="Coût"
                        aria-describedby="validationServer03Feedback"
                        className={`form-control ${
                          formik.errors.base_cost ? "is-invalid" : ""
                        }`}
                        name="base_cost.category1"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.base_cost.category1}
                      />
                      {formik.errors.base_cost && (
                        <div className="invalid-feedback">
                          {formik.errors.base_cost}
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        placeholder="Coût1"
                        aria-describedby="password2-modern-vertical"
                        className="form-control"
                        // className={`form-control ${
                        //   formik.errors.category1 ? "is-invalid" : ""
                        // }`}
                        name="base_cost.category2"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.base_cost.category2}
                      />
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        placeholder="Coût"
                        name="base_cost.category3"
                        className="form-control"
                        // className={`form-control ${
                        //   formik.errors.category1 ? "is-invalid" : ""
                        // }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.base_cost.category3}
                      />
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        placeholder="Coût"
                        name="base_cost.category4"
                        className="form-control"
                        // className={`form-control ${
                        //   formik.errors.category1 ? "is-invalid" : ""
                        // }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.base_cost.category4}
                      />
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        placeholder="Coût"
                        className="form-control"
                        // className={`form-control ${
                        //   formik.errors.category1 ? "is-invalid" : ""
                        // }`}
                        name="base_cost.category5"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.base_cost.category5}
                      />
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        placeholder="Coût"
                        className="form-control"
                        name="base_cost.category6"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.base_cost.category6}
                      />
                    </div>
                  </td>
                </tr>

                <div className="px-4 pb-0 mt-3">Coût KM Supp.</div>
                <tr>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        // className="form-control"
                        className={`form-control ${
                          formik.errors.cost_per_adk ? "is-invalid" : ""
                        }`}
                        placeholder="Coût"
                        aria-describedby="password2-modern-vertical"
                        name="cost_per_adk.category1"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.cost_per_adk.category1}
                      />
                      {formik.errors.cost_per_adk && (
                        <div className="invalid-feedback">
                          {formik.errors.cost_per_adk}
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        className="form-control"
                        placeholder="Coût"
                        aria-describedby="password2-modern-vertical"
                        name="cost_per_adk.category2"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.cost_per_adk.category2}
                      />
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        className="form-control"
                        placeholder="Coût"
                        aria-describedby="password2-modern-vertical"
                        name="cost_per_adk.category3"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.cost_per_adk.category3}
                      />
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        className="form-control"
                        placeholder="Coût"
                        aria-describedby="password2-modern-vertical"
                        name="cost_per_adk.category4"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.cost_per_adk.category4}
                      />
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        className="form-control"
                        placeholder="Coût"
                        aria-describedby="password2-modern-vertical"
                        name="cost_per_adk.category5"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.cost_per_adk.category5}
                      />
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        placeholder="Coût"
                        aria-describedby="password2-modern-vertical"
                        className="form-control"
                        name="cost_per_adk.category6"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.cost_per_adk.category6}
                      />
                    </div>
                  </td>
                </tr>

                <div className="px-4 pb-0 mt-2">
                  Franchise perte totale ou vol
                </div>
                <tr>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        // className="form-control"
                        className={`form-control ${
                          formik.errors.total_loss_theft_access
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="Coût"
                        aria-describedby="password2-modern-vertical"
                        name="total_loss_theft_access.category1"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.total_loss_theft_access.category1}
                      />
                      {formik.errors.total_loss_theft_access && (
                        <div className="invalid-feedback">
                          {formik.errors.total_loss_theft_access}
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        className="form-control"
                        placeholder="Coût"
                        aria-describedby="password2-modern-vertical"
                        name="total_loss_theft_access.category2"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.total_loss_theft_access.category2}
                      />
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        className="form-control"
                        placeholder="Coût"
                        aria-describedby="password2-modern-vertical"
                        name="total_loss_theft_access.category3"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.total_loss_theft_access.category3}
                      />
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        className="form-control"
                        placeholder="Coût"
                        aria-describedby="password2-modern-vertical"
                        name="total_loss_theft_access.category4"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.total_loss_theft_access.category4}
                      />
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        className="form-control"
                        placeholder="Coût"
                        aria-describedby="password2-modern-vertical"
                        name="total_loss_theft_access.category5"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.total_loss_theft_access.category5}
                      />
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        placeholder="Coût"
                        aria-describedby="password2-modern-vertical"
                        className="form-control"
                        name="total_loss_theft_access.category6"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.total_loss_theft_access.category6}
                      />
                    </div>
                  </td>
                </tr>

                <div className="px-4 pb-0 mt-2">Caution</div>
                <tr>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        // className="form-control"
                        className={`form-control ${
                          formik.errors.bail ? "is-invalid" : ""
                        }`}
                        placeholder="Montant"
                        aria-describedby="password2-modern-vertical"
                        name="bail.category1"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.bail.category1}
                      />
                      {formik.errors.bail && (
                        <div className="invalid-feedback">
                          {formik.errors.bail}
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        className="form-control"
                        placeholder="Montant"
                        aria-describedby="password2-modern-vertical"
                        name="bail.category2"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.bail.category2}
                      />
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        className="form-control"
                        placeholder="Montant"
                        aria-describedby="password2-modern-vertical"
                        name="bail.category3"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.bail.category3}
                      />
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        className="form-control"
                        placeholder="Montant"
                        aria-describedby="password2-modern-vertical"
                        name="bail.category4"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.bail.category4}
                      />
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        className="form-control"
                        placeholder="Montant"
                        aria-describedby="password2-modern-vertical"
                        name="bail.category5"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.bail.category5}
                      />
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        placeholder="Coût"
                        aria-describedby="password2-modern-vertical"
                        className="form-control"
                        name="bail.category6"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.bail.category6}
                      />
                    </div>
                  </td>
                </tr>

                <div className="px-4 pb-0 mt-2">Coût Netoyage Extérieur</div>
                <tr>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        // className="form-control"
                        className={`form-control ${
                          formik.errors.exte_cleaning ? "is-invalid" : ""
                        }`}
                        placeholder="Coût"
                        aria-describedby="password2-modern-vertical"
                        name="exte_cleaning.category1"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.exte_cleaning.category1}
                      />
                      {formik.errors.exte_cleaning && (
                        <div className="invalid-feedback">
                          {formik.errors.exte_cleaning}
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        className="form-control"
                        placeholder="Coût"
                        aria-describedby="password2-modern-vertical"
                        name="exte_cleaning.category2"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.exte_cleaning.category2}
                      />
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        className="form-control"
                        placeholder="Coût"
                        aria-describedby="password2-modern-vertical"
                        name="exte_cleaning.category3"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.exte_cleaning.category3}
                      />
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        className="form-control"
                        placeholder="Coût"
                        aria-describedby="password2-modern-vertical"
                        name="exte_cleaning.category4"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.exte_cleaning.category4}
                      />
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        className="form-control"
                        placeholder="Coût"
                        aria-describedby="password2-modern-vertical"
                        name="exte_cleaning.category5"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.exte_cleaning.category5}
                      />
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        placeholder="Coût"
                        aria-describedby="password2-modern-vertical"
                        className="form-control"
                        name="exte_cleaning.category6"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.exte_cleaning.category6}
                      />
                    </div>
                  </td>
                </tr>
                <div className="px-4 pb-0 mt-2">Coût Netoyage Intérieur</div>
                <tr>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        // className="form-control"
                        placeholder="Coût"
                        aria-describedby="password2-modern-vertical"
                        name="inte_cleaning.category1"
                        className={`form-control ${
                          formik.errors.inte_cleaning ? "is-invalid" : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.inte_cleaning.category1}
                      />
                      {formik.errors.inte_cleaning && (
                        <div className="invalid-feedback">
                          {formik.errors.inte_cleaning}
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        className="form-control"
                        placeholder="Coût"
                        aria-describedby="password2-modern-vertical"
                        name="inte_cleaning.category2"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.inte_cleaning.category2}
                      />
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        className="form-control"
                        placeholder="Coût"
                        aria-describedby="password2-modern-vertical"
                        name="inte_cleaning.category3"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.inte_cleaning.category3}
                      />
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        className="form-control"
                        placeholder="Coût"
                        aria-describedby="password2-modern-vertical"
                        name="inte_cleaning.category4"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.inte_cleaning.category4}
                      />
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        className="form-control"
                        placeholder="Coût"
                        aria-describedby="password2-modern-vertical"
                        name="inte_cleaning.category5"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.inte_cleaning.category5}
                      />
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        placeholder="Coût"
                        aria-describedby="password2-modern-vertical"
                        className="form-control"
                        name="inte_cleaning.category6"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.inte_cleaning.category6}
                      />
                    </div>
                  </td>
                </tr>
                <div className="px-4 pb-0 mt-2">
                  Coût d'immobilisation véhicule
                </div>
                <tr>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        className={`form-control ${
                          formik.errors.vehicle_immobilization_cost
                            ? "is-invalid"
                            : ""
                        }`}
                        // className="form-control"
                        placeholder="Coût"
                        aria-describedby="password2-modern-vertical"
                        name="vehicle_immobilization_cost.category1"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={
                          formik.values.vehicle_immobilization_cost.category1
                        }
                      />
                      {formik.errors.vehicle_immobilization_cost && (
                        <div className="invalid-feedback">
                          {formik.errors.vehicle_immobilization_cost}
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        className="form-control"
                        placeholder="Coût"
                        aria-describedby="password2-modern-vertical"
                        name="vehicle_immobilization_cost.category2"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={
                          formik.values.vehicle_immobilization_cost.category2
                        }
                      />
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        className="form-control"
                        placeholder="Coût"
                        aria-describedby="password2-modern-vertical"
                        name="vehicle_immobilization_cost.category3"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={
                          formik.values.vehicle_immobilization_cost.category3
                        }
                      />
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        className="form-control"
                        placeholder="Coût"
                        aria-describedby="password2-modern-vertical"
                        name="vehicle_immobilization_cost.category4"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={
                          formik.values.vehicle_immobilization_cost.category4
                        }
                      />
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        className="form-control"
                        placeholder="Coût"
                        aria-describedby="password2-modern-vertical"
                        name="vehicle_immobilization_cost.category5"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={
                          formik.values.vehicle_immobilization_cost.category5
                        }
                      />
                    </div>
                  </td>
                  <td className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        id="password-modern-vertical"
                        placeholder="Coût"
                        className="form-control"
                        name="vehicle_immobilization_cost.category6"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={
                          formik.values.vehicle_immobilization_cost.category6
                        }
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex justify-content-end gap-2 mt-3">
          <button className="btn bg-label-warning" type="button">
            <span>
              <span className="d-none d-sm-inline-block">Annuler</span>
            </span>
          </button>
          {isSubscriptioneBtn && (
            <div
              onClick={async () => {
                setModalShow(true);
              }}
              className="btn bg-label-danger"
            >
              <span>
                <span className="d-none d-sm-inline-block">Supprimer</span>
              </span>
            </div>
          )}

          <button
            className="dt-button add-new btn btn-primary waves-effect waves-light"
            type="submit"
          >
            <div className="d-flex align-items-center">
              {isloding && (
                <span
                  class="spinner-border text-white me-2"
                  role="status"
                ></span>
              )}
              {/* <span className="d-none d-sm-inline-block">Ajouter</span> */}
              {isSubscriptioneBtn ? (
                <span className="d-none d-sm-inline-block">mise à jour</span>
              ) : (
                <span className="d-none d-sm-inline-block">Ajouter</span>
              )}
            </div>
          </button>
        </div>
      </div>

      {/* Modal Start  */}
      <DeleteModal
        title={"Supprimer Cette Abonnement"}
        description={"Êtes-vous sûr de supprimer le Abonnement ?"}
        show={modalShow}
        onHide={() => setModalShow(false)}
        deleteData={() => deleteData(getSubscription?.subscription?._id)}
      />
      {/* Modal end  */}
    </form>
  );
};

export default AddNewSubscription;
