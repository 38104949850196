import { createSlice } from "@reduxjs/toolkit";
import {
  getFuleDropdownValue,
  getManufacturerDropdownValue,
  getModalALLDropdownValue,
  getModalDropdownValue,
  getVehicle,
  getVehicleById,
  getDocumentById,
  singleVisibiliteInfo,
} from "../services/AddVehicle";
import { formatDate } from "../components/Common/FormatDate";

const vehicleSlice = createSlice({
  name: "vehicleSlice",
  initialState: {
    manufacturersDropdown: [],
    modalDropdown: [],
    fuleDropdown: [],
    vehicleList: [],
    vehicleAllData: [],
    vehicleSearchList: [],
    singlevehicleData: {},
    singlevehicleDocument: {},
    currentFleetPage: 0,
    loading: false,
    searchText: {
      search: "",
      company: "",
      status: "",
      owner: "",
      vehicle_location: "",
    },
  },

  reducers: {
    changeSearchText: (state, action) => {
      state.searchText = action.payload;
    },

    loader(state, action) {
      state.loading = action.payload;
    },

    getVehicleList: (state, action) => {
      const updatedVehicleList = action.payload.map((vehicle) => ({
        ...vehicle,
        updated_date: formatDate(vehicle.updated_date),
      }));
      state.vehicleList = updatedVehicleList;
      state.vehicleAllData = updatedVehicleList;
      state.vehicleSearchList = updatedVehicleList;
      state.loading = false;
    },

    getSingleVehicleData: (state, action) => {
      const { msg, ...dataWithoutMsg } = action.payload;
      state.singlevehicleData = dataWithoutMsg;
      state.loading = false;
    },

    getSingleVisibiliteData: (state, action) => {
      state.singleVisibiliteData = action.payload;
    },

    getSingleVehicleDocument: (state, action) => {
      state.singlevehicleDocument = action.payload;
    },

    getManufacturerDropdown: (state, action) => {
      const manufacturerData = action.payload;
      const sortedManufacturerData = manufacturerData?.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      state.manufacturersDropdown = sortedManufacturerData;
    },

    getModalDropdown: (state, action) => {
      const modalData = action.payload;
      const sortModalData = modalData?.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      state.modalDropdown = sortModalData;
    },

    getModalALLValues: (state, action) => {
      const modalData = action.payload;
      const sortModalData = modalData?.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      state.modalDropdown = sortModalData;
    },

    getFuleDropdown: (state, action) => {
      state.fuleDropdown = action.payload;
    },

    setFleetCurrentPage: (state, action) => {
      state.currentFleetPage = action.payload;
    },

    searchVehicleData: (state, action) => {
      const { search, company, status, owner, vehicle_location } =
        action.payload;

      const searchTerm = search?.toLowerCase();
      // const { name } = company;
      const filtered = state.vehicleAllData?.filter((item) => {
        const manufacturerMatch =
          `${item.manufacturer} ${item.model} ${item?.license_plate}`
            ?.toLowerCase()
            ?.includes(searchTerm);
        const companyMatch = company?.name
          ? item.manufacturer?.toLowerCase() === company?.name?.toLowerCase()
          : item;
        const statusMatch = status
          ? item.status?.toLowerCase() === status?.toLowerCase()
          : item;
        const ownerMatch = owner
          ? item.owner?.toLowerCase().includes(owner?.toLowerCase())
          : item;
        const locationMatch = vehicle_location
          ? item.vehicle_location
              ?.toLowerCase()
              ?.includes(vehicle_location.toLowerCase())
          : item;
        return (
          manufacturerMatch &&
          companyMatch &&
          statusMatch &&
          ownerMatch &&
          locationMatch
        );
      });
      state.vehicleSearchList = filtered;
    },

    sortingAtoZ: (state, action) => {
      state.vehicleSearchList?.sort((a, b) =>
        a?.[action.payload]?.localeCompare(b?.[action.payload])
      );
    },

    sortingZtoA: (state, action) => {
      state.vehicleSearchList?.sort((a, b) =>
        b?.[action.payload]?.localeCompare(a?.[action.payload])
      );
    },
  },
});

export const {
  getModalDropdown,
  getModalALLValues,
  getManufacturerDropdown,
  getFuleDropdown,
  getVehicleList,
  searchVehicleData,
  getSingleVehicleData,
  getSingleVehicleDocument,
  sortingAtoZ,
  sortingZtoA,
  getSingleVisibiliteData,
  changeSearchText,
  setFleetCurrentPage,
  loader,
} = vehicleSlice.actions;
export default vehicleSlice.reducer;

// Api call

// Manufacturer Start
export const fetchManufacturerDropdown = () => async (dispatch) => {
  try {
    const tempData = await getManufacturerDropdownValue();
    dispatch(getManufacturerDropdown(tempData?.data));
  } catch (error) {
    console.log(
      "🚀 ~ file: vehicleSlice.js:176 ~ fetchManufacturerDropdown ~ error:",
      error
    );
  }
};

// Modal Start
export const fetchModalDropdown = (id) => async (dispatch) => {
  try {
    const tempData = await getModalDropdownValue(id);
    dispatch(getModalDropdown(tempData?.data));
  } catch (error) {
    console.log(
      "🚀 ~ file: vehicleSlice.js:186 ~ fetchModalDropdown ~ error:",
      error
    );
  }
};
export const fetchModalAllValue = (id) => async (dispatch) => {
  try {
    const tempData = await getModalALLDropdownValue(id);
    dispatch(getModalALLValues(tempData?.data));
  } catch (error) {
    console.log(
      "🚀 ~ file: vehicleSlice.js:194 ~ fetchModalAllValue ~ error:",
      error
    );
  }
};

// FuelDropdown start
export const fetchFuelDropdown = () => async (dispatch) => {
  try {
    const tempData = await getFuleDropdownValue();
    dispatch(getFuleDropdown(tempData?.data));
  } catch (error) {
    console.log(
      "🚀 ~ file: vehicleSlice.js:204 ~ fetchFuelDropdown ~ error:",
      error
    );
  }
};

// Vehicle List
export const fetchVehicleList = () => async (dispatch) => {
  try {
    dispatch(loader(true));
    const tempData = await getVehicle();
    dispatch(getVehicleList(tempData?.data));
    dispatch(loader(false));
  } catch (error) {
    console.log(
      "🚀 ~ file: vehicleSlice.js:216 ~ fetchVehicleList ~ error:",
      error
    );
  }
};
// Single Vehicle Data
export const fetchSingleVehicleList = (id) => async (dispatch) => {
  console.log(
    "🚀 ~ file: vehicleSlice.js:237 ~ fetchSingleVehicleList ~ id:",
    id
  );
  try {
    dispatch(loader(true));
    const tempData = await getVehicleById(id);
    console.log(
      "🚀 ~ file: vehicleSlice.js:241 ~ fetchSingleVehicleList ~ tempData:",
      tempData
    );
    const tempDocumentData = await getDocumentById(id);
    dispatch(getSingleVehicleData(tempData?.data));
    dispatch(getSingleVehicleDocument(tempDocumentData?.data));
  } catch (error) {
    console.log(
      "🚀 ~ file: vehicleSlice.js:228 ~ fetchSingleVehicleList ~ error:",
      error
    );
  }
};
// Single Vehicle Data
export const fetchSingleVisibilite = (id) => async (dispatch) => {
  try {
    const tempData = await singleVisibiliteInfo(id);
    dispatch(getSingleVisibiliteData(tempData?.data));
  } catch (error) {
    console.log(
      "🚀 ~ file: vehicleSlice.js:237 ~ fetchSingleVisibilite ~ error:",
      error
    );
  }
};
