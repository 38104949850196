// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-ecommerce-category .comment-editor .ql-editor,.app-ecommerce .comment-editor .ql-editor{min-height:7rem}@media(max-width: 575.98px){.widget-separator .border-shift.border-end{border-right:none !important;border-left:none !important}}
`, "",{"version":3,"sources":["webpack://./src/assets/vendor/css/pages/app-ecommerce.css"],"names":[],"mappings":"AAAA,6FAA6F,eAAe,CAAC,4BAA4B,2CAA2C,4BAA4B,CAAC,2BAA2B,CAAC","sourcesContent":[".app-ecommerce-category .comment-editor .ql-editor,.app-ecommerce .comment-editor .ql-editor{min-height:7rem}@media(max-width: 575.98px){.widget-separator .border-shift.border-end{border-right:none !important;border-left:none !important}}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
