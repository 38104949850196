import { Modal } from "react-bootstrap";
import { GoSearch } from "react-icons/go";
// import { NavLink, useNavigate, useParams } from "react-router-dom";
import React from "react";

const ValidateAddress = ({
  address,
  showModal,
  handleValidateModal,
  handleCloseModal,
  selectedResv
}) => {
  

  return (
    <>
      <Modal
        show={showModal}
        centered
        onHide={handleCloseModal}
        animation={false}
      >
        <div className="modal-editLocation-Parent">
          <div className="modal-editLocation text-center">
            <p>Voulez-vous valider l’addresse</p>
            <div className="editLocation-input mb-3">
              <input placeholder="Address" type="search" value={address} />
              <button className="border-0 bg-transparent">
                <GoSearch />
              </button>
            </div>
            <div className="d-flex justify-content-center">
              <button className="btn btn-primary" onClick={handleValidateModal}>
                Valider
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {/* {show && <ValidateModalWindow show={show} setShow={setShow}/>
      } */}
    </>
  );
};

export default ValidateAddress;
