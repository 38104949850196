import React from "react";
import { useSelector } from "react-redux";

const TimeSlot = () => {
  const { customerData } = useSelector((state) => state?.clientsSlice);
  const singleUser = customerData?.customer;
  return (
    <div>
      {singleUser?.customer_support && (
        <div className="card p-3 fw-bold mt-3">
          Schedule time : {singleUser?.timing}
        </div>
      )}
    </div>
  );
};

export default TimeSlot;
