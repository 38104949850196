import React, { useState } from "react";
import { deleteDocumentById } from "../../../../services/AddVehicle";

const Documents = React.forwardRef(
  (
    {
      uploadImge,
      openFileInput,
      selectedImage,
      removeFile,
      isinitialImg,
      id,
      setRerender,
    },
    ref
  ) => {
    const [isImgId, setImgId] = useState("");
    console.log("🚀 ~ file: Documents.js:16 ~ initialImg:", isinitialImg);

    return (
      <div className="card mb-4">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5 className="mb-0 card-title">Documents</h5>
        </div>
        <div className="card-body">
          <div
            onClick={openFileInput}
            className="dropzone needsclick dz-clickable"
            id="dropzone-multi"
          >
            {isinitialImg?.length > 0 || selectedImage.length > 0 ? (
              <>
                <div className="row justify-content-center">
                  {selectedImage.map((image, index) => (
                    <div
                      key={index}
                      className="dz-preview dz-processing dz-image-preview dz-success dz-complete p-0"
                    >
                      <div className="dz-details">
                        <div className="dz-thumbnail">
                          <img alt="066.png" src={image.source} />
                        </div>
                        <div className="dz-filename">{image?.file?.name}</div>
                        <div className="dz-size">
                          <strong>
                            {(image.file.size / (1024 * 1024)).toFixed(2)}
                          </strong>
                          MB
                        </div>
                      </div>
                      <div
                        className="dz-remove cursor-pointer"
                        onClick={(e) => {
                          removeFile(index, e);
                        }}
                      >
                        Remove file
                      </div>
                    </div>
                  ))}
                  {isinitialImg?.map((image, index) => (
                    <div
                      key={index}
                      className="dz-preview dz-processing dz-image-preview dz-success dz-complete p-0"
                    >
                      <div className="dz-details">
                        <div className="dz-thumbnail">
                          <img
                            alt="066.png"
                            src={`${process.env.REACT_APP_IMG_URL}${image?.document}`}
                          />
                        </div>
                        <div className="dz-filename">066.png</div>
                        <div className="dz-size">
                          <strong>0.1</strong>
                          MB
                        </div>
                      </div>
                      <div
                        onClick={async (e) => {
                          e.stopPropagation();
                          await deleteDocumentById(image?._id);
                          setRerender((prev) => !prev);
                          setImgId(image?._id);
                        }}
                        className="dz-remove cursor-pointer d-flex justify-content-center align-content-center"
                      >
                        {isImgId === image?._id && (
                          <span
                            class="spinner-border spinner-border-sm text-secondary me-2"
                            role="status"
                          ></span>
                        )}
                        Remove file
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className="dz-message needsclick">
                Glissez et déposez vos documents
                <span className="text-muted d-block fw-normal mb-2">Ou</span>
                <span className="note needsclick btn bg-label-primary d-inline">
                  Parcourir les documents
                </span>
              </div>
            )}
            <input
              hidden
              multiple
              type="file"
              ref={ref}
              onChange={uploadImge}
              accept="image/*,application/pdf"
              // accept="image/*"
            />
          </div>
        </div>
      </div>
    );
  }
);

export default Documents;
