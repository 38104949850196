import React from "react";

const BreadCrumbs = ({ title, subTitle }) => {
  return (
    <h4 className="py-3">
      <span className="text-muted fw-light">{title} /</span> {subTitle}
    </h4>
  );
};

export default BreadCrumbs;
