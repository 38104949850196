import { useFormik } from "formik";
import React, { useContext } from "react";
import { Offcanvas } from "react-bootstrap";
import { InvoiceContext } from "../invoiceContext";

const InvoiceDiscount = (props) => {
  const { invoiceDiscount, handleClose, isSingleInvoice } = props;
  const { isValue, setValue } = useContext(InvoiceContext);
  const [amount, type] = isSingleInvoice?.invoice?.discount_per?.split?.("_")  ?? [];

  const handleInputChange = (event, activeInput) => {
    formik.handleChange(event);
    formik.setFieldValue(event.target.value, activeInput);
    const inactiveInput =
      activeInput === "eroAmount" ? "percentAmount" : "eroAmount";
    formik.setFieldValue(inactiveInput, "");
  };

  const formik = useFormik({
    initialValues: {
      eroAmount: type === "amount" ? amount : "",
      percentAmount: type === "per" ? amount : "",
    },
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      const updatedValue = {
        ...isValue,
        discount_per: values.percentAmount
          ? `${values.percentAmount}_per`
          : `${values.eroAmount}_amount`,
      };
      console.log(
        "🚀 ~ file: InviceDiscount.js:26 ~ onSubmit: ~ values:",
        updatedValue
      );
      setValue(updatedValue);
      handleClose();
    },
  });
  return (
    <>
      <Offcanvas
        show={invoiceDiscount}
        onHide={handleClose}
        placement="end"
        {...props}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Discount</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="offcanvas-body mx-0 flex-grow-0 pt-0 h-100">
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-3">
              <div>
                <label className="form-label" htmlFor="eroAmount">
                  Euro Amount
                </label>
                <div className="input-group mt-1">
                  <span className="input-group-text">€</span>
                  <input
                    type="number"
                    id="eroAmount"
                    name="eroAmount"
                    className="form-control invoice-amount"
                    placeholder="Enter €"
                    value={formik.values.eroAmount}
                    onChange={(e) => handleInputChange(e, "eroAmount")}
                  />
                </div>
              </div>

              <div className="mt-2">
                <label className="form-label" htmlFor="percentAmount">
                  Percentage Amount
                </label>
                <div className="input-group mt-1">
                  <span className="input-group-text">%</span>
                  <input
                    type="number"
                    id="percentAmount"
                    name="percentAmount"
                    className="form-control invoice-amount"
                    placeholder="Enter %"
                    value={formik.values.percentAmount}
                    onChange={(e) => handleInputChange(e, "percentAmount")}
                  />
                </div>
              </div>
            </div>
            <div className="mb-3 d-flex flex-wrap">
              <button className="btn btn-primary me-3" type="submit">
                Add
              </button>
            </div>
          </form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default InvoiceDiscount;
