import React from "react";
import { useSelector } from "react-redux";
import SelectedVehicle from "../Components/SelectedVehicle";

const VehicleList = () => {
  const { customerData } = useSelector((state) => state?.clientsSlice);
  const singleUser = customerData?.customer;
  return (
    <div>
      {singleUser?.selected_vehicle?.length > 0 && (
        <SelectedVehicle
          selected_vehicle={singleUser?.selected_vehicle}
          customerData={customerData}
        />
      )}
    </div>
  );
};

export default VehicleList;
