import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";

import { editManufacturer } from "../../../../services/AddVehicle";
import { fetchManufacturerDropdown } from "../../../../Redux/vehicleSlice";
const EditManufacturer = (props) => {
  const [modelName, setModelName] = useState(props.modelName);
  // const [isManufacturer , setManufacturer]=useState(props.manufacturer);
  const [isUpdateModel, setUpdateModel] = useState(false);
  const dispatch = useDispatch();
  const { singlevehicleData } = useSelector((state) => state.vehicleSlice);
  console.log(
    singlevehicleData?.General?.manufacturer?._id,
    "singlevehicleData?.General?.manufacturer?._id"
  );
  const onSaveChanges = async () => {
    setUpdateModel(true);
    try {
      const response = await editManufacturer(props.id, {
        // manufacturer:isManufacturer,
        name: modelName,
      });
      if (response) {
        props.onHide();
        // dispatch(fetchModalDropdown(singlevehicleData?.General?.manufacturer?._id));
        // dispatch(fetchModalAllValue());
        dispatch(fetchManufacturerDropdown());
      }
      return response;
    } catch (error) {
      console.log("Error updating model:", error);
    }
  };

  return (
    <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modifier le modèle
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col">
          <label className="form-label mb-1">Nom</label>
          <input
            value={modelName}
            onChange={(e) => setModelName(e.target.value)}
            type="text"
            className="form-control"
            placeholder="Modifier le modèle"
            aria-controls="DataTables_Table_0"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-label-danger waves-effect"
          onClick={props.onHide}
        >
          Annuler
        </button>
        <button
          className="btn btn-primary waves-effect waves-light"
          onClick={onSaveChanges}
        >
          Mise à jour
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditManufacturer;
