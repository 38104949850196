import React, { useRef, useState } from "react";
import BreadCrumbs from "../Common/BreadCrumbs";
import { NavLink } from "react-router-dom";
import profileImg from "../../assets/img/Angel.jpeg";

const AccountSettings = () => {
  const fileInputRef = useRef();
  const [selectedImage, setSelectedImage] = useState();
  const openFileInput = () => {
    fileInputRef.current.click();
  };
  const uploadImge = (e) => {
    const selectedFile = e.target.files;
    setSelectedImage(URL.createObjectURL(selectedFile?.[0]));
  };
  return (
    <div className="content-wrapper">
      <div className="container-fluid flex-grow-1 container-p-y">
        <BreadCrumbs title={"User Profile"} subTitle={" Profile"} />
        <div className="row">
          <div className="col-md-12">
            <ul className="nav nav-pills flex-column flex-sm-row mb-4">
              <li className="nav-item active">
                <NavLink
                  className="nav-link text-white"
                  is="dmx-link"
                  href="profile/account"
                >
                  <i className="ti-xs ti ti-user-check me-1 text-white" />
                  <span className="text-c-white">Profile</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" is="dmx-link" href="#">
                  <i className="ti-xs ti ti-layout-grid me-1" />
                  <span className="text-c-white">Documents</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" is="dmx-link" href="#">
                  <i className="ti-xs ti ti-users me-1" />
                  <span className="text-c-white"> Contracts</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" is="dmx-link" href="#">
                  <i className="ti-xs ti ti-link me-1" />
                  <span className="text-c-white">Invoices</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="card mb-4">
          <div className="card-header">Profile Details</div>
          <div className="card-body">
            <div className="d-flex align-items-start align-items-sm-center gap-4">
              <img
                src={selectedImage ? selectedImage : profileImg}
                alt="user-avatar"
                className="d-block w-px-100 h-px-100 rounded"
                id="uploadedAvatar"
              />
              <div className="">
                <label
                  htmlFor="upload"
                  className="btn btn-primary me-2 mb-3"
                  tabIndex={0}
                >
                  <span
                    onClick={openFileInput}
                    className="d-none d-sm-block"
                    data-i18n="Upload photo"
                  >
                    Upload photo
                  </span>
                  <i className="ti ti-upload d-block d-sm-none" />
                  <input
                    ref={fileInputRef}
                    onChange={(e) => uploadImge(e)}
                    type="file"
                    className="account-file-input"
                    hidden
                    accept="image/png, image/jpeg"
                    is="dmx-input-file"
                  />
                </label>
                <label className="btn btn-label-secondary account-image-reset mb-3">
                  <i className="ti ti-refresh-dot d-block d-sm-none" />
                  <span className="d-none d-sm-block" data-i18n="Reset">
                    Reset
                  </span>
                </label>
                <div className="text-muted" data-i18n="JPG or PNG">
                  JPG or PNG
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <form>
              <div className="row">
                <div className="mb-3 col-md-6">
                  <label className="form-label mb-1">First Name</label>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="First Name"
                    aria-controls="DataTables_Table_0"
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <label className="form-label mb-1">Last Name</label>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Last Name"
                    aria-controls="DataTables_Table_0"
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <label className="form-label mb-1">E-mail</label>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="@gmail.com"
                    aria-controls="DataTables_Table_0"
                  />
                </div>
                <div className="mb-3 col-md-3">
                  <label className="form-label mb-1">Company</label>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Company"
                    aria-controls="DataTables_Table_0"
                  />
                </div>
                <div className="mb-3 col-md-3">
                  <label className="form-label mb-1">Company VAT</label>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Company VAT"
                    aria-controls="DataTables_Table_0"
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <label className="form-label mb-1">Phone Number</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="0261 234556"
                    aria-controls="DataTables_Table_0"
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <label className="form-label mb-1">Address</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Address"
                    aria-controls="DataTables_Table_0"
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <label className="form-label mb-1">State</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="State"
                    aria-controls="DataTables_Table_0"
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <label className="form-label mb-1">Zipcode</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="18756"
                    aria-controls="DataTables_Table_0"
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <label className="form-label mb-1">Country</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="18756"
                    aria-controls="DataTables_Table_0"
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <label className="form-label mb-1">Language</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="18756"
                    aria-controls="DataTables_Table_0"
                  />
                </div>
              </div>
              <div className="mt-2">
                <button className="btn btn-primary me-2" type="submit">
                  Save changes
                </button>
                <button className="btn btn-label-secondary" type="reset">
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>

        {/* Delete Account start */}
        <div className="card">
          <h5 className="card-header">Delete Account</h5>
          <div className="card-body">
            <div className="mb-3 col-12 mb-0">
              <div className="alert alert-warning">
                <h5 className="alert-heading mb-1">
                  Are you sure you want to delete your account?
                </h5>
                <p className="mb-0">
                  Once you delete your account, there is no going back. Please
                  be sure.
                </p>
              </div>
            </div>
            <form
              id="formAccountDeactivation"
              is="dmx-form"
              noValidate="novalidate"
              className="fv-plugins-bootstrap5 fv-plugins-framework"
            >
              <div className="form-check mb-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="accountActivation"
                />
                <label className="form-check-label" htmlFor="accountActivation">
                  I confirm my account deactivation
                </label>
                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />
              </div>
              <button
                className="btn btn-danger deactivate-account"
                type="submit"
              >
                Deactivate Account
              </button>
              <input type="hidden" />
            </form>
          </div>
        </div>

        {/* Delete Account end */}
      </div>
    </div>
  );
};

export default AccountSettings;
