import React, { useState } from "react";
import fullLogo from "../assets/img/icons/logo/Turismo.svg";
import halfLogo from "../assets/img/icons/logo/single-logo.svg";
import { Link, NavLink, useLocation } from "react-router-dom";
// import Logo from "../assets/img/icons/logo/Logo.png";

const Sidebar = ({
  iscollapsed,
  setHover,
  setToggle,
  handleCollapsed,
  isHover,
}) => {
  const [closeHover, setCloseHover] = useState(true);

  const handleToggleHover = () => {
    handleCollapsed();
    setCloseHover(!closeHover);
    setHover(false);
  };
  const { pathname } = useLocation();
  return (
    <>
      <aside
        id="layout-menu"
        className="layout-menu menu-vertical menu bg-menu-theme overflow-auto"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div className="app-brand demo ">
          <a href="#!" className="app-brand-link">
            <img
              style={{ width: "65%" }}
              className="img-fluid"
              src={isHover ? fullLogo : !iscollapsed ? fullLogo : halfLogo}
              alt="logo-not-found"
            />
          </a>

          <div className="layout-menu-toggle menu-link text-large ms-auto cursor-pointer">
            <svg
              className="d-none d-xl-inline"
              onClick={handleToggleHover}
              width="20"
              height="20"
              viewBox="0 0 27 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="11" cy="11" r="10.5" stroke="#6F6B7D" />
              {!iscollapsed && <circle cx="11" cy="11" r="2" fill="#6F6B7D" />}
            </svg>
            <i
              onClick={() => setToggle(false)}
              className="ti ti-x d-block d-xl-none ti-sm align-middle"
            ></i>
          </div>
        </div>
        <div className="menu-inner-shadow" />
        <ul className="menu-inner py-1">
          <li className="menu-item">
            <NavLink
              to={""}
              className="menu-link"
              onClick={() => setToggle(false)}
            >
              <i className="menu-icon tf-icons ti ti-smart-home" />
              <div>Dashboards</div>
            </NavLink>
          </li>

          <li
            className="menu-header small text-uppercase"
            onClick={() => setToggle(false)}
          >
            <span className="menu-header-text" data-i18n="Apps & Pages">
              OPERATIONEL
            </span>
          </li>
          <li className="menu-item" onClick={() => setToggle(false)}>
            <NavLink
              to={"calendar"}
              href="app-calendar.html"
              className="menu-link"
            >
              <i className="menu-icon tf-icons ti ti-calendar" />
              <div data-i18n="Calendar">Calendrier</div>
            </NavLink>
          </li>
          <li className="menu-item" onClick={() => setToggle(false)}>
            <NavLink to={"driver"} className="menu-link">
              <i className="menu-icon tf-icons ti ti-users" />
              <div data-i18n="Users">Abonnés</div>
            </NavLink>
          </li>
          <li
            className="menu-header small text-uppercase"
            onClick={() => setToggle(false)}
          >
            <span className="menu-header-text" data-i18n="Apps & Pages">
              Flotte
            </span>
          </li>

          <li className="menu-item" onClick={() => setToggle(false)}>
            <NavLink to={"fleet"} className="menu-link ">
              <i className="menu-icon tf-icons ti ti-car" />
              <div data-i18n="eCommerce">Flotte</div>
            </NavLink>
          </li>

          <li className="menu-item" onClick={() => setToggle(false)}>
            <NavLink
              to={"checks/checkList"}
              className={`${
                pathname === "/checks/check-in" && "active"
              } menu-link`}
            >
              <i className="menu-icon tf-icons ti ti-checkbox" />
              <div data-i18n="Logistics">Checks</div>
            </NavLink>
          </li>
          <li className="menu-item" onClick={() => setToggle(false)}>
            <NavLink
              to={"deliveries/delivery-out"}
              className={`${
                pathname === "/deliveries/delivery-in" && "active"
              } menu-link`}
            >
              <i className="menu-icon tf-icons ti ti-repeat" />
              <div data-i18n="Invoice">Livraisons</div>
            </NavLink>
          </li>
          {/* Fleet end */}
          <li
            className="menu-header small text-uppercase"
            onClick={() => setToggle(false)}
          >
            <span className="menu-header-text" data-i18n="Apps & Pages">
              FINANCIER
            </span>
          </li>
          <li className="menu-item" onClick={() => setToggle(false)}>
            <NavLink to={"/client"} className="menu-link ">
              <i className="menu-icon tf-icons ti ti-user" />
              <div data-i18n="Pages">Client</div>
            </NavLink>
          </li>
          <li className="menu-item" onClick={() => setToggle(false)}>
            <NavLink to={"invoice"} className="menu-link ">
              <i className="menu-icon tf-icons ti ti-file-euro" />
              <div data-i18n="Users">Factures</div>
            </NavLink>
          </li>

          <li className="menu-header small text-uppercase">
            <span className="menu-header-text" data-i18n="Apps & Pages">
              MARKETING
            </span>
          </li>
          <li className="menu-item" onClick={() => setToggle(false)}>
            <NavLink to={"prospect"} className="menu-link ">
              <i className="menu-icon tf-icons ti ti-user" />
              <div data-i18n="Pages">Prospect</div>
            </NavLink>
          </li>

          <li className="menu-header small text-uppercase">
            <span className="menu-header-text" data-i18n="Apps & Pages">
              TURISMO
            </span>
          </li>

          <li className="menu-item" onClick={() => setToggle(false)}>
            <NavLink
              to={"settings/company"}
              href="app-calendar.html"
              className={`${
                pathname === "/settings/subscription" && "active"
              } menu-link`}
            >
              <i className="menu-icon tf-icons ti ti-settings" />
              <div data-i18n="Roles & Permissions">Paramètres</div>
            </NavLink>
          </li>
          <li className="menu-item" onClick={() => setToggle(false)}>
            <NavLink to={"team/profile"} className="menu-link ">
              <i className="menu-icon tf-icons ti ti-user-circle" />
              <div data-i18n="Wizard Examples">Profile</div>
            </NavLink>
          </li>
          <li className="menu-item">
            <Link
              onClick={() => localStorage.clear()}
              href="modal-examples.html"
              className="menu-link"
            >
              <i className="menu-icon tf-icons ti ti-logout" />
              <div data-i18n="Modal Examples">Se déconnecter</div>
            </Link>
          </li>
        </ul>
      </aside>
    </>
  );
};

export default Sidebar;
