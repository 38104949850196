// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-card .user-info-title{min-width:100px}@media(min-width: 1200px){.light-style .user-card .border-container-lg{border-right:1px solid #dbdade}.light-style[dir=rtl] .user-card .border-container-lg{border-right:0;border-left:1px solid #dbdade}}@media(max-width: 1199.98px){.light-style .user-card .border-container-lg{padding-bottom:1rem}}@media(min-width: 576px){.light-style .user-card .border-container{border-right:1px solid #dbdade}.light-style .timeline .break-text{width:calc(100% - 90px)}.light-style[dir=rtl] .user-card .border-container{border-right:0;border-left:1px solid #dbdade}}@media(min-width: 992px){.dark-style .user-card .border-container-lg{border-right:1px solid #434968}.dark-style[dir=rtl] .user-card .border-container-lg{border-right:0;border-left:1px solid #434968}}@media(min-width: 576px){.dark-style .user-card .border-container{border-right:1px solid #434968}.dark-style .timeline .break-text{width:calc(100% - 90px)}.dark-style[dir=rtl] .user-card .border-container{border-right:0;border-left:1px solid #434968}}
`, "",{"version":3,"sources":["webpack://./src/assets/vendor/css/pages/page-user-view.css"],"names":[],"mappings":"AAAA,4BAA4B,eAAe,CAAC,0BAA0B,6CAA6C,8BAA8B,CAAC,sDAAsD,cAAc,CAAC,6BAA6B,CAAC,CAAC,6BAA6B,6CAA6C,mBAAmB,CAAC,CAAC,yBAAyB,0CAA0C,8BAA8B,CAAC,mCAAmC,uBAAuB,CAAC,mDAAmD,cAAc,CAAC,6BAA6B,CAAC,CAAC,yBAAyB,4CAA4C,8BAA8B,CAAC,qDAAqD,cAAc,CAAC,6BAA6B,CAAC,CAAC,yBAAyB,yCAAyC,8BAA8B,CAAC,kCAAkC,uBAAuB,CAAC,kDAAkD,cAAc,CAAC,6BAA6B,CAAC","sourcesContent":[".user-card .user-info-title{min-width:100px}@media(min-width: 1200px){.light-style .user-card .border-container-lg{border-right:1px solid #dbdade}.light-style[dir=rtl] .user-card .border-container-lg{border-right:0;border-left:1px solid #dbdade}}@media(max-width: 1199.98px){.light-style .user-card .border-container-lg{padding-bottom:1rem}}@media(min-width: 576px){.light-style .user-card .border-container{border-right:1px solid #dbdade}.light-style .timeline .break-text{width:calc(100% - 90px)}.light-style[dir=rtl] .user-card .border-container{border-right:0;border-left:1px solid #dbdade}}@media(min-width: 992px){.dark-style .user-card .border-container-lg{border-right:1px solid #434968}.dark-style[dir=rtl] .user-card .border-container-lg{border-right:0;border-left:1px solid #434968}}@media(min-width: 576px){.dark-style .user-card .border-container{border-right:1px solid #434968}.dark-style .timeline .break-text{width:calc(100% - 90px)}.dark-style[dir=rtl] .user-card .border-container{border-right:0;border-left:1px solid #434968}}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
