import React from "react";
import Modal from "react-bootstrap/Modal";
import { updataClientApi } from "../../../services/client/addclientApi";
import { useDispatch } from "react-redux";
import { fetchAllUserList } from "../../../Redux/commonSlice";

function ChangeRole(props) {
  const { onHide, isData } = props;
  const dispatch = useDispatch();
  const handleConfirmation = async () => {
    await updataClientApi(isData.id, isData?.formData);
    dispatch(fetchAllUserList());
    onHide();
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{isData.msg}</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={props.onHide}>
          Annuler
        </button>
        {/* <button className="btn btn-danger" onClick={() =>}> */}
        <button className="btn btn-success" onClick={handleConfirmation}>
          Oui
        </button>
      </Modal.Footer>
    </Modal>
  );
}
export default ChangeRole;
