import { useEffect, useState } from "react";
import ModifyLocationModal from "./ModifyLocationModal";
import ValidateAddress from "./ValidateAddress";
import { Modal } from "react-bootstrap";
import ValidateModalWindow from "./ValidateModalWindow";
import { checkStatusUpdate } from "../../../services/check";
import {
  getSingleResDelivery,
  validateAddress,
} from "../../../services/Deliveries";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddressValidate,
  setLocationModal,
  setModalVerification,
} from "../../../Redux/deliverySlice";
import { toast } from "react-toastify";

const LocationCheck = ({
  showModal,
  // setShowModal,
  address,
  setAddress,
  selectedResv,
}) => {
  // const dispatch = useDispatch();

  // const {  show  } = useSelector(
  //   (state) => state?.deliverySlice
  // );
  const dispatch = useDispatch();
  const { addressValidate } = useSelector((state) => state?.deliverySlice);

  const [modifyLocation, setModifyLocation] = useState(false);
  let [stepDetail, setStepDetail] = useState(null);

  useEffect(() => {
    getReservDetail();
  }, []);

  const getReservDetail = async () => {
    let reservId = selectedResv._id;
    let details = await getSingleResDelivery(reservId);
    if (details && details.data) {
      setStepDetail(details?.data);
    }
  };

  const handleModifyFunction = (e) => {
    setModifyLocation(true);
    e.stopPropagation();
  };

  const handleValidate = () => {
    dispatch(setAddressValidate(true));
  };

  const handleCloseModal = () => {
    dispatch(setLocationModal(false));
    dispatch(setAddressValidate(false));
  };

  const handleValidateModal = async () => {
    let addresss =
      selectedResv && selectedResv.validationAddress
        ? selectedResv.validationAddress
        : "";
    let payload = {
      reservationId: selectedResv && selectedResv._id ? selectedResv._id : "",
      reservation_status: "ADDRESSVALID",
    };
    let validationParams = {
      address: {
        addressLines: [addresss],
      },
    };

    let validate = await validateAddress(validationParams);

    if (
      validate &&
      validate.result &&
      validate.result.verdict &&
      validate.result.verdict.validationGranularity &&
      validate.result.verdict.validationGranularity != "OTHER"
    ) {
      let checkStatus = await checkStatusUpdate(payload);
      if (checkStatus && checkStatus.data) {
        dispatch(setModalVerification(true));
        dispatch(setAddressValidate(false));
        getReservDetail();
      }
    } else {
      let payload = {
        reservationId: selectedResv && selectedResv._id ? selectedResv._id : "",
        reservation_status: "ADDRESSNOTVALID",
      };
      let checkStatus = await checkStatusUpdate(payload);
      if (checkStatus && checkStatus.data) {
        dispatch(setAddressValidate(false));
      }
      toast.error("L'adresse indiquée n'est pas valide", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleCloseValidateModal = () => {
    dispatch(setLocationModal(false));
  };

  return (
    <>
      <Modal
        show={showModal}
        centered
        onHide={handleCloseModal}
        animation={false}
      >
        <div className="modal-locationChecking-Parent">
          <div className="text-center modal-locationChecking">
            <div className="d-flex flex-column gap-3 p-3">
              <div>
                <p className="mb-0">Voulez-vous valider l’addresse</p>
              </div>
              <div>
                <div className="">
                  <p className="mb-0 text-wrap px-4">{address}</p>
                </div>
              </div>
              <div className="d-flex justify-content-center gap-3">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleValidate}
                >
                  Valider
                </button>

                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={handleModifyFunction}
                >
                  Modifier
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* VALIDATE BUTTON */}
        {addressValidate && (
          <ValidateAddress
            address={address}
            showModal={showModal}
            handleValidateModal={handleValidateModal}
            handleCloseModal={handleCloseModal}
            selectedResv={selectedResv}
          />
        )}

        {/* MODIFICATION BUTTON */}
        {modifyLocation && (
          <ModifyLocationModal
            address={address}
            setAddress={setAddress}
            modifyLocation={modifyLocation}
            setModifyLocation={setModifyLocation}
            showModal={showModal}
            // setShowModal={setShowModal}
            selectedResv={selectedResv}
          />
        )}

        {/* {show && (
          <ValidateModalWindow
            handleCloseModal={handleCloseModal}
            stepDetail={stepDetail}
            selectedResv={selectedResv}
            address={address}
            show={show}
            handleCloseValidateModal={handleCloseValidateModal}
          />
        )} */}
      </Modal>
    </>
  );
};

export default LocationCheck;
