import moment from "moment";
import React from "react";
import { Button } from "react-bootstrap";

const DeliveryListItem = ({
  item,
  expandedItemId,
  toggleExpand,
  ontoggleExpand,
  handleLocationCheck,
}) => {
  // let addresswithSpace = item?.addressline1  + (item?.addressline1 && ", " + item?.addressline2) + (item?.city + item?.city && ", " + item.state) + item?.postal_code + (item?.postal_code && ", " + item?.country);
  // {item.addressline1 && item.addressline2 && <br />}
  //  item.addressline1 && item.addressline2 ? <br /> : ""}

  // console.log("addresswithSpace", addresswithSpace);
  // console.log("addressfrom db", item?.address);

  const manufacturer = item?.manufacturer;
  const model = item?.model;
  const dontopenAddress = false;
  const openAddress = true;

  const renderButton = (status) => {
    switch (status) {
      case "ADDRESSVALID":
        // case "ADDRESSINVALID":
        // case "DELIVERYOUT":
        return (
          <button
            className="px-2 py-1 border-none label-DOutList rounded-1 disable px-sm-3 py-sm-0"
            textcapitalized=""
            style={{ background: "#00CFE829", color: "#00CFE8" }}
            onClick={(e) =>
              handleLocationCheck(
                e,
                item.address ? item.address : "No address to verify",
                item,
                manufacturer,
                model,
                dontopenAddress
              )
            }
          >
            L
          </button>
        );
      case "ADDRESSNOTVALID":
      case "DELIVERYOUT":
        // case "ADDRESSVALID":
        return (
          <button
            variant="outline-dark"
            className="px-2 py-1 border-1 label-DOutList rounded-1 px-sm-3 py-sm-0"
            textcapitalized=""
            onClick={(e) =>
              handleLocationCheck(
                e,
                item.address ? item.address : "No address to verify",
                item,
                manufacturer,
                model,
                openAddress
              )
            }
          >
            L
          </button>
        );
      case "DELIVERYIN":
        return (
          <button
            // variant="outline-dark"
            className="px-2 py-1 border-none label-DOutList rounded-1 px-sm-3 py-sm-0"
            textcapitalized=""
            onClick={(e) =>
              handleLocationCheck(
                e,
                item.address ? item.address : "No address to verify",
                item,
                manufacturer,
                model,
                dontopenAddress
              )
            }
            style={{ background: "#FF9F4329", color: "#FF9F43" }}
          >
            R
          </button>
        );
      default:
        return null;
    }
  };

  return (
    <div className="card mb-2 w-100">
      <div
        className="p-1 d-flex  align-items-center"
        onClick={() => toggleExpand(item?._id)}
        style={{ cursor: "pointer" }}
      >
        <div
          className="text-left transferBadge mt-3"
          style={{ width: "100%", maxWidth: "70px" }}
        >
          <span
            className="badge bg-label-primary p-1 fs-5 transferBadgeText"
            textcapitalized=""
          >
            {item?.leftDays} J
          </span>
        </div>
        <div
          className="text-left table-data vehicleDetails flex-grow-1"
          style={{ minWidth: "200px" }}
        >
          <span className="brandName fs-5 text-wrap">
            {item?.manufacturer} {item?.model}
          </span>{" "}
          <br />
          <span>{item?.license_plate}</span> <br />
          <span className="text-wrap">
            {item?.clientName}
            &nbsp;
          </span>
        </div>
        <div
          className="text-left table-data addressDetails flex-grow-1 text-wrap"
          style={{ minWidth: "200px" }}
        >
          <span>
            {item?.addressline1}
            {item?.addressline1 && ","} {item?.addressline2}
          </span>
          {item.addressline1 && item.addressline2 && <br />}
          <span>
            {item?.city}
            {item?.city && ","} {item.state}
          </span>
          {item.addressline1 && item.addressline2 ? <br /> : ""}
          <span>
            {item?.postal_code}
            {item?.postal_code && ","} {item?.country}
          </span>
        </div>
        <div
          className="text-end table-data transferStatusBadge"
          style={{ minWidth: "50px" }}
        >
          {renderButton(item.reservation_status)}
        </div>
      </div>

      {expandedItemId === item?._id && (
        <div className="card-body p-0 px-3 pb-1">
          <p className="card-text mb-1">
            <span
              className="badge me-2 "
              style={{ background: "#00CFE829", color: "#00CFE8" }}
              textcapitalized=""
            >
              C
            </span>
            {item?.clientName}
          </p>
          <p className="card-text mb-1">
            <span
              className="badge me-2"
              style={{ background: "#0084FF29", color: "#0084FF" }}
            >
              M
            </span>
            <span>{item?.Maindrivername}</span>
            {item.subscriptionname && (
              <span
                className=" me-2 ms-2 text-white px-1"
                style={{
                  background: "#00CFE8",
                  borderRadius: "4px",
                  padding: "1px",
                }}
              >
                {item?.subscriptionname}
              </span>
            )}
          </p>

          {item &&
            item.auxDrivers &&
            item.auxDrivers.length > 0 &&
            item.auxDrivers.map((aux, index) => (
              <p className="card-text mb-1" key={index}>
                <span
                  style={{ background: "#0084FF29", color: "#0084FF" }}
                  className="badge me-2"
                >
                  A
                </span>
                <span>
                  {aux?.firstname} {aux?.lastname}
                </span>
              </p>
            ))}

          <p className="card-text mb-1">
            <p className="mb-1">{item.monthlyprice} €</p>

            <p className="mb-0">
              {/* {item.return_date && new Date(item.return_date).toLocaleString()} */}
              {moment(item?.return_date).format("DD/MM/YYYY  HH:mm:ss")}
            </p>
          </p>
        </div>
      )}
    </div>
  );
};

export default DeliveryListItem;
