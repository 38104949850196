import { Formik, Form, Field } from "formik";
import React, { useState } from "react";
import { updateVehicle } from "../../../../../services/AddVehicle";
import { useSelector } from "react-redux";
import { SmallLoader } from "../../../../Common/Loader";
import { useParams } from "react-router-dom";

const DamageMaintenance = () => {
  const [isEdit, setEdit] = useState(true);
  const [isLoding, setLoding] = useState(false);
  const { singlevehicleData } = useSelector((state) => state.vehicleSlice);
  const { id } = useParams();

  const initialValue = {
    next_mileage: singlevehicleData?.DamageMaintenance?.next_mileage || "",
    next_date: singlevehicleData?.DamageMaintenance?.next_date
      ? new Date(singlevehicleData?.DamageMaintenance?.next_date)
          .toISOString()
          .split("T")[0]
      : "",
    pre_mileage: singlevehicleData?.DamageMaintenance?.pre_mileage || "",
    pre_date: singlevehicleData?.DamageMaintenance?.pre_date
      ? new Date(singlevehicleData?.DamageMaintenance?.pre_date)
          .toISOString()
          .split("T")[0]
      : "",
  };
  return (
    <Formik
      initialValues={initialValue}
      enableReinitialize={true}
      onSubmit={async (values) => {
        setLoding(true);
        const tempData = { ...singlevehicleData, DamageMaintenance: values };
        const data = await updateVehicle(id, tempData);
        if (data) {
          setEdit(false);
          setLoding(false);
        }
      }}
    >
      {({ values, errors, handleChange }) => (
        <Form className="card mb-4">
          <div className="card-header d-flex justify-content-between">
            <h5 className="card-title mb-0">Dommages et Entretiens</h5>
            {isEdit ? (
              <div
                onClick={() => setEdit(false)}
                className="btn btn-primary waves-effect waves-light"
              >
                Modifier
              </div>
            ) : (
              <button
                type="submit"
                className="btn btn-primary waves-effect waves-light"
              >
                Save {isLoding && <SmallLoader />}
              </button>
            )}
          </div>
          <div className="card-body">
            <div className="mb-4">
              <h6>Entretien Suivant</h6>
              <div>
                <div className="d-flex gap-3">
                  <Field
                    type="number"
                    readOnly={isEdit}
                    name="next_mileage"
                    value={values.next_mileage}
                    placeholder="Type"
                    className={` ${isEdit ? "hide-input" : "form-control"} ${
                      errors.next_mileage && "dropdown-invalid"
                    }`}
                  />
                  <Field
                    type="Date"
                    readOnly={isEdit}
                    name="next_date"
                    value={values.next_date}
                    placeholder="Type"
                    className={` ${isEdit ? "hide-input" : "form-control"} ${
                      errors.next_date && "dropdown-invalid"
                    }`}
                  />
                </div>
              </div>
            </div>
            <div className="mb-4">
              <h6>Entretien Précédent</h6>
              <div>
                <div className="d-flex gap-3">
                  <Field
                    type="number"
                    readOnly={isEdit}
                    name="pre_mileage"
                    value={values.pre_mileage}
                    placeholder="Type"
                    className={` ${isEdit ? "hide-input" : "form-control"} ${
                      errors.pre_mileage && "dropdown-invalid"
                    }`}
                  />
                  <Field
                    type="Date"
                    readOnly={isEdit}
                    name="pre_date"
                    value={values.pre_date}
                    placeholder="Type"
                    className={` ${isEdit ? "hide-input" : "form-control"} ${
                      errors.pre_date && "dropdown-invalid"
                    }`}
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DamageMaintenance;
