/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import { useSelector } from "react-redux";
import CustomerActivationModale from "../../Common/CustomertActivationModale";
import { useParams } from "react-router-dom";
import { RoleLabel, subscriptionStatusClass } from "../../Common/roles";
import { Dropdown } from "primereact/dropdown";
import PhoneInput from "react-phone-input-2";
import AutoResizingTextArea from "../../Common/AutoResizingTextArea";

const RelativeClient = () => {
  const { id } = useParams();
  const isEdit = true;
  const [isModelShow, setModelShow] = useState(false);
  const { customerData } = useSelector((state) => state?.clientsSlice);

  const initialValues = {
    firstname: customerData?.relatedCustomer?.firstname || "",
    lastname: customerData?.relatedCustomer?.lastname || "",
    email: customerData?.relatedCustomer?.email || "",
    contact: customerData?.relatedCustomer?.contact || "",
    DOB: customerData?.relatedCustomer?.DOB
      ? new Date(customerData?.relatedCustomer?.DOB).toISOString().split("T")[0]
      : "",
    language: customerData?.relatedCustomer?.language,
    gender: customerData?.relatedCustomer?.gender,
    licensedate: customerData?.relatedCustomer?.licensedate
      ? new Date(customerData?.relatedCustomer?.licensedate)
          .toISOString()
          .split("T")[0]
      : "",
    // ${
    //   customerData?.relatedCustomer?.houseno
    //     ? customerData?.relatedCustomer?.houseno + ", "
    //     : ""
    // }
    address: `${customerData?.relatedCustomer?.address || ""}`,
    buildingNumber: customerData?.customer?.houseno || "",
  };

  return (
    <div>
      <Formik initialValues={initialValues} enableReinitialize={true}>
        {({ values, setFieldValue, handleChange }) => (
          <Form className="card mb-4">
            <div className="card-body">
              <div className="d-flex justify-content-between position-relative">
                <div className="position-absolute end-0 top-0"></div>
                <div className="mx-auto">
                  <div className="text-center d-flex justify-content-center gap-2">
                    {!isEdit ? (
                      <div className="d-flex gap-2">
                        <Field
                          readOnly={isEdit}
                          name="firstname"
                          value={values?.firstname}
                          placeholder="firstname"
                          className={`${
                            isEdit ? "hide-input" : "form-control"
                          }`}
                        />
                        <Field
                          readOnly={isEdit}
                          name="lastname"
                          value={values?.lastname}
                          placeholder="lastname"
                          className={`${
                            isEdit ? "hide-input" : "form-control"
                          }`}
                        />
                      </div>
                    ) : (
                      <h4>
                        {customerData?.relatedCustomer?.firstname}{" "}
                        {customerData?.relatedCustomer?.lastname}
                      </h4>
                    )}
                  </div>

                  <div className="d-flex gap-2 justify-content-center">
                    <RoleLabel userrols={customerData?.relatedCustomer?.role} />
                    <span
                      className={
                        subscriptionStatusClass[
                          customerData?.relatedCustomer?.sub_id
                            ?.subscription_name
                        ]
                      }
                    >
                      {customerData?.relatedCustomer?.sub_id?.subscription_name}
                    </span>
                  </div>
                </div>
              </div>
              <hr />
              <small className="card-text text-uppercase text-muted">
                DRIVER DETAILS
              </small>

              <ul className="list-unstyled mb-4 mt-3">
                <li className="d-flex align-items-center mb-3">
                  <span className="fw-medium mx-2 text-heading">Email:</span>
                  <Field
                    readOnly={isEdit}
                    name="email"
                    value={values?.email}
                    placeholder="email"
                    className={`${isEdit ? "hide-input" : "form-control"}`}
                  />
                </li>

                <li className="d-flex align-items-center mb-3">
                  <span className="fw-medium mx-2 text-heading">Contact:</span>
                  <PhoneInput
                    disabled={isEdit}
                    country={"Luxembourg"}
                    enableSearch={true}
                    className={` ${
                      !isEdit ? "form-control flex-grow-1 " : "hide-input"
                    }`}
                    placeholder="+352 369 852 472"
                    value={values?.contact?.toString() || "+352"}
                    onChange={(value) => setFieldValue("contact", value)}
                  />
                </li>

                <li className="d-flex align-items-start mb-3">
                  <span className="fw-medium mx-2 text-heading text-nowrap">
                    Address:
                  </span>

                  <AutoResizingTextArea
                    value={values.address}
                    name="address"
                    id="address"
                    className="w-100 hide-input resize-none"
                  />
                </li>
                <li className="d-flex align-items-center mb-3">
                  <span className="fw-medium mx-2 text-heading text-nowrap">
                    Street/Building No:
                  </span>
                  <Field
                    readOnly={isEdit}
                    name="buildingNumber"
                    placeholder=" "
                    className={`${isEdit ? "hide-input" : "form-control"}`}
                  />
                </li>
                <li className="d-flex align-items-center mb-3">
                  <span className="fw-medium mx-2 text-heading">DOB:</span>
                  <Field
                    type="date"
                    readOnly={isEdit}
                    name="DOB"
                    value={values?.DOB}
                    className={`${isEdit ? "hide-input" : "form-control"}`}
                  />
                </li>
                <hr />

                <li className="d-flex align-items-center mb-3 ">
                  <span className="fw-medium mx-2 text-heading">
                    Languages:
                  </span>
                  <Dropdown
                    disabled={isEdit}
                    options={["FR", "EN"]}
                    className={`w-full ${
                      isEdit ? "p-dropdown-hide" : "p-dropdown"
                    }`}
                    name="language"
                    value={values.language}
                    onChange={handleChange}
                  />
                </li>
                <li className="d-flex align-items-center  mb-3">
                  <span className="fw-medium mx-2 text-heading">Gender:</span>
                  <Dropdown
                    disabled={isEdit}
                    options={["Male", "Female", "Other"]}
                    name="gender"
                    value={values.gender}
                    className={`w-full ${
                      isEdit ? "p-dropdown-hide" : "p-dropdown"
                    }`}
                    onChange={handleChange}
                  />
                </li>
                <li className="d-flex align-items-center mb-3">
                  <span className="fw-medium mx-2 text-heading text-nowrap">
                    Permis B :
                  </span>
                  <input
                    readOnly={isEdit}
                    type="Date"
                    value={values?.licensedate}
                    onChange={handleChange}
                    name="licensedate"
                    className={` ${!isEdit ? "form-control" : "hide-input"}`}
                    placeholder="-"
                  />
                </li>
              </ul>
            </div>
          </Form>
        )}
      </Formik>

      {isModelShow && (
        <CustomerActivationModale
          show={isModelShow}
          onHide={() => setModelShow(false)}
          title={" are you sure you want ot send active link"}
          id={id}
        />
      )}
    </div>
  );
};

export default RelativeClient;
