import React from "react";
import Modal from "react-bootstrap/Modal";
import { updateResrvationStatus } from "../../../services/Calendar/reservation";
import { fetchFilterReservationById } from "../../../Redux/clientsSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
function ConfirmedReservation(props) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { onHide, reservationId } = props;

  const handleConfirmation = async (reservationId) => {
    const body = {
      reservationId,
      status: "Confirmed",
    };
    const tempData = await updateResrvationStatus(body);
    dispatch(fetchFilterReservationById(id));
    if (tempData?.status === true) {
      onHide();
    }
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>êtes-vous sûr de confirmer cette réservation ?</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={() => onHide()}>
          Annuler
        </button>
        <button
          className="btn btn-success"
          onClick={() => handleConfirmation(reservationId)}
        >
          Oui
        </button>
      </Modal.Footer>
    </Modal>
  );
}
export default ConfirmedReservation;
