import * as React from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { thumbnailPlugin } from "@react-pdf-viewer/thumbnail";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";
import { useParams } from "react-router-dom";
import { Loader } from "../../../../Common/Loader";
import { vehiclePdgPageDelete } from "../../../../../services/AddVehicle";

const FeeltDoumentPdf = ({
  isActiveDocument,
  setActiveDocument,
  getImage,
  handleDocDelete,
}) => {
  const { id } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [selectedPages, setSelectedPages] = React.useState([]);
  const handleDocumentLoad = (e) => {
    setSelectedPages(Array(e.doc.numPages).fill(false));
  };

  const handleDelete = async (page, allpageNamuber) => {
    setLoading(true);
    const body = {
      doc_id: isActiveDocument?._id,
      page_number: page + 1,
    };
    if (allpageNamuber === 1) {
      await handleDocDelete();
      getImage(id);
    } else {
      const data = await vehiclePdgPageDelete(id, body);
      if (data.status) {
        setActiveDocument(data?.document);
        setLoading(false);
      }
    }
  };

  const renderThumbnailItem = (props) => {
    return (
      <div
        key={props.pageIndex}
        className={`custom-thumbnail-item position-relative`}
        data-testid={`thumbnail-${props.pageIndex}`}
      >
        <div
          className={`thumbnail-img ${
            props.pageIndex === props.currentPage ? "c-border" : ""
          }`}
          style={{ marginBottom: "0.5rem" }}
          onClick={props.onJumpToPage}
        >
          {props.renderPageThumbnail}
        </div>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            margin: "0 auto",
            width: "100px",
          }}
        >
          {/* <div className="text-center"> {props.renderPageLabel}</div> */}
          <i
            className="menu-icon tf-icons ti ti-sm ti-trash delet-icon position-absolute"
            onClick={() => handleDelete(props.pageIndex, props.numPages)}
          ></i>
          {/* <div className="bg-bcke">hii</div>  */}
        </div>
      </div>
    );
  };

  const thumbnailPluginInstance = thumbnailPlugin();
  const { Thumbnails } = thumbnailPluginInstance;

  return (
    <div className="rpv-core__viewer row">
      {!loading ? (
        <>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.2.146/build/pdf.worker.js">
            <div className="col-10" style={{ height: "75vh" }}>
              {
                <Viewer
                  key={isActiveDocument?.url}
                  defaultSelectedPage={selectedPages.findIndex(
                    (isSelected) => isSelected
                  )}
                  onDocumentLoad={handleDocumentLoad}
                  fileUrl={`${process.env.REACT_APP_IMG_URL}${isActiveDocument?.url}`}
                  plugins={[thumbnailPluginInstance]}
                />
              }
            </div>
          </Worker>
          <div className="col-2 thambnai-shadow">
            <Thumbnails renderThumbnailItem={renderThumbnailItem} />
          </div>
        </>
      ) : (
        <div
          style={{
            height: "75vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      )}
    </div>
  );
};

export default FeeltDoumentPdf;
