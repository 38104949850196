import { Modal } from "react-bootstrap";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { addClientApi, updataClientApi } from "../../../services/client/addclientApi";
import { useNavigate } from "react-router-dom";
import { fetchDeliveryNew, setLocationModal } from "../../../Redux/deliverySlice";
import LocationSearchInput from "../../Calendar/LocationSearchInput";


const ModifyLocationModal = ({ address, setAddress, modifyLocation, setModifyLocation, showModal, selectedResv }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { countriesList, companyDropdown } = useSelector(
    (state) => state?.commonSlice
  );

  const [selected, onSelect] = useState(null);
  const [selectedAddress, setSelectedAdd] = useState([]);

  const formik = useFormik({
    initialValues: {
      addressline1: '',
      addressline2: '',
      city: '',
      country: '',
      postal_code: '',
      state: '',
    },
    onSubmit: async values => {
      const formData = new FormData();
      formData.append("address", values.addressline1);
      formData.append("address1", values.addressline2);
      formData.append("city", values.city);
      formData.append("state", values.state);
      formData.append("postal_code", values.postal_code);
      formData.append("country", values.country);

      const data = await updataClientApi(selectedResv.cusId, formData);

      if (data) {
        handleCloseModal();
        dispatch(fetchDeliveryNew());
      }
    }
  });

  const { setValues } = formik;

  useEffect(() => {
    if (selectedResv) {
      setValues(selectedResv);
    }
  }, [selectedResv]);

  const handleCloseModal = () => {
    // setShowModal(false);
    dispatch(setLocationModal(false))
    setModifyLocation(false);
  };

  const handleChange = (e) => {
    setAddress(e.target.value);
  };

  const countryOptions = [
    { label: 'United States', value: 'US' },
    { label: 'Canada', value: 'CA' },
    { label: 'France', value: 'FR' },
    // Add more countries as needed
  ];

  const getAddress = (type) => {
    const addressComponents = selectedAddress[0].address_components;
    const component = addressComponents.find(comp => comp.types.includes(type));
    return component ? component.long_name : '';
  };

  const onSubmitAddress = async () => {
    if (!selectedAddress || selectedAddress.length === 0) {
      console.error('No address selected');
      return;
    }

    const formData = new FormData();
    formData.append("address", `${getAddress('street_number')} ${getAddress('route')}`);
    formData.append("address1", '');
    formData.append("city", getAddress('locality'));
    formData.append("state", getAddress('administrative_area_level_1'));
    formData.append("postal_code", getAddress('postal_code'));
    formData.append("country", getAddress('country'));
    const data = await updataClientApi(selectedResv.cusId, formData);

    if (data) {
      handleCloseModal();
      dispatch(fetchDeliveryNew());
    }
  }

  return (
    <Modal show={modifyLocation} centered onHide={handleCloseModal} animation={true}>
      <div className="modal-editLocation-Parent">
        <div className="modal-editLocation">
          <p className="text-center">Voulez-vous modifier l’addresse</p>
          <LocationSearchInput onSelect={onSelect} addressExist={address} setSelectedAdd={setSelectedAdd}></LocationSearchInput>
          <div className="d-flex justify-content-center mt-3">
            <button className="btn btn-primary" type="submit" onClick={() => onSubmitAddress()} >
              Modifier
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModifyLocationModal;
