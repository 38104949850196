// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.name-btn{
    width: 20%;
    text-align: center;
    height: 2.5rem;
    border-radius: 0.5rem;
  }
  .default{
    width: 70%;
    margin-left: 2rem;
    border: 2px solid gainsboro;
  }
  .check-final input{
    height: 2.5rem;
    border-radius: 0.5rem;
  } 
  .co-grn{
      background-color: rgba(4, 245, 85, 0.3); 
      border: 1px solid #03a238; 
      color: #04b81f;
  }
  .co-orng{
    background-color: rgba(255, 165, 0, 0.3); 
      border: 1px solid #f64505d7; 
      color: #f64505d7;
  }
  .co-red{
    background-color: rgba(255, 0, 0, 0.2); 
    border: 1px solid #f92206; 
    color: #f92206;
  }
  .chk-final{
    color: black !important;
  }
  li{
    list-style: none;
  }
  .card-footer{
    background-color: white !important;
  }
  .circle-chk {
    margin-left: 10px;
    background-color: rgba(128, 128, 128, 0.5);
    border-radius: 50%; 
    fill: rgba(128, 128, 128, 0.5);
  }
  .outer-circle {
    background-color: rgba(128, 128, 128, 0.5);
  }`, "",{"version":3,"sources":["webpack://./src/css/check-final.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,kBAAkB;IAClB,cAAc;IACd,qBAAqB;EACvB;EACA;IACE,UAAU;IACV,iBAAiB;IACjB,2BAA2B;EAC7B;EACA;IACE,cAAc;IACd,qBAAqB;EACvB;EACA;MACI,uCAAuC;MACvC,yBAAyB;MACzB,cAAc;EAClB;EACA;IACE,wCAAwC;MACtC,2BAA2B;MAC3B,gBAAgB;EACpB;EACA;IACE,sCAAsC;IACtC,yBAAyB;IACzB,cAAc;EAChB;EACA;IACE,uBAAuB;EACzB;EACA;IACE,gBAAgB;EAClB;EACA;IACE,kCAAkC;EACpC;EACA;IACE,iBAAiB;IACjB,0CAA0C;IAC1C,kBAAkB;IAClB,8BAA8B;EAChC;EACA;IACE,0CAA0C;EAC5C","sourcesContent":[".name-btn{\n    width: 20%;\n    text-align: center;\n    height: 2.5rem;\n    border-radius: 0.5rem;\n  }\n  .default{\n    width: 70%;\n    margin-left: 2rem;\n    border: 2px solid gainsboro;\n  }\n  .check-final input{\n    height: 2.5rem;\n    border-radius: 0.5rem;\n  } \n  .co-grn{\n      background-color: rgba(4, 245, 85, 0.3); \n      border: 1px solid #03a238; \n      color: #04b81f;\n  }\n  .co-orng{\n    background-color: rgba(255, 165, 0, 0.3); \n      border: 1px solid #f64505d7; \n      color: #f64505d7;\n  }\n  .co-red{\n    background-color: rgba(255, 0, 0, 0.2); \n    border: 1px solid #f92206; \n    color: #f92206;\n  }\n  .chk-final{\n    color: black !important;\n  }\n  li{\n    list-style: none;\n  }\n  .card-footer{\n    background-color: white !important;\n  }\n  .circle-chk {\n    margin-left: 10px;\n    background-color: rgba(128, 128, 128, 0.5);\n    border-radius: 50%; \n    fill: rgba(128, 128, 128, 0.5);\n  }\n  .outer-circle {\n    background-color: rgba(128, 128, 128, 0.5);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
