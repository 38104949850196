import { Field, FieldArray, Form, Formik } from "formik";
import { Dropdown } from "primereact/dropdown";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { variables } from "../../../../Common/DropdownValue";
import { updateVehicle } from "../../../../../services/AddVehicle";
import { SmallLoader } from "../../../../Common/Loader";
import { useParams } from "react-router-dom";

const Tires = () => {
  const { id } = useParams();
  const [isEdit, setEdit] = useState(true);
  const [isLoding, setLoding] = useState(false);
  const { singlevehicleData } = useSelector((state) => state.vehicleSlice);
  const initialValues = { variables: singlevehicleData?.Variables } || [{}];
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={async (values) => {
        setLoding(true);
        const tempData = { ...singlevehicleData, Variables: values.variables };
        const data = await updateVehicle(id, tempData);
        if (data) {
          setEdit(true);
          setLoding(false);
        }
      }}
    >
      {({ values, handleChange, errors }) => (
        <Form>
          {console.log(values, "valuesvaluesvaluesvaluesvalues")}
          <div className="card mb-4">
            <div className="card-header d-flex justify-content-between">
              <h5 className="card-title mb-0">Pneus</h5>
              {isEdit ? (
                <div
                  onClick={() => setEdit(false)}
                  className="btn btn-primary waves-effect waves-light"
                >
                  Modifier
                </div>
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary waves-effect waves-light"
                >
                  Save {isLoding && <SmallLoader />}
                </button>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <h6>Wheel</h6>
                </div>
                <div className="col">
                  <h6>Memo</h6>
                </div>
                <div className="col">
                  <h6>Cost</h6>
                </div>
                {!isEdit && (
                  <div className="col flex-grow-0 opacity-0">
                    <button className="btn btn-danger">Remove</button>
                  </div>
                )}
              </div>
              <FieldArray
                name="variables"
                render={(arrayHelpers) => (
                  <div>
                    {values?.variables?.length > 0 &&
                      values?.variables.map((tire, index) => (
                        <div className="row mb-4" key={index}>
                          <div className="col">
                            <Dropdown
                              disabled={isEdit}
                              value={values?.variables?.[index].wheel_type}
                              onChange={handleChange}
                              options={variables}
                              name={`variables.${index}.wheel_type`}
                              placeholder="Pneus"
                              className={`w-full ${
                                isEdit ? "p-dropdown-hide" : ".p-dropdown"
                              } ${errors?.main_fuel && "dropdown-invalid"}`}
                            />
                          </div>
                          <div className="col">
                            <Field
                              name={`variables.${index}.memo`}
                              id={`${index}.memo`}
                              type="text"
                              placeholder="Mémo"
                              className={`col ${
                                isEdit ? "hide-input" : "form-control"
                              } ${
                                errors.franchise_crash && "dropdown-invalid"
                              }`}
                            />
                          </div>
                          <div className="col">
                            <Field
                              name={`variables.${index}.price`}
                              id={`${index}.price`}
                              type="text"
                              placeholder="Mémo"
                              className={`col ${
                                isEdit ? "hide-input" : "form-control"
                              } ${
                                errors.franchise_crash && "dropdown-invalid"
                              }`}
                            />
                          </div>
                          {!isEdit && (
                            <div className="col flex-grow-0">
                              <button
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                                className="btn btn-danger"
                              >
                                Remove
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                    {!isEdit && (
                      <div
                        onClick={() =>
                          arrayHelpers.push({
                            wheel_type: "",
                            memo: "",
                            price: "",
                          })
                        }
                        className="btn btn-primary waves-effect waves-light"
                      >
                        Ajouter un Type
                      </div>
                    )}
                  </div>
                )}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Tires;
