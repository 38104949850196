import React, { useState, useRef, useCallback, useEffect } from "react";
import Webcam from "react-webcam";

import {
  BsApp,
  BsFillExclamationCircleFill,
  BsChevronRight,
} from "react-icons/bs";
import { useParams, useNavigate } from "react-router-dom";
import { Rnd } from "react-rnd";
import {
  checkCreateAnnotate,
  checkCreateImage,
  getImageById,
  getSingleCheckData,
} from "../../services/check";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCheckImageByTypeId,
  fetchCheckImageSingle,
  fetchCheckSingle,
  resetCheckImageListByType,
} from "../../Redux/checkSlice";
import {
  PointSelector,
  RectangleSelector,
  OvalSelector,
} from "react-image-annotation/lib/selectors";
import Annotation from "react-image-annotation";
import Avant from "../../assets/img/car-view/Avant.png";
import Droite from "../../assets/img/car-view/Droite.png";
import Gauche from "../../assets/img/car-view/Gauche.png";
import Top from "../../assets/img/car-view/Top.png";
import Arrie from "../../assets/img/car-view/Arrie╠Çre.png";
import { Loader } from "../Common/Loader";
import { toast } from "react-toastify";
import { setisFromAnnotatePage } from "../../Redux/deliverySlice";

const AnnotateImage = (props) => {
  const defaultImageOrder = [
    { blocknumber: "1", blockName: "face-avant", actualName: "Face Avant" },
    { blocknumber: "2", blockName: "face-arriere", actualName: "Face Arriere" },
    {
      blocknumber: "3",
      blockName: "profil-conducteur",
      actualName: "Profil Conducteur",
    },
    {
      blocknumber: "4",
      blockName: "profil-passanger",
      actualName: "Profil Passager",
    },
    { blocknumber: "5", blockName: "roue-avg", actualName: "Roue AV.G" },
    { blocknumber: "6", blockName: "roue-avd", actualName: "Roue AV.D" },
    { blocknumber: "7", blockName: "roue-ard", actualName: "Roue AR.D" },
    { blocknumber: "8", blockName: "roue-arg", actualName: "Roue AR.G" },
    {
      blocknumber: "9",
      blockName: "tableau-de-bord",
      actualName: "Tableau de bord",
    },
    { blocknumber: "10", blockName: "interieur", actualName: "Interieur" },
  ];

  const webcamRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [screenshot, setScreenshot] = useState(null);

  // const { checkImageListByType, checkListSingle, loading } = useSelector((state) => state?.checkSlice);
  const { id, generalId, blocknumber, blockName, from } = useParams();
  let currentBlockObj = defaultImageOrder.find(
    (item) => item.blocknumber === blocknumber
  );
  const [listannotation, setListAnnotation] = useState([]);
  const [annotations, setAnnotations] = useState([]);
  const [annotation, setAnnotation] = useState({});

  const [selectedImage, setSelectedImage] = useState("");
  const [hoveredAnnotation, setHoveredAnnotation] = useState(null);

  const [annotatedImageUrl, setAnnotatedImageUrl] = useState("");
  const undoStackRef = useRef([]);
  const redoStackRef = useRef([]);
  const style = {
    button: "text-[#fff] bg-[#4ca3dd] py-[2px] px-2 rounded-[5px]",
  };

  let [checkImgId, setCheckImgId] = useState(null);
  const [checkImageListByType, setcheckImageListByType] = useState(null);
  let [isloading, setLoading] = useState(true);
  let [isRenewedImage, setIsRenewedImage] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchImageData = async () => {
      currentBlockObj = defaultImageOrder.find(
        (item) => item.blocknumber === blocknumber
      );
      let param = {
        reservationId: id,
        generalId: generalId,
        is_latest_yn: true,
      };
      if (blockName && blockName !== "add") {
        param["image_type"] = currentBlockObj?.actualName || blockName;
        let response = await getImageById(param);
        if (response) {
          setcheckImageListByType(response ? response : null);
          getReservationDetail(response);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    fetchImageData();
  }, [blocknumber]);

  const handleNext = () => {
    const nextIndex = parseInt(blocknumber, 10) + 1;
    setAnnotation({});
    setIsRenewedImage(false);
    setAnnotations([]);
    if (nextIndex <= 10) {
      setScreenshot(null);
      const nextBlockObj = defaultImageOrder.find(
        (item) => item.blocknumber === nextIndex.toString()
      );
      setTimeout(() => {
        navigate(
          `/checks/annotate/${id}/${generalId}/${nextBlockObj.blocknumber}/${nextBlockObj.blockName}/${from}`
        );
      }, 100);
    }
    if (blocknumber == 10) {
      if (from && from === "from-check") {
        navigate(`/checks/check-out/` + id + "/" + generalId);
      } else if (from && from === "from-delivery") {
        navigate("/deliveries/delivery-out");
        dispatch(setisFromAnnotatePage(true));
      }
    }
    if (blocknumber == 11) {
      if (from && from === "from-check") {
        navigate(`/checks/check-out/` + id + "/" + generalId);
      } else if (from && from === "from-delivery") {
        navigate("/deliveries/delivery-out");
        dispatch(setisFromAnnotatePage(true));
      }
    }
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot({
      // width: 800,
      // height: 370,
      // objectFit : 'contain'
    });
    setScreenshot(imageSrc);
    saveCarImage(imageSrc);
  }, [webcamRef, generalId, currentBlockObj, isRenewedImage]);

  const saveCarImage = async (imageSrc) => {
    let payload = {
      reservation_id: id,
      general_id: generalId,
      images: imageSrc,
      is_renewed_yn: isRenewedImage,
    };
    if (blockName && blockName != "add") {
      payload["image_type"] =
        currentBlockObj && currentBlockObj.actualName
          ? currentBlockObj.actualName
          : blockName;
    }
    if (blockName && blockName == "add") {
      payload["image_type"] = "additional" + Math.random();
    }
    const response = await checkCreateImage(payload);
    if (
      response &&
      response.data &&
      response.data.images &&
      response.data.images.length &&
      response.data.images[0]._id
    ) {
      setCheckImgId(response.data.images[0]._id);
      setIsRenewedImage(true);
    } else {
      toast.error("Failed to save image", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const Box = ({ children, geometry, style, onClick }) => (
    <div
      style={{
        ...style,
        position: "absolute",
        left: `${geometry.x}%`,
        top: `${geometry.y}%`,
        height: `${geometry.height}%`,
        width: `${geometry.width}%`,
        zIndex: 10,
        cursor: "pointer",
      }}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      {children}
    </div>
  );

  function renderSelector({ annotation }) {
    const { geometry } = annotation;
    if (!geometry) return null;

    return (
      <Box
        geometry={geometry}
        style={{
          border: `solid 2px #E10000`,
          borderRadius: "50%", // Make it look like an ellipse
        }}
        onDragEnd={() => ondown()}
      />
    );
  }

  function renderHighlight({ annotation }) {
    const { geometry } = annotation;
    if (!geometry) return null;

    return (
      <Box
        key={annotation.data.id}
        geometry={geometry}
        style={{
          border: `solid 3px ${"#E10000"}`,
          borderRadius: "50%", // Make it look like an ellipse
        }}
        onMouseEnter={() => setHoveredAnnotation(annotation)}
        onMouseLeave={() => setHoveredAnnotation(null)}
        onClick={() => onClickDamage(annotation)}
      />
    );
  }

  const onChange = (newAnnotation) => {
    setAnnotation(newAnnotation);
  };

  const getReservationDetail = (responseData) => {
    let imageType =
      currentBlockObj && currentBlockObj.actualName
        ? currentBlockObj.actualName
        : blockName && blockName != "add"
          ? blockName
          : "";
    // setScreenshot(null);
    if (
      responseData &&
      responseData.check_image_data &&
      responseData.check_image_data.image_type === imageType
    ) {
      let img =
        responseData &&
          responseData.check_image_data &&
          responseData.check_image_data.images
          ? `${process.env.REACT_APP_IMG_URL}/${responseData.check_image_data.images[0].image_path}`
          : null;
      setIsRenewedImage(img ? true : false);
      setScreenshot(img);
      setCheckImgId(
        responseData &&
        responseData.check_image_data &&
        responseData.check_image_data &&
        responseData.check_image_data.images[0]._id
      );

      if (
        responseData &&
        responseData.annotations &&
        responseData.annotations.length
      ) {
        const updatedAnnotations = responseData.annotations.map(
          (res, index) => ({
            geometry: {
              type: "OVAL",
              x: res.position_x,
              y: res.position_y,
              width: res.width,
              height: res.height,
            },
            data: {
              text: (index + 1).toString(),
              id: res._id,
            },
          })
        );

        setAnnotations(updatedAnnotations);
      }
    }
  };

  function renderContent({ annotation }) {
    const { geometry } = annotation;
    return (
      <div
        key={annotation.data.id}
        style={{
          background: `${"#C60606"}`,
          color: "white",
          paddingRight: 10,
          paddingLeft: 10,
          fontWeight: "bolder",
          fontSize: 15,
          position: "absolute",
          left: `${geometry.x}%`,
          top: `${geometry.y - 1}%`,
        }}
      >
        {annotation.data && annotation.data.text}
      </div>
    );
  }

  const onClickDamage = async (anno) => {
    let annotateId = anno && anno.data && anno.data.id;
    if (anno && anno.data && !anno.data.text) {
      let payloda = {
        image_id: checkImgId,
        width: anno.geometry.width,
        height: anno.geometry.height,
        position_x: anno.geometry.x,
        position_y: anno.geometry.y,
      };

      if (blockName && blockName != "add") {
        payloda["image_type"] =
          currentBlockObj && currentBlockObj.actualName
            ? currentBlockObj.actualName
            : blockName;
      }
      if (blockName && blockName == "add") {
        payloda["image_type"] = "additional" + Math.random();
      }
      let res = await checkCreateAnnotate(payloda);
      if (res && res.data) {
        let pkId = res && res.data && res.data._id;
        navigate(`/checks/damage-report/` + pkId);
      } else {
        toast.error("Failed to add annotation", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      navigate(`/checks/damage-report/` + annotateId);
    }
  };

  const onSubmit = (newAnnotation) => {
    const { geometry, data } = newAnnotation;

    redoStackRef.current = [];

    setAnnotation({});
    const newAnnotationData = {
      geometry,
      data: {
        ...data,
        id: Math.random(),
        text: null,
      },
    };

    setAnnotations([...annotations, newAnnotationData]);

    undoStackRef.current.push(annotations);
  };

  function renderEditor(props) {
    const { geometry } = props.annotation;
    // const {data} = {onSubmit}

    onSubmit(props.annotation);
    ondown(annotation);
  }

  const videoConstraints = {
    facingMode: { exact: "environment" }, // for back cam
    // facingMode: "user", // for front cam
  };

  const handleRedo = () => {
    setScreenshot(null);
    setAnnotation({});
    setAnnotations([]);
  };

  const ondown = async (data) => {
    // onClickDamage(data)

    let payloda = {
      image_id: checkImgId,
      width: data.geometry.width,
      height: data.geometry.height,
      position_x: data.geometry.x,
      position_y: data.geometry.y,
    };

    if (blockName && blockName != "add") {
      payloda["image_type"] =
        currentBlockObj && currentBlockObj.actualName
          ? currentBlockObj.actualName
          : blockName;
    }
    if (blockName && blockName == "add") {
      payloda["image_type"] = "additional" + Math.random();
    }
    let res = await checkCreateAnnotate(payloda);
    if (res && res.data) {
      let pkId = res && res.data && res.data._id;
      navigate(`/checks/damage-report/` + pkId);
    } else {
      toast.error("Failed to add annotation", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  let handleNavToCheck = () => {
    if (from && from === "from-check") {
      navigate(`/checks/check-out/` + id + "/" + generalId);
    } else if (from && from === "from-delivery") {
      navigate("/deliveries/delivery-out");
      dispatch(setisFromAnnotatePage(true));
    }
  };

  return (
    <div className="webcam-container" style={{height: 'auto'}}
    >
      {!screenshot && blockName ? (
        <>
          <div className="position-absolute top-4 end-0 pe-2 pt-1 z-1 bottom-100 fs-5" onClick={handleNavToCheck}>
            <div className="btn btn-secondary px-2 py-2">Back </div>
          </div>
          <div style={{ position: 'relative', width: '100%', height: '83vh' }}>
            <Webcam
              videoConstraints={videoConstraints}
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              screenshotQuality={1}
              className="webcam"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
            />
            <img
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: '100%',
                maxHeight: '100%',
              }}
              src={
                blockName === 'face-avant'
                  ? Avant
                  : blockName === 'face-arriere'
                    ? Arrie
                    : ''
              }
              onError={(e) => (e.currentTarget.style.display = 'none')}
              alt=""
            />
          </div>
        </>

      ) : (
        <>
          <div
            style={{ width: '100%', height: '73vh' }}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="position-absolute top-4 end-0 pe-2 pt-1 z-1 bottom-100 fs-5" onClick={handleNavToCheck}>
              <div className="btn btn-secondary px-2 py-2">Back </div>
            </div>
            <>
              <Annotation
                style={{
                  height: '73vh',
                  width: "100%",
                  objectFit: 'cover'
                }}
                src={screenshot}
                alt="Annotate image"
                annotations={annotations}
                value={annotation}
                type={OvalSelector.TYPE}
                renderOverlay={() => null}
                renderHighlight={renderHighlight}
                renderEditor={renderEditor}
                onChange={onChange}
                allowTouch
                renderSelector={renderSelector}
                renderContent={renderContent}
                onSubmit={onSubmit}
              />
            </>
          </div>

        </>
      )}

      {/* <BsFillExclamationCircleFill className="icon icon-exclamatory" onClick={toggleCircle} /> */}

      {!screenshot && (
        <>
          <BsApp className="icon icon-capture" onClick={capture} />{" "}
        </>
      )}

      <div className="footer d-flex justify-content-between align-items-center p-2 border-top border-bottom gap-2">
        {blocknumber && blocknumber != 11 && (
          <div className="fw-bold">{blocknumber}/10</div>
        )}
        <div className="label text-center fw-bold">
          {currentBlockObj ? currentBlockObj.actualName : ""}
        </div>
        <div className="buttons d-flex gap-1">
          <button
            className="btn btn-primary pe-2 pe-3"
            onClick={() => handleRedo()}
          >
            Refaire
          </button>
          <button className="btn btn-primary pe-2" onClick={handleNext}>
            Suivant
            <span>
              <BsChevronRight
                fontWeight={900}
                style={{
                  marginLeft: "15px",
                }}
              />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AnnotateImage;
