import React, { useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { CiEraser } from "react-icons/ci";
import SignatureCanvas from "react-signature-canvas";
import { addVehicleDeliveryInfo, deliveryDriverUpdate } from "../../../../services/Deliveries";
import { checkStatusUpdate } from "../../../../services/check";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchDeliveryNew, setLocationModal, setModalVerification } from "../../../../Redux/deliverySlice";

const Signature = ({ show, onHide, selectedResv, value, newKmValue, selectAuxIds }) => {
  const sigCanvas = useRef({});
  const [signatureURL, setSignatureURL] = useState(null);
  const navigate = useNavigate();

  let dispatch = useDispatch();

  const [isClientPresent, setIsClientPresent] = useState(false);
  let ststus = selectedResv && selectedResv.reservation_status ? selectedResv.reservation_status : null;

  const toggleClientPresence = (e) => {
    setIsClientPresent(!isClientPresent);
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
    setSignatureURL(null);
  };

  const saveSignature = (e) => {
    setSignatureURL(
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
    // console.log(signatureURL);
    // console.log(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    e.stopPropagation();

    saveStatus(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    dispatch(setLocationModal(false))
  };

  const saveStatus = async (sign) => {
    let type = ststus && (ststus === 'ADDRESSVALID' || ststus ===  'DELIVERYOUT' || ststus ===  'ADDRESSNOTVALID') ? 'OUT' : 'IN'
    let payload = {
      'reservation_id': selectedResv._id,
      'general_id': selectedResv.generalId,
      "kilometer": parseInt(newKmValue),
      "fuel_level": parseInt(value),
      "delivery_type": type,
      "is_client_present_yn": isClientPresent,
      "signature": sign
    }

    let payloadAUX = {
      reservationId: selectedResv._id,
      reservation_status: "DELIVERYOUT",
      linked_driver_details: selectAuxIds
    }

    let res = await addVehicleDeliveryInfo(payload);
    if (res) {
      let payload = {
        reservationId: selectedResv._id,
      }
      if (ststus && (ststus === 'ADDRESSVALID' || ststus === 'DELIVERYOUT' || ststus === 'ADDRESSNOTVALID')) {
        payload['reservation_status'] = 'DELIVERYIN'
      }
      if (ststus && ststus === 'DELIVERYIN') {
        payload['reservation_status'] = 'CHECKIN'
      }
     
      if (ststus && ststus != "DELIVERYIN" && selectAuxIds && selectAuxIds.length) {
        let auxDriverAdd = await deliveryDriverUpdate(payloadAUX);
      }
      let checkStatus = await checkStatusUpdate(payload);
      
      if (checkStatus) {
          dispatch(setModalVerification(false));
          dispatch(fetchDeliveryNew());
          onHide();
        // navigate('deliveries/delivery-out');
      }
    }
  }

  return (
    <>
      <Modal show={show} fullscreen onHide={onHide}>
        <Modal.Header className="border-0 bg-primary text-white signaturerHeader">
          <IoClose size={25} className="" onClick={onHide} />
          <div className="w-100 fs-5 text-center">
            SORTIE (LIVRAISON) - Client Name
          </div>
        </Modal.Header>
        <Modal.Body className="">
          <SignatureCanvas
            ref={sigCanvas}
            penColor="black"
            canvasProps={{ className: "w-100 signatureWindow" }}
          />

          <div className="d-flex justify-content-end">
            <CiEraser
              size={40}
              className="text-primary rounded-5 border p-2 border-primary btn"
              onClick={clearSignature}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 p-0">
          <Button
            variant="primary"
            className="w-100 fs-4 m-0 validateSign-Button-container"

          >
            <div
              className="validateSign-toggle-container"
              onClick={toggleClientPresence}
            >
              <div
                className={`toggle-button ${isClientPresent ? "present" : "absent"
                  }`}
              >
                <div
                  className={`toggle-circle ${isClientPresent ? "present" : "absent"
                    }`}
                ></div>
              </div>
              <span className="toggle-text ms-1">
                {isClientPresent ? "Client Present" : "Client Non Present"}
              </span>
            </div>

            <span className="validateSign-ValidBtn text-center w-100" onClick={saveSignature}>
              Valider
            </span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Signature;
