import React from "react";

const PriceSection = ({ isSingleData, customerData, isPrice }) => {
  return (
    <div>
      {isPrice && (
        <>
          <div className="d-flex justify-content-between">
            <h6>Monthly Cost</h6>
            <div>{isPrice}€</div>
          </div>

          <div>Time and Date of Delivery</div>
          <hr className="text-black border-2" />
        </>
      )}
    </div>
  );
};

export default PriceSection;
