// import React, { useEffect } from "react";
// import BreadCrumbs from "../components/Common/BreadCrumbs";
// import { NavLink, Outlet } from "react-router-dom";

// import {
//   fetchDelivery,
//   fetchDeliveryIn,
//   fetchSingleDeliveryOut,
// } from "../Redux/deliverySlice";
// import { useDispatch, useSelector } from "react-redux";

// const Deliveries = () => {
//   const dispatch = useDispatch();
//   const { deliveryListData } = useSelector((state) => state?.checkSlice);
//   console.log("delevery data", deliveryListData);
//   useEffect(() => {
//     dispatch(fetchDelivery());
//     // dispatch(fetchSingleDeliveryOut());
//     // dispatch(fetchDeliveryIn());
//   }, []);
//   return (
//     <>
//       <div className="content-wrapper delivers">
//         <div className="container-fluid flex-grow-1 container-p-y">
//           <BreadCrumbs title={"Flotte"} subTitle={"Livraisons"} />

//           <div className="step d-flex gap-3 pb-3 mb-1">
//             <NavLink to={"delivery-out"} className="rounded-2 border p-1 px-2">
//               <span className="bs-stepper-label">
//                 <span className="bs-stepper-title}">Sortie</span>
//               </span>
//             </NavLink>
//             <NavLink to={"delivery-in"} className="rounded-2 border p-1 px-2">
//               <span className="bs-stepper-label ">
//                 <span className="bs-stepper-title">Entrée</span>
//               </span>
//             </NavLink>
//           </div>
//           <Outlet />
//         </div>
//       </div>
//     </>
//   );
// };

// export default Deliveries;



import React, { useEffect } from "react";
import BreadCrumbs from "../components/Common/BreadCrumbs";
import { NavLink, Outlet } from "react-router-dom";
import {
  fetchDelivery,
  fetchDeliveryIn,
  fetchSingleDeliveryOut,
} from "../Redux/deliverySlice";
import { useDispatch, useSelector } from "react-redux";
import "../css/deliveries.css";

const Deliveries = () => {
  const dispatch = useDispatch();
  const { deliveryListData } = useSelector((state) => state?.checkSlice);
  // console.log("delevery data", deliveryListData);
  useEffect(() => {
    dispatch(fetchDelivery());
    // dispatch(fetchSingleDeliveryOut());
    // dispatch(fetchDeliveryIn());
  }, []);
  return (
    <>
      <div className="content-wrapper delivers">
        <div className="container-fluid flex-grow-1 py-0 d-flex justify-content-between align-items-center deliveryOutHeaderContainer pt-3">
          <BreadCrumbs title={"Flotte"} subTitle={"Transfer"} />

          <div className="step mb-3">
            <NavLink to={"delivery-out"} className="rounded-2 text-decoration-none px-3 py-2">
              <span className="bs-stepper-label">
                <span className="bs-stepper-title}">A Faire</span>
              </span>
            </NavLink>
            <NavLink to={"delivery-in"} className="rounded-2 text-decoration-none px-3 py-2">
              <span className="bs-stepper-label ">
                <span className="bs-stepper-title">Historique</span>
              </span>
            </NavLink>
          </div>
        </div>
        <Outlet />
      </div>

      {/* <div className="content-wrapper delivers">
        <div className="container-fluid flex-grow-1 container-p-y">
          <BreadCrumbs title={"Flotte"} subTitle={"Livraisons"} />

          <div className="step d-flex gap-3 pb-3 mb-1">
            <NavLink to={"delivery-out"} className="rounded-2 border p-1 px-2">
              <span className="bs-stepper-label">
                <span className="bs-stepper-title}">Sortie</span>
              </span>
            </NavLink>
            <NavLink to={"delivery-in"} className="rounded-2 border p-1 px-2">
              <span className="bs-stepper-label ">
                <span className="bs-stepper-title">Entrée</span>
              </span>
            </NavLink>
          </div>
          <Outlet />
        </div>
      </div> */}
    </>
  );
};

export default Deliveries;
