import { toast } from "react-toastify";
import axiosInstanceAuth from "../../apiInstances/axiosInstanceAuth";

export const addCompanyApi = async (body) => {
  try {
    const response = await axiosInstanceAuth.post("/company/add", body);
    toast.success(response?.data?.msg, { position: toast.POSITION.TOP_RIGHT });
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
export const getCompanyDetails = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(`company/${id}`);
    return response.data;
  } catch (error) {
    console.log("Error in loginUserAPI:", error?.response?.data?.msg);
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const getCompanylist = async () => {
  try {
    const response = await axiosInstanceAuth.get(`settings`);
    return response.data;
  } catch (error) {
    console.log("Error in loginUserAPI:", error?.response?.data?.msg);
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const companyDetailsUpdata = async (id, body) => {
  try {
    const response = await axiosInstanceAuth.patch(`company/${id}`, body);
    toast.success(response?.data?.msg, { position: toast.POSITION.TOP_RIGHT });
    return response.data;
  } catch (error) {
    console.log("Error in loginUserAPI:", error?.response?.data?.msg);
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const companyDetailsDelete = async (id) => {
  try {
    const response = await axiosInstanceAuth.delete(`company/${id}`);
    toast.success(response?.data?.msg, { position: toast.POSITION.TOP_RIGHT });
    return response.data;
  } catch (error) {
    console.log("Error in loginUserAPI:", error?.response?.data?.msg);
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
