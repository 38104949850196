import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../Common/Loader";
import { fetchDeliveryHistory } from "../../../Redux/deliverySlice";
import DeliveryHistoies from "./DeliveryHistoies";

const DeliveryInList = () => {
  const dispatch = useDispatch();
  const [expandedItemId, setExpandedItemId] = useState(null);

  const { deliveryOutListData, loading } = useSelector(
    (state) => state?.deliverySlice
  );

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchDeliveryHistory());
    };

    fetchData();
  }, [dispatch]);

  const toggleExpand = (id) => {
    setExpandedItemId(expandedItemId === id ? null : id);
  };

  return (
    <>
      <div className=" client delieveryContainer px-2">
        <div className="table-responsive text-nowrap">
          {deliveryOutListData &&
            deliveryOutListData.map((item) => (
              <DeliveryHistoies
                item={item}
                key={item._id}
                toggleExpand={toggleExpand}
                expandedItemId={expandedItemId}
              />
            ))}

          {loading && (
            <div className="mt-4">
              <Loader />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DeliveryInList;
