import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerData } from "../../../Redux/clientsSlice";
import pencilIcon from "../../../assets/icons/pencil.png";
import { updataClientApi } from "../../../services/client/addclientApi";
import { SmallLoader } from "../../Common/Loader";
import PhoneInput from "react-phone-input-2";
import { roleClasses } from "../../Common/roles";
import CustomerActivationModale from "../../Common/CustomertActivationModale";
import { Dropdown } from "primereact/dropdown";
import { useParams } from "react-router-dom";
import ValidateProspectModal from "../Modals/ValidateProspectModal";
import AddressAutocompleteInput from "../../Common/AddressAutocompleteInput";
import { useLoadScript } from "@react-google-maps/api";
import AutoResizingTextArea from "../../Common/AutoResizingTextArea";
import moment from "moment";

const libraries = ["places"];
const ProspectAfterValidateDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isModelShow, setModelShow] = useState(false);
  const [isEdit, setEdit] = useState(true);
  const [isLoding, setLoding] = useState(false);
  const [validModalShow, setValidModalShow] = useState(false);
  const { customerData } = useSelector((state) => state?.clientsSlice);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCfC8Izlpa7eb4Rae3EtA5-WcmrHr8BHAA",
    libraries,
  });

  const {
    houseno,
    address,
    contact,
    invoice_email,
    email,
    lastname,
    firstname,
    gender,
    language,
    DOB,
  } = customerData?.customer || {};

  const handleModal = () => {
    setValidModalShow(true);
  };

  if (loadError) return <></>;
  if (!isLoaded) return <></>;
  return (
    <div className="card mb-4">
      <div className="card-body">
        <Formik
          initialValues={{
            firstname: firstname || "",
            lastname: lastname || "",
            email: email || "",
            // ${houseno ? houseno + ", " : ""}
            address: `${address || ""}`,
            buildingNumber: houseno || "",
            language: language || "",
            gender: gender || "",
            contact: `+${contact || ""}`,
            invoice_email: invoice_email || "",
            DOB: DOB || "",
          }}
          enableReinitialize={true}
          onSubmit={async (values) => {
            setLoding(true);
            const formData = new FormData();
            formData.append("firstname", values.firstname);
            formData.append("lastname", values.lastname);
            formData.append("email", values.email);
            if (values.buildingNumber)
              formData.append("houseno", values.buildingNumber);
            if (values.address) formData.append("address", values.address);
            formData.append("contact", values.contact);
            formData.append("language", values.language);
            formData.append("gender", values.gender);
            formData.append("invoice_email", values.invoice_email);
            formData.append("DOB", values.DOB);
            const data = await updataClientApi(id, formData);

            if (data) {
              setEdit(true);
              setLoding(false);
              dispatch(fetchCustomerData(id));
            }
          }}
        >
          {({ values, setFieldValue, handleChange, handleSubmit }) => (
            <Form>
              <div className="d-flex justify-content-center position-relative">
                <div className="position-absolute start-0 top-0">
                  {isEdit && (
                    <button
                      className="btn waves-effect waves-light p-0 align-items-start"
                      onClick={() => {
                        setEdit(false);
                      }}
                    >
                      <img src={pencilIcon} alt="pencil-icon" />
                    </button>
                  )}
                </div>
                <div className="mb-4">
                  <div className="d-flex gap-2">
                    {!isEdit ? (
                      <>
                        <div>
                          {!isEdit && (
                            <button
                              onClick={handleSubmit}
                              type="button"
                              className="btn btn-primary waves-effect waves-light ms-3"
                            >
                              Save
                              {isLoding && <SmallLoader />}
                            </button>
                          )}
                        </div>
                        <h4>
                          <Field
                            readOnly={isEdit}
                            name="firstname"
                            placeholder="-"
                            className={` ${
                              isEdit ? "hide-input" : "form-control"
                            }`}
                          />
                        </h4>
                        <h4>
                          <Field
                            readOnly={isEdit}
                            name="lastname"
                            placeholder="-"
                            className={` ${
                              isEdit ? "hide-input" : "form-control"
                            }`}
                          />
                        </h4>
                      </>
                    ) : (
                      <>
                        <div className="text-center">
                          <h4 className="card-text my-auto">
                            {customerData?.customer?.firstname}{" "}
                            {customerData?.customer?.lastname}{" "}
                          </h4>

                          <div className="d-flex justify-content-center align-items-center gap-2 mt-2">
                            <div className="text-nowrap">
                              {customerData?.customer?.role?.length > 1 ? (
                                customerData?.customer?.role?.map((item) => (
                                  <span
                                    className={`me-1 btn waves-effect py-1 ${
                                      item?.charAt(0).toUpperCase() === "C"
                                        ? "btn-label-primary"
                                        : "btn-label-success"
                                    }`}
                                  >
                                    {item?.charAt(0).toUpperCase()}
                                  </span>
                                ))
                              ) : (
                                <span
                                  className={`${
                                    roleClasses[
                                      customerData?.customer?.role?.[0]
                                    ]
                                  }`}
                                >
                                  {customerData?.customer?.role?.[0]}
                                </span>
                              )}
                            </div>
                            {customerData?.customer?.beclient === "true" && (
                              <span className="bg-success rounded px-2 c-badge">
                                <i
                                  className="menu-icon text-white tf-icons ti ti-checkup-list m-0 fs-5"
                                  type="button"
                                />
                              </span>
                            )}
                            {customerData?.customer?.beclient === "false" &&
                              customerData?.customer?.contact && (
                                <span className="bg-warning rounded px-2 c-badge">
                                  <i
                                    className="menu-icon text-white tf-icons ti ti-file m-0 fs-5"
                                    type="button"
                                  />
                                </span>
                              )}
                            {/* {customerData?.customer?.role?.length === 1 && (
                              <div
                                className="bg-warning rounded c-badge cursor-pointer text-white px-2 fw-600"
                                onClick={() => handleModal()}
                              >
                                Validate
                              </div>
                            )} */}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <small className="card-text text-uppercase">CLIENT DETAIL</small>

              <ul className="list-unstyled mb-0 mt-2">
                <li className="d-flex align-items-center mb-3 mt-2">
                  <span className="fw-medium mx-2 text-nowrap">Email:</span>
                  <Field
                    readOnly
                    type="text"
                    name="email"
                    className={` ${isEdit ? "hide-input" : "form-control"}`}
                    placeholder="email"
                  />

                  {customerData?.customer?.active === false && (
                    <btn
                      className="text-primary text-decoration-underline cursor-pointer"
                      onClick={(e) => {
                        setModelShow(true);
                      }}
                    >
                      Activate
                    </btn>
                  )}
                </li>

                <li className="d-flex align-items-center mb-3 mt-2">
                  <span className="fw-medium mx-2 text-nowrap">Contact:</span>
                  <PhoneInput
                    disabled={isEdit}
                    country={"Luxembourg"}
                    enableSearch={true}
                    className={` ${
                      !isEdit ? "form-control flex-grow-1 " : "hide-input"
                    }`}
                    placeholder="+352 369 852 472"
                    value={values?.contact || "+352"}
                    onChange={(value) => setFieldValue("contact", value)}
                  />
                </li>

                <li className="d-flex align-items-center mb-3">
                  <span className="fw-medium mx-2 text-heading">DOB:</span>
                  <Field
                    readOnly={isEdit}
                    name="DOB"
                    value={
                      values?.DOB
                        ? moment(values?.DOB).format("YYYY-MM-DD")
                        : ""
                    }
                    placeholder="DOB"
                    type="date"
                    className={`${isEdit ? "hide-input" : "form-control"}`}
                  />
                </li>

                <hr />
                {isEdit ? (
                  <li className="d-flex align-items-start mb-3">
                    <span className="fw-medium mx-2 text-heading text-nowrap">
                      Address:
                    </span>

                    <AutoResizingTextArea
                      value={values.address}
                      name="address"
                      id="address"
                      className="w-100 hide-input resize-none"
                    />
                  </li>
                ) : (
                  <li className="d-flex align-items-center mb-3">
                    <span className="fw-medium mx-2 text-heading text-nowrap">
                      Address:
                    </span>
                    <AddressAutocompleteInput
                      name="address"
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </li>
                )}

                <li className="d-flex align-items-center mb-3">
                  <span className="fw-medium mx-2 text-heading text-nowrap">
                    Street/Building No:
                  </span>

                  <Field
                    readOnly={isEdit}
                    name="buildingNumber"
                    placeholder=" Street/Building No"
                    className={`${isEdit ? "hide-input" : "form-control"}`}
                  />
                </li>
                <hr />
                <small className="card-text text-uppercase">CONTACTS</small>

                <li className="d-flex align-items-center mb-3 mt-2">
                  <span className="fw-medium mx-2 text-nowrap">Languages:</span>
                  <Dropdown
                    disabled={isEdit}
                    options={["FR", "EN"]}
                    className={`w-full ${
                      isEdit ? "p-dropdown-hide" : "p-dropdown"
                    }`}
                    name="language"
                    value={values.language}
                    onChange={handleChange}
                  />
                </li>
                <li className="d-flex align-items-center mb-3 mt-2">
                  <span className="fw-medium mx-2 text-nowrap">Gender:</span>
                  <Dropdown
                    disabled={isEdit}
                    options={["Male", "Female", "Other"]}
                    placeholder="Gender"
                    name="gender"
                    value={values.gender}
                    className={`w-full ${
                      isEdit ? "p-dropdown-hide" : "p-dropdown"
                    }`}
                    onChange={handleChange}
                  />
                </li>
              </ul>

              {isModelShow && (
                <CustomerActivationModale
                  show={isModelShow}
                  onHide={() => setModelShow(false)}
                  title={"are you sure you want ot send active link"}
                  id={id}
                />
              )}
              {validModalShow && (
                <ValidateProspectModal
                  comp_id={customerData?.customer?.comp_id?._id}
                  show={validModalShow}
                  id={id}
                  onHide={() => setValidModalShow(false)}
                />
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ProspectAfterValidateDetails;
