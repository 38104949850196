import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import {
  addDriverApi,
  getSubscriptionByCompanylist,
  mainDriverList,
} from "../../../services/client/addclientApi";
import { SmallLoader } from "../../Common/Loader";
import { fetchCustomerData } from "../../../Redux/clientsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const addDriverSchema = Yup.object({
  firstName: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter Your firstName"),
  lastName: Yup.string().min(2).max(25).required("Please Enter Your lastName"),
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
});

const AddDriverModal = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isLoding, setLoding] = useState(false);
  const [isActive, setActive] = useState(0);
  const [isMainDriverDropdwonValue, setMainDriverDropdwonValue] = useState();
  const [isSubscriptionValue, setSubscriptionValue] = useState();
  const { customerData } = useSelector((state) => state?.clientsSlice);
  const driveRole = ["MAIN", "AUX"];

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      belongTo: "",
    },
    onSubmit: async (values) => {
      setLoding(true);
      const body = {
        customer_id: props.clientId,
        firstname: values?.firstName,
        lastname: values?.lastName,
        receiver_email: values?.email,
        roles: isActive === 0 ? "MAIN" : "AUX",
        comp_id: customerData?.customer?.comp_id?._id,
        sub_id: isActive === 0 && values?.subscription?._id,
        main_customer_id: values?.belongTo?._id,
        country:
          customerData?.customer.cust_type === "Entreprise"
            ? customerData?.customer?.company?.company_country
            : customerData?.customer?.country,
        cust_type: "Particulier",
      };
      const data = await addDriverApi(body);
      if (data?.response?.data?.error) {
        setLoding(false);
      }
      if (data?.status) {
        props.onHide();
        setLoding(false);
        dispatch(fetchCustomerData(id));
      }
    },
    validationSchema: addDriverSchema,
  });

  const mainDriver = async () => {
    const tempData = await mainDriverList(props.clientId);
    const dropDownValue = tempData?.data?.map((item) => ({
      _id: item?._id,
      label: `${item?.firstname} ${item?.lastname}`,
    }));

    setMainDriverDropdwonValue(
      typeof dropDownValue === "undefined" ? [] : dropDownValue
    );
  };

  // subscriptionDropdownValue start
  const subscriptionDropdownValue = async (id) => {
    const subscriptionValue = await getSubscriptionByCompanylist(id);

    if (subscriptionValue !== undefined) {
      setSubscriptionValue(subscriptionValue?.data);
    } else {
      setSubscriptionValue([]);
    }
  };
  // subscriptionDropdownValue end

  useEffect(() => {
    mainDriver(props.clientId);
    subscriptionDropdownValue(customerData?.customer?.comp_id?._id);
  }, []);

  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-center w-100"
        >
          Add a Driver
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="modal-tab mx-auto">
            <ul>
              {driveRole?.map((item, index) => (
                <li
                  key={index}
                  className={`border-0 cursor-pointer ${
                    isActive === index && "is-tab-active"
                  }`}
                  onClick={() => {
                    setActive(index);
                  }}
                >
                  {item}
                </li>
              ))}
            </ul>
          </div>

          {isActive !== 0 && (
            <div className="mb-3">
              <label className="form-label">
                BELONGS TO (MAIN DRIVER LIST FOR THIS CLIENT)
              </label>
              <Dropdown
                placeholder="Type"
                className="w-full"
                name="belongTo"
                value={formik.values.belongTo}
                options={isMainDriverDropdwonValue}
                onChange={formik.handleChange}
              />
            </div>
          )}
          <div className="row">
            <div className="col mb-3">
              <label className="form-label ">First Name</label>
              <input
                type="text"
                name="firstName"
                placeholder="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control"
                aria-controls="DataTables_Table_0"
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <div className="invalid-feedback">
                  {formik.errors.firstName}
                </div>
              )}
            </div>
            <div className="col mb-3">
              <label className="form-label ">Last Name</label>
              <input
                type="text"
                name="lastName"
                placeholder="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control"
                aria-controls="DataTables_Table_0"
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <div className="invalid-feedback">{formik.errors.lastName}</div>
              )}
            </div>
          </div>
          <div className="mb-3">
            <label className="form-label ">Email</label>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formik.values.email}
              onChange={(e) => {
                const lowerCaseEmail = e.target.value.toLowerCase();
                formik.setFieldValue("email", lowerCaseEmail);
              }}
              onBlur={formik.handleBlur}
              className="form-control"
              aria-controls="DataTables_Table_0"
            />
            {formik.touched.email && formik.errors.email && (
              <div className="invalid-feedback">{formik.errors.email}</div>
            )}
          </div>
          {isActive === 0 && (
            <div className="mb-3">
              <label className="form-label">Abonnement</label>
              <Dropdown
                value={formik.values.subscription}
                onChange={formik.handleChange}
                options={isSubscriptionValue}
                optionLabel="subscription_name"
                placeholder="Abonnement"
                className={`w-full ${
                  formik.errors.subscription ? "dropdown-invalid" : ""
                }`}
                name="subscription"
                {...(formik.values.subscription && { showClear: true })}
              />
            </div>
          )}

          <div className="d-flex justify-content-center gap-3 ">
            <button
              className="btn btn-primary waves-effect waves-light"
              type="submit"
            >
              Submit
              {isLoding && <SmallLoader />}
            </button>
            <button
              className="btn btn-label-danger waves-effect"
              onClick={props.onHide}
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddDriverModal;
