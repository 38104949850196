import React, { useRef } from "react";

const AutoResizingTextArea = ({ value, name, className }) => {
  const textAreaRef = useRef(null);

  const adjustHeight = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  };

  React.useEffect(() => {
    adjustHeight();
  }, []);

  return (
    <textarea
      ref={textAreaRef}
      name={name}
      className={className}
      rows={1}
      defaultValue={value}
      placeholder="Address"
    />
  );
};

export default AutoResizingTextArea;
