// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stick-top{position:sticky;bottom:0;top:10px}.stick-top.course-content-fixed{top:80px}.accordion-button::after{margin-top:1rem}
`, "",{"version":3,"sources":["webpack://./src/assets/vendor/css/pages/app-academy-details.css"],"names":[],"mappings":"AAAA,WAAW,eAAe,CAAC,QAAQ,CAAC,QAAQ,CAAC,gCAAgC,QAAQ,CAAC,yBAAyB,eAAe","sourcesContent":[".stick-top{position:sticky;bottom:0;top:10px}.stick-top.course-content-fixed{top:80px}.accordion-button::after{margin-top:1rem}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
