import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { IoClose } from "react-icons/io5";
import { HiOutlineCalendar } from "react-icons/hi2";
import { GoPerson } from "react-icons/go";
import { IoStatsChartOutline } from "react-icons/io5";
import { MdOutlinePhotoCamera } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import ReservationDetails from "./DeliverySteps/ReservationDetails";
import CustomerDetails from "./DeliverySteps/CustomerDetails";
import VehicleInformation from "./DeliverySteps/VehicleInformation";
import VehicleConditionPhoto from "./DeliverySteps/VehicleConditionPhoto";
import Signature from "./DeliverySteps/Signature";
import { addVehicleDeliveryInfo, getSingleResDelivery } from "../../../services/Deliveries";
// import { useParams, useNavigate } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkStatusUpdate } from "../../../services/check";
import { fetchDeliveryNew } from "../../../Redux/deliverySlice";

function ValidateModalWindow({
  address,
  show,
  handleCloseValidateModal,
  handleCloseModal,
  selectedResv,
}) {
  // let { state } = useLocation();
  // const isFromAnnotatePage = state?.isFromAnnotatePage;
  // console.log('isFromAnnotatePage', isFromAnnotatePage);

  const { isFromAnnotatePage } = useSelector((state) => state?.deliverySlice);

  const initialStep = isFromAnnotatePage ? 4 : 1;

  const [currentStep, setCurrentStep] = useState(initialStep);
  const [showSignature, setShowSignature] = useState(false);

  // const [isdriver1, setIsdriver1] = useState(false);
  // const [isdriver2, setIsdriver2] = useState(false);

  const [manufacturer, setManufacturer] = useState("");
  const [model, setMmodel] = useState("");
  let [stepDetail, setStepDetail] = useState(null);

  const [newKmValue, setNewKmValue] = useState(null);
  const [value, setValue] = useState(0);
  const [signatureValue, setSignatureValue] = useState(null);
  const [isClient, setIsclinet] = useState(false);
  const dispatch = useDispatch();

  // let handleDriver1 = () => [setIsdriver1(true)];
  // let handleDriver2 = () => [setIsdriver2(true)];

  const getVehicleData = (dataManu, dataModel) => {
    setManufacturer(dataManu);
    setMmodel(dataModel);
  };

  let [selectAuxIds, setSelectedAuxIds] = useState([]);

  useEffect(() => {
    getReservDetail();
  }, []);

  const getReservDetail = async () => {
    let reservId = selectedResv._id;
    let details = await getSingleResDelivery(reservId);
    if (details && details.data) {
      setStepDetail(details?.data);
    }
  };

  const components = [
    {
      id: 1,
      header: "étape 1: détails de la réservation",
      component: <ReservationDetails stepDetail={stepDetail} />,
      icons: <HiOutlineCalendar size={54} className="text-black" />,
    },
    {
      id: 2,
      header: "étape 2: Détails du client",
      component: (
        <CustomerDetails
          stepDetail={stepDetail}
          address={address}
          // handleAddDriver={handleAddDriver}
          // handleDriver1={handleDriver1}
          // handleDriver2={handleDriver2}
          selectedResv={selectedResv}
          selectAuxIds={selectAuxIds}
          setSelectedAuxIds={setSelectedAuxIds}
        />
      ),
      icons: <GoPerson size={54} className="text-black" />,
    },
    {
      id: 3,
      header: "étape 3: Informations du véhicule",
      component: (
        <VehicleInformation
          stepDetail={stepDetail}
          selectedResv={selectedResv}
          getVehicleData={getVehicleData}
          newKmValue={newKmValue}
          setNewKmValue={setNewKmValue}
          setValue={setValue}
          value={value}
          setIsclinet={setIsclinet}
          setSignatureValue={setSignatureValue}
        />
      ),
      icons: <IoStatsChartOutline size={54} className="text-black" />,
    },
    {
      id: 4,
      header: "étape 4: Photos de l’état du véhicule",
      component: <VehicleConditionPhoto reservDetail={selectedResv} />,
      icons: <MdOutlinePhotoCamera size={54} className="text-black" />,
    },
  ];

  const handleNext = async () => {
    if (currentStep < components.length) {
      setCurrentStep(currentStep + 1);
    } else {
      let payload = {
        reservationId: selectedResv._id,
      };
      if (
        selectedResv &&
        selectedResv.reservation_status &&
        selectedResv.reservation_status === "DELIVERYIN"
      ) {
        let payloadDelivery = {
          'reservation_id': selectedResv._id,
          'general_id': selectedResv.generalId,
          "kilometer": parseInt(newKmValue),
          "fuel_level": parseInt(value),
          "delivery_type": 'IN',
          "is_client_present_yn": isClient,
          "signature": signatureValue
        }
        let res = await addVehicleDeliveryInfo(payloadDelivery);

        payload["reservation_status"] = "CHECKIN";
        let checkStatus = await checkStatusUpdate(payload);
        if (checkStatus) {
          dispatch(fetchDeliveryNew());
          handleCloseValidateModal();
        }
      } else {
        setShowSignature(true);
      }
      // Show signature modal after the last step
    }
  };

  return (
    <>
      <Modal
        show={show}
        centered
        // onHide={() => setShow(false)}
        onHide={handleCloseValidateModal}
        // fullscreen
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="validateModal-Parentcontainer"
      >
        <div className="position-relative">
          <div className=" position-absolute">
            <IoClose
              size={30}
              style={{ fontSize: "13rem" }}
              className="mx-1 mt-1 closeBtnValidationModal"
              onClick={handleCloseValidateModal}
            />
          </div>
          <div className="validateModal-container d-flex flex-column pb-sm-3 px-sm-3 gap-3 pt-2">
            <div className=" text-center fw-medium text-black mt-1">
              <span className="validationModalHeader fs-5">
                {components.find((comp) => comp.id === currentStep).header}
              </span>
            </div>
            <div className=" d-flex justify-content-between px-2">
              {components.map((comp, index) => (
                <div
                  key={index}
                  className={`image-item ${currentStep === comp.id ? "active" : ""
                    } ${currentStep > comp.id ? "completed" : ""}`}
                >
                  <div className="validateIconsDiv p-1">{comp.icons}</div>
                  {currentStep > comp.id && (
                    <span className="tick-badge">
                      <FaCheckCircle color="#0057FF" size={21} />
                    </span>
                  )}
                </div>
              ))}
            </div>
            <div className="body-content">
              {components.find((comp) => comp.id === currentStep).component}
            </div>
            <div className="d-flex justify-content-end ">
              <Button
                className="btn-validationModal px-4"
                onClick={handleNext}
                style={{ background: "#0057FF", borderRadius: "15px" }}
              >
                Suivant
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <Signature
        show={showSignature}
        value={value}
        newKmValue={newKmValue}
        selectedResv={selectedResv}
        onHide={() => setShowSignature(false)}
        selectAuxIds={selectAuxIds}
      />
    </>
  );
}

export default ValidateModalWindow;
