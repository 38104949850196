import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { editModel } from "../../../../services/AddVehicle";
import {
  fetchModalAllValue,
  fetchModalDropdown,
} from "../../../../Redux/vehicleSlice";
const EditModel = (props) => {
  const [modelName, setModelName] = useState(props.modelName);
  const [isManufacturer, setManufacturer] = useState(props.isManufacturerId);
  const [isUpdateModel, setUpdateModel] = useState(false);
  const dispatch = useDispatch();
  const { singlevehicleData } = useSelector((state) => state.vehicleSlice);
  console.log(props.isManufacturerId, "props.isManufacturerId");
  const onSaveChanges = async () => {
    setUpdateModel(true);
    try {
      const response = await editModel(props.id, {
        manufacturer: isManufacturer,
        name: modelName,
      });
      if (response) {
        props.onHide();
        await dispatch(fetchModalAllValue());
        await dispatch(fetchModalDropdown(props.isManufacturerId));
      }
      return response;
    } catch (error) {
      console.log("Error updating model:", error);
    }
  };
  return (
    <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modifier le modèle
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col">
          <label className="form-label mb-1">Nom</label>
          <input
            value={modelName}
            onChange={(e) => setModelName(e.target.value)}
            type="text"
            className="form-control"
            placeholder="Modifier le modèle"
            aria-controls="DataTables_Table_0"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-label-danger waves-effect"
          onClick={props.onHide}
        >
          Annuler
        </button>
        <button
          className="btn btn-primary waves-effect waves-light"
          onClick={onSaveChanges}
        >
          Mise à jour
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditModel;
