// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-pills .nav-item {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
}
.nav-pills .active {
  background-color: #0084ff;
  border-radius: 5px;
  color: white !important;
}
.nav-pills .active .text-c-white {
  color: white !important;
}
/* .nav-pills .nav-link:hover {
    color: #fcfcfc !important;
  } */
`, "",{"version":3,"sources":["webpack://./src/css/nav-pills.css"],"names":[],"mappings":"AAAA;EACE,kEAAkE;AACpE;AACA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,uBAAuB;AACzB;AACA;EACE,uBAAuB;AACzB;AACA;;KAEK","sourcesContent":[".nav-pills .nav-item {\n  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);\n}\n.nav-pills .active {\n  background-color: #0084ff;\n  border-radius: 5px;\n  color: white !important;\n}\n.nav-pills .active .text-c-white {\n  color: white !important;\n}\n/* .nav-pills .nav-link:hover {\n    color: #fcfcfc !important;\n  } */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
