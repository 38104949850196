/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { TagsInput } from "react-tag-input-component";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import { Dropdown } from "primereact/dropdown";
import {
  createNotes,
  deleteClient,
  getSubscriptionByCompanylist,
} from "../services/client/addclientApi";
import BreadCrumbs from "../components/Common/BreadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../components/Common/Loader";
import { BsChevronUp } from "react-icons/bs";
import { BsChevronDown } from "react-icons/bs";
import AddNewProspect from "../components/Prospect/AddProspect";
import { useNavigate } from "react-router-dom";
import { pagination } from "../components/Common/Pagination";
import TableFooter from "../components/Common/TableFooter";
import {
  alphabeticalBasic,
  fetchcompanyDropdown,
  searchProsUserList,
  setProspectSearches,
  setProspectSortBy,
} from "../Redux/commonSlice";
import DeleteModal from "../components/Common/DeleteModal";
import RoleAndsubscriptionname from "../components/Prospect/Components/RoleAndsubscriptionname";
import moment from "moment/moment";

const Prospect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addShow, setAddShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [clientId, setclientId] = useState(null);
  const [isSubscriptionValue, setSubscriptionValue] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setitemsPerPage] = useState("25");
  // const [initialValue, setInitialValue] = useState({
  //   search: "",
  //   company: "",
  //   status: "",
  //   subscription: "",
  // });

  const {
    companyDropdown,
    basicList,
    tempBasicList,
    loading,
    prospectSearches,
    prospectSortBy,
  } = useSelector((state) => state?.commonSlice);

  // pagination start
  const basicListData = pagination(basicList, currentPage, itemsPerPage);
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  // pagination end

  // subscriptionDropdownValue start
  const subscriptionDropdownValue = async (id) => {
    const subscriptionValue = await getSubscriptionByCompanylist(id);
    if (subscriptionValue !== undefined) {
      setSubscriptionValue(subscriptionValue?.data);
    } else {
      setSubscriptionValue([]);
    }
  };
  // subscriptionDropdownValue end

  const handleSelectChange = (e) => {
    setitemsPerPage(e.value);
  };

  const aTozSorting = (key) => {
    dispatch(setProspectSortBy({ key, order: "aToz" }));
    // Create a temporary array with 'member' assigned to 'comp_id.name' for sorting.
    const tempData = tempBasicList.map((item) => ({
      ...item,
      member: item?.comp_id?.name,
    }));

    // Perform A-to-Z (ascending) sorting based on the key.
    const sortedData = tempData.sort((a, b) => {
      // Handle potential undefined or null values gracefully.
      const valA = a?.[key] ?? "";
      const valB = b?.[key] ?? "";

      // Check if the key is 'index' to perform numerical sorting.
      if (key === "index") {
        return Number(valA) - Number(valB); // Ascending numerical sort
      }

      return valA.localeCompare(valB);
    });

    // Dispatch the sorted data to the store.
    dispatch(alphabeticalBasic(sortedData));
  };

  const zToaSorting = (key) => {
    dispatch(setProspectSortBy({ key, order: "zToa" }));
    // Create a temporary array with 'member' assigned to 'comp_id.name' for sorting.
    const tempData = tempBasicList.map((item) => ({
      ...item,
      member: item?.comp_id?.name,
    }));

    // Perform Z-to-A (descending) sorting based on the key.
    const sortedData = tempData.sort((a, b) => {
      // Handle potential undefined or null values gracefully.
      const valA = a?.[key] ?? "";
      const valB = b?.[key] ?? "";

      // Check if the key is 'index' to perform numerical sorting.
      if (key === "index") {
        return Number(valB) - Number(valA); // Descending numerical sort
      }

      return valB.localeCompare(valA);
    });

    // Dispatch the sorted data to the store.
    dispatch(alphabeticalBasic(sortedData));
  };

  const handleSingleProspect = async (customer) => {
    if (customer?.role?.length > 1) {
      navigate(`/client/profile/${customer?._id}`);
    } else {
      if (customer?.role?.includes("BASIC")) {
        navigate(`/prospect/document/${customer?._id}`);
      } else if (
        customer?.role?.includes("AUX") ||
        customer?.role?.includes("MAIN")
      ) {
        navigate(`/driver/profile/${customer?._id}`);
      } else if (customer?.role?.includes("MAIN")) {
        navigate(`/driver/profile/${customer?._id}`);
      } else if (customer?.role?.includes("CLIENT")) {
        navigate(`/client/profile/${customer?._id}`);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentPage(0);
    dispatch(setProspectSearches({ ...prospectSearches, [name]: value }));
    // setInitialValue((prev) => {
    //   const updatedFilters = { ...prev, [name]: value };
    //   return updatedFilters;
    // });
  };

  const handleDelete = async (id) => {
    const form = new FormData();
    form.append("role", ["MAIN", "CLIENT", "AUX", "BASIC"]);
    await deleteClient(clientId);
    setModalShow(false);
    dispatch(searchProsUserList(form));
  };

  // Handle tag change
  const handleTagChange = async (id, newTags) => {
    const body = { tags: newTags };
    await createNotes(id, body);
  };

  const Status = [
    { label: "Basic", value: "BASIC" },
    { label: "Client", value: "CLIENT" },
    { label: "AUX", value: "AUX" },
    { label: "One", value: "One" },
    { label: "Plus", value: "Plus" },
    { label: "Demande", value: "Demande" },
  ];

  useEffect(() => {
    const form = new FormData();
    if (prospectSearches?.search)
      form.append("searchname", prospectSearches?.search);
    if (prospectSearches?.company?._id)
      form.append("comp_id", prospectSearches?.company?._id);
    if (prospectSearches?.subscription?._id)
      form.append("sub_id", prospectSearches?.subscription?._id);
    if (prospectSearches?.status) form.append("role", prospectSearches?.status);
    const debounceTimeout = setTimeout(() => {
      dispatch(searchProsUserList(form));
    }, 300);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [
    prospectSearches?.search,
    prospectSearches?.company,
    prospectSearches?.subscription,
    prospectSearches?.status,
  ]);

  useEffect(() => {
    dispatch(fetchcompanyDropdown());
  }, []);

  useEffect(() => {
    if (prospectSortBy?.key && tempBasicList.length > 0) {
      if (prospectSortBy?.order === "aToz") aTozSorting(prospectSortBy?.key);
      if (prospectSortBy?.order === "zToa") zToaSorting(prospectSortBy?.key);
    }
  }, [tempBasicList]);

  return (
    <div className="container-fluid flex-grow-1 container-p-y client">
      {/* Users List Table */}
      <BreadCrumbs title={"Marketing"} subTitle={"Prospect"} />
      <div className="card">
        <div className="card-header">
          <div className="d-flex align-items-center gap-3">
            <div className="flex-grow-1">
              <input
                type="search"
                className="form-control"
                placeholder="Rechercher.."
                name="search"
                onChange={handleChange}
                value={prospectSearches?.search}
              />
            </div>
            <div className="col-12 col-md-3 col-lg-2 d-none d-lg-inline">
              <Dropdown
                value={prospectSearches?.company}
                options={companyDropdown}
                optionLabel="name"
                filter
                name="company"
                placeholder="Société"
                className="w-full"
                onChange={(e) => {
                  handleChange(e);
                  subscriptionDropdownValue(e.target.value?._id);
                }}
                {...(prospectSearches?.company && { showClear: true })}
              />
            </div>
            {/* <div className="col-12 col-md-3 col-lg-2 d-none d-lg-inline">
              <Dropdown
                value={prospectSearches?.subscription}
                onChange={handleChange}
                options={isSubscriptionValue}
                optionLabel="subscription_name"
                placeholder="Abonnement"
                className="w-full"
                name="subscription"
                {...(prospectSearches?.subscription && { showClear: true })}
              />
            </div> */}
            <div className="col-12 col-md-3 col-lg-2 d-none d-lg-inline">
              <Dropdown
                value={prospectSearches?.status}
                onChange={handleChange}
                optionLabel="label"
                options={Status}
                placeholder="Status"
                className="w-full"
                name="status"
                {...(prospectSearches?.status && { showClear: true })}
              />
            </div>
            <div className="d-flex align-items-center flex-grow-0">
              <button
                className="dt-button add-new btn btn-primary waves-effect waves-light"
                onClick={() => setAddShow(true)}
                type="button"
              >
                <span>
                  <i className="ti ti-plus me-0 me-sm-1 ti-xs" />
                  <span className="d-none d-sm-inline-block">
                    Ajouter un Prospect
                  </span>
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className=" table-responsive">
          <table className="table  table-hover">
            <thead className="border-top">
              <tr>
                <th>
                  #{" "}
                  <div className="d-inline-block item-center">
                    <span className="d-flex flex-column ps-1">
                      <BsChevronUp
                        fontSize={12}
                        onClick={() => aTozSorting("index")}
                      />
                      <BsChevronDown
                        fontSize={12}
                        onClick={() => zToaSorting("index")}
                      />
                    </span>
                  </div>
                </th>
                <th>
                  <span className="position-relative top-4">STATUS</span>
                  <div className="d-inline-block item-center">
                    <span
                      className="d-flex flex-column ps-1"
                      style={{ opacity: "0" }}
                    >
                      <BsChevronUp fontSize={12} />
                      <BsChevronDown fontSize={12} />
                    </span>
                  </div>
                </th>
                <th className="w-25">
                  <span className="position-relative top-4">Tags</span>
                  <div className="d-inline-block item-center">
                    <span
                      className="d-flex flex-column ps-1"
                      style={{ opacity: "0" }}
                    >
                      <BsChevronUp fontSize={12} />
                      <BsChevronDown fontSize={12} />
                    </span>
                  </div>
                </th>
                <th>
                  <span className="position-relative top-4">NOM</span>
                  <div className="d-inline-block item-center">
                    <span className="d-flex flex-column ps-1">
                      <BsChevronUp
                        fontSize={12}
                        onClick={() => aTozSorting("firstname")}
                      />
                      <BsChevronDown
                        fontSize={12}
                        onClick={() => zToaSorting("firstname")}
                      />
                    </span>
                  </div>
                </th>
                <th>
                  <span className="position-relative top-4">E-MAIL</span>
                  <div className="d-inline-block item-center">
                    <span className="d-flex flex-column ps-1">
                      <BsChevronUp
                        fontSize={12}
                        onClick={() => aTozSorting("email")}
                      />
                      <BsChevronDown
                        fontSize={12}
                        onClick={() => zToaSorting("email")}
                      />
                    </span>
                  </div>
                </th>
                <th>
                  <span className="position-relative top-4">
                    Date de Creation
                  </span>
                  <div className="d-inline-block item-center">
                    <span className="d-flex flex-column ps-1">
                      <BsChevronUp
                        fontSize={12}
                        onClick={() => aTozSorting("createdAt")}
                      />
                      <BsChevronDown
                        fontSize={12}
                        onClick={() => zToaSorting("createdAt")}
                      />
                    </span>
                  </div>
                </th>
                <th>ACTIONS </th>
              </tr>
            </thead>
            <tbody>
              {basicListData?.length > 0 &&
                basicListData?.map((customer, index) => {
                  return (
                    <tr
                      className="cursor-pointer"
                      key={customer?.id}
                      onClick={() => {
                        handleSingleProspect(customer);
                      }}
                    >
                      <td>{customer?.index}</td>
                      <td className="text-nowrap">
                        <div className="d-flex gap-2">
                          <RoleAndsubscriptionname
                            roles={customer?.role}
                            beclient={customer?.beclient}
                            subscription_name={
                              customer?.sub_id?.subscription_name
                            }
                            onboardingStep={customer?.onboarding_last_step}
                            contactFilled={customer?.contact}
                          />
                        </div>
                      </td>
                      <td onClick={(e) => e.stopPropagation()}>
                        <TagsInput
                          value={customer.tags}
                          onChange={(newTags) =>
                            handleTagChange(customer._id, newTags)
                          }
                          placeHolder="Enter tags"
                        />
                      </td>
                      <td className="text-nowrap cursor-pointer">
                        {customer?.firstname} &nbsp;{customer?.lastname}
                      </td>
                      <td>{customer?.email}</td>
                      <td>
                        {moment(customer?.createdAt).format(
                          `DD MMM yyyy hh:mm a`
                        )}
                      </td>
                      <td>
                        <i
                          onClick={() => {
                            handleSingleProspect(customer);
                          }}
                          className="menu-icon tf-icons ti ti-edit"
                          type="button"
                        />
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            setModalShow(true);
                            setclientId(customer?._id);
                          }}
                          className="menu-icon tf-icons  ti ti-sm ti-trash"
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          {!loading && (basicList?.length === 0 || !basicList) && (
            <div className="text-center my-4 mx-auto">No Data Found</div>
          )}

          {loading && (
            <div className="text-center my-4 mx-auto">
              <Loader />
            </div>
          )}

          {basicList?.length > 0 && (
            <TableFooter
              itemsPerPage={itemsPerPage}
              handleSelectChange={handleSelectChange}
              listData={basicList}
              handlePageChange={handlePageChange}
            />
          )}
        </div>

        {/* add prospect */}
        {addShow && (
          <AddNewProspect
            show={addShow}
            onHide={() => setAddShow(false)}
            setclientId={setclientId}
          />
        )}

        {modalShow && (
          <DeleteModal
            title={"Supprimer Cette Prospect"}
            description={"Êtes-vous sûr de supprimer le Prospect ?"}
            show={modalShow}
            clientId={clientId}
            deleteData={() => handleDelete(false)}
            onHide={() => setModalShow(false)}
          />
        )}
      </div>
    </div>
  );
};

export default Prospect;
