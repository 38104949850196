/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addClientApi,
  getSubscriptionByCompanylist,
} from "../../services/client/addclientApi";
import { fetchClientList } from "../../Redux/clientsSlice";
import PhoneInput from "react-phone-input-2";
import { CustomerType } from "../Common/DropdownValue";

const AddDriver = (props) => {
  const { show, onHide } = props;
  const [isSubscriptionValue, setSubscriptionValue] = useState([]);
  const [isLoding, setLoding] = useState(null);
  const dispatch = useDispatch();
  const { countriesList, companyDropdown } = useSelector(
    (state) => state?.commonSlice
  );

  const Role = ["AUX", "MAIN"];
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastname: "",
      email: "",
      contact: "",
      comp_id: "",
      customerType: "",
      business: "",
      vatNumber: "",
      postalCode: "",
      address: "",
      city: "",
      country: "",
      subscription: "",
      // driverInfofirstName: "",
      // driverInfoLastName: "",
      // driverInfoPostcode: "",
      // driverInfoAddress: "",
      // driverInfodriver_city: "",
      // driverInfodriver_country: "",
      // driverInfoPhoneNumber: "",
      // driverInfoEmail: "",
      dob: "",
      image: "",
      role: "",
    },

    onSubmit: async (values, { resetForm }) => {
      console.log("🚀  file: Addnewuser.js:58  onSubmit: ~ values:", values);
      setLoding(true);
      const newValues = {
        ...values,
        comp_id: values?.comp_id._id,
        subscription: values?.subscription?._id
          ? values?.subscription?._id
          : "",
      };

      const formData = new FormData();
      console.log(formData, "newValues?.role");
      formData.append("firstname", newValues.firstName);
      formData.append("lastname", newValues.lastname);
      formData.append("email", newValues.email);
      formData.append("contact", newValues.contact);
      formData.append("comp_id", newValues.comp_id);
      formData.append("cust_type", newValues.customerType);
      formData.append(
        "clientDropdown",
        newValues.clientDropdown ? newValues.clientDropdown : ""
      );
      formData.append("business", newValues.business);
      formData.append("vat_number", newValues.vatNumber);
      formData.append("postal_code", newValues.postalCode);
      formData.append("address", newValues.address);
      formData.append("city", newValues.city);
      formData.append("role", newValues.role);
      formData.append("country", newValues.country);
      {
        newValues.subscription !== "" &&
          formData.append("sub_id", newValues.subscription);
      }
      formData.append(
        "driver_country",
        newValues.customerType === "Particulier"
          ? newValues.country
          : newValues.driver_country
          ? newValues.driver_country
          : ""
      );
      formData.append("driver_dob", newValues.dob);
      formData.append("licence", newValues.image);
      const data = await addClientApi(formData);
      if (data) {
        resetForm();
        onHide();
      }
      setLoding(false);
      dispatch(fetchClientList());
    },
    validate: (values) => {
      const errors = {};
      // Add validation rules here
      if (!values.firstName.trim()) {
        errors.firstName = "This field is required.";
      }
      if (!values.lastname.trim()) {
        errors.lastname = "This field is required.";
      }
      if (!values.email.trim()) {
        errors.email = "This field is required.";
      }
      if (!values.contact?.toString().trim()) {
        errors.contact = "This field is required.";
      }
      if (!values.comp_id?.toString().trim()) {
        errors.comp_id = "This field is required.";
      }
      // Add validation for other fields
      return errors;
    },
  });

  useEffect(() => {
    formik.resetForm();
  }, [show]);

  // subscriptionDropdownValue start
  const subscriptionDropdownValue = async () => {
    try {
      const compId = formik.values?.comp_id?._id;
      if (compId) {
        const subscriptionValue = await getSubscriptionByCompanylist(compId);
        setSubscriptionValue(subscriptionValue?.data);
      }
    } catch (error) {
      console.error("Error fetching subscription values:", error);
    }
  };

  useEffect(() => {
    if (formik.values?.comp_id?._id) {
      subscriptionDropdownValue();
    }
  }, [formik.values?.comp_id]);
  // subscriptionDropdownValue end

  return (
    <>
      {show && <div className="fade c-offcanvas-backdrop show"></div>}
      <div
        className={`c-offcanvas offcanvas-end ${show ? "c-show" : ""}`}
        tabIndex={-1}
        id="offcanvasEnd"
        aria-labelledby="offcanvasEndLabel"
        aria-modal="true"
        role="dialog"
      >
        <div className="c-offcanvas-header">
          <h5 id="offcanvasEndLabel" className="offcanvas-title">
            Ajouter un Client
          </h5>
          <button
            onClick={() => onHide()}
            type="button"
            className="btn-close text-reset"
            aria-label="Close"
          />
        </div>
        <div className="c-offcanvas-body my-auto mx-0 flex-grow-0">
          <form
            className="add-new-user pt-0"
            id="addNewUserForm"
            onSubmit={formik.handleSubmit}
          >
            <div className="mb-3 d-flex gap-3">
              <div>
                <label className="form-label" htmlFor="add-user-fullname">
                  Prénom
                </label>
                <input
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  type="text"
                  className={`form-control ${
                    formik.errors.firstName ? "form-control-invalid" : ""
                  }`}
                  id="add-user-fullname"
                  placeholder="Prénom"
                  name="firstName"
                  aria-label="John"
                />
                {formik.errors.firstName && (
                  <div className="invalid-feedback">
                    {formik.errors.firstName}
                  </div>
                )}
              </div>
              <div className="">
                <label className="form-label" htmlFor="add-user-email">
                  Nom de famille
                </label>
                <input
                  value={formik.values.lastname}
                  onChange={formik.handleChange}
                  type="text"
                  placeholder="Nom de famille"
                  aria-label="doe"
                  name="lastname"
                  className={`form-control ${
                    formik.errors.lastname ? "form-control-invalid" : ""
                  }`}
                  id="add-user-lastname"
                />
                {formik.errors.lastname && (
                  <div className="invalid-feedback">
                    {formik.errors.lastname}
                  </div>
                )}
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label" htmlFor="add-user-email">
                E-mail
              </label>
              <input
                type="email"
                id="add-user-email"
                placeholder="@turismo.com"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                className={`form-control ${
                  formik.errors.email ? "form-control-invalid" : ""
                }`}
              />
              {formik.errors.email && (
                <div className="invalid-feedback">{formik.errors.email}</div>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="add-user-email">
                Contact
              </label>
              <PhoneInput
                country={"Luxembourg"}
                enableSearch={true}
                className={`form-control ${
                  formik.errors.contact ? "form-control-invalid" : ""
                }`}
                placeholder="+352 369 852 472"
                value={"+352" || formik.values.contact}
                onChange={(value) => formik.setFieldValue("contact", value)}
              />

              {formik.errors.contact && (
                <div className="invalid-feedback">{formik.errors.contact}</div>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label" htmlFor="add-user-email">
                Membre
              </label>
              <Dropdown
                options={companyDropdown}
                placeholder="Société"
                className={`w-full ${
                  formik.errors.firstName ? "dropdown-invalid" : ""
                }`}
                name="comp_id"
                optionLabel="name"
                value={formik.values.comp_id}
                onChange={formik.handleChange}
              />
              {formik.errors.comp_id && (
                <div className="invalid-feedback">{formik.errors.comp_id}</div>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="add-user-email">
                Type de Client
              </label>
              <Dropdown
                options={CustomerType}
                placeholder="Type"
                className="w-full"
                name="customerType"
                value={formik.values.customerType}
                onChange={formik.handleChange}
              />
            </div>
            {formik.values.customerType === "Entreprise" && (
              <>
                <div className="mb-3">
                  <label className="form-label" htmlFor="add-user-email">
                    Nom de la Société
                  </label>
                  <input
                    type="text"
                    id="add-user-email"
                    className="form-control"
                    placeholder="Nom"
                    name="business"
                    value={formik.values.business}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label" htmlFor="add-user-email">
                    Numéro de TVA
                  </label>
                  <input
                    type="text"
                    id="add-user-email"
                    className="form-control"
                    placeholder="LU000"
                    name="vatNumber"
                    value={formik.values.vatNumber}
                    onChange={formik.handleChange}
                  />
                </div>
              </>
            )}

            <div className="mb-3">
              <label className="form-label" htmlFor="add-user-email">
                Code Postal
              </label>
              <input
                type="number"
                id="add-user-email"
                className="form-control"
                placeholder="0000"
                aria-label="Postal code"
                name="postalCode"
                value={formik.values.postalCode}
                onChange={formik.handleChange}
              />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="add-user-contact">
                Addresse
              </label>
              <input
                type="text"
                id="add-user-contact"
                className="form-control phone-mask"
                placeholder="Address"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
              />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="add-user-contact">
                Ville
              </label>
              <input
                type="text"
                id="add-user-contact"
                className="form-control phone-mask"
                placeholder="Ville"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
              />
            </div>
            <div className="mb-3" tabIndex={10}>
              <label className="form-label" htmlFor="add-user-company">
                Country
              </label>
              <Dropdown
                options={countriesList}
                placeholder="Country"
                filter
                className="w-full"
                name="country"
                filterInputAutoFocus
                value={formik.values.country}
                onChange={formik.handleChange}
              />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="country">
                Abonnement
              </label>
              <Dropdown
                options={isSubscriptionValue}
                placeholder="Abonnement"
                optionLabel="subscription_name"
                className="w-full"
                name="subscription"
                value={formik.values.subscription}
                onChange={formik.handleChange}
              />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="country">
                Role
              </label>
              <Dropdown
                options={Role}
                placeholder="Role"
                className="w-full"
                name="role"
                value={formik.values.role}
                onChange={formik.handleChange}
              />
            </div>
            <hr className="mb-1" />
            <div className="mb-3">
              <label className="form-label" htmlFor="user-role">
                Date de Naissance
              </label>
              <input
                type="date"
                id="add-user-company"
                className="form-control mt-1"
                placeholder="Last Name Frist Name"
                aria-label="jdoe1"
                name="dob"
                value={formik.values.dob}
                onChange={formik.handleChange}
              />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="user-role">
                Permis de conduire et Carte ID
              </label>
              <input
                type="file"
                id="add-user-company"
                className="form-control mt-1"
                placeholder="Info d'identité"
                aria-label="jdoe1"
                name="image"
                accept="image/*"
                onChange={(event) =>
                  formik.setFieldValue("image", event.currentTarget.files[0])
                }
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary me-sm-3 me-1 mb-3 data-submit"
            >
              {isLoding && (
                <span
                  class="spinner-border text-white me-2"
                  role="status"
                ></span>
              )}
              Ajouter
            </button>
            <div className="mb-3"></div>
            <button
              type="reset"
              className="btn btn-label-secondary"
              onClick={() => onHide()}
            >
              Annuler
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddDriver;
