// LocationSearchInput.js
import React, { useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const LocationSearchInput = ({ onSelect,addressExist,setSelectedAdd }) => {
  const [address, setAddress] = useState(addressExist);

  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      setAddress(selectedAddress);
      setSelectedAdd(results)
      // console.log('Selected Address:', selectedAddress,results);
      // console.log('Latitude and Longitude:', latLng);
      onSelect(selectedAddress, latLng);
    } catch (error) {
      console.error('Error selecting address:', error);
    }
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={setAddress}
      onSelect={handleSelect}
      searchOptions={{ key: 'AIzaSyCfC8Izlpa7eb4Rae3EtA5-WcmrHr8BHAA' }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input
            {...getInputProps({
              placeholder: 'Enter your address',
              className: 'form-control', // Adjust styling as needed
              name: 'search_input'
            })}
          />
          <div>
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion,i) => (
              <div key={i} {...getSuggestionItemProps(suggestion)} style={{cursor: 'pointer'}}>
                {suggestion.description}
                {i < suggestions.length - 1 && <hr />}
              </div>
            ))}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default LocationSearchInput;
