// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pricing-plans-comparison .table tr>th:first-child,.pricing-plans-comparison .table tr>td:first-child{white-space:nowrap;text-align:start}.pricing-plans-comparison .table tbody tr:last-child td{border-bottom:0}.price-yearly-toggle{position:absolute;margin:auto;left:0;right:0}.light-style .bg-alt-pricing{background-color:#f8f7fa}.dark-style .bg-alt-pricing{background-color:#25293c}@media(min-width: 992px){.pricing-free-trial img{position:absolute;bottom:0}html:not([dir=rtl]) .pricing-free-trial img{right:0}[dir=rtl] .pricing-free-trial img{left:0}}
`, "",{"version":3,"sources":["webpack://./src/assets/vendor/css/pages/page-pricing.css"],"names":[],"mappings":"AAAA,sGAAsG,kBAAkB,CAAC,gBAAgB,CAAC,wDAAwD,eAAe,CAAC,qBAAqB,iBAAiB,CAAC,WAAW,CAAC,MAAM,CAAC,OAAO,CAAC,6BAA6B,wBAAwB,CAAC,4BAA4B,wBAAwB,CAAC,yBAAyB,wBAAwB,iBAAiB,CAAC,QAAQ,CAAC,4CAA4C,OAAO,CAAC,kCAAkC,MAAM,CAAC","sourcesContent":[".pricing-plans-comparison .table tr>th:first-child,.pricing-plans-comparison .table tr>td:first-child{white-space:nowrap;text-align:start}.pricing-plans-comparison .table tbody tr:last-child td{border-bottom:0}.price-yearly-toggle{position:absolute;margin:auto;left:0;right:0}.light-style .bg-alt-pricing{background-color:#f8f7fa}.dark-style .bg-alt-pricing{background-color:#25293c}@media(min-width: 992px){.pricing-free-trial img{position:absolute;bottom:0}html:not([dir=rtl]) .pricing-free-trial img{right:0}[dir=rtl] .pricing-free-trial img{left:0}}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
