/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { updataClientApi } from "../../../services/client/addclientApi";
import { Field, Form, Formik } from "formik";
import {
  fetchClientList,
  fetchCustomerData,
} from "../../../Redux/clientsSlice";
import { useDispatch, useSelector } from "react-redux";
import { SmallLoader } from "../../Common/Loader";
import CustomerActivationModale from "../../Common/CustomertActivationModale";
import pencilIcon from "../../../assets/icons/pencil.png";
import { useParams } from "react-router-dom";
import { RoleLabel, subscriptionStatusClass } from "../../Common/roles";
import { Dropdown } from "primereact/dropdown";
import PhoneInput from "react-phone-input-2";
import AddressAutocompleteInput from "../../Common/AddressAutocompleteInput";
import AutoResizingTextArea from "../../Common/AutoResizingTextArea";

const DriverDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isEdit, setEdit] = useState(true);
  const [isLoding, setLoding] = useState(false);
  const [isModelShow, setModelShow] = useState(false);
  const { customerData } = useSelector((state) => state?.clientsSlice);

  const initialValues = {
    firstname: customerData?.customer?.firstname || "",
    lastname: customerData?.customer?.lastname || "",
    email: customerData?.customer?.email || "",
    contact: customerData?.customer?.contact || "",
    address: `${customerData?.customer?.address || ""}`,
    buildingNumber: customerData?.customer?.houseno || "",
    DOB: customerData?.customer?.DOB
      ? new Date(customerData?.customer?.DOB).toISOString().split("T")[0]
      : "",
    language: customerData?.customer?.language,
    gender: customerData?.customer?.gender,
    licensedate: customerData?.customer?.licensedate
      ? new Date(customerData?.customer?.licensedate)
          .toISOString()
          .split("T")[0]
      : "",
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={async (values) => {
          setLoding(true);
          const formData = new FormData();
          formData.append("firstname", values.firstname);
          formData.append("lastname", values.lastname);
          if (customerData?.customer?.email !== values.email) {
            formData.append("email", values.email);
            formData.append("active", false);
          }
          formData.append("contact", values.contact);
          if (values.buildingNumber)
            formData.append("houseno", values.buildingNumber);
          if (values.address) formData.append("address", values.address);
          formData.append("DOB", values.DOB);
          formData.append("language", values.language);
          formData.append("gender", values.gender);
          formData.append("licensedate", values.licensedate);
          const data = await updataClientApi(id, formData);

          if (data) {
            dispatch(fetchClientList());
            dispatch(fetchCustomerData(id));
            setEdit(true);
            setLoding(false);
          }
        }}
      >
        {({ values, setFieldValue, handleChange, handleSubmit }) => (
          <Form className="card mb-4">
            <div className="card-body">
              <div className="d-flex justify-content-between position-relative">
                <div className="position-absolute start-0 top-0">
                  {isEdit && (
                    <button
                      className="btn waves-effect waves-light p-0 align-items-start"
                      onClick={() => {
                        setEdit(false);
                      }}
                    >
                      <img src={pencilIcon} alt="pencil-icon" />
                    </button>
                  )}
                </div>
                <div className="mx-auto">
                  <div className="text-center d-flex justify-content-center gap-2">
                    <div>
                      {!isEdit && (
                        <button
                          type="button"
                          onClick={handleSubmit}
                          className="btn btn-primary waves-effect waves-light ms-2"
                        >
                          Save
                          {isLoding && <SmallLoader />}
                        </button>
                      )}
                    </div>
                    <h4 className="">
                      {!isEdit ? (
                        <div className="d-flex gap-2">
                          <Field
                            readOnly={isEdit}
                            name="firstname"
                            value={values?.firstname}
                            placeholder="firstname"
                            className={`${
                              isEdit ? "hide-input" : "form-control"
                            }`}
                          />
                          <Field
                            readOnly={isEdit}
                            name="lastname"
                            value={values?.lastname}
                            placeholder="lastname"
                            className={`${
                              isEdit ? "hide-input" : "form-control"
                            }`}
                          />
                        </div>
                      ) : (
                        <div>
                          {customerData?.customer?.firstname}{" "}
                          {customerData?.customer?.lastname}
                        </div>
                      )}
                    </h4>
                    {customerData?.customer?.firstname &&
                      customerData?.customer?.lastname &&
                      customerData?.customer?.sub_id?.subscription_name &&
                      customerData?.customer?.email &&
                      customerData?.customer?.comp_id?.name &&
                      customerData?.customer?.address &&
                      customerData?.customer?.billingaddress &&
                      customerData?.customer?.invoice_email && (
                        <div className=" badge-center bg-success p-3 ms-2 rounded-2">
                          <i className="ti ti-check ti-sm text-white"></i>
                        </div>
                      )}
                  </div>

                  <div className="d-flex justify-content-center gap-2">
                    <RoleLabel userrols={customerData?.customer?.role} />
                    <span
                      className={
                        subscriptionStatusClass[
                          customerData?.customer?.sub_id?.subscription_name
                        ]
                      }
                    >
                      {customerData?.customer?.sub_id?.subscription_name}
                    </span>
                  </div>
                </div>
              </div>
              <hr />
              <small className="card-text text-uppercase text-muted">
                DRIVER DETAILS
              </small>

              <ul className="list-unstyled mb-4 mt-3">
                <li className="d-flex align-items-center mb-3">
                  <span className="fw-medium mx-2 text-heading">Email:</span>
                  <Field
                    readOnly={isEdit}
                    name="email"
                    value={values?.email}
                    placeholder="email"
                    className={`${isEdit ? "hide-input" : "form-control"}`}
                  />
                  {isEdit === true &&
                    customerData?.customer?.active === false && (
                      <btn
                        className="text-primary text-decoration-underline cursor-pointer"
                        onClick={(e) => {
                          setModelShow(true);
                        }}
                      >
                        Activate
                      </btn>
                    )}
                </li>

                <li className="d-flex align-items-center mb-3">
                  <span className="fw-medium mx-2 text-heading">Contact:</span>
                  <PhoneInput
                    disabled={isEdit}
                    country={"Luxembourg"}
                    enableSearch={true}
                    className={` ${
                      !isEdit ? "form-control flex-grow-1 " : "hide-input"
                    }`}
                    placeholder="+352 369 852 472"
                    value={values?.contact?.toString() || "+352"}
                    onChange={(value) => setFieldValue("contact", value)}
                  />
                </li>

                {isEdit ? (
                  <li className="d-flex align-items-start mb-3">
                    <span className="fw-medium mx-2 text-heading text-nowrap">
                      Address:
                    </span>

                    <AutoResizingTextArea
                      value={values.address}
                      name="address"
                      id="address"
                      className="w-100 hide-input resize-none"
                    />
                  </li>
                ) : (
                  <li className="d-flex align-items-center mb-3">
                    <span className="fw-medium mx-2 text-heading text-nowrap">
                      Address:
                    </span>
                    <AddressAutocompleteInput
                      name="address"
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </li>
                )}
                <li className="d-flex align-items-center mb-3">
                  <span className="fw-medium mx-2 text-heading text-nowrap">
                    Street/Building No:
                  </span>
                  <Field
                    readOnly={isEdit}
                    name="buildingNumber"
                    placeholder=" "
                    className={`${isEdit ? "hide-input" : "form-control"}`}
                  />
                </li>

                <li className="d-flex align-items-center mb-3">
                  <span className="fw-medium mx-2 text-heading">DOB:</span>
                  <Field
                    type="date"
                    readOnly={isEdit}
                    name="DOB"
                    value={values?.DOB}
                    className={`${isEdit ? "hide-input" : "form-control"}`}
                  />
                </li>
                <hr />

                <li className="d-flex align-items-center mb-3 ">
                  <span className="fw-medium mx-2 text-heading">
                    Languages:
                  </span>
                  <Dropdown
                    disabled={isEdit}
                    options={["FR", "EN"]}
                    className={`w-full ${
                      isEdit ? "p-dropdown-hide" : "p-dropdown"
                    }`}
                    name="language"
                    value={values.language}
                    onChange={handleChange}
                  />
                </li>
                <li className="d-flex align-items-center  mb-3">
                  <span className="fw-medium mx-2 text-heading">Gender:</span>
                  <Dropdown
                    disabled={isEdit}
                    options={["Male", "Female", "Other"]}
                    name="gender"
                    value={values.gender}
                    className={`w-full ${
                      isEdit ? "p-dropdown-hide" : "p-dropdown"
                    }`}
                    onChange={handleChange}
                  />
                </li>
                <li className="d-flex align-items-center mb-3">
                  {/* <i className="ti ti-credit-card text-heading" /> */}
                  <span className="fw-medium mx-2 text-heading text-nowrap">
                    Permis B :
                  </span>
                  <input
                    readOnly={isEdit}
                    type="Date"
                    value={values?.licensedate}
                    onChange={handleChange}
                    name="licensedate"
                    className={` ${!isEdit ? "form-control" : "hide-input"}`}
                    placeholder="-"
                  />
                </li>
              </ul>
            </div>
          </Form>
        )}
      </Formik>

      {isModelShow && (
        <CustomerActivationModale
          show={isModelShow}
          onHide={() => setModelShow(false)}
          title={" are you sure you want ot send active link"}
          id={id}
        />
      )}
    </div>
  );
};

export default DriverDetails;
