/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import uploadIcon from "../../../assets/icons/upload.svg";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";
import {
  addUserDocument,
  updateUserDocument,
  userDocumentDelete,
} from "../../../services/client/addclientApi";
import { fetchUserDocument } from "../../../Redux/clientsSlice";
import DeleteModal from "../../Common/DeleteModal";
import { useParams } from "react-router-dom";
import DoumentPdf from "../../Common/DocPdf/DoumentPdf";
import { formatDate } from "../../Common/FormatDate";
import CustomFileUploader from "../../Client/Components/ClientDocument/CustomFileUploader";
import { IoCloseOutline } from "react-icons/io5";
import DocumentConfirmModal from "../../Common/DocumentConfirmModal";
import { ImFolderDownload } from "react-icons/im";

const DriverDocuments = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isDocuments, setIsDocuments] = useState([]);
  const [isDocumentsId, setIsDocumentsId] = useState("");
  const [isActiveDocument, setActiveDocument] = useState({});
  const [modalShow, setModalShow] = useState("");
  const [isOpenAccordion, setOpenAccordion] = useState();
  const [isDocId, setIsDocsId] = useState("");
  const [isStatus, setIsStatus] = useState("");
  const [isDescription, setIsDescription] = useState("");
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [isUserId, setIsUserId] = useState(false);

  const { customerData, userDocumentList } = useSelector(
    (state) => state?.clientsSlice
  );
  const singleUser = customerData?.customer;

  // img upload
  const handleUploadImg = async (file, id) => {
    const form = new FormData();
    form.append("cust_id", id);
    form.append("firstname", "testing");
    form.append("lastname", "123");
    form.append("name", file?.name);
    form.append("document", file);
    const data = await addUserDocument(form);

    if (data?.status) {
      setActiveDocument(data?.documents?.[0]);
      if (isDocuments?.length > 0) {
        setIsDocuments((prev) => [...prev, { ...data?.documents?.[0] }]);
      } else {
        setIsDocuments([data?.documents?.[0]]);
      }
    }
  };

  const handleConfirmModal = (item, status) => {
    setIsUserId(id);
    setConfirmModalShow(true);
    setIsDocsId(item?._id);
    setIsStatus(status);
    if (status) {
      setIsDescription("Avez-vous confirmé le document ?");
    } else {
      setIsDescription("Êtes-vous rejeté le document ?");
    }
  };

  //  delete document
  const handleDelete = async () => {
    const body = { doc_id: isActiveDocument?._id };
    await userDocumentDelete(userDocumentList?._id, body);
    dispatch(fetchUserDocument(isOpenAccordion));
    setModalShow(false);
    setActiveDocument({});
  };

  // download Img
  const handleDownload = async () => {
    try {
      const downloadUrl = `${process.env.REACT_APP_IMG_URL}${
        isActiveDocument?.image || isActiveDocument?.url
      }`;
      const response = await fetch(downloadUrl);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      const link = document.createElement("a");
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.download = isActiveDocument?.name || "document";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("There was an error downloading the file:", error);
    }
  };

  // set documnet
  useEffect(() => {
    setIsDocuments(userDocumentList?.documents);
  }, [userDocumentList]);

  // initial open Accordion
  useEffect(() => {
    setOpenAccordion(singleUser?._id);
    setActiveDocument(userDocumentList?.documents?.[0]);
  }, [id, userDocumentList]);

  useEffect(() => {
    dispatch(fetchUserDocument(id));
  }, []);

  return (
    <div className="row documents">
      <div className="col-3">
        {isDocuments?.length > 0 &&
          isDocuments?.map((item) => (
            <Formik
              initialValues={{
                name: item?.name?.split(".")[0] || "",
                fileType: item?.name?.split(".")[1] || "",
              }}
              enableReinitialize={true}
              onSubmit={async (values) => {
                const form = new FormData();
                form.append("name", values?.name + "." + values?.fileType);
                form.append("doc_id", item?._id);
                await updateUserDocument(singleUser?._id, form);
                setIsDocumentsId(" ");
              }}
            >
              {({ values, setFieldValue, handleChange }) => (
                <Form onClick={() => setActiveDocument(item)}>
                  <div
                    style={{
                      "--border-colore":
                        item?.status === "pending"
                          ? "#FF9F43"
                          : item?.status === "accept"
                          ? "#28C76F"
                          : "red",
                    }}
                    className={`doc-status border-left-3 border-right-3 card cursor-pointer mb-2 ${
                      item?._id === isActiveDocument?._id && "doc-active"
                    }`}
                  >
                    <div className="row align-items-center px-3 py-2 ">
                      <div className="col-9">
                        {isDocumentsId === item?._id ? (
                          <div className="d-flex align-items-center">
                            <Field
                              readOnly={isDocumentsId !== item?._id}
                              name="name"
                              className={`cursor-pointer flex-grow-0 ${
                                isDocumentsId === item?._id
                                  ? "form-control mb-1 "
                                  : "hide-input"
                              }`}
                              placeholder=""
                            />
                            .<div>{values?.fileType}</div>
                          </div>
                        ) : (
                          <div>{values?.name + "." + values?.fileType}</div>
                        )}

                        <div className="d-flex fs-7 gap-2">
                          <span>
                            {item?.firstname} {item?.lastname}
                          </span>
                          <span>{formatDate(item?.uploadedAt)}</span>
                        </div>
                      </div>

                      <div className=" col-3">
                        {item?.status === "pending" ? (
                          <div className="d-flex flex-column gap-1  justify-content-center align-items-center">
                            <IoCloseOutline
                              className="fs-5"
                              onClick={() => {
                                handleConfirmModal(item, false);
                              }}
                            />
                            <span
                              className="tf-icons ti-xs ti ti-check d-block text-center"
                              onClick={() => {
                                handleConfirmModal(item, true);
                              }}
                            />
                          </div>
                        ) : (
                          <div className="d-flex gap-2 justify-content-end align-items-center">
                            <div>
                              {isDocumentsId === item?._id ? (
                                <button
                                  type="submit"
                                  className="btn btn-primary waves-effect waves-light px-2 py-1"
                                >
                                  Save
                                </button>
                              ) : (
                                <span
                                  onClick={() => setIsDocumentsId(item?._id)}
                                  className="tf-icons ti-xs ti ti-edit d-block mb-2"
                                ></span>
                              )}
                              <span
                                onClick={() => setModalShow(true)}
                                className="tf-icons ti-xs ti ti-trash d-block text-center"
                              ></span>
                            </div>
                            <div>
                              <input type="checkbox" />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          ))}

        <div className="position-relative">
          <CustomFileUploader
            label={"Ajouter un document"}
            handleChange={(file) => handleUploadImg(file, singleUser?._id)}
            name="file"
            types={["pdf", "JPG", "PNG", "GIF", "webp"]}
            customMessage={"Uploaded Successfully"}
          />
          <div className="p-2 rounded drag-upload-icon">
            <img src={uploadIcon} alt="upload-icons" />
          </div>
        </div>
      </div>

      <div className="col-9">
        <div className="card">
          <div className="card-body position-relative">
            {isActiveDocument?.url && (
              <ImFolderDownload
                onClick={handleDownload}
                className="fs-4 text-black position-absolute cursor-pointer"
                style={{
                  zIndex: 999,
                  inset: "20px",
                }}
              />
            )}
            {isActiveDocument?.url?.includes("pdf") ? (
              <DoumentPdf
                isActiveDocument={isActiveDocument}
                setActiveDocument={setActiveDocument}
                isOpenAccordion={isOpenAccordion}
              />
            ) : isActiveDocument?.url ? (
              <img
                src={`${process.env.REACT_APP_IMG_URL}${isActiveDocument?.url}`}
                alt=""
                className="img-fluid"
              />
            ) : isActiveDocument?.image ? (
              <img src={isActiveDocument?.image} alt="" className="img-fluid" />
            ) : (
              <div className="text-center">No Data Found</div>
            )}
          </div>
        </div>
      </div>

      <DeleteModal
        title={"Supprimer ce document"}
        description={"Êtes-vous sûr de supprimer le document ?"}
        show={modalShow}
        deleteData={() => handleDelete(false)}
        onHide={() => setModalShow(false)}
      />

      <DocumentConfirmModal
        isUserId={isUserId}
        isDocId={isDocId}
        isStatus={isStatus}
        description={isDescription}
        show={confirmModalShow}
        onHide={() => setConfirmModalShow(false)}
      />
    </div>
  );
};

export default DriverDocuments;
