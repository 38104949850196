import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { SmallLoader } from "../../Common/Loader";
import { addDriverApi } from "../../../services/client/addclientApi";

const LinkedDriverModal = (props) => {
  const customer_id = localStorage.getItem("customer_id");
  const [isLoding, setLoding] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const onSaveChanges = async () => {
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }
    setLoding(true);
    const body = {
      customer_id: customer_id,
      receiver_email: email,
      roles: "AUX",
      main_customer_id: customer_id,
    };
    const data = await addDriverApi(body);

    if (data?.response?.data?.error) {
      setLoding(false);
    }
    if (data?.status) {
      props.onHide();
      setLoding(false);
    }
  };

  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className=" w-100">
          Add Driver
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="col profilemodel">
          <label className="form-label mb-1">Email</label>
          <input
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError("");
            }}
            type="email"
            className="form-control"
            placeholder="Email"
            aria-controls="DataTables_Table_0"
          />
          {emailError && <div className="text-danger">{emailError}</div>}
        </div>
      </Modal.Body>

      <Modal.Footer className="align-self-end">
        <button
          className="btn btn-label-danger waves-effect"
          onClick={props.onHide}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary waves-effect waves-light"
          onClick={onSaveChanges}
        >
          Submit
          {isLoding && <SmallLoader />}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default LinkedDriverModal;
