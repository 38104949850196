import React from "react";
import Modal from "react-bootstrap/Modal";
import { updateUserDocument } from "../../services/client/addclientApi";
import { useDispatch } from "react-redux";
import { fetchUserDocument } from "../../Redux/clientsSlice";

function DocumentConfirmModal(props) {
  const { onHide, isDocId, description, isStatus,isUserId } = props;
  // const { id } = useParams();
  const dispatch = useDispatch();

  const handleConfirmation = async () => {
    if (isStatus) {
      const form = new FormData();
      form.append("status", "accept");
      form.append("doc_id", isDocId);
      await updateUserDocument(isUserId, form);
      dispatch(fetchUserDocument(isUserId));
    } else {
      const form = new FormData();
      form.append("status", "reject");
      form.append("doc_id", isDocId);
      await updateUserDocument(isUserId, form);
      dispatch(fetchUserDocument(isUserId));
    }
    onHide();
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-0">{description}</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={props.onHide}>
          Annuler
        </button>
        <button
          className={`btn ${isStatus ? "btn-success" : "btn-danger"}`}
          onClick={handleConfirmation}
        >
          Oui
        </button>
      </Modal.Footer>
    </Modal>
  );
}
export default DocumentConfirmModal;
