import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { addVisibilite } from "../../../../../services/AddVehicle";
import { useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { SmallLoader } from "../../../../Common/Loader";
import { useParams } from "react-router-dom";

const VisibiliteInformation = () => {
  const { id } = useParams();
  const [editVisibilite, setVisibilite] = useState(true);
  const [isLoding, setLoding] = useState(false);
  const { singleVisibiliteData } = useSelector((state) => state.vehicleSlice);
  const visibiliteData = singleVisibiliteData?.visibiliteData || {
    One_Of_One: "",
    Electrique: "",
    Engine: "",
    Consumption: "",
    V_Max: "",
    RangeData: "",
  };
  return (
    <Formik
      initialValues={visibiliteData}
      enableReinitialize={true}
      onSubmit={async (values) => {
        setLoding(true);
        const tempData = {
          visibiliteData: {
            general_info: id,
            ...values,
          },
        };
        const data = await addVisibilite(tempData);
        if (data) {
          setLoding(false);
          setVisibilite(true);
        }
      }}
    >
      {({ values, handleChange }) => (
        <Form className="card info mb-4">
          <div className="card-header d-flex justify-content-between">
            <h5 className="card-tile mb-0">Informations visibles</h5>
            {editVisibilite ? (
              <div
                onClick={() => setVisibilite(false)}
                className="btn btn-primary waves-effect waves-light"
              >
                Modifier
              </div>
            ) : (
              <button
                type="submit"
                className="btn btn-primary waves-effect waves-light"
              >
                Sauvegarder {isLoding && <SmallLoader />}
              </button>
            )}
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-4">
                  <h6>One Of One</h6>
                  <Dropdown
                    disabled={editVisibilite}
                    value={values?.One_Of_One}
                    onChange={handleChange}
                    options={["Yes", "No"]}
                    name="One_Of_One"
                    placeholder="Yes / No"
                    className={`w-full ${
                      editVisibilite ? "p-dropdown-hide" : ".p-dropdown"
                    }`}
                  />
                </div>
                <div className="row">
                  <div className="col">
                    <h6>Moteur</h6>
                    <Field
                      readOnly={editVisibilite}
                      name="Engine"
                      value={values.Engine}
                      placeholder="Engine"
                      className={`${
                        editVisibilite ? "hide-input" : "form-control"
                      }`}
                    />
                  </div>
                  <div className="col">
                    <h6>Consommation</h6>
                    <Field
                      readOnly={editVisibilite}
                      name="Consumption"
                      value={values.Consumption}
                      placeholder="Consumption"
                      className={`${
                        editVisibilite ? "hide-input" : "form-control"
                      }`}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row mb-4">
                  <div className="mb-4">
                    <h6>Electrique</h6>
                    <Dropdown
                      disabled={editVisibilite}
                      value={values?.Electrique}
                      onChange={handleChange}
                      options={["Yes", "No"]}
                      name="Electrique"
                      placeholder="Yes / No"
                      className={`w-full ${
                        editVisibilite ? "p-dropdown-hide" : ".p-dropdown"
                      }`}
                    />
                  </div>
                  <div className="col">
                    <h6>V Max</h6>
                    <Field
                      readOnly={editVisibilite}
                      name="V_Max"
                      value={values.V_Max}
                      placeholder="V-Max"
                      className={`${
                        editVisibilite ? "hide-input" : "form-control"
                      }`}
                    />
                  </div>
                  <div className="col">
                    <h6>0-100</h6>
                    <Field
                      readOnly={editVisibilite}
                      name="RangeData"
                      value={values.RangeData}
                      placeholder="0-100"
                      className={`${
                        editVisibilite ? "hide-input" : "form-control"
                      }`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default VisibiliteInformation;
