import { toast } from "react-toastify";
import axiosInstanceAuth from "../../apiInstances/axiosInstanceAuth";

export const companyDropdownList = async () => {
  try {
    const response = await axiosInstanceAuth.get(`/company`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const getSubscription = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(`subscription/${id}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
export const addSubscription = async (body) => {
  try {
    const response = await axiosInstanceAuth.post(`subscription`, body);
    toast.success(response?.data?.msg, { position: toast.POSITION.TOP_RIGHT });
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const updataSubscriptionValue = async (id, body) => {
  try {
    const response = await axiosInstanceAuth.patch(`subscription/${id}`, body);
    toast.success(response?.data?.msg, { position: toast.POSITION.TOP_RIGHT });
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const deleteSubscription = async (id) => {
  try {
    const response = await axiosInstanceAuth.delete(`subscription/${id}`);
    toast.success(response?.data?.msg, { position: toast.POSITION.TOP_RIGHT });
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
