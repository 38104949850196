import { toast } from "react-toastify";
import axiosInstanceAuth from "../../apiInstances/axiosInstanceAuth";

export const userList = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(`/user/${id}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const userPasswordUpdate = async (params) => {
  try {
    const response = await axiosInstanceAuth.post(
      `/user/changepassword`,
      params
    );
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    throw error;
  }
};

export const userInfoUpdate = async (id, params) => {
  try {
    const response = await axiosInstanceAuth.put(`/user/${id}`, params);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    throw error;
  }
};
