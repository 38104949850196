import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { updataClientApi } from "../../../services/client/addclientApi";
import { fetchCustomerData } from "../../../Redux/clientsSlice";
import { useDispatch } from "react-redux";
import { Accordion } from "react-bootstrap";
import { SmallLoader } from "../../Common/Loader";
import { Dropdown } from "primereact/dropdown";
import PhoneInput from "react-phone-input-2";
import { Autocomplete } from "@react-google-maps/api";
import AddressAutocompleteInput from "../../Common/AddressAutocompleteInput";

const Auxdata = ({
  id,
  aux,
  setLoding,
  setEdit,
  openMain,
  toggleAccordion,
  toggleAccordionMain,
  openAuxIndex,
  setOpenAuxIndex,
  isLoding,
  handleNavigate,
  setUserId,
  toggleAuxAccordion,
  setModelShow,
  main,
  pencilIcon,
}) => {
  const dispatch = useDispatch();

  return (
    <>
      {aux !== null && (
        <div className="card mb-2 ms-4">
          <div className="card-boday">
            <Formik
              initialValues={{
                firstname: aux?.firstname || "",
                lastname: aux?.lastname || "",
                email: aux?.email || "",
                contact: aux?.contact || "",
                // ${aux?.houseno ? aux?.houseno + ", " : ""}
                address: `${aux?.address || ""}`,
                buildingNumber: aux?.houseno || "",
                DOB: aux?.DOB
                  ? new Date(aux?.DOB).toISOString().split("T")[0]
                  : "",
                language: aux?.language,
                gender: aux?.gender,
                licensedate: aux?.licensedate
                  ? new Date(aux?.licensedate).toISOString().split("T")[0]
                  : "",
              }}
              enableReinitialize={true}
              onSubmit={async (values) => {
                setLoding(true);
                const formData = new FormData();
                formData.append("firstname", values.firstname);
                formData.append("lastname", values.lastname);
                formData.append("contact", values.contact);
                formData.append("houseno", values.buildingNumber);
                formData.append("address", values.address);
                formData.append("DOB", values.DOB);
                formData.append("language", values.language);
                formData.append("gender", values.gender);
                formData.append("licensedate", values.licensedate);
                const data = await updataClientApi(aux?._id, formData);

                if (data) {
                  setLoding(false);
                  setEdit(false);
                  toggleAccordion(null);
                  setOpenAuxIndex("");
                  dispatch(fetchCustomerData(id));
                } else {
                  toggleAccordion(null);
                  setEdit(false);
                  setLoding(false);
                }
              }}
            >
              {({ values, handleChange, setFieldValue, handleSubmit }) => {
                return (
                  <Form>
                    <Accordion activeKey={openMain}>
                      <Accordion.Item eventKey={aux?._id}>
                        <Accordion.Header
                          onClick={() => toggleAccordionMain(aux?._id, values)}
                        >
                          <div className="w-100">
                            <div className="d-flex align-items-center ">
                              <div className="text-center d-flex justify-content-center gap-2 align-content-center w-100">
                                {openAuxIndex === aux?._id ? (
                                  <div className="d-flex gap-2">
                                    <div>
                                      <button
                                        onClick={handleSubmit}
                                        type="button"
                                        className="btn btn-primary waves-effect waves-light"
                                      >
                                        Save
                                        {isLoding && <SmallLoader />}
                                      </button>
                                    </div>
                                    <Field
                                      name="firstname"
                                      placeholder="-"
                                      className={` ${
                                        openAuxIndex === aux?._id
                                          ? "form-control"
                                          : "hide-input"
                                      }`}
                                      onClick={(e) => e.stopPropagation()}
                                    />
                                    <Field
                                      name="lastname"
                                      placeholder="-"
                                      className={` ${
                                        openAuxIndex === aux?._id
                                          ? "form-control"
                                          : "hide-input"
                                      }`}
                                      onClick={(e) => e.stopPropagation()}
                                    />
                                  </div>
                                ) : (
                                  <>
                                    <div>
                                      <button
                                        className="btn waves-effect waves-light py-0 ps-0"
                                        onClick={() => {
                                          toggleAuxAccordion(aux?._id);
                                        }}
                                      >
                                        <img
                                          src={pencilIcon}
                                          alt="pencil-icon"
                                        />
                                      </button>
                                    </div>
                                    <h6
                                      className="my-auto text-nowrap"
                                      onClick={(e) =>
                                        handleNavigate(e, aux?._id)
                                      }
                                    >
                                      {aux?.firstname} {aux?.lastname}
                                    </h6>
                                    <div className=" w-100">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div className="bg-info rounded badge">
                                          {aux?.role?.[0]}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </Accordion.Header>

                        <Accordion.Body>
                          <ul className="list-unstyled mb-0 mt-2">
                            <li className="d-flex align-items-center mb-3">
                              <span className="fw-medium mx-2 text-heading">
                                Email:
                              </span>
                              <Field
                                readOnly
                                type="email"
                                name="email"
                                className={` ${
                                  openAuxIndex === aux?._id
                                    ? "form-control"
                                    : "hide-input"
                                }`}
                                placeholder="-"
                              />
                              {aux?.active === false && (
                                <btn
                                  className="text-primary text-decoration-underline cursor-pointer ms-2"
                                  onClick={(e) => {
                                    setUserId(main?._id);
                                    setModelShow(true);
                                  }}
                                >
                                  Activate
                                </btn>
                              )}
                            </li>
                            <li className="d-flex align-items-center mb-3">
                              <span className="fw-medium mx-2 text-heading">
                                Contact:
                              </span>
                              <PhoneInput
                                disabled={openAuxIndex !== aux?._id}
                                country={"Luxembourg"}
                                enableSearch={true}
                                className={` ${
                                  openAuxIndex !== aux?._id
                                    ? "hide-input"
                                    : "form-control flex-grow-1"
                                }`}
                                placeholder="+352 369 852 472"
                                value={values?.contact?.toString() || "+352"}
                                onChange={(value) =>
                                  setFieldValue("contact", value)
                                }
                              />
                            </li>

                            {openAuxIndex !== aux?._id ? (
                              <li className="d-flex align-items-start mb-3">
                                <span className="fw-medium mx-2 text-heading text-nowrap">
                                  Address:
                                </span>

                                <textarea
                                  value={values.address}
                                  name="address"
                                  id="address"
                                  className="w-100 hide-input resize-none"
                                />
                              </li>
                            ) : (
                              <li className="d-flex align-items-center mb-3">
                                <span className="fw-medium mx-2 text-heading text-nowrap">
                                  Address:
                                </span>
                                <AddressAutocompleteInput
                                  name="address"
                                  values={values}
                                  setFieldValue={setFieldValue}
                                />
                              </li>
                            )}
                            <li className="d-flex align-items-center mb-3">
                              <span className="fw-medium mx-2 text-heading text-nowrap">
                                Building Number:
                              </span>

                              <Field
                                readOnly={openAuxIndex !== aux?._id}
                                name="buildingNumber"
                                placeholder=" "
                                className={`${
                                  openAuxIndex !== aux?._id
                                    ? "hide-input"
                                    : "form-control"
                                }`}
                              />
                            </li>
                            <li className="d-flex align-items-center mb-3">
                              <span className="fw-medium mx-2 text-heading">
                                DOB:
                              </span>
                              <input
                                readOnly={openAuxIndex !== aux?._id}
                                type="Date"
                                value={values?.DOB}
                                onChange={handleChange}
                                name="DOB"
                                className={` ${
                                  openAuxIndex === aux?._id
                                    ? "form-control"
                                    : "hide-input"
                                }`}
                                placeholder="-"
                              />
                            </li>
                            <hr />

                            <small className="card-text text-uppercase text-muted ">
                              CONTACTS
                            </small>
                            <li className="d-flex align-items-center  mt-2 mb-3 ">
                              <span className="fw-medium mx-2 text-heading">
                                Languages:
                              </span>
                              <Dropdown
                                disabled={openAuxIndex !== aux?._id}
                                options={["FR", "EN"]}
                                placeholder="Language"
                                className={`w-full ${
                                  openAuxIndex !== aux?._id
                                    ? "p-dropdown-hide"
                                    : "p-dropdown"
                                }`}
                                name="language"
                                value={values.language}
                                onChange={handleChange}
                              />
                            </li>
                            <li className="d-flex align-items-center  mb-3">
                              <span className="fw-medium mx-2 text-heading">
                                Gender:
                              </span>
                              <Dropdown
                                disabled={openAuxIndex !== aux?._id}
                                options={["Male", "Female", "Other"]}
                                placeholder="Gender"
                                name="gender"
                                value={values.gender}
                                className={`w-full ${
                                  openAuxIndex !== aux?._id
                                    ? "p-dropdown-hide"
                                    : "p-dropdown"
                                }`}
                                onChange={handleChange}
                              />
                            </li>
                            <li className="d-flex align-items-center mb-3">
                              {/* <i className="ti ti-credit-card text-heading" /> */}
                              <span className="fw-medium mx-2 text-heading text-nowrap">
                                Permis B :
                              </span>
                              <input
                                readOnly={openAuxIndex !== aux?._id}
                                type="Date"
                                value={values?.licensedate}
                                onChange={handleChange}
                                name="licensedate"
                                className={` ${
                                  openAuxIndex !== aux?._id
                                    ? "hide-input"
                                    : "form-control"
                                }`}
                                placeholder=" "
                              />
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};

export default Auxdata;
