/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { invoiceById } from "../../../services/Invoice";
import EditeBill from "./EditeBill";
import EditeInvoiceActions from "./EditeInvoiceActions";
import { CenterLoader } from "../../Common/Loader";

const EditeInvoice = () => {
  const { id } = useParams();
  const [isSingleInvoice, setSingleInvoice] = useState();

  const singelInvoice = async () => {
    const data = await invoiceById(id);
    setSingleInvoice(data?.data);
  };

  useEffect(() => {
    singelInvoice();
  }, []);

  return (
    <div className="content-wrapper">
      <div className="container-fluid flex-grow-1 container-p-y">
        <Formik enableReinitialize={true}>
          {!isSingleInvoice ? (
            <CenterLoader />
          ) : (
            (props) => {
              const { handleChange, values } = props;
              return (
                <>
                  <div className="row invoice-edit">
                    <EditeBill
                      isSingleInvoice={isSingleInvoice}
                      values={values}
                      handleChange={handleChange}
                    />
                    <EditeInvoiceActions
                    singelInvoice={singelInvoice}
                      id={id}
                      isSingleInvoice={isSingleInvoice}
                    />
                    
                  </div>
                </>
              );
            }
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditeInvoice;
