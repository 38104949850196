import React from "react";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

const FleetNavbar = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();
  const tabNavigate = (item) => {
    navigate(item);
  };
  return (
    <ul className="nav nav-pills mb-3 nav-fill" role="tablist">
      <li classname="pe-3">
        <NavLink
          to={`general/${id}`}
          className={`nav-link ${pathname.includes("general") && "active"}`}
        >
          <i className="tf-icons ti ti-home ti-xs me-1" /> General
        </NavLink>
      </li>
      <li classname="pe-3">
        <NavLink
          to={`info/${id}`}
          className={`nav-link ${pathname.includes("info") && "active"}`}
        >
          <i className="tf-icons ti ti-info-circle ti-xs me-1" /> Centre D’info
        </NavLink>
      </li>
      <li classname="pe-3">
        <NavLink
          to={`visibilite/${id}`}
          className={`nav-link ${pathname.includes("visibilite") && "active"}`}
        >
          <i className="tf-icons ti ti-layout-navbar ti-xs me-1" />
          Visibilité
        </NavLink>
      </li>
      <li classname="pe-3">
        <Link className="nav-link">
          <i className="tf-icons ti ti-currency-euro ti-xs me-1" /> TCO
        </Link>
      </li>
      <li classname="pe-3">
        <NavLink
          to={`document/${id}`}
          type="button"
          className={`nav-link ${pathname.includes("document") && "active"}`}
        >
          <i className="tf-icons ti ti-books ti-xs me-1" /> Documents
        </NavLink>
      </li>
      <li classname="pe-3">
        <button type="button" className="nav-link">
          <i className="tf-icons ti ti-clipboard-check ti-xs me-1" />
          Dommages
        </button>
      </li>
    </ul>
  );
};

export default FleetNavbar;
