import { ErrorMessage, Field } from "formik";
import React, { useEffect, useState } from "react";
import { variables } from "../../../Common/DropdownValue";

const InsuranceInformation = ({
  values,
  handleChange,
  isinitialValues,
  errors,
}) => {
  const [isDate, setDate] = useState();
  console.log(
    "🚀 ~ file: InsuranceInformation.js:7 ~ InsuranceInformation ~ isDate:",
    isDate
  );
  useEffect(() => {
    if (isinitialValues?.InsuranceInfo) {
      const newDate = new Date(isinitialValues?.InsuranceInfo?.date_issue)
        .toISOString()
        .split("T")[0];
      setDate(newDate);
    }
  }, [isinitialValues]);
  return (
    <div className="card mb-4">
      <div className="card-header">
        <h5 className="card-tile mb-0">Informations d'Assurances</h5>
      </div>
      <div className="card-body">
        <div className="row mb-3">
          <div className="col">
            <label className="form-label mb-1">Courtier d'Assurances</label>
            <input
              value={values.insuranceInfo.issuer}
              name="insuranceInfo.issuer"
              onChange={handleChange}
              options={variables}
              placeholder="Nom"
              className={`form-control ${
                errors?.insuranceInfo?.issuer && "is-invalid"
              }`}
              // className="form-control"
            />
            <ErrorMessage
              name="insuranceInfo.issuer"
              component="div"
              className="text-danger ps-2"
            />
          </div>
          <div className="col">
            <label className="form-label mb-1">Date de début</label>
            <input
              defaultValue={isDate}
              onChange={handleChange}
              name="insuranceInfo.date_issue"
              type="date"
              className={`form-control ${
                errors?.insuranceInfo?.date_issue && "date-invalid"
              }`}
              placeholder="Memo"
              aria-controls="DataTables_Table_0"
            />
            <ErrorMessage
              name="insuranceInfo.date_issue"
              component="div"
              className="text-danger ps-2"
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <label className="form-label mb-1">Contact Assistance</label>
            <Field
              name="insuranceInfo.support_contact"
              type="text"
              className="form-control"
              placeholder="Contact"
              aria-controls="DataTables_Table_0"
            />
          </div>
          <div className="col">
            <label className="form-label mb-1">Mémo Pour le Client</label>
            <Field
              type="text"
              name="insuranceInfo.info"
              className="form-control"
              placeholder="Mémo"
              aria-controls="DataTables_Table_0"
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <label className="form-label mb-1">Franchise Vol</label>
            <Field
              name="insuranceInfo.flight_franchise"
              type="number"
              className="form-control"
              placeholder="0€"
              aria-controls="DataTables_Table_0"
            />
          </div>
          <div className="col">
            <label className="form-label mb-1">Franchise Crash</label>
            <Field
              name="insuranceInfo.franchise_crash"
              type="text"
              className="form-control"
              placeholder="0€"
              aria-controls="DataTables_Table_0"
            />
          </div>
          <div className="col">
            <label className="form-label mb-1">Franchise Perte Totale</label>
            <Field
              name="insuranceInfo.total_claims"
              type="number"
              className="form-control"
              placeholder="0€"
              aria-controls="DataTables_Table_0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceInformation;
