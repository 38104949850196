import { Dropdown } from "primereact/dropdown";
import React from "react";
import { options } from "./DropdownValue";
import ReactPaginate from "react-paginate";

const TableFooter = ({
  itemsPerPage,
  handleSelectChange,
  listData,
  handlePageChange,
  handleActive,
  currentPage,
}) => {
  return (
    <div className="d-flex justify-content-between">
      <div className="my-auto mx-4">
        <Dropdown
          value={itemsPerPage}
          onChange={handleSelectChange}
          options={options}
          placeholder="Select an option"
          className=""
        />
      </div>

      <ReactPaginate
        pageCount={Math.ceil(listData?.length / itemsPerPage)}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        onPageChange={handlePageChange}
        onPageActive={handleActive}
        containerClassName={"pagination"}
        activeClassName={"active"}
        forcePage={currentPage} // Force active page
      />
    </div>
  );
};

export default TableFooter;
