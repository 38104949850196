import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { customerActivationApi } from "../../services/client/addclientApi";
import { SmallLoader } from "./Loader";

const CustomerActivationModale = (props) => {
  const [isLoding, setLoding] = useState(false);
  // const { id } = useParams();
  const onSaveChanges = async () => {
    setLoding(true);
    const data = await customerActivationApi(props?.id);

    if (data?.status) {
      setLoding(false);
      props.onHide();
    }
  };

  return (
    <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col profilemodel">
          {" "}
          Are you sure you want to send active link
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-label-danger waves-effect"
          onClick={props.onHide}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary waves-effect waves-light"
          onClick={onSaveChanges}
        >
          Send
          {isLoding && <SmallLoader />}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomerActivationModale;
