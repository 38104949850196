import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import AutoResizingTextArea from "../../Common/AutoResizingTextArea";

const ProspectCompanyDetails = () => {
  const { customerData } = useSelector((state) => state?.clientsSlice);
  const [isEdit, setEdit] = useState(true);

  return (
    <div className="card mb-4">
      <div className="card-body">
        <Formik
          initialValues={{
            business: customerData?.customer?.business || "",
            vat_number: customerData?.customer?.vat_number || "",
            invoice_email: customerData?.customer?.invoice_email || "",
            address: customerData?.customer?.company?.company_address || "",
            buildingNumber: customerData?.customer?.company?.houseno || "",
          }}
          enableReinitialize={true}
          onSubmit={() => {}}
        >
          {({ values, handleChange }) => (
            <Form>
              <div className="d-flex justify-content-between position-relative">
                <div className="mx-auto ">
                  <div className="text-center d-flex flex-column justify-content-center gap-2">
                    <div className="d-flex gap-2">
                      <h4 className="mb-0">
                        {customerData?.customer?.business}
                      </h4>
                      {customerData?.customer?.role?.length === 1 && (
                        <div className="bg-warning rounded badge align-self-center py-2">
                          Validate
                        </div>
                      )}
                    </div>
                    <div>
                      <span className="bg-info rounded badge py-2">
                        {customerData?.customer?.comp_id?.name}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <small className="card-text text-uppercase text-muted">
                CLIENT DETAIL
              </small>

              <ul className="list-unstyled mb-0 mt-2">
                <li className="d-flex align-items-center mb-3">
                  <span className="fw-medium mx-2 text-nowrap">
                    VAT Number:
                  </span>
                  <Field
                    readOnly={isEdit}
                    type="text"
                    name="vat_number"
                    className={`${isEdit ? "hide-input" : "form-control"}`}
                    placeholder="-"
                  />
                </li>

                <li className="d-flex align-items-start mb-3">
                  <span className="fw-medium mx-2 text-heading text-nowrap">
                    Address:
                  </span>

                  <AutoResizingTextArea
                    value={values.address}
                    name="address"
                    id="address"
                    className="w-100 hide-input resize-none"
                  />
                </li>
                <li className="d-flex align-items-center mb-3">
                  <span className="fw-medium mx-2 text-heading text-nowrap">
                    Street/Building No:
                  </span>

                  <Field
                    readOnly={isEdit}
                    name="buildingNumber"
                    placeholder=" Street/Building No"
                    className={`${isEdit ? "hide-input" : "form-control"}`}
                  />
                </li>

                <small className="card-text text-uppercase text-muted">
                  CONTACTS
                </small>

                <li className="d-flex  align-items-center mb-3 mt-2">
                  <span className="fw-medium mx-2 text-nowrap">
                    Invoice Email:
                  </span>
                  <Field
                    readOnly={isEdit}
                    type="text"
                    name="invoice_email"
                    className={` ${isEdit ? "hide-input" : "form-control"}`}
                    placeholder="-"
                  />
                </li>
              </ul>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ProspectCompanyDetails;
