/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { roleClassesSecound } from "../../Common/roles";
import { Field, Form, Formik } from "formik";
import PhoneInput from "react-phone-input-2";
import * as Yup from 'yup';
import { ErrorMessage } from 'formik';
import { updateUserDetails } from "../../../Redux/userSlice";
import { Dropdown } from "primereact/dropdown";

const ProfileEdit = () => {
    const { userData } = useSelector((state) => state?.userSlice);
    const [isEdit, setEdit] = useState(true);
    const dispatch = useDispatch();
    const userInfo = JSON.parse(localStorage.getItem("userinfo"));
    const { countriesList } = useSelector((state) => state?.commonSlice);

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
        fname: Yup.string().required('First name is required'),
        lname: Yup.string().required('Last name is required'),
        company: Yup.string().required('Company name is required'),
        phone_no: Yup.string().required('Phone number is required'),
        language: Yup.string().required('Language is required'),
        country: Yup.string().required('Country is required'),
    });

    const handleSupprimer = (resetForm) => {
        resetForm(userData); // Reset the form to initial values
        setEdit(true); // Set edit mode to true to hide error messages and show previous values
    };

    return (

        <div className="card ">
            <div className="card-body">
                <Formik
                    initialValues={userData}
                    enableReinitialize={true}
                    validationSchema={validationSchema} // Add this line
                    onSubmit={async (values) => {
                        dispatch(
                            updateUserDetails(userInfo?._id, values)
                        );
                    }}
                >
                    {({ values, setFieldValue, handleChange, handleSubmit, resetForm }) => (
                        <Form onSubmit={handleSubmit}>
                            <div className="mx-auto">
                                <div className="text-center d-flex justify-content-center gap-2 flex-column">


                                    {(

                                        <>
                                            <div className={`d-flex gap-2 ${isEdit ? "d-none" : ""}`}>
                                                <Field
                                                    readOnly={isEdit}
                                                    name="fname"
                                                    value={values?.fname}
                                                    placeholder="firstname"
                                                    className={`${isEdit ? "hide-input" : "form-control"
                                                        }`}
                                                />
                                                <Field
                                                    readOnly={isEdit}
                                                    name="lname"
                                                    value={values?.lname}
                                                    placeholder="lname"
                                                    className={`${isEdit ? "hide-input" : "form-control"}`}
                                                />
                                            </div>
                                            <div className={`d-flex gap-2 ${isEdit ? "d-none" : ""}`}>
                                                <div className="col-6 text-center"> {/* 50% width */}
                                                    <ErrorMessage name="fname" component="div" className="text-danger" />
                                                </div>
                                                <div className="col-6 text-center"> {/* 50% width */}
                                                    <ErrorMessage name="lname" component="div" className="text-danger" />
                                                </div>
                                            </div>
                                        </>

                                    )
                                    }

                                    <h4 className="mb-0">

                                        {isEdit ? <div>
                                            {userData?.fname}{" "}
                                            {userData?.lname}
                                        </div> : ""}
                                    </h4>

                                    <div className="d-flex justify-content-center gap-2">
                                        {['Administrateur'].map((item) => (
                                            <span className={`mx-1 ${roleClassesSecound["CLIENT"]}`}>
                                                {item}
                                            </span>
                                        ))}
                                    </div>
                                </div>


                            </div>
                            <hr />

                            <small className="card-text text-uppercase text-muted">
                                DRIVER DETAILS
                            </small>

                            <ul className="list-unstyled  mt-3">
                                <li className="d-flex align-items-center ">
                                    <span className="fw-medium mx-2 text-heading">Email:</span>
                                    <Field
                                        readOnly={isEdit}
                                        name="email"
                                        value={values?.email}
                                        placeholder="email"
                                        className={`${isEdit ? "hide-input" : "form-control"}`}
                                    />
                                </li>
                                <div className="d-flex justify-content-center align-items-center">
                                    <ErrorMessage name="email" component="div" className="text-danger" />
                                </div>
                            </ul>


                            <ul className="list-unstyled mb-4 mt-3">
                                <li className="d-flex align-items-center ">

                                    <span className="fw-medium mx-2 text-heading">Company:</span>
                                    <Field
                                        readOnly={isEdit}
                                        name="company"
                                        value={values?.company}
                                        placeholder="company"
                                        className={`${isEdit ? "hide-input" : "form-control"}`}
                                    />

                                </li>
                                <div className="d-flex justify-content-center align-items-center">
                                    <ErrorMessage name="company" component="div" className="text-danger" />
                                </div>
                            </ul>

                            <ul className="list-unstyled mb-4 mt-3">
                                <li className="d-flex align-items-center ">

                                    <span className="fw-medium mx-2 text-heading">Contact:</span>

                                    <PhoneInput
                                        disabled={isEdit}
                                        country={"Luxembourg"}
                                        enableSearch={true}
                                        className={` ${!isEdit ? "form-control flex-grow-1 " : "hide-input"
                                            }`}
                                        placeholder="+352 369 852 472"
                                        value={values?.phone_no?.toString() || "+352"}
                                        onChange={(value) => setFieldValue("phone_no", value)}
                                    />
                                </li>
                                <div className="d-flex justify-content-center align-items-center">
                                    <ErrorMessage name="phone_no" component="div" className="text-danger" />
                                </div>
                            </ul>

                            <ul className="list-unstyled mb-4 mt-3">
                                <li className="d-flex align-items-center ">
                                    {/* <i className="ti ti-mail" /> */}
                                    <span className="fw-medium mx-2 text-heading">Languages:</span>
                                    <Dropdown
                                        disabled={isEdit}
                                        options={["FR", "EN"]}
                                        className={`w-full ${isEdit ? "p-dropdown-hide" : "p-dropdown"
                                            }`}
                                        name="language"
                                        value={values.language}
                                        onChange={handleChange}
                                    />

                                </li>
                                <div className="d-flex justify-content-center align-items-center">
                                    <ErrorMessage name="language" component="div" className="text-danger" />
                                </div>
                            </ul>

                            <ul className="list-unstyled mb-4 mt-3">
                                <li className="d-flex align-items-center ">
                                    {/* <i className="ti ti-mail" /> */}
                                    <span className="fw-medium mx-2 text-heading">Country:</span>

                                    <Dropdown
                                        disabled={isEdit}
                                        options={countriesList}
                                        filter
                                        className={`w-full ${isEdit ? "p-dropdown-hide" : "p-dropdown"
                                            }`}
                                        name="country"
                                        filterInputAutoFocus
                                        value={values.country}
                                        onChange={handleChange}
                                    />

                                </li>
                                <div className="d-flex justify-content-center align-items-center">
                                    <ErrorMessage name="country" component="div" className="text-danger" />
                                </div>
                            </ul>

                            <div class="row justify-content-center">
                                <div class="col-auto d-flex gap-2">
                                    {isEdit ?
                                        <div className="btn btn-primary" type="button"
                                            onClick={() => setEdit(false)}>Modifier</div>
                                        :
                                        <>
                                            <button className="btn btn-primary" type="submit">Save</button>
                                            <div className="btn btn-danger ml-2" type="button"
                                                onClick={() => handleSupprimer(resetForm)}>Supprimer</div>
                                        </>
                                    }
                                </div>
                            </div>
                        </Form>
                    )}

                </Formik>
            </div>

        </div>
    );
};

export default ProfileEdit;
