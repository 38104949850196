import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { SmallLoader } from "../../Common/Loader";
import { updataClientApi } from "../../../services/client/addclientApi";
import { fetchCustomerData } from "../../../Redux/clientsSlice";
import CustomerActivationModale from "../../Common/CustomertActivationModale";
import pencilIcon from "../../../assets/icons/pencil.png";
import { useParams } from "react-router-dom";
import { roleClasses, subscriptionStatusClass } from "../../Common/roles";
import PhoneInput from "react-phone-input-2";
import moment from "moment";

const ProspectDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isEdit, setEdit] = useState(true);
  const [isLoding, setLoding] = useState(false);
  const [isModelShow, setModelShow] = useState(false);
  const { customerData } = useSelector((state) => state?.clientsSlice);

  const initialValues = {
    firstname: customerData?.customer?.firstname || "",
    lastname: customerData?.customer?.lastname || "",
    email: customerData?.customer?.email || "",
    contact: `${customerData?.customer?.contact}` || "",
    DOB: `${customerData?.customer?.DOB}` || "",
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={async (values) => {
          setLoding(true);
          const formData = new FormData();
          formData.append("firstname", values.firstname);
          formData.append("lastname", values.lastname);
          if (values.email !== customerData?.customer?.email) {
            formData.append("email", values.email);
            formData.append("active", false);
          }
          formData.append("contact", values.contact);
          formData.append("DOB", values.DOB);
          const data = await updataClientApi(id, formData);

          if (data) {
            dispatch(fetchCustomerData(id));
            setEdit(true);
            setLoding(false);
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className="card mb-4">
            <div className="card-body">
              <div className="d-flex justify-content-between position-relative">
                <div className="position-absolute left-0 top-0">
                  {isEdit && (
                    <button
                      className="btn waves-effect waves-light p-0 align-items-start"
                      onClick={() => {
                        setEdit(false);
                      }}
                    >
                      <img src={pencilIcon} alt="pencil-icon" />
                    </button>
                  )}
                </div>
                <div>
                  {!isEdit && (
                    <button
                      type="submit"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Save
                      {isLoding && <SmallLoader />}
                    </button>
                  )}
                </div>
                <div className="mx-auto">
                  <div className="text-center d-flex justify-content-center gap-2">
                    <h4>
                      {customerData?.customer?.firstname}{" "}
                      {customerData?.customer?.lastname}
                    </h4>
                  </div>

                  <div className="d-flex justify-content-center gap-2">
                    <span className={roleClasses[customerData?.customer?.role]}>
                      {customerData?.customer?.role}
                    </span>
                    {customerData?.customer?.beclient === "true" && (
                      <span className="bg-success rounded px-2 c-badge">
                        <i
                          className="menu-icon text-white tf-icons ti ti-checkup-list m-0 fs-5"
                          type="button"
                        />
                      </span>
                    )}
                    {customerData?.customer?.beclient === "false" &&
                      customerData?.customer?.contact && (
                        <span className="bg-warning rounded px-2 c-badge">
                          <i
                            className="menu-icon text-white tf-icons ti ti-file m-0 fs-5"
                            type="button"
                          />
                        </span>
                      )}
                  </div>
                </div>
              </div>
              <hr />
              <small className="card-text text-uppercase">
                Prospect Detail
              </small>

              <ul className="list-unstyled mb-4 mt-3">
                <li className="d-flex align-items-center justify-content-between mb-3">
                  <i className="ti ti-mail" />
                  <span className="fw-medium mx-2 text-heading">Email:</span>
                  <Field
                    readOnly={isEdit}
                    name="email"
                    value={values?.email}
                    placeholder="email"
                    className={`${isEdit ? "hide-input" : "form-control"}`}
                  />
                  {isEdit === true &&
                    customerData?.customer?.active === false && (
                      <div
                        className="text-primary text-decoration-underline cursor-pointer"
                        onClick={(e) => {
                          setModelShow(true);
                        }}
                      >
                        Activate
                      </div>
                    )}
                </li>

                <li className="d-flex align-items-center mb-3">
                  <i className="ti ti-phone-call" />
                  <span className="fw-medium mx-2 text-heading">Contact:</span>
                  <PhoneInput
                    disabled={isEdit}
                    country={"Luxembourg"}
                    enableSearch={true}
                    className={` ${isEdit ? "hide-input" : "form-control"} `}
                    placeholder="+352 369 852 472"
                    value={values?.contact}
                    onChange={(value) => setFieldValue("contact", value)}
                  />
                </li>

                <li className="d-flex align-items-center mb-3">
                  <span className="fw-medium mx-2 text-heading">DOB:</span>
                  <Field
                    readOnly={isEdit}
                    name="DOB"
                    value={
                      values?.DOB
                        ? moment(values?.DOB).format("YYYY-MM-DD")
                        : ""
                    }
                    placeholder="DOB"
                    type="date"
                    className={`${isEdit ? "hide-input" : "form-control"}`}
                  />
                </li>
              </ul>
            </div>
          </Form>
        )}
      </Formik>

      {isModelShow && (
        <CustomerActivationModale
          show={isModelShow}
          onHide={() => setModelShow(false)}
          title={" are you sure you want ot send active link"}
          id={id}
        />
      )}
    </div>
  );
};

export default ProspectDetails;
