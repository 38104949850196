/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import BreadCrumbs from "../../Common/BreadCrumbs";
import { KmCounter } from "../../Common/DropdownValue";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { fetchDeliveryIn, fetchSingleDeliveryIn } from "../../../Redux/deliverySlice";
import { useDispatch, useSelector } from "react-redux";
import { addDeliveryIn } from "../../../services/Deliveries";
import * as Yup from 'yup';


const DeliveryIn = () => {
  const { id } = useParams();
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState([]);
  const [isLimite, setLimite] = useState("");
  const { singleDeliveryInData } = useSelector((state) => state?.deliverySlice);
  const newlength = singleDeliveryInData?.[0]?.photos
    ? singleDeliveryInData?.[0]?.photos.length
    : 0;
  const uploadImge = (e) => {
    const selectedFile = e.target.files;
    if (selectedFile && selectedFile.length > 0) {
      const newImages = Array.from(selectedFile).map((file) => {
        return {
          file: file,
          source: URL.createObjectURL(file),
        };
      });

      if (selectedImage.length + newImages.length <= 12) {
        setSelectedImage((prevImages) => [...prevImages, ...newImages]);
        setLimite("");
      } else {
        setLimite("You can only upload up to 12 images.");
      }
    }
  };
  const handleLicenseButtonClick = (e) => {
    e.stopPropagation();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const removeFile = (index, e) => {
    let temImgData = selectedImage.filter((_, i) => i !== index);
    setSelectedImage(temImgData);
    setLimite("");
    e.stopPropagation();
  };

  const initialValues = {
    km_counter: singleDeliveryInData?.[0]?.km_counter || "",
    fuel_gauge: singleDeliveryInData?.[0]?.fuel_gauge || "",
  };


  const validationSchema = Yup.object().shape({
    km_counter: Yup.number()
      .required("Kilometrage is required"),
    fuel_gauge: Yup.string().required("Niveau d'essence is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,

    validateOnChange:false,
    validateOnBlur:false, 
    validationSchema:validationSchema,
   
    onSubmit: async (values) => {
      const form = new FormData();
      form.append("r_id", id);
      form.append("km_counter", values?.km_counter);
      form.append("fuel_gauge", values?.fuel_gauge);
      form.append("status", 3);
      selectedImage.map((item) => form.append("photos", item?.file));
      await addDeliveryIn(form);
      navigate("/deliveries/delivery-in");
    },
  });

  useEffect(() => {
    dispatch(fetchSingleDeliveryIn(id));
  }, [id]);

  return (
    <div className="content-wrapper ">
      <div className=" py-0">
        <form onSubmit={formik.handleSubmit}>
          <div className="d-flex align-items-center justify-content-between p-0 ">
            <BreadCrumbs title={"Flotte"} subTitle={"Entrée"} />
            <h4 className=" m-0">
              <NavLink
                to={"/deliveries/delivery-in"}
                className="btn btn-label-danger waves-effect"
                type="button"
              >
                Annuler
              </NavLink>
              <button
                type="submite"
                className="btn btn-primary waves-effect waves-light"
              >
                Valider
              </button>
            </h4>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="card mb-4">
                <div class="card-header">
                  <h5 class="card-tile mb-0">Details Véhicule </h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <label class="form-label mb-1">Kilometrage</label>
                      <input       
                        value={formik.values.km_counter}
                        name="km_counter"
                        type="text"
                        placeholder="KM"
                        aria-controls="DataTables_Table_0" 
                        // class="form-control"
                        className={`form-control ${
                          formik.errors.km_counter 
                            ? "is-invalid"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                      />
                       {formik.errors.km_counter && (
                        <div className="invalid-feedback">
                          {formik.errors.km_counter}
                        </div>
                      )}
                    </div>
                    <div className="col-12 col-md-6">
                      <label class="form-label mb-1">Niveau d'essence</label>
                      <Dropdown
                        value={formik.values.fuel_gauge}
                        options={KmCounter}
                        filter
                        name="fuel_gauge"
                        onChange={formik.handleChange}
                        placeholder="Niveau d'essence"
                        // className="w-full"
                        className={`w-full ${
                          formik.errors.fuel_gauge
                            ? "dropdown-invalid"
                            : ""
                        }`}
                      />
                       {formik.errors.fuel_gauge && (
                        <div className="invalid-feedback">
                          {formik.errors.fuel_gauge}
                        </div>
                      )}
                    </div>
                    <div className="col-12 col-md-6"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="card mb-4">
                <div class="card-header">
                  <h5 class="card-tile mb-0">Photo</h5>
                </div>
                <div className="card-body">
                  <div className="text-muted mb-3">
                    {selectedImage.length + newlength}
                    /12
                  </div>
                  <div className="row justify-content-center">
                    {selectedImage.map((image, index) => (
                      <div
                        key={index}
                        className="dz-preview dz-processing dz-image-preview dz-success dz-complete p-0 overflow-hidden"
                      >
                        <div className="dz-details">
                          <div className="dz-thumbnail">
                            <img alt="066.png" src={image.source} />
                          </div>
                          <div className="dz-filename">{image?.file?.name}</div>
                          <div className="dz-size">
                            <strong>
                              {(image.file.size / (1024 * 1024)).toFixed(2)}
                            </strong>
                            MB
                          </div>
                        </div>
                        <div
                          className="dz-remove cursor-pointer"
                          onClick={(e) => {
                            removeFile(index, e);
                          }}
                        >
                          Supprimer
                        </div>
                      </div>
                    ))}
                    {singleDeliveryInData?.[0]?.photos?.map((image, index) => (
                      <div
                        key={index}
                        className="dz-preview dz-processing dz-image-preview dz-success dz-complete p-0 overflow-hidden"
                      >
                        <div className="dz-details">
                          <div className="dz-thumbnail">
                            <img
                              alt="066.png"
                              src={`${process.env.REACT_APP_IMG_URL}${image}`}
                            />
                          </div>
                          <div className="dz-filename">{image?.file?.name}</div>
                          <div className="dz-size">
                            <strong>
                              {/* {(image.file.size / (1024 * 1024)).toFixed(2)} */}
                            </strong>
                            MB
                          </div>
                        </div>
                        <div
                          className="dz-remove cursor-pointer"
                          onClick={(e) => {
                            removeFile(index, e);
                          }}
                        >
                          Supprimer
                        </div>
                      </div>
                    ))}
                  </div>
                  <center className="py-3">{isLimite}</center>
                  <div className="text-center">
                    <div
                      type="file"
                      className="btn btn-outline-dark waves-effect"
                      onClick={handleLicenseButtonClick}
                    >
                      Ajouter Photo
                    </div>
                    <input
                      multiple
                      hidden
                      ref={fileInputRef}
                      type="file"
                      onChange={uploadImge}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DeliveryIn;
