import { toast } from "react-toastify";
import axiosInstance from "../apiInstances/axiosInstance";

export const loginUserAPI = async (body) => {
  try {
    const response = await axiosInstance.post("/user", body);
    if (response.status === 200) {
      toast.success("Login successful", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
export const googleAutoAddress = async (body) => {
  try {
    const response = await axiosInstance.post(
      `/customer/google/autoaddress?keyword=${body}`
    );

    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
