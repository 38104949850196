/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import logo from "../../../assets/img/icons/logo/Turismo.svg";
import { useSelector } from "react-redux";
import { FieldArray } from "formik";
import { InvoiceContext } from "../invoiceContext";
import { numberWithEuroFormat } from "../../Common/Currency";
import { Dropdown } from "primereact/dropdown";

function calculateOverallValues(items) {
  console.log("🚀 ~ calculateOverallValues ~ items:", items)
  return items.reduce(
    (acc, currentItem) => {
      acc.overallPvat += parseFloat(currentItem.p_vat) || 0;
      acc.overallVat += parseFloat(currentItem.vat) || 0;
      acc.overallTotal += parseFloat(currentItem.total_ex_vat) || 0;
      return acc;
    },
    { overallPvat: 0, overallVat: 0, overallTotal: 0 }
  );
}

const Bill = ({
  setOtherValues,
  otherValues,
  setInitialValues,
  isInitialValues,
}) => {
  console.log("🚀 ~ isInitialValues:", isInitialValues)
  const { isValue, setValue } = useContext(InvoiceContext);
  const { singleCompanyData, singleClientData } = useSelector(
    (state) => state?.invoiceSlice
    );
    const overallValues = calculateOverallValues(isInitialValues);
    console.log("🚀 ~ file: Bill.js:29 ~ isValue:",   singleCompanyData);

  // new calculateTotalPrice strat
  const calculateTotalPrice = (value) => {
    const { period, price, qty } = value;

    switch (period) {
      case "A":
        return (price * qty * 12).toFixed(2);
      case "M":
        return (price * qty).toFixed(2);
      case "S":
        return ((price / 4) * qty).toFixed(2);
      case "J":
        return ((price * qty) / 30).toFixed(2);
      default:
        return 0;
    }
  };
  const calculateTotalPriceWithVat = (value) => {
    const { period, price, qty, vat } = value;

    switch (period) {
      case "A":
        return (price * qty * 12 + vat).toFixed(2);
      case "M":
        return (price * qty + vat).toFixed(2);
      case "S":
        return ((price / 4) * qty + vat).toFixed(2);
      case "J":
        return ((price * qty) / 30 + vat).toFixed(2);
      default:
        return 0;
    }
  };

  // new calculateTotalPrice end

  // discount start
  const data = isValue?.discount_per?.split?.("_");
  const discount =
    data[1] === "per"
      ? (
          ((overallValues?.overallTotal + overallValues?.overallVat) *
          isValue?.discount_per?.split?.("_")?.[0]) /
          100
        )?.toFixed(2)
      : isValue?.discount_per?.split?.("_")?.[0];
  // discount end

  // Total start
  const Total = (
    overallValues?.overallTotal +
    overallValues?.overallVat -
    discount
  ).toFixed(2);
  // Total en

  const productHandleChange = (e, index) => {
    const { name, value } = e.target;
    const updatedValues = [...isInitialValues];
    updatedValues[index][name] = value;
    // Calculate VAT and update the value
    const vatRate = singleCompanyData?.company?.vate_rate || 0;
    const price = parseFloat(updatedValues[index].price) || 0;
    const qty = parseFloat(updatedValues[index].qty) || 0;
    updatedValues[index].vat = (vatRate * price * qty) / 100;
    updatedValues[index].p_vat = price * qty;
    const newValues = {
      period: updatedValues[index].period,
      price: price,
      qty: qty,
    };
    const newValues2 = {
      vat: updatedValues[index].vat,
      period: updatedValues[index].period,
      price: price,
      qty: qty,
    };
    updatedValues[index].total_ex_vat = calculateTotalPrice(newValues);
    updatedValues[index].total = calculateTotalPrice(newValues);
    updatedValues[index].totalwithvat = calculateTotalPriceWithVat(newValues2);
    setInitialValues(updatedValues);
  };

  const otherHandleChange = (e) => {
    const { name, value } = e.target;
    setOtherValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addInput = () => {
    setInitialValues([
      ...isInitialValues,
      {
        item_name: "",
        item_desc: "",
        price: "",
        length: "",
        qty: "",
        p_vat: "",
        vat: "",
        period: "",
        total_ex_vat: "",
      },
    ]);
  };

  const handleRemoveItem = (index) => {
    setInitialValues((prev) => {
      return prev.filter((_, i) => i !== index);
    });
  };

  useEffect(() => {
    setOtherValues((prev) => ({
      ...prev,
      subtotal_ex_vat: overallValues?.overallPvat,
      total_vat: overallValues?.overallVat,
      total_in_vat: overallValues?.overallTotalExVat,
      paid: 0,
      refunded: 0,
      total_pay: Total,
      productDetails: isInitialValues,
    }));

    setValue((prev) => ({
      ...prev,
      invoice_id: otherValues?.invoice_id,
      subtotal_ex_vat: overallValues?.overallTotal,
      total_vat: overallValues?.overallVat,
      total_in_vat: overallValues?.overallVat,
      paid: 0,
      refunded: 0,
      total_pay: Total,
      notes: otherValues?.notes,
      billing_date: otherValues?.billing_date,
      due_date: otherValues?.due_date,
      productDetails: isInitialValues,
      discount,
    }));
  }, [
    isValue?.discount_per,
    isInitialValues,
    otherValues?.notes,
    otherValues?.due_date,
    otherValues?.billing_date,
    otherValues?.invoice_id,
  ]);

  return (
    <div className="col-lg-9 col-12 mb-lg-0 mb-4">
      <div className="card invoice-preview-card">
        <div className="card-body">
          {/* New */}
          {/* <div className="row m-sm-4 m-0 mx-0"> */}
          <div className="row my-sm-4 m-0 mx-0">
            <div className="col-md-7 mb-md-0 mb-4 ps-0">
              <div className="d-flex svg-illustration mb-5 align-items-center">
                <img
                  width={150}
                  className="img-fluid"
                  src={logo}
                  alt="logo-not-found"
                />
              </div>
            </div>
            <div className="col-md-5 text-end p-0 m-0">
              <span
                id="FactureProForma"
                className="h2 text-capitalize mb-0 text-nowrap m-0"
              >
                {isValue?.proformate ? "Facture Pro-format " : "Facture"}
              </span>
            </div>
          </div>
          <div className="row m-sm-4 m-0">
            <div className="col-md-5 mb-md-0 mb-4 ps-0">
              <p className="mb-3 fw-bold">{singleCompanyData?.company?.name}</p>
              <p className="mb-2">
                {singleCompanyData?.billing?.number}
                {singleCompanyData?.billing?.number && ","}
                {singleCompanyData?.billing?.street}
              </p>
              <p className="mb-2">
                {singleCompanyData?.billing?.province}
                {singleCompanyData?.billing?.province && ","}
                {singleCompanyData?.billing?.postal}
                {singleCompanyData?.billing?.postal && ","}
                {singleCompanyData?.billing?.city}
              </p>
              <p className="mb-4">{singleCompanyData?.billing?.country}</p>
              <p className="mb-1">{singleCompanyData?.billing?.legalname}</p>
            </div>
            <div className="col-md-5 col-sm-5 col-12 mb-sm-0 mb-4">
              <p className="mb-3 fw-bold">
                {singleClientData?.customer?.firstname}{" "}
                {singleClientData?.customer?.lastname}
              </p>
              <p className="mb-2">
                {singleClientData?.customer?.postal_code}
                {singleClientData?.customer?.postal_code && ","}{" "}
                {singleClientData?.customer?.address}
              </p>
              <p className="mb-2">
                {singleClientData?.customer?.city}
                {singleClientData?.customer?.city && ","}
                {singleClientData?.customer?.country}
              </p>
              {/* <p className="mb-4">Developer, Developer</p> */}
              <p className="mb-1">
                {singleClientData?.customer?.email}
                {singleClientData?.customer?.email && ","}
                {singleClientData?.customer?.contact}
              </p>
            </div>
          </div>
          <hr className="py-3 mx-n4" />
          <div className="border py-4 row mx-0">
            <div className="d-flex mt-3 position-relative pe-0">
              <div className="row w-100 px-3">
                <div className="col flex-grow-1">
                  <p className="mb-2 repeater-title">Numéro de Facture</p>
                  <div className="input-group disabled w-px-150 has-validation">
                    <span className="input-group-text">#</span>
                    <input
                      type="number"
                      onChange={(e) => otherHandleChange(e)}
                      className="form-control is-valid"
                      id="InvoiceNo"
                      name="invoice_id"
                      Value={otherValues?.invoice_id}
                    />
                  </div>
                </div>
                <div className="col flex-grow-1">
                  <p className="mb-2 repeater-title">Date de Facturation</p>
                  <input
                    value={otherValues.billing_date}
                    onChange={(e) => otherHandleChange(e)}
                    name="billing_date"
                    type="date"
                    className="form-control w-px-150 date-picker flatpickr-input"
                    placeholder="YYYY-MM-DD"
                  />
                </div>
                <div className="col flex-grow-1">
                  <p className="mb-2 repeater-title">Date d'Échéance</p>
                  <input
                    value={otherValues.due_date}
                    type="date"
                    onChange={(e) => otherHandleChange(e)}
                    className="form-control w-px-150 date-picker flatpickr-input"
                    placeholder="YYYY-MM-DD"
                    name="due_date"
                  />
                </div>
                <div className="col flex-grow-1">
                  <p className="mb-2 repeater-title">Numéro Client</p>
                  <p>{singleClientData?.customer?.c_id}</p>
                </div>
                <div className="col flex-grow-1">
                  <p className="mb-2 repeater-title">Numéro TVA</p>
                  <p>{singleCompanyData?.company?.vatno}</p>
                </div>
              </div>
            </div>
          </div>
          {/* Old Start*/}
          <hr className="my-3 mx-n4" />

          <section>
            <hr className="my-3 mx-n4" />
            <div className="table-responsive text-center overflow-hidden">
              <table className="table" id="invoiceTable">
                <FieldArray
                  name="productDetails"
                  render={(arrayHelpers) => (
                    <>
                      <div className="table-responsive text-center">
                        <table className="table" id="invoiceTable">
                          <thead className="modal-thead">
                            <tr>
                              <th scope="col" className="w-50">
                                <span>Reference</span>
                              </th>
                              <th scope="col" className="px-1 w-25">
                                <span>Price</span>
                              </th>
                              <th scope="col" className="px-1 w-30">
                                <span>QTY</span>
                              </th>
                              <th scope="col" className="px-1">
                                <span>Select</span>
                              </th>
                              <th scope="col" className="px-1">
                                <div
                                  onClick={addInput}
                                  className="btn btn-primary btn-sm addRow waves-effect waves-light"
                                >
                                  <span>Add</span>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <input
                            type="hidden"
                            id="CurrencySymbol"
                            name="CurrencySymbol"
                            is="dmx-input"
                            value="€"
                          />
                          <input
                            type="hidden"
                            name="vatRateAll"
                            id="vatRateAll"
                            is="dmx-input"
                            value="1"
                          />
                          <input
                            type="hidden"
                            name="status"
                            id="status"
                            is="dmx-input"
                            value="0"
                          />
                          <tbody className="modal-tbody" id="tableRepeat2">
                            {isInitialValues?.map((value, index) => {
                              return (
                                <tr className="py-3 border">
                                  <td className="px-1 ">
                                    <input
                                      type="text"
                                      placeholder="Référence"
                                      value={value?.item_name}
                                      name="item_name"
                                      className="form-control mb-2"
                                      onChange={(e) =>
                                        productHandleChange(e, index)
                                      }
                                    />
                                    <br />
                                    <br />
                                  </td>
                                  <td className="px-1 align-baseline">
                                    <input
                                      value={value?.price}
                                      onChange={(e) =>
                                        productHandleChange(e, index)
                                      }
                                      name="price"
                                      type="number"
                                      className="mx-auto form-control invoice-item-price px-1 text-center unit_price1"
                                      placeholder="01"
                                      min="12"
                                      id="unit_price"
                                      data="1"
                                    />
                                    <div className="mb-0 p_vat_count p_vat1 mt-3">
                                      Price excluding VAT :{" "}
                                      {numberWithEuroFormat(value.p_vat)}
                                    </div>
                                  </td>
                                  <td className="px-1 w-25 align-baseline">
                                    <input
                                      className="form-control invoice-item-lenght px-1 text-center unit_length1"
                                      placeholder="01"
                                      min="1"
                                      max="50"
                                      value={value?.qty}
                                      onChange={(e) =>
                                        productHandleChange(e, index)
                                      }
                                      name="qty"
                                      type="number"
                                      id="unit_qty"
                                    />
                                    <div className="mt-3">
                                      VAT : {value.vat}
                                    </div>
                                  </td>
                                  <td className="px-1 align-baseline">
                                    <Dropdown
                                      value={value?.period}
                                      onChange={(e) =>
                                        productHandleChange(e, index)
                                      }
                                      options={["A", "M", "S", "J"]}
                                      name="period"
                                      placeholder="Select"
                                      className="w-full"
                                    />
                                    <div className="mt-3">
                                      {value?.totalwithvat}
                                    </div>
                                  </td>
                                  <th
                                    scope="col"
                                    className="px-1 wi-30 align-baseline"
                                  >
                                    <div
                                      onClick={() => handleRemoveItem(index)}
                                      className="btn btn-label-danger btn-sm deleteRow"
                                    >
                                      <span>X</span>
                                    </div>
                                  </th>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                />
              </table>
            </div>
            <hr className="my-3 mx-n4" />
            <div className="row p-0 p-sm-4">
              <div className="col-md-6 col-sm-7 border rounded">
                <h6 className="mt-2 mb-4 fw-bold">Communication:</h6>
                <table>
                  <tbody>
                    <tr>
                      <td className="pe-4">Référence:</td>
                      <td />
                    </tr>
                    <tr>
                      <td className="pe-4">Compte Bancaire :</td>
                      <td>{singleCompanyData?.banking?.iban}</td>
                    </tr>
                    <tr>
                      <td className="pe-4">Code BIC :</td>
                      <td>{singleCompanyData?.banking?.bic}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-6 d-flex justify-content-end">
                <div className="invoice-calculations">
                  <div className="d-flex justify-content-between mb-2">
                    <span className="w-px-200">Prix HTVA:</span>
                    <span className="fw-medium text-end">
                      <div>
                        {numberWithEuroFormat(overallValues?.overallTotal)}
                      </div>
                    </span>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span className="w-px-200">TVA:</span>
                    <span className="fw-medium text-end">
                      <div>
                        {numberWithEuroFormat(
                          overallValues?.overallVat?.toFixed(2)
                        )}
                      </div>
                    </span>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span className="w-px-200">Prix TVAC:</span>
                    <span className="fw-medium text-end">
                      <div>
                        {numberWithEuroFormat(
                          overallValues?.overallTotal +
                            overallValues?.overallVat
                        )}
                      </div>
                    </span>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span className="w-px-200">Payé:</span>
                    <span className="fw-medium text-end">0.00</span>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span className="w-px-200">Discount:</span>
                    <span className="fw-medium text-end">
                      {numberWithEuroFormat(discount)}
                    </span>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <span className="w-px-200">Total a Payer:</span>
                    <span className="fw-medium text-end">
                      <div>{numberWithEuroFormat(Total)}</div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* Old End */}

            {/* New Start */}
            <hr className="my-3 mx-n4" />
            <div className="col-md-12 col-12 mb-md-0 mb-3">
              <label htmlFor="note" className="form-label fw-medium">
                Conditions soon:
              </label>
              <textarea
                value={otherValues?.notes}
                className="form-control"
                rows={3}
                id="note"
                placeholder="Conditions viendront ici"
                name="notes"
                onChange={(e) => otherHandleChange(e)}
              />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Bill;
