/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import ProfileEdit from "./ProfileEdit";
import ChangePassword from "./ChangePassword";
import ConnectedAccounts from "./ConnectedAccounts";

const Profile = () => {
    const [isTab, setTab] = useState("Sécurité");
    const tab = [{ label: "Sécurité", icon: "ti ti-lock" }, { label: "Connections", icon: "fas fa-link fa-sm" }];

    return (
        <div className="row">
            <div className="col-xl-4">
                <ProfileEdit />
            </div>
            <div className="col-xl-8 d-flex flex-column gap-4">
                <div className="d-flex justify-content-between">
                    <ul className="nav nav-pills flex-column flex-sm-row">
                        {tab?.map((item) => (
                            <li
                                onClick={() => setTab(item.label)}
                                key={item.label}
                                className={`me-1 cursor-pointer ${isTab === item.label && "sub-menu-active "
                                    }`}
                            >
                                <div className="nav-link ">
                                    <i className={`menu-icon tf-icons ${item.icon}`} />
                                    {item.label}</div>
                            </li>
                        ))}
                    </ul>
                </div>
                {isTab === "Sécurité" && <ChangePassword />}
                {isTab === "Connections" && <ConnectedAccounts />}
            </div>
        </div >
    );
};

export default Profile;
