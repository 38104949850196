import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { SmallLoader } from "../../Common/Loader";
import { updataClientApi } from "../../../services/client/addclientApi";
import { useDispatch } from "react-redux";
import { fetchClientList } from "../../../Redux/clientsSlice";
import pencilIcon from "../../../assets/icons/pencil.png";
import ValidateClientModal from "../Modals/ValidateClientModal";
import CustomerActivationModale from "../../Common/CustomertActivationModale";
import { roleClasses } from "../../Common/roles";
import { useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";

const ClientDetails = ({ customerData, singleClientValue }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isLoding, setLoding] = useState();
  const [clientProfileEdit, setClientProfileEdit] = useState(true);
  const [isModelShow, setModelShow] = useState(false);
  const [validModalShow, setValidModalShow] = useState(false);

  const handleModal = () => {
    setValidModalShow(true);
  };

  return (
    <div className="card-body">
      <Formik
        initialValues={{
          firstname: customerData?.customer?.firstname || "",
          lastname: customerData?.customer?.lastname || "",
          email: customerData?.customer?.email || "",
          contact: `+${customerData?.customer?.contact || ""}`,
          role: `${customerData?.customer?.role || ""}`,
        }}
        enableReinitialize={true}
        onSubmit={async (values) => {
          setLoding(true);
          const formData = new FormData();
          formData.append("firstname", values.firstname);
          formData.append("lastname", values.lastname);
          formData.append("contact", values.contact);
          const data = await updataClientApi(id, formData);
          if (data) {
            setClientProfileEdit(true);
            setLoding(false);
            dispatch(fetchClientList());
          }
        }}
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <Form>
            <div className="card mb-2">
              <div className="card-body p-3">
                <div className="">
                  <div className="d-flex justify-content-between align-content-center">
                    <div className="d-flex gap-2">
                      {!clientProfileEdit ? (
                        <>
                          <div>
                            <button
                              type="button"
                              onClick={() => handleSubmit()}
                              className="btn btn-primary waves-effect waves-light ms-3"
                            >
                              Save
                              {isLoding && <SmallLoader />}
                            </button>
                          </div>
                          <h4>
                            <Field
                              readOnly={clientProfileEdit}
                              name="firstname"
                              placeholder="-"
                              className={` ${
                                clientProfileEdit
                                  ? "hide-input"
                                  : "form-control"
                              }`}
                            />
                          </h4>
                          <h4>
                            <Field
                              readOnly={clientProfileEdit}
                              name="lastname"
                              placeholder="-"
                              className={` ${
                                clientProfileEdit
                                  ? "hide-input"
                                  : "form-control"
                              }`}
                            />
                          </h4>
                        </>
                      ) : (
                        <>
                          <div>
                            <button
                              className="btn waves-effect waves-light ps-0"
                              onClick={() => {
                                setClientProfileEdit(false);
                              }}
                            >
                              <img src={pencilIcon} alt="pencil-icon" />
                            </button>
                          </div>
                          <h6 className="card-text my-auto">
                            {values?.firstname} {values?.lastname}{" "}
                          </h6>

                          <div className="d-flex justify-content-center align-items-center gap-2">
                            <td className="text-nowrap">
                              {customerData?.customer?.role?.length > 1 ? (
                                customerData?.customer?.role?.map((item) => (
                                  <span
                                    className={`me-1 btn waves-effect py-1 ${
                                      item?.charAt(0).toUpperCase() === "C"
                                        ? "btn-label-primary"
                                        : "btn-label-success"
                                    }`}
                                  >
                                    {item?.charAt(0).toUpperCase()}
                                  </span>
                                ))
                              ) : (
                                <span
                                  className={`${
                                    roleClasses[
                                      customerData?.customer?.role?.[0]
                                    ]
                                  }`}
                                >
                                  {customerData?.customer?.role?.[0]}
                                </span>
                              )}
                            </td>
                            {customerData?.customer?.role?.length === 1 && (
                              <div
                                className="bg-success rounded badge py-2 fs-7 cursor-pointer "
                                onClick={() => handleModal()}
                              >
                                Validate
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div>
                    <ul className="list-unstyled mb-0 mt-2">
                      <small className="card-text text-uppercase">
                        CONTACTS
                      </small>

                      <li className="d-flex align-items-center mb-3 mt-2">
                        <i className="ti ti-mail" />
                        <span className="fw-medium mx-2 text-nowrap">
                          Email:
                        </span>
                        <Field
                          readOnly
                          type="text"
                          name="email"
                          className={` ${
                            clientProfileEdit ? "hide-input" : "form-control"
                          }`}
                          placeholder="email"
                        />

                        {customerData?.customer?.active === false && (
                          <btn
                            className="text-primary text-decoration-underline cursor-pointer"
                            onClick={(e) => {
                              setModelShow(true);
                            }}
                          >
                            Activate
                          </btn>
                        )}
                      </li>

                      <li className="d-flex align-items-center mb-3 mt-2">
                        <i className="ti ti-phone-call" />
                        <span className="fw-medium mx-2 text-nowrap">
                          Contact:
                        </span>
                        <PhoneInput
                          disabled={clientProfileEdit}
                          country={"Luxembourg"}
                          enableSearch={true}
                          className={` ${
                            !clientProfileEdit
                              ? "form-control flex-grow-1 "
                              : "hide-input"
                          }`}
                          placeholder="+352 369 852 472"
                          value={values?.contact || "+352"}
                          onChange={(value) => setFieldValue("contact", value)}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {isModelShow && (
        <CustomerActivationModale
          show={isModelShow}
          onHide={() => setModelShow(false)}
          title={"are you sure you want ot send active link"}
          id={id}
        />
      )}
      {validModalShow && (
        <ValidateClientModal
          customerData={customerData}
          show={validModalShow}
          id={id}
          onHide={() => setValidModalShow(false)}
        />
      )}
    </div>
  );
};

export default ClientDetails;
