import React, { useState } from "react";
import LinkedDriverModal from "../Modal/AddDriverModal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const LinkedDriver = ({ clientId }) => {
  const navigate = useNavigate();
  const [modalShow, setmodalShow] = useState();
  const { customerData } = useSelector((state) => state?.clientsSlice);
  return (
    <>
    {customerData?.connectedCustomers?.[0].AUX?.length >0&&  <div className="card mb-4">
      <div className="card-body">
        <div className="d-flex justify-content-between">
          <p className="card-text text-uppercase text-muted">LINKED DRIVERS</p>
          {/* <button
            className="btn btn-primary waves-effect waves-light mb-2"
            onClick={() => setmodalShow(true)}
          >
            <i class="ti ti-plus me-sm-1 ti-xs"></i> Add
          </button> */}
        </div>
        <ul className="list-unstyled mb-0 mt-2">
          {customerData?.connectedCustomers?.map((item) =>
            item.AUX?.map((auxData) => (
              <>
                {auxData !== null && (
                  <li className="d-grid align-items-center mb-2">
                    <div className="d-flex justify-content-between">
                      <div className="d-grid align-items-center">
                        <span
                          className="fw-medium  text-heading cursor-pointer"
                          onClick={() => {
                            navigate(`/driver/profile/${auxData?._id}`);
                            window.scroll(0, 0);
                          }}
                        >
                          {auxData?.firstname} {auxData?.lastname}
                        </span>
                        <span className="profile-email">{auxData?.email}</span>
                      </div>
                      <div className="mx-3 mt-1 d-flex align-items-center gap-2">
                        <span
                          className="badge badge-center rounded-pill"
                          style={{
                            backgroundColor: "#28C76F",
                            width: "10px",
                            height: "10px",
                          }}
                        >
                          <i className="ti ti-cercle ti-sm pb-2"></i>
                        </span>
                        {auxData?.role?.[0]}
                      </div>
                    </div>
                  </li>
                )}
              </>
            ))
          )}
        </ul>
      </div>
      <LinkedDriverModal
        show={modalShow}
        onHide={() => setmodalShow(false)}
        title={"Supprimer Cette Société"}
        clientId={clientId}
      />
    </div>}
    </>
  );
};

export default LinkedDriver;
