import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { RoleLabel, subscriptionStatusClass } from "../../../Common/roles";
import { Field, Form, Formik } from "formik";
import { formatDate } from "../../../Common/FormatDate";
import uploadIcon from "../../../../assets/icons/upload.svg";
import { updateUserDocument } from "../../../../services/client/addclientApi";
import { useParams } from "react-router-dom";
import CustomFileUploader from "./CustomFileUploader";
import { IoCloseOutline } from "react-icons/io5";
import DocumentConfirmModal from "../../../Common/DocumentConfirmModal";

const SingleClientDocument = ({
  handleClentAccordionToggle,
  isOpenClientAccordion,
  customerData,
  apiData,
  isDocuments,
  setIsDocumentsId,
  handleUploadImg,
  setActiveDocument,
  isActiveDocument,
  isDocumentsId,
  setModalShow,
}) => {
  const { id } = useParams();
  const [isDocId, setIsDocsId] = useState("");
  const [isStatus, setIsStatus] = useState("");
  const [isDescription, setIsDescription] = useState("");
  const [confirmModalShow, setConfirmModalShow] = useState(false);

  const handleConfirmModal = (item, status) => {
    setConfirmModalShow(true);
    setIsDocsId(item?._id);
    setIsStatus(status);
    if (status) {
      setIsDescription("Avez-vous confirmé le document ?");
    } else {
      setIsDescription("Êtes-vous rejeté le document ?");
    }
  };

  return (
    <>
      <Accordion activeKey={isOpenClientAccordion}>
        <Accordion.Item eventKey={customerData?.customer?._id}>
          <Accordion.Header
            className="card mb-2"
            onClick={(e) => {
              handleClentAccordionToggle(customerData?.customer?._id);
            }}
          >
            <div className="d-flex gap-2 align-items-center">
              <h6 className="my-0 text-nowrap">
                {customerData?.customer?.firstname}{" "}
                {customerData?.customer?.lastname}
              </h6>
              <div className="flex-grow-1  gap-2 d-flex">
                <RoleLabel userrols={customerData?.customer?.role} />
                <span
                  className={
                    subscriptionStatusClass[
                      customerData?.customer?.sub_id?.subscription_name
                    ]
                  }
                >
                  {customerData?.customer.sub_id?.subscription_name}
                </span>
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Body className="p-0">
            {apiData.length === 0 &&
              isDocuments?.length > 0 &&
              isDocuments?.map((item) => (
                <Formik
                  initialValues={{
                    name: item?.name?.split(".")[0] || "",
                    fileType: item?.name?.split(".")[1] || "",
                  }}
                  enableReinitialize={true}
                  onSubmit={async (values) => {
                    const form = new FormData();
                    form.append("name", values?.name + "." + values?.fileType);
                    form.append("doc_id", item?._id);
                    await updateUserDocument(customerData?.customer?._id, form);
                    setIsDocumentsId(" ");
                  }}
                >
                  {({ values, setFieldValue, handleChange }) => (
                    <>
                      <Form onClick={() => setActiveDocument(item)}>
                        <div
                          style={{
                            "--border-colore":
                              item?.status === "pending"
                                ? "#FF9F43"
                                : item?.status === "accept"
                                ? "#28C76F"
                                : "red",
                          }}
                          className={`doc-status border-left-3 border-right-3 card cursor-pointer mb-2 ${
                            item?._id === isActiveDocument?._id && "doc-active"
                          }`}
                        >
                          <div className="row align-items-center px-3 py-2 ">
                            <div className="col-9">
                              {isDocumentsId === item?._id ? (
                                <div className="d-flex align-items-center">
                                  <Field
                                    readOnly={isDocumentsId !== item?._id}
                                    name="name"
                                    className={`cursor-pointer flex-grow-0 ${
                                      isDocumentsId === item?._id
                                        ? "form-control mb-1 "
                                        : "hide-input"
                                    }`}
                                    placeholder=""
                                  />
                                  .<div>{values?.fileType}</div>
                                </div>
                              ) : (
                                <div>
                                  {values?.name + "." + values?.fileType}
                                </div>
                              )}

                              <div className="d-flex fs-7 gap-2">
                                <span>
                                  {item?.firstname} {item?.lastname}
                                </span>
                                <span>{formatDate(item?.uploadedAt)}</span>
                              </div>
                            </div>

                            <div className=" col-3">
                              {item?.status === "pending" ? (
                                <div className="d-flex flex-column gap-1  justify-content-center align-items-center">
                                  <IoCloseOutline
                                    className="fs-5"
                                    onClick={() => {
                                      handleConfirmModal(item, false);
                                    }}
                                  />
                                  <span
                                    className="tf-icons ti-xs ti ti-check d-block text-center"
                                    onClick={() => {
                                      handleConfirmModal(item, true);
                                    }}
                                  />
                                </div>
                              ) : (
                                <div className="d-flex gap-2 justify-content-end align-items-center">
                                  <div>
                                    {isDocumentsId === item?._id ? (
                                      <button
                                        type="submit"
                                        className="btn btn-primary waves-effect waves-light px-2 py-1"
                                      >
                                        Save
                                      </button>
                                    ) : (
                                      <span
                                        onClick={() =>
                                          setIsDocumentsId(item?._id)
                                        }
                                        className="tf-icons ti-xs ti ti-edit d-block mb-2"
                                      ></span>
                                    )}
                                    <span
                                      onClick={() => setModalShow(true)}
                                      className="tf-icons ti-xs ti ti-trash d-block text-center"
                                    ></span>
                                  </div>
                                  <div>
                                    <input type="checkbox" />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Form>
                    </>
                  )}
                </Formik>
              ))}
            <div className="position-relative mb-2">
              <CustomFileUploader
                label={"Ajouter un document"}
                handleChange={(file) => handleUploadImg(file, id)}
                name="file"
                types={["pdf", "JPG", "PNG", "GIF", "webp"]}
                customMessage={"Uploaded Successfully"}
              />
              <div className="p-2 rounded drag-upload-icon">
                <img src={uploadIcon} alt="upload-icons" />
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <DocumentConfirmModal
        isDocId={isDocId}
        isStatus={isStatus}
        isUserId={id}
        description={isDescription}
        show={confirmModalShow}
        onHide={() => setConfirmModalShow(false)}
      />
    </>
  );
};

export default SingleClientDocument;
