import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import {
  getCompanyDetails,
  getCompanylist,
} from "../services/Company/addCompanyApi";
import { getSubscription } from "../services/Company/addSubscriptionApi";
import BreadCrumbs from "../components/Common/BreadCrumbs";

const Settings = () => {
  const navigate = useNavigate();
  const [isCompanylist, setCompanylist] = useState();
  const [isCompanyDetails, setCompanyDetails] = useState();
  const [issubscriptionDetails, setSubscriptionDetails] = useState();
  const [isFetchCompanylist, setFetchCompanylist] = useState("");
  const [isCompanyDeleteBtn, setCompanyDeleteBtn] = useState(false);
  const [isSubscriptioneBtn, setSubscriptioneBtn] = useState(false);
  const [isActiveCompany, setActiveCompany] = useState("");
  const [isActiveSubscription, setActiveSubscription] = useState("");

  const AddCompanyDetails = async (id) => {
    setCompanyDeleteBtn(true);
    const companyData = await getCompanyDetails(id);
    setCompanyDetails(companyData?.data);
    navigate("company");
  };

  const addSubscriptionDetails = async (id) => {
    setSubscriptioneBtn(true);
    const subscriptionData = await getSubscription(id);
    setSubscriptionDetails(subscriptionData?.data);
    navigate("subscription");
  };

  const allCompanylist = async () => {
    const list = await getCompanylist();
    setCompanylist(list);
  };

  useEffect(() => {
    allCompanylist();
  }, [isFetchCompanylist]);
  return (
    <>
      <div className="content-wrapper setting">
        {/* Content */}
        <div className="container-fluid flex-grow-1 container-p-y">
          <BreadCrumbs title={"Turismo"} subTitle={"Paramètres"} />
          {/* Modern */}
          <div
            className="step d-flex gap-3"
            data-target="#account-details-modern-vertical"
          >
            <NavLink
              to={"/settings/company"}
              // to={"/settings"}
            >
              <div className={`rounded-2 border p-1 px-2 btn-active`}>
                <span className="bs-stepper-label">
                  <span className={`bs-stepper-title}`}>
                    <i className="ti ti-building-community me-1"></i>
                    Sociétés
                  </span>
                </span>
              </div>
            </NavLink>
            <NavLink to={"/settings/subscription"}>
              <div className={`rounded-2 border p-1 px-2 btn-active`}>
                <span className="bs-stepper-label ">
                  <span className={`bs-stepper-title`}>
                    <i className="ti ti-currency-euro me-1"></i>
                    Abonnements
                  </span>
                </span>
              </div>
            </NavLink>
          </div>
          <div className="row gy-4">
            {/* Modern Vertical Wizard */}
            <div className="col-lg-3">
              <div className="bs-stepper vertical wizard-modern wizard-modern-vertical mt-2">
                <div className="bs-stepper-header">
                  {isCompanylist &&
                    isCompanylist?.data?.map((items, index) => (
                      <React.Fragment key={index}>
                        <div
                          className={`btn btn-outline-primary waves-effect justify-content-start mt-3 d-block text-start ${
                            isActiveCompany === items?._id
                              ? "com-sub-active"
                              : ""
                          }`}
                          onClick={() => {
                            setActiveSubscription(items?._id);
                            setActiveCompany(items?._id);
                            AddCompanyDetails(items?._id);
                          }}
                        >
                          {items.name}
                        </div>
                        {items?.subscriptions?.map((subscriptions, index) => (
                          <div
                            key={index}
                            className={`btn btn-outline-primary waves-effect mt-1 ms-4 justify-content-start d-block text-start ${
                              isActiveSubscription === subscriptions?.sub_id
                                ? "com-sub-active"
                                : ""
                            }`}
                            onClick={() => {
                              addSubscriptionDetails(subscriptions?.sub_id);
                              setActiveSubscription(subscriptions?.sub_id);
                              setActiveCompany(items?._id);
                            }}
                          >
                            <i className="ti ti-arrow-narrow-right me-2"></i>
                            {subscriptions?.sub_name}
                          </div>
                        ))}
                      </React.Fragment>
                    ))}
                  <div className="mt-3 d-flex justify-content-end">
                    <div
                      className="btn btn-outline-primary waves-effect border-end-0 btn-company w-full"
                      onClick={() => {
                        navigate("company");
                        setCompanyDetails();
                        setCompanyDeleteBtn(false);
                        setActiveCompany();
                        setActiveSubscription();
                      }}
                    >
                      <i className="ti ti-plus me-2"></i> Ajouter une Société
                    </div>
                    <div
                      onClick={() => {
                        navigate("subscription");
                        setSubscriptionDetails();
                        setSubscriptioneBtn(false);
                        setActiveSubscription();
                      }}
                      className="btn btn-outline-primary waves-effect btn-subscription w-full"
                    >
                      <i className="ti ti-plus me-2"></i> Ajouter un Abonnement
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <Outlet
                context={[
                  isCompanyDetails,
                  setCompanyDetails,
                  isCompanyDeleteBtn,
                  issubscriptionDetails,
                  setFetchCompanylist,
                  isSubscriptioneBtn,
                  setSubscriptionDetails,
                ]}
              />
            </div>
            {/* /Modern Vertical Wizard */}
          </div>
        </div>
        {/* / Content */}
        <div className="content-backdrop fade" />
      </div>
    </>
  );
};

export default Settings;
