/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../Common/Loader";
import EditChecks from "../../../Checks/EditChecks";
import { fetchCheck, fetchCheckSingle } from "../../../../Redux/checkSlice";
import { Row, Col, Button } from 'react-bootstrap';
import ImageAnnotation from "../../../Checks/ImageEdit";
import ImageUpload from "../../../Checks/ImageUpload";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { BsCheckCircleFill, BsFillExclamationCircleFill } from "react-icons/bs";
import { getSingleCheckData } from "../../../../services/check";
import '../../../../css/checks.css'

const VehicleConditionPhoto = ({reservDetail}) => {
  const [showModal, setShowModal] = useState(false);
  const [isId, setId] = useState("");
  const dispatch = useDispatch();
  const [checkListSingle, setCheckListSingle] = useState(null);
  let baseImgPath = process.env.REACT_APP_IMG_URL;
  let id = reservDetail && reservDetail._id ? reservDetail._id : null;
  let generalId = reservDetail && reservDetail.generalId ? reservDetail.generalId : null;

  const [showDamageReport, setShowDamageReport] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getCheckData = async () => {
      let payload = {
        "reservationId": id,
        "generalId": generalId,
        "is_latest_yn": true
      }
      let response = await getSingleCheckData(payload);
      setCheckListSingle(response ? response : null);
      
    }
    getCheckData();
  }, []);

  const getImagebyType = (type) => {
    if (!checkListSingle || !checkListSingle.checkImages) return null;
    const checkImageData = checkListSingle.checkImages.length ? checkListSingle.checkImages : [];
    const imgData = checkImageData.find(res => res.image_type === type);
    return imgData?.images?.[0]?.image_path ? `${baseImgPath}/${imgData.images[0].image_path}` : null;
  };

  const isImageRenewed = (type) => {
    if (!(checkListSingle) || (checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length == 0)) return 'qus';

    const imgData = checkListSingle.checkImages.find(item => item.image_type === type);

    if (!imgData || !imgData.images || !imgData.images.length) return 'qus';

    const image = imgData.images[0];

    if (image.is_renewed_yn) {
      if (!image.annotations || image.annotations.length === 0) return 'green';
      const isDamaged = image.annotations.some(annotation => annotation.is_damaged_yn);
      if (isDamaged) return 'orange';
      return 'green';
    } else {
      if (!image.annotations || image.annotations.length === 0) return 'qus';
      const isDamaged = image.annotations.some(annotation => annotation.is_damaged_yn);
      if (isDamaged) return 'orange';
      return 'qus';
    }
  };


  const getAdditionalImgBatch = (images) => {
    const image = images;

    if (image.is_renewed_yn) {
      if (!image.annotations || image.annotations.length === 0) return 'green';
      const isDamaged = image.annotations.some(annotation => annotation.is_damaged_yn);
      if (isDamaged) return 'orange';
      return 'green';
    } else {
      if (!image.annotations || image.annotations.length === 0) return 'qus';
      const isDamaged = image.annotations.some(annotation => annotation.is_damaged_yn);
      if (isDamaged) return 'orange';
      return 'qus';
    }
  }

  const additionalImages = checkListSingle?.checkImages?.filter(item => item.image_type.includes('additional')) || [];

  const renderAdditionalImages = () => {
    const rows = [];
    const fixedBlocks = [
      { image_type: 'add' },
    ];

    const allImages = [...fixedBlocks, ...additionalImages];
    for (let i = 0; i < allImages.length; i += 4) {
      const rowItems = allImages.slice(i, i + 4);
      rows.push(
        <Row key={i}>
          {rowItems.map((item, index) => (

            <Col key={index} md={3} className="border p-0 d-flex align-items-center justify-content-center icon-container"
              onClick={() => {
                navigate(`/checks/annotate/${id}/${generalId}/11/${item.image_type}/from-delivery`);
              }}
            >
              {item.image_type === 'add' ? (
                <span>+</span>
              ) : (
                item && item.images && item.images.length && item.images[0].image_path &&
                <img src={`${baseImgPath}/${item.images[0].image_path}`} alt={item.image_type} />
              )}
              {
                item && item.images && item.images.length && item.images[0].image_path && getAdditionalImgBatch(item.images[0]) === 'qus' &&
                <BsFillQuestionCircleFill className="info-icon" />
              }
              {
                item && item.images && item.images.length && item.images[0].image_path && getAdditionalImgBatch(item.images[0]) === 'green' &&
                <BsCheckCircleFill className="check-circle-icon" />
              }
              {
                item && item.images && item.images.length && item.images[0].image_path && getAdditionalImgBatch(item.images[0]) === 'orange' &&
                <BsFillExclamationCircleFill className="exclamatory-icon" />
              }

            </Col>

          ))}
        </Row>
      );
    }
    return rows;
  }

  return (
    <div className="mt-4">
      <Row>
        <Col md={3} className="border p-0 d-flex align-items-center justify-content-center icon-container" onClick={() => {
          navigate(`/checks/annotate/${id}/${generalId}/1/face-avant/from-delivery`);
        }} >

          {checkListSingle && checkListSingle.checkImages && getImagebyType('Face Avant') ?
            <img src={getImagebyType('Face Avant')} alt="Face Avant"></img> :
            <span>Face Avant</span>
          }
          {(!checkListSingle || (checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length === 0) ||
            (checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length && isImageRenewed('Face Avant') === 'qus')) &&
            <BsFillQuestionCircleFill className="info-icon" />
          }
          {(checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length && isImageRenewed('Face Avant') === 'green') ?
            <BsCheckCircleFill className="check-circle-icon" /> : <></>}
          {(checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length && isImageRenewed('Face Avant') === 'orange') ?
            <BsFillExclamationCircleFill className="exclamatory-icon" /> : <></>
          }

        </Col>
        <Col md={3} className="border p-0 d-flex align-items-center justify-content-center icon-container" onClick={() => {
          navigate(`/checks/annotate/${id}/${generalId}/2/face-arriere/from-delivery`);
        }}>
          {checkListSingle && checkListSingle.checkImages && getImagebyType('Face Arriere') ?
            <img src={getImagebyType('Face Arriere')} alt="Face Arrière"></img> :
            <span>Face Arrière</span>
          }
          {(!checkListSingle || (checkListSingle.checkImages && checkListSingle.checkImages.length === 0) ||
            (checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length && isImageRenewed('Face Arriere') === 'qus')) &&
            <BsFillQuestionCircleFill className="info-icon" />
          }
          {(checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length && isImageRenewed('Face Arriere') === 'green') ?
            <BsCheckCircleFill className="check-circle-icon" /> : <></>}
          {(checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length && isImageRenewed('Face Arriere') === 'orange') ?
            <BsFillExclamationCircleFill className="exclamatory-icon" /> : <></>}

        </Col>
        <Col md={3} className="border p-0 d-flex align-items-center justify-content-center icon-container" onClick={() => {
          navigate(`/checks/annotate/${id}/${generalId}/3/profil-conducteur/from-delivery`);
        }}>

          {checkListSingle && checkListSingle.checkImages && getImagebyType('Profil Conducteur') ?
            <img src={getImagebyType('Profil Conducteur')} alt="Profil Conducteur"></img> :
            <span>Profil Conducteur</span>
          }
          {(!checkListSingle || (checkListSingle.checkImages && checkListSingle.checkImages.length === 0) ||
            (checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length && isImageRenewed('Profil Conducteur') === 'qus')) &&
            <BsFillQuestionCircleFill className="info-icon" />
          }
          {(checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length && isImageRenewed('Profil Conducteur') === 'green') ?
            <BsCheckCircleFill className="check-circle-icon" /> : <></>}
          {(checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length && isImageRenewed('Profil Conducteur') === 'orange') ?
            <BsFillExclamationCircleFill className="exclamatory-icon" /> : <></>}

        </Col>
        <Col md={3} className="border p-0 d-flex align-items-center justify-content-center icon-container" onClick={() => {
          navigate(`/checks/annotate/${id}/${generalId}/4/profil-passanger/from-delivery`);
        }}>
          {checkListSingle && checkListSingle.checkImages && getImagebyType('Profil Passager') ?
            <img src={getImagebyType('Profil Passager')} alt="Profil Passager"></img> :
            <span>Profil Passager</span>
          }
          {(!checkListSingle || (checkListSingle.checkImages && checkListSingle.checkImages.length === 0) ||
            (checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length && isImageRenewed('Profil Passager') === 'qus')) &&
            <BsFillQuestionCircleFill className="info-icon" />
          }
          {(checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length && isImageRenewed('Profil Passager') === 'green') ?
            <BsCheckCircleFill className="check-circle-icon" /> : <></>}
          {(checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length && isImageRenewed('Profil Passager') === 'orange') ?
            <BsFillExclamationCircleFill className="exclamatory-icon" /> : <></>}

        </Col>
      </Row>
      <Row>
        <Col md={3} className="border p-0 d-flex align-items-center justify-content-center icon-container" onClick={() => {
          navigate(`/checks/annotate/${id}/${generalId}/5/roue-avg/from-delivery`);
        }}>

          {checkListSingle && checkListSingle.checkImages && getImagebyType('Roue AV.G') ?
            <img src={getImagebyType('Roue AV.G')} alt="Roue AV.G"></img> :
            <span>Roue AV.G</span>
          }
          {(!checkListSingle || (checkListSingle.checkImages && checkListSingle.checkImages.length === 0) ||
            (checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length && isImageRenewed('Roue AV.G') === 'qus')) &&
            <BsFillQuestionCircleFill className="info-icon" />
          }
          {(checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length && isImageRenewed('Roue AV.G') === 'green') ?
            <BsCheckCircleFill className="check-circle-icon" /> : <></>}
          {(checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length && isImageRenewed('Roue AV.G') === 'orange') ?
            <BsFillExclamationCircleFill className="exclamatory-icon" /> : <></>}

        </Col>
        <Col md={3} className="border p-0 d-flex align-items-center justify-content-center icon-container" onClick={() => {
          navigate(`/checks/annotate/${id}/${generalId}/6/roue-avd/from-delivery`);
        }}>
          {checkListSingle && checkListSingle.checkImages && getImagebyType('Roue AV.D') ?
            <img src={getImagebyType('Roue AV.D')} alt="Roue AV.D"></img> :
            <span>Roue AV.D</span>
          }
          {(!checkListSingle || (checkListSingle.checkImages && checkListSingle.checkImages.length === 0) ||
            (checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length && isImageRenewed('Roue AV.D') === 'qus')) &&
            <BsFillQuestionCircleFill className="info-icon" />
          }
          {(checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length && isImageRenewed('Roue AV.D') === 'green') ?
            <BsCheckCircleFill className="check-circle-icon" /> : <></>}
          {(checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length && isImageRenewed('Roue AV.D') === 'orange') ?
            <BsFillExclamationCircleFill className="exclamatory-icon" /> : <></>}

        </Col>
        <Col md={3} className="border p-0 d-flex align-items-center justify-content-center icon-container" onClick={() => {
          navigate(`/checks/annotate/${id}/${generalId}/7/roue-ard/from-delivery`);
        }}>

          {checkListSingle && checkListSingle.checkImages && getImagebyType('Roue AR.D') ?
            <img src={getImagebyType('Roue AR.D')} alt="Roue AR.D"></img> :
            <span>Roue AR.D</span>
          }
          {(!checkListSingle || (checkListSingle.checkImages && checkListSingle.checkImages.length === 0) ||
            (checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length && isImageRenewed('Roue AR.D') === 'qus')) &&
            <BsFillQuestionCircleFill className="info-icon" />
          }
          {(checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length && isImageRenewed('Roue AR.D') === 'green') ?
            <BsCheckCircleFill className="check-circle-icon" /> : <></>}
          {(checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length && isImageRenewed('Roue AR.D') === 'orange') ?
            <BsFillExclamationCircleFill className="exclamatory-icon" /> : <></>}

        </Col>
        <Col md={3} className="border p-0 d-flex align-items-center justify-content-center icon-container" onClick={() => {
          navigate(`/checks/annotate/${id}/${generalId}/8/roue-arg/from-delivery`);
        }}>

          {checkListSingle && checkListSingle.checkImages && getImagebyType('Roue AR.G') ?
            <img src={getImagebyType('Roue AR.G')} alt="Roue AR.G"></img> :
            <span>Roue AR.G</span>
          }
          {(!checkListSingle || (checkListSingle.checkImages && checkListSingle.checkImages.length === 0) ||
            (checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length && isImageRenewed('Roue AR.G') === 'qus')) &&
            <BsFillQuestionCircleFill className="info-icon" />
          }
          {(checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length && isImageRenewed('Roue AR.G') === 'green') ?
            <BsCheckCircleFill className="check-circle-icon" /> : <></>}
          {(checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length && isImageRenewed('Roue AR.G') === 'orange') ?
            <BsFillExclamationCircleFill className="exclamatory-icon" /> : <></>}

        </Col>
      </Row>
      <Row>
        <Col md={3} className="border p-0 d-flex align-items-center justify-content-center icon-container" onClick={() => {
          navigate(`/checks/annotate/${id}/${generalId}/9/tableau-de-bord/from-delivery`);
        }}>

          {checkListSingle && checkListSingle.checkImages && getImagebyType('Tableau de bord') ?
            <img src={getImagebyType('Tableau de bord')} alt="Tableau de bord"></img> :
            <span>Tableau de bord</span>
          }
          {(!checkListSingle || (checkListSingle.checkImages && checkListSingle.checkImages.length === 0) ||
            (checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length && isImageRenewed('Tableau de bord') === 'qus')) &&
            <BsFillQuestionCircleFill className="info-icon" />
          }
          {(checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length && isImageRenewed('Tableau de bord') === 'green') ?
            <BsCheckCircleFill className="check-circle-icon" /> : <></>}
          {(checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length && isImageRenewed('Tableau de bord') === 'orange') ?
            <BsFillExclamationCircleFill className="exclamatory-icon" /> : <></>}

        </Col>
        <Col md={3} className="border p-0 d-flex align-items-center justify-content-center icon-container" onClick={() => {
          navigate(`/checks/annotate/${id}/${generalId}/10/interieur/from-delivery`);
        }}>
          {checkListSingle && checkListSingle.checkImages && getImagebyType('Interieur') ?
            <img src={getImagebyType('Interieur')} alt="Interieur"></img> :
            <span>Interieur</span>
          }
          {(!checkListSingle || (checkListSingle.checkImages && checkListSingle.checkImages.length === 0) ||
            (checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length && isImageRenewed('Interieur') === 'qus')) &&
            <BsFillQuestionCircleFill className="info-icon" />
          }
          {(checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length && isImageRenewed('Interieur') === 'green') ?
            <BsCheckCircleFill className="check-circle-icon" /> : <></>}
          {(checkListSingle && checkListSingle.checkImages && checkListSingle.checkImages.length && isImageRenewed('Interieur') === 'orange') ?
            <BsFillExclamationCircleFill className="exclamatory-icon" /> : <></>}

        </Col>
      </Row>
      <Row>
        <Col md={12} className="bg-primary text-white text-center py-2">
          PHOTO SUPPLÉMENTAIRE
        </Col>
      </Row>
      {renderAdditionalImages()}
    </div>

  );
};

export default VehicleConditionPhoto;
