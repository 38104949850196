/* eslint-disable react-hooks/exhaustive-deps */
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useRef, useState } from "react";
import BreadCrumbs from "../../Common/BreadCrumbs";
import { KmCounter } from "../../Common/DropdownValue";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleDeliveryOut } from "../../../Redux/deliverySlice";
import { Field, Form, Formik } from "formik";
import { formatDate } from "../../Common/FormatDate";
import { addDeliveryOut } from "../../../services/Deliveries";
import * as Yup from 'yup';


const DeliveryOut = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { singleDeliveryOutData } = useSelector(
    (state) => state?.deliverySlice
  );
  const [licenseImge, setLicenseImge] = useState("");
  const [idcardImge, setIdcardImge] = useState("");
  const dispatch = useDispatch();
  const idCardRef = useRef();
  const licenseRef = useRef();
  console.log("🚀 ~ singleDeliveryOutData:", singleDeliveryOutData)

  const uploadLicenseImge = (e) => {
    const selectedFile = e.target.files;
    setLicenseImge({
      ...selectedFile,
      img: URL.createObjectURL(selectedFile?.[0]),
    });
  };
  const uploadIdCardImge = (e) => {
    const selectedFile = e.target.files;
    setIdcardImge({
      ...selectedFile,
      img: URL.createObjectURL(selectedFile?.[0]),
    });
  };
  const handleLicenseButtonClick = () => {
    if (licenseRef.current) {
      licenseRef.current.click();
    }
  };
  const handleIdCardButtonClick = () => {
    if (idCardRef.current) {
      idCardRef.current.click();
    }
  };
  useEffect(() => {
    dispatch(fetchSingleDeliveryOut(id));
  }, [id]);

  const initialValues = {
    r_id: "",
    km_counter: singleDeliveryOutData?.delivery_info?.km_counter || "",
    fuel_gauge: singleDeliveryOutData?.delivery_info?.fuel_gauge || "",
    driver_id: "",
  };

  // calculation start
  const newDeliveryListData = [singleDeliveryOutData]?.map((item) => {
    const today = new Date();
    const returnDays = new Date(item?.return_date);
    var timeDifference = returnDays?.getTime() - today.getTime();
    var dayDifference = timeDifference / (1000 * 3600 * 24);
    dayDifference = Math.round(dayDifference);
    return { leftDays: dayDifference };
  });

  // get current month days
  var currentDate = new Date();
  var currentMonth = currentDate.getMonth();
  currentDate.setMonth(currentMonth + 1, 1);
  currentDate.setDate(currentDate.getDate() - 1);
  var numberOfDays = currentDate.getDate();

  // validation Schema
  const validationSchema = Yup.object().shape({
    km_counter: Yup.number().required('Kilometrage is required'),
    fuel_gauge: Yup.string().required('Niveau d\'essence is required'),
  });

  // calculation end
  return (
    <div className="content-wrapper delivers">
      <div className="container-fluid flex-grow-1 container-p-y">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validateOnChange={false}
          validateOnBlur={false} 
          validationSchema={validationSchema}
          
          onSubmit={async (values) => {
            console.log("🚀 ~ onSubmit={ ~ values:", singleDeliveryOutData?._id)
            
            const newValue = {
              ...values,
              r_id: singleDeliveryOutData?._id,
              driver_id: singleDeliveryOutData?.driver_info._id,
              id_card: idcardImge[0]
                ? idcardImge[0]
                : singleDeliveryOutData?.driver_info?.id_card,
              license: licenseImge[0]
                ? licenseImge[0]
                : singleDeliveryOutData?.driver_info?.license,
            };
             const formData = new FormData();
             console.log(newValue,"newValue")
            formData.append("r_id", newValue.r_id);
            formData.append("km_counter", newValue.km_counter);
            formData.append("fuel_gauge", newValue.fuel_gauge);
            formData.append("id_card", newValue.id_card);
            formData.append("license", newValue.license);
            formData.append("driver_id", newValue.driver_id);
            formData.append("status", 2);
            await addDeliveryOut(formData);
            navigate("/deliveries/delivery-out");
          }}
        >
          {(props) => {
            const { handleChange, values , errors } = props;
            return (
              <Form className="row">
                <div className="d-flex align-items-center justify-content-between">
                  <BreadCrumbs title={"Flotte"} subTitle={"Sortie"} />
                  <h4 className="d-flex gap-3">
                    <NavLink
                      to={"/deliveries/delivery-out"}
                      className="btn btn-label-danger waves-effect"
                      type="button"
                    >
                      Annuler
                    </NavLink>

                    <button
                      type="submit"
                      // to={"/deliveries/delivery-out"}
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Valider
                    </button>
                  </h4>
                </div>

                <div className="col-12 col-lg-6">
                  <div className="card mb-4">
                    <div class="card-header">
                      <h5 class="card-tile mb-0">Details Véhicule</h5>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <label class="form-label mb-1">Kilometrage</label>
                          <Field
                            name="km_counter"
                            type="text"
                            placeholder="KM"
                            aria-controls="DataTables_Table_0"
                            // class="form-control"
                            className={`form-control ${errors.km_counter ? 'is-invalid' : ''}`}
                          />
                          {errors.km_counter && (
            <div className="invalid-feedback">{errors.km_counter}</div>
          )}
                        </div>
                        <div className="col-12 col-md-6">
                          <label class="form-label mb-1">
                            Niveau d'essence
                          </label>
                          <Dropdown
                            value={values?.fuel_gauge}
                            options={KmCounter}
                            onChange={handleChange}
                            filter
                            name="fuel_gauge"
                            placeholder="Niveau d'essence"
                            // className="w-full"
                            className={`w-full ${errors.fuel_gauge ? 'dropdown-invalid' : ''}`}
                          />
                          {errors.fuel_gauge && (
            <div className="invalid-feedback">{errors.fuel_gauge}</div>
          )}
                        </div>

                        <div className="col-12 col-md-6"></div>
                      </div>
                    </div>
                  </div>

                  <div className="card mb-4">
                    <div class="card-header">
                      <h5 class="card-tile mb-0">Details de Réservation</h5>
                    </div>
                    <div className="card-body">
                      <div className="mb-3">
                        <label class="form-label mb-1 fs-6">
                          {
                            singleDeliveryOutData?.general_info?.manufacturer
                              ?.name
                          }
                          , {singleDeliveryOutData?.general_info?.model?.name}
                        </label>
                        <div className="d-flex justify-content-between">
                          <div className="text-muted">
                            {singleDeliveryOutData?.general_info?.license_plate}
                          </div>
                          <div>
                            {(
                              (newDeliveryListData?.[0]?.leftDays /
                                numberOfDays) *
                              singleDeliveryOutData?.subscription_info
                                ?.base_cost
                            ).toFixed(2)}
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-12 col-md-4">
                          <label class="form-label mb-1 fs-6">
                            {formatDate(singleDeliveryOutData?.start_date)}
                          </label>
                          <div className="text-muted">
                            {singleDeliveryOutData?.dept_loc}
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <label class="form-label mb-1 fs-6">
                            Customer Location
                          </label>
                          <div className="text-muted">
                            {singleDeliveryOutData?.cust_loc}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 text-end">
                          <label class="form-label mb-1 fs-6">
                            {formatDate(singleDeliveryOutData?.return_date)}
                          </label>
                          <div className="text-muted">
                            {singleDeliveryOutData?.return_loc}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div class="card-header">
                      <h5 class="card-tile mb-0">Details Financier</h5>
                    </div>
                    <div className="card-body">
                      <div className="d-flex justify-content-between mb-1">
                        <div className="fs-6 text-heading">Caution</div>
                        <div className="text-muted">
                          {singleDeliveryOutData?.subscription_info?.bail}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mb-1">
                        <div className="fs-6 text-heading">Franchise</div>
                        <div className="text-muted">
                          {
                            singleDeliveryOutData?.subscription_info
                              ?.total_loss_theft_access
                          }
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mb-1">
                        <div className="fs-6 text-heading">Coût Km Supp.</div>
                        <div className="text-muted">
                          {
                            singleDeliveryOutData?.subscription_info
                              ?.cost_per_adk
                          }
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mb-1">
                        <div className="fs-6 text-heading">
                          Charge Plein d'essence
                        </div>
                        <div className="text-muted">
                          {
                            singleDeliveryOutData?.subscription_info
                              ?.fuel_fill_price
                          }
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mb-1">
                        <div className="fs-6 text-heading">
                          Prix de l'essence surchargé
                        </div>
                        <div className="text-muted">
                          {singleDeliveryOutData?.miscSetting?.add_cost}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mb-1">
                        <div className="fs-6 text-heading">
                          Charge Immobilisation véhicule
                        </div>
                        <div className="text-muted">
                          {
                            singleDeliveryOutData?.subscription_info
                              ?.vehicle_immobilization_cost
                          }
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mb-1">
                        <div className="fs-6 text-heading">
                          Charge de Netoyage (Int/Ext)
                        </div>
                        <div className="text-muted">
                          {
                            singleDeliveryOutData?.subscription_info
                              ?.inte_cleaning
                          }
                          /
                          {
                            singleDeliveryOutData?.subscription_info
                              ?.exte_cleaning
                          }
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mb-1">
                        <div className="fs-6 text-heading">
                          Coût Administratif
                        </div>
                        <div className="text-muted">
                          {singleDeliveryOutData?.miscSetting?.admin_cost}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="card mb-4">
                    <div class="card-header">
                      <h5 class="card-tile mb-0">Détails Client</h5>
                    </div>
                    <div className="card-body">
                      <div className="fs-6 text-heading mb-3">
                        {singleDeliveryOutData?.customer_info?.firstname}{" "}
                        {singleDeliveryOutData?.customer_info?.lastname}
                      </div>
                      <div className="fs-6 text-heading mb-3">
                        {singleDeliveryOutData?.customer_info?.address}
                      </div>
                      <hr />
                      <div className="fs-6 text-heading mb-3">
                        {singleDeliveryOutData?.driver_info?.driver_first}{" "}
                        {singleDeliveryOutData?.driver_info?.driver_last}
                      </div>
                      <div className="fs-6 text-heading mb-3">
                        {singleDeliveryOutData?.driver_info?.driver_address}
                      </div>
                      <div className="fs-6 text-heading mb-3">
                        {singleDeliveryOutData?.driver_info?.driver_email}
                      </div>
                      <div className="fs-6 text-heading mb-3">
                        {singleDeliveryOutData?.driver_info?.driver_phone}
                      </div>

                      <div className="d-flex justify-content-center flex-wrap gap-5">
                        {/* idCard-Img start */}
                        {idcardImge ? (
                          <div className="row justify-content-center">
                            <div className="dz-preview dz-processing dz-image-preview dz-success dz-complete p-0">
                              <div className="dz-details">
                                <div className="dz-thumbnail">
                                  <img src={idcardImge.img} />
                                </div>
                                <div className="dz-filename">
                                  {idcardImge?.[0]?.name}
                                </div>
                                <div className="dz-size">
                                  <strong>
                                    {(
                                      idcardImge?.[0]?.size /
                                      (1024 * 1024)
                                    ).toFixed(2)}
                                  </strong>
                                  MB
                                </div>
                              </div>
                              <div
                                onClick={() => setIdcardImge("")}
                                className="dz-remove cursor-pointer d-flex justify-content-center align-content-center"
                              >
                                Supprimer le Ficher
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row justify-content-center">
                            <div className="dz-preview dz-processing dz-image-preview dz-success dz-complete p-0">
                              <div className="dz-details">
                                <div className="dz-thumbnail">
                                  <img
                                    alt="066.png"
                                    src={`${process.env.REACT_APP_IMG_URL}${singleDeliveryOutData?.driver_info?.id_card}`}
                                  />
                                </div>
                                <div className="dz-filename">
                                  {idcardImge?.[0]?.name
                                    ? idcardImge?.[0]?.name
                                    : "carte Id"}
                                </div>
                                <div className="dz-size">
                                  <strong>
                                    {idcardImge?.[0]?.size
                                      ? (
                                          idcardImge?.[0]?.size /
                                          (1024 * 1024)
                                        ).toFixed(2)
                                      : "00"}
                                  </strong>
                                  MB
                                </div>
                              </div>
                              <div
                                onClick={() => setIdcardImge("")}
                                className="dz-remove cursor-pointer d-flex justify-content-center align-content-center"
                              >
                                Supprimer le Ficher
                              </div>
                            </div>
                          </div>
                        )}
                        {/* license-Img start */}
                        {licenseImge ? (
                          <div className="row justify-content-center">
                            <div className="dz-preview dz-processing dz-image-preview dz-success dz-complete p-0">
                              <div className="dz-details">
                                <div className="dz-thumbnail">
                                  <img alt="066.png" src={licenseImge.img} />
                                </div>
                                <div className="dz-filename">
                                  {licenseImge?.[0]?.name}
                                </div>
                                <div className="dz-size">
                                  <strong>
                                    {(
                                      licenseImge?.[0]?.size /
                                      (1024 * 1024)
                                    ).toFixed(2)}
                                  </strong>
                                  MB
                                </div>
                              </div>
                              <div
                                onClick={() => setLicenseImge("")}
                                className="dz-remove cursor-pointer d-flex justify-content-center align-content-center"
                              >
                                Supprimer le Ficher
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row justify-content-center">
                            <div className="dz-preview dz-processing dz-image-preview dz-success dz-complete p-0">
                              <div className="dz-details">
                                <div className="dz-thumbnail">
                                  <img
                                    alt="066.png"
                                    src={`${process.env.REACT_APP_IMG_URL}${singleDeliveryOutData?.driver_info?.license}`}
                                  />
                                </div>
                                <div className="dz-filename">
                                  {licenseImge?.[0]?.name
                                    ? licenseImge?.[0]?.name
                                    : "permis"}
                                </div>
                                <div className="dz-size">
                                  <strong>
                                    {licenseImge?.[0]?.size
                                      ? (
                                          licenseImge?.[0]?.size /
                                          (1024 * 1024)
                                        ).toFixed(2)
                                      : "00"}
                                  </strong>
                                  MB
                                </div>
                              </div>
                              <div
                                onClick={() => setLicenseImge("")}
                                className="dz-remove cursor-pointer d-flex justify-content-center align-content-center"
                              >
                                Supprimer le Ficher
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className=" d-flex justify-content-center gap-3">
                        <button
                          type="button"
                          className="btn btn-outline-dark waves-effect"
                          onClick={handleIdCardButtonClick}
                        >
                          Carte ID
                        </button>
                        <input
                          hidden
                          ref={idCardRef}
                          type="file"
                          onChange={uploadIdCardImge}
                        />
                        <input
                          hidden
                          ref={licenseRef}
                          type="file"
                          onChange={uploadLicenseImge}
                        />
                        <button
                          type="button"
                          className="btn btn-outline-dark waves-effect"
                          onClick={handleLicenseButtonClick}
                        >
                          Permis
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default DeliveryOut;
