export const demo = [
  { name: "Essence 95" },
  { name: "Essence 85" },
  { name: "Diesel" },
  { name: "Électrique" },
];
export const demo2 = ["Manuelle", "Automatique"];
export const variables = [
  "Roue Été Avant",
  "Roue Hiver Avant",
  "Roue Été Arrière",
  "Roue Hiver Arrière",
];
export const Registered = [
  { _id: "659fe14a6264de6e2227c452", name: "Leasing Financier" },
  { _id: "659fe1596264de6e2227c453", name: "Operational Leasing" },
  { _id: "659fe1696264de6e2227c454", name: "Bank Financing" },
  { _id: "659fe1856264de6e2227c455", name: "Average Purchase Own" },
];
export const Manufacturer = [
  { name: "Audi" },
  { name: "Porsche" },
  { name: "Mercedes" },
  { name: "Mclaren" },
  { name: "Lamborghini" },
  { name: "Ferrari" },
  { name: "BMW" },
  { name: "Bentley" },
  { name: "Land-Rover" },
];
export const Vehiclecategory = [
  { _id: "659fe2ca6264de6e2227c46f", name: "1" },
  { _id: "659fe2c56264de6e2227c46e", name: "2" },
  { _id: "659fbfb26264de6e2227c437", name: "3" },
  { _id: "659fe2ce6264de6e2227c470", name: "4" },
];
export const Year = [2022, 2023, 2024, 2025, 2026, 2027, 2028];
export const Color = [
  { name: "NOIR ULTRAMARINE", image: "NOIR_ULTRAMARINE.png" },
  { name: "NOIR", image: "NOIR.png" },
  { name: "BLEU SCOZZIA", image: "BLEU_SCOZZIA.png" },
  { name: "BLEU", image: "BLEU.png" },
  { name: "BLEU R8", image: "BLEU_R8.png" },
  { name: "BLUE GENTIANE", image: "BLUE_GENTIANE.png" },
  { name: "CHICANE EFFECT", image: "CHICANE_EFFECT.png" },
  { name: "GIALLO MODENA", image: "GIALLO_MODENA.png" },
  { name: "VERT BRITISH", image: "GREEN_BRITISH.png" },
  { name: "VERT PORSCHE RACING", image: "GREEN_PORSCHE_RACING.png" },
  { name: "VERDANT", image: "VERDANT.png" },
  { name: "VERDE LARES", image: "VERDE_LARES.png" },
  { name: "VERT ÉMERAUDE", image: "VERT_ÉMERAUDE.png" },
  { name: "GRIS ARCTIC", image: "GRIS_ARCTIC.png" },
  { name: "GRIS CRAYON", image: "GRIS_CRAYON.png" },
  { name: "GRIS KEMORA", image: "GRIS_KEMORA.png" },
  { name: "GRIS NARDO", image: "GRIS_NARDO.png" },
  { name: "GRIS DAYTONNA", image: "GRIS_DAYTONNA.png" },
  { name: "GRIS", image: "GRIS.png" },
  { name: "JAUNE GT4", image: "JAUNE_GT4.png" },
  { name: "JAUNE PISTA", image: "JAUNE_PISTA.png" },
  { name: "JAUNE", image: "JAUNE.png" },
  { name: "ROSSO CORSA", image: "ROSSO_CORSA.png" },
  { name: "ROUGE GT4RS", image: "ROUGE_GT4RS.png" },
  { name: "ROUGE", image: "ROUGE.png" },
  { name: "ROUGE RS3", image: "ROUGE_RS3.png" },
  { name: "SILVER FLUX", image: "SILVER_FLUX.png" },
  { name: "SILVER GT", image: "SILVER_GT.png" },
  { name: "VIOLET PACIFAE", image: "VIOLET_PACIFAE.png" },
  { name: "VIOLET", image: "VIOLET.png" },
  { name: "BLEU MAROCAINS", image: "MAROCAINS_BLEU.png" },
  { name: "ORANGE FLAME", image: "ORANGE_FLAME.png" },
  { name: "BORASCO GREY", image: "BORASCO_GREY.png" },
];
export const Status = [
  "A louer",
  "A sortir",
  "Logistique",
  "Voitire de fonction",
];
export const Active = ["Command", "Active", "At the manufaturer"];
export const Damage = [
  "Display Screen",
  "Brake Pedal",
  "Clutch",
  "Air Bags",
  "Air Ventilation",
  "Side Mirror",
  "Rear View Mirror",
  "Speedometer",
  "Steering Wheel",
  "Door Handle",
];
export const License = ["A", "B", "C"];
export const Model = [{ name: "Vantage" }, { name: "Vantage Roadster" }];
export const Company = [{ name: "Tourism" }, { name: "Tourism Belgium" }];
export const KmCounter = [
  "1/8",
  "2/8",
  "3/8",
  "4/8",
  "5/8",
  "6/8",
  "7/8",
  "8/8",
];
export const Type = [
  "Berline",
  "SUV",
  "Coupé",
  "Cabriolet",
  "Break",
  "Monospace",
  "Fourgonnette",
  "Camionnette",
  "Utilitaire",
  "Hatchback",
  "Roadster",
  "4x4",
  "Cross-over",
  "Coupé Sport",
  "Véhicule hybride",
  "Véhicule électrique",
];
export const LocationTypeDropdown = ["Luxembourg", "Belgium", "France"];
export const CustomerType = ["Entreprise", "Particulier"];
export const options = [
  { label: "5", value: "5" },
  { label: "10", value: "10" },
  { label: "20", value: "20" },
  { label: "25", value: "25" },
  { label: "40", value: "40" },
  { label: "50", value: "50" },
  { label: "100", value: "100" },
];
export const locationOptions = [
  { label: "Belgium", value: "Belgium" },
  { label: "France", value: "France" },
  { label: "Luxembourg", value: "Luxembourg" },
];
