// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper{width:100%;height:400px}.swiper .swiper-slide{padding:2rem 0;text-align:center;font-size:1.5rem;background-color:#ecebed;background-position:center;background-size:cover}#swiper-multiple-slides,#swiper-3d-coverflow-effect{height:300px}#swiper-3d-coverflow-effect .swiper-slide{max-width:300px}#swiper-3d-cube-effect{max-width:400px}#swiper-3d-flip-effect{padding:50px}#swiper-gallery{height:500px}#swiper-gallery .gallery-top{height:80%;width:100%}#swiper-gallery .gallery-thumbs{height:20%;box-sizing:border-box;padding:10px 0}#swiper-gallery .gallery-thumbs .swiper-slide{width:25%;height:100%;opacity:.4;cursor:pointer}#swiper-gallery .gallery-thumbs .swiper-slide-thumb-active{opacity:1}
`, "",{"version":3,"sources":["webpack://./src/assets/vendor/css/pages/ui-carousel.css"],"names":[],"mappings":"AAAA,QAAQ,UAAU,CAAC,YAAY,CAAC,sBAAsB,cAAc,CAAC,iBAAiB,CAAC,gBAAgB,CAAC,wBAAwB,CAAC,0BAA0B,CAAC,qBAAqB,CAAC,oDAAoD,YAAY,CAAC,0CAA0C,eAAe,CAAC,uBAAuB,eAAe,CAAC,uBAAuB,YAAY,CAAC,gBAAgB,YAAY,CAAC,6BAA6B,UAAU,CAAC,UAAU,CAAC,gCAAgC,UAAU,CAAC,qBAAqB,CAAC,cAAc,CAAC,8CAA8C,SAAS,CAAC,WAAW,CAAC,UAAU,CAAC,cAAc,CAAC,2DAA2D,SAAS","sourcesContent":[".swiper{width:100%;height:400px}.swiper .swiper-slide{padding:2rem 0;text-align:center;font-size:1.5rem;background-color:#ecebed;background-position:center;background-size:cover}#swiper-multiple-slides,#swiper-3d-coverflow-effect{height:300px}#swiper-3d-coverflow-effect .swiper-slide{max-width:300px}#swiper-3d-cube-effect{max-width:400px}#swiper-3d-flip-effect{padding:50px}#swiper-gallery{height:500px}#swiper-gallery .gallery-top{height:80%;width:100%}#swiper-gallery .gallery-thumbs{height:20%;box-sizing:border-box;padding:10px 0}#swiper-gallery .gallery-thumbs .swiper-slide{width:25%;height:100%;opacity:.4;cursor:pointer}#swiper-gallery .gallery-thumbs .swiper-slide-thumb-active{opacity:1}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
