/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import car_exterior from "../../assets/img/car_exterior.png";
import car_int from "../../assets/img/car_int.png";
import { Dropdown } from "primereact/dropdown";
import Slider from "react-slick";
import { checkAdd, checkInAdd, getSingleCheck } from "../../services/check";
import { useDispatch, useSelector } from "react-redux";
import { fetchCheck, fetchCheckin } from "../../Redux/checkSlice";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  draggable: false,
};

function EditChecks(props) {
  const { isId, setId, checkListInData } = props;
  console.log(
    "🚀 ~ file: EditChecks.js:25 ~ EditChecks ~ checkListInData:",
    checkListInData
  );
  const sliderRef = useRef(null);
  const [exteriorDamage, setExteriorDamage] = useState(true);
  const [isInterior, setInterior] = useState(false);
  const [isEquipment, setEquipment] = useState(true);
  const [isTags, setTags] = useState([]);
  const [isNewEquipment, setNewEquipment] = useState([]);
  console.log("🚀 ~ EditChecks ~ isNewEquipment:", isNewEquipment)
  const dispatch = useDispatch();
  const {
    exteriorPartsDropdownData,
    exteriorStripeDropdownData,
    exteriorElementDropdownData,
    interiorPartsDropdownData,
    interiorStripeDropdownData,
  } = useSelector((state) => state?.checkSlice);

  // new Equipment start
  const Equipment = isTags?.map((item) => ({
    name: item?.name,
    // before:item?.value === "before" ? true : item?.value === "both" ? true : "",
    // after: item?.value === "after" ? true : item?.value === "both" ? true : "",
  }));
  useEffect(() => {
    setNewEquipment(Equipment);
  }, [isTags]);
  // new Equipment end

  const [damageFields, setDamageFields] = useState([
    { id: 1, damage_part: "", stripe: "", parts: "", image: "" },
  ]);
  console.log(
    "🚀 ~ file: EditChecks.js:60 ~ EditChecks ~ damageFields:",
    damageFields
  );
  const [interiorDamage, setInteriorDamage] = useState([
    { id: 1, interior_damage_part: "", stripe: "", images: "" },
  ]);

  // addDamageField start
  const addDamageField = () => {
    setDamageFields([...damageFields, { id: damageFields.length + 1 }]);
  };

  const handleInputChange = (e, index) => {
    const { name, value, files } = e.target;
    const newFields = [...damageFields];
    if (name === "image") {
      newFields[index][name] = files[0];
    } else {
      newFields[index][name] = value;
    }
    setDamageFields(newFields);
  };

  const addInteriorField = () => {
    setInteriorDamage([...interiorDamage, { id: interiorDamage.length + 1 }]);
  };
  // addDamageField end

  // Interior start
  const handleInteriorChange = (e, index) => {
    const { name, value, files } = e.target;
    const newFields = [...interiorDamage];
    if (name === "images") {
      newFields[index][name] = files[0];
    } else {
      newFields[index][name] = value;
    }
    setInteriorDamage(newFields);
  };
  // Interior end

  // slider start
  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const goToexterior = async () => {
    setExteriorDamage(true);
    setInterior(false);
    setTimeout(() => {
      previous();
    }, 100);
  };

  const goToInterior = async () => {
    setInterior(true);
    setTimeout(() => {
      previous();
    }, 100);
  };

  const interiorDamagePage = async () => {
    setExteriorDamage(false);
    setInterior(true);
    setTimeout(() => {
      next();
    }, 100);
  };
  const lastPage = async () => {
    setInterior(false);
    setEquipment(true);
    setTimeout(() => {
      next();
    }, 100);
  };
  // slider End

  const singleCheck = async () => {
    const data = await getSingleCheck(isId);
    if (data) {
      setDamageFields(data?.data?.exterior_damage);
      setInteriorDamage(data?.data?.interior_damage);
      setTags(data?.data?.tags);
    }
  };

  useEffect(() => {
    if (isId) {
      singleCheck();
    }
  }, [isId]);

  const handleFollowingClick = async (btnName) => {
    console.log(
      "🚀 ~ file: EditChecks.js:153 ~ handleFollowingClick ~ btnName:",
      btnName
    );
    const formData = new FormData();
    damageFields?.map((item, index) => {
      formData.append(
        `exterior_damage[${index}][damage_part]`,
        item.damage_part
      );
      formData.append(`exterior_damage[${index}][stripe]`, item.stripe);
      formData.append(`exterior_damage[${index}][parts]`, item.parts);
      formData.append(`exterior_damage[${index}][image]`, item.image);
      return null;
    });

    interiorDamage?.map((item, index) => {
      formData.append(
        `interior_damage[${index}][interior_damage_part]`,
        item.interior_damage_part
      );
      formData.append(`interior_damage[${index}][stripe]`, item.stripe);
      formData.append(`interior_damage[${index}][images]`, item.images);
      return null;
    });
    formData.append("reservation_id", isId);
    isNewEquipment?.map((item, index) => {
      formData.append(`tags[${index}][name]`, item.name);
      if (item.before === true && item.after !== true) {
        formData.append(`tags[${index}][value]`, "before");
      } else if (item.after === true && item.before !== true) {
        formData.append(`tags[${index}][value]`, "after");
      } else if (item.after === true && item.before === true) {
        formData.append(`tags[${index}][value]`, "both");
      }
      return null;
    });
    if (btnName === "toValidate") formData.append("status", 1);

    if (checkListInData?.[0]?.status === 3) {
      const body = { reservation_id: isId, status: 4 };
      const data = await checkInAdd(body);
      if (data) {
        await props.onHide();
        setDamageFields([
          { id: 1, damage_part: "", stripe: "", parts: "", file: "" },
        ]);
        setInteriorDamage([
          {
            id: 1,
            interior_damage_part: "",
            interior_stripe: "",
            InteriorFile: "",
          },
        ]);
        setId("");
        dispatch(fetchCheckin());
      }
    } else {
      const checkData = await checkAdd(formData);
      dispatch(fetchCheck());
      if (checkData) {
        await props.onHide();
        setExteriorDamage(true);
        setDamageFields([
          { id: 1, damage_part: "", stripe: "", parts: "", file: "" },
        ]);
        setInteriorDamage([
          {
            id: 1,
            interior_damage_part: "",
            interior_stripe: "",
            InteriorFile: "",
          },
        ]);
        setId("");
      }
    }
  };

  const newExteriorParts = exteriorPartsDropdownData?.map((item) => item.name);
  const newExteriorStripe = exteriorStripeDropdownData?.map(
    (item) => item.name
  );
  const newExteriorElement = exteriorElementDropdownData?.map(
    (item) => item.name
  );
  const newInteriorParts = interiorPartsDropdownData?.map((item) => item.name);
  const newinteriorStripe = interiorStripeDropdownData?.map(
    (item) => item.name
  );

  const handleCheckboxChange = (rowIndex, checkboxName) => {
    setNewEquipment((prevCheckboxes) => {
      const newCheckboxes = [...prevCheckboxes];
      newCheckboxes[rowIndex] = {
        ...newCheckboxes[rowIndex],
        [checkboxName]: !newCheckboxes[rowIndex][checkboxName],
      };
      return newCheckboxes;
    });
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton />

      <Modal.Body>
        <Slider ref={sliderRef} {...settings}>
          <div>
            {exteriorDamage && (
              <div>
                <h4 className="onboarding-title text-body text-center">
                  Dégats Extérieur
                </h4>
                <div className="col onboarding-info">
                  <p className="text-center">
                    <img src={car_exterior} alt="" className="img-fluid" />
                  </p>
                </div>
                <button
                  className="btn btn-primary waves-effect waves-light mb-3"
                  onClick={addDamageField}
                >
                  Ajouter un Dégat
                </button>
                {damageFields.map((field, index) => (
                  <>
                    <div className="col mb-3" key={field.id}>
                      <Dropdown
                        options={newExteriorParts}
                        value={field?.damage_part}
                        onChange={(e) => handleInputChange(e, index)}
                        placeholder="Sélectionner la partie endommagé"
                        id={`damage_part_${index}`}
                        // optionLabel="name"
                        className="w-full mb-3"
                        name={`damage_part`}
                      ></Dropdown>
                      <Dropdown
                        options={newExteriorStripe}
                        value={field?.stripe}
                        onChange={(e) => handleInputChange(e, index)}
                        placeholder="Sélectionner"
                        className="w-full mb-3"
                        name={`stripe`}
                      ></Dropdown>
                      <Dropdown
                        options={newExteriorElement}
                        value={field?.parts}
                        onChange={(e) => handleInputChange(e, index)}
                        placeholder="Sélectionner"
                        id={`parts_${index}`}
                        className="w-full mb-3"
                        name={`parts`}
                      ></Dropdown>
                      <div className="col mb-3">
                        <input
                          //   value={field?.file}
                          onChange={(e) => handleInputChange(e, index)}
                          type="file"
                          id={`image_${index}`}
                          name={`image`}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <hr className="text-danger my-5" />
                  </>
                ))}
                {damageFields?.map(
                  (item, index) =>
                    item?.image?.length > 0 && (
                      <div className="col mb-3 nav-align-top border py-3">
                        <img
                          style={{ height: "8rem" }}
                          className="img-fluid object-fit-contain"
                          src={`${process.env.REACT_APP_IMG_URL}/${item?.image}`}
                          // src={`http://192.168.29.65:8000/${item?.image}`}
                          alt=""
                        />
                      </div>
                    )
                )}
                <div className="col-12 d-flex justify-content-end mt-4 ">
                  <button
                    onClick={async () => {
                      interiorDamagePage();
                    }}
                    className="btn btn-primary btn-next waves-effect waves-light"
                    type="button"
                  >
                    <span className="align-middle d-sm-inline-block d-none me-sm-1">
                      Suivant
                    </span>
                    <i className="ti ti-arrow-right ti-xs"></i>
                  </button>
                </div>
              </div>
            )}
          </div>
          <div>
            {isInterior && (
              <div>
                <h4 className="onboarding-title text-body text-center">
                  Dégats Intérieur
                </h4>
                <div className="col onboarding-info">
                  <p className="text-center">
                    <img src={car_int} alt="" className="img-fluid" />
                  </p>
                </div>
                <button
                  className="btn btn-primary waves-effect waves-light mb-3"
                  onClick={addInteriorField}
                >
                  Ajouter un Dégat
                </button>
                {interiorDamage?.map((field, index) => (
                  <>
                    <div className="col mb-3" key={field.id}>
                      <Dropdown
                        options={newInteriorParts}
                        value={field?.interior_damage_part}
                        onChange={(e) => handleInteriorChange(e, index)}
                        placeholder="Sélectionner la partie endommagée"
                        id={`interior_damage_part_${index}`}
                        className="w-full mb-3"
                        name="interior_damage_part"
                      ></Dropdown>
                      <Dropdown
                        options={newinteriorStripe}
                        value={field?.stripe}
                        onChange={(e) => handleInteriorChange(e, index)}
                        placeholder="Sélectionner"
                        className="w-full mb-3"
                        name="stripe"
                      ></Dropdown>
                      <div className="col mb-3">
                        <input
                          //   value={field?.InteriorFile}
                          onChange={(e) => handleInteriorChange(e, index)}
                          type="file"
                          id={`image_${index}`}
                          name="images"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <hr className="text-danger my-5" />
                  </>
                ))}
                {interiorDamage?.map(
                  (item, index) =>
                    item?.images?.length > 0 && (
                      <div className="col mb-3 nav-align-top border py-3">
                        <img
                          style={{ height: "8rem" }}
                          className="img-fluid object-fit-contain"
                          src={`${process.env.REACT_APP_IMG_URL}/${item?.images}`}
                          // src={`http://192.168.29.65:8000/${item?.images}`}
                          alt=""
                        />
                      </div>
                    )
                )}
                <div className="col-12 d-flex justify-content-between mt-4">
                  <button
                    className="btn btn-label-secondary btn-prev waves-effect"
                    type="button"
                    onClick={() => {
                      goToexterior();
                    }}
                  >
                    <i className="ti ti-arrow-left ti-xs me-sm-1 me-0"></i>
                    <span className="align-middle d-sm-inline-block d-none">
                      Précédent 
                    </span>
                  </button>
                  <button
                    onClick={() => {
                      lastPage();
                    }}
                    className="btn btn-primary btn-next waves-effect waves-light"
                    type="button"
                  >
                    <span className="align-middle d-sm-inline-block d-none me-sm-1">
                      Suivant
                    </span>
                    <i className="ti ti-arrow-right ti-xs"></i>
                  </button>
                </div>
              </div>
            )}
          </div>

          <div>
            {isEquipment && (
              <div className="carousel-item active">
                <div className="table-responsive rounded">
                  <table className="table table-striped table-sm">
                    <thead>
                      <tr>
                        <th className="text-nowrap w-50">
                          <span>Equipement</span>
                        </th>
                        <th className="text-nowrap text-center w-25">
                          <span>Pré</span>
                        </th>
                        <th className="text-nowrap text-center w-25">
                          <span>Post</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody id="repeatChecksTag">
                      {isNewEquipment && 
                        isNewEquipment?.map((row, index) => (
                          <tr key={index}>
                            <td className="text-nowrap">{row.name}</td>
                            <td>
                              <div className="form-check d-flex justify-content-center p-0">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={row.before}
                                  onChange={() =>
                                    handleCheckboxChange(index, "before")
                                  }
                                />
                              </div>
                            </td>
                            <td>
                              <div className="form-check d-flex justify-content-center p-0">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={row.after}
                                  onChange={() =>
                                    handleCheckboxChange(index, "after")
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <div className="col-12 d-flex justify-content-between mt-4">
                    <button
                      className="btn btn-label-secondary btn-prev waves-effect"
                      type="button"
                      onClick={goToInterior}
                    >
                      <i className="ti ti-arrow-left ti-xs me-sm-1 me-0"></i>
                      <span className="align-middle d-sm-inline-block d-none">
                        Précédent
                      </span>
                    </button>
                    <div className=" d-flex gap-3">
                      {/* <button
                        onClick={() => handleFollowingClick("save")}
                        className="btn btn-primary btn-next btn-submit waves-effect waves-light"
                        type="submit"
                      >
                        Save
                      </button> */}
                      <button
                        onClick={() => handleFollowingClick("toValidate")}
                        className="btn btn-success btn-next btn-submit waves-effect waves-light"
                        type="submit"
                      >
                        <span className="align-middle d-sm-inline-block d-none me-sm-1">
                          Valider
                        </span>
                        <i className="ti ti-check ti-xs"></i>
                      </button>
                    </div>
                    {/* data-bs-dismiss="modal" aria-label="Close" */}
                  </div>
                </div>
              </div>
            )}
          </div>
        </Slider>
      </Modal.Body>
    </Modal>
  );
}
export default EditChecks;
