import { Field, Form, Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { fetchCustomerData } from "../../../Redux/clientsSlice";
import pencilIcon from "../../../assets/icons/pencil.png";
import { updataClientApi } from "../../../services/client/addclientApi";
import { SmallLoader } from "../../Common/Loader";
import AddressAutocompleteInput from "../../Common/AddressAutocompleteInput";
import AutoResizingTextArea from "../../Common/AutoResizingTextArea";

const EnterpriseDetails = ({
  customerData,
  setLoding,
  id,
  setEdit,
  isEdit,
  isLoding,
}) => {
  const dispatch = useDispatch();

  const { houseno, company_address } = customerData?.customer?.company || {};
  const { business, invoice_email, comp_id, vat_number } =
    customerData?.customer || {};

  return (
    <div className="card mb-4">
      <div className="card-body">
        <Formik
          initialValues={{
            business: business || "",
            vat_number: vat_number || "",
            invoice_email: invoice_email || "",
            address: `${company_address}` || "",
            buildingNumber: houseno || "",
          }}
          enableReinitialize={true}
          onSubmit={async (values) => {
            setLoding(true);
            const formData = new FormData();
            if (values.business) formData.append("business", values.business);
            if (values.vat_number)
              formData.append("vat_number", values.vat_number);
            if (values.buildingNumber)
              formData.append("company[houseno]", values.buildingNumber);
            if (values.address)
              formData.append("company[company_address]", values.address);
            if (values.invoice_email)
              formData.append("invoice_email", values.invoice_email);

            const data = await updataClientApi(id, formData);
            if (data?.status) {
              setEdit(true);
              setLoding(false);
              dispatch(fetchCustomerData(id));
            }
          }}
        >
          {({ values, setFieldValue, handleSubmit }) => {
            return (
              <Form>
                <div className="d-flex justify-content-between position-relative">
                  <div>
                    {isEdit && (
                      <button
                        className="btn waves-effect waves-light p-0 align-items-start"
                        onClick={() => {
                          setEdit(false);
                        }}
                      >
                        <img src={pencilIcon} alt="pencil-icon" />
                      </button>
                    )}
                  </div>
                  <div className="mx-auto mb-4 w-100">
                    <div className="text-center d-flex gap-2 justify-content-center flex-wrap">
                      {isEdit ? (
                        <>
                          <h4 className="mb-0 text-wrap w-100">{business} </h4>{" "}
                          <span className="bg-info rounded badge py-2">
                            {comp_id?.name}
                          </span>
                        </>
                      ) : (
                        <div className="d-flex gap-2 w-100">
                          <div>
                            <button
                              type="button"
                              onClick={handleSubmit}
                              className="btn btn-primary waves-effect waves-light "
                            >
                              Save
                              {isLoding && <SmallLoader />}
                            </button>
                          </div>
                          <Field
                            readOnly={isEdit}
                            name="business"
                            placeholder=" "
                            className={`w-100 ${
                              isEdit ? "hide-input" : "form-control"
                            }`}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <small className="card-text text-uppercase">
                  CLIENT DETAIL
                </small>

                <ul className="list-unstyled mb-0 mt-2">
                  <li className="d-flex align-items-center mb-3">
                    <span className="fw-medium mx-2 text-nowrap">
                      VAT Number:
                    </span>
                    <Field
                      readOnly={isEdit}
                      type="text"
                      name="vat_number"
                      className={`${isEdit ? "hide-input" : "form-control"}`}
                      placeholder=" "
                    />
                  </li>

                  {isEdit ? (
                    <li className="d-flex align-items-start mb-3">
                      <span className="fw-medium mx-2 text-heading text-nowrap">
                        Address:
                      </span>

                      <AutoResizingTextArea
                        value={values.address}
                        name="address"
                        id="address"
                        className="w-100 hide-input resize-none"
                      />
                    </li>
                  ) : (
                    <li className="d-flex align-items-center mb-3">
                      <span className="fw-medium mx-2 text-heading text-nowrap">
                        Address:
                      </span>
                      <AddressAutocompleteInput
                        name="address"
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    </li>
                  )}
                  <li className="d-flex  align-items-center mb-3 mt-2">
                    <span className="fw-medium mx-2 text-nowrap">
                      Street/Building Number:
                    </span>
                    <Field
                      readOnly={isEdit}
                      type="text"
                      name="buildingNumber"
                      className={` ${isEdit ? "hide-input" : "form-control"}`}
                      placeholder=" "
                    />
                  </li>
                  <small className="card-text text-uppercase">CONTACTS</small>
                  <li className="d-flex  align-items-center mb-3 mt-2">
                    <i className="ti ti-mail" />
                    <span className="fw-medium mx-2 text-nowrap">
                      Invoice Email:
                    </span>
                    <Field
                      readOnly={isEdit}
                      type="text"
                      name="invoice_email"
                      className={`${isEdit ? "hide-input" : "form-control"}`}
                      placeholder=" "
                    />
                  </li>
                </ul>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default EnterpriseDetails;
