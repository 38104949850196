import { Field, FieldArray } from "formik";
import { Dropdown } from "primereact/dropdown";
import React from "react";
import { variables } from "../../../Common/DropdownValue";

const Variables = ({ values, handleChange }) => {
  return (
    <div className="card mb-4">
      <div className="card-header">
        <h5 className="card-tile mb-0">Pneus</h5>
      </div>
      <div className="card-body">
        <FieldArray
          name="variables"
          render={(arrayHelpers) => (
            <div className="row mb-3">
              {values?.variables?.map((variable, index) => {
                return (
                  <div key={index} className="row mb-3">
                    <div className="col">
                      <Dropdown
                        value={values?.variables?.[index].wheel_type}
                        onChange={handleChange}
                        options={variables}
                        name={`variables.${index}.wheel_type`}
                        placeholder="Pneus"
                        className="w-full"
                      />
                    </div>
                    <div className="col">
                      <Field
                        className="form-control col"
                        name={`variables.${index}.memo`}
                        id={`variables.${index}.memo`}
                        type="text"
                        placeholder="Mémo"
                      />
                    </div>
                    <div className="col">
                      <Field
                        className="form-control col"
                        name={`variables.${index}.price`}
                        id={`variables.${index}.price`}
                        type="text"
                        placeholder="Coût"
                      />
                    </div>
                    {/* <div><label htmlFor=""></label></div> */}

                    <div className="col flex-grow-0">
                      <button
                        type="button"
                        onClick={() => arrayHelpers.remove(index)}
                        className="btn btn-danger waves-effect waves-light"
                      >
                        Supprimer
                      </button>
                    </div>
                  </div>
                );
              })}
              <div>
                <button
                  onClick={() =>
                    arrayHelpers.push(values?.employees?.length + 1, {
                      wheel_type: "",
                      memo: "",
                      price: "",
                    })
                  }
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                >
                  Ajouter un Type
                </button>
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default Variables;
