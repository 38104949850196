import { Field } from "formik";
import { Dropdown } from "primereact/dropdown";
import React from "react";
import { useSelector } from "react-redux";

const FinancialInformation = ({ values, handleChange }) => {
  const { financetypeDropdownList } = useSelector((state) => state.commonSlice);
  return (
    <div className="card mb-4">
      <div className="card-header">
        <h5 className="card-tile mb-0">Information financière</h5>
      </div>
      <div className="card-body">
        <div className="row mb-3">
          <div className="col">
            <label className="form-label mb-1">Enregistré par</label>
            <Field
              name="financialInfo.registered_by"
              type="text"
              className="form-control"
              placeholder="Nom"
              aria-controls="DataTables_Table_0"
            />
          </div>
          <div className="col">
            <label className="form-label mb-1">
              <label className="form-label mb-1">Type de Financement</label>
            </label>
            <Dropdown
              name="financialInfo.finance_type"
              value={values.financialInfo.finance_type}
              onChange={handleChange}
              options={financetypeDropdownList}
              optionLabel="name"
              filter
              placeholder="Type"
              className="w-full"
            />
          </div>
        </div>
        <div class="form-check mb-3">
          <Field
            name="financialInfo.purchase_option"
            type="checkbox"
            class="form-check-input"
            id="basic-default-checkbox"
          />
          <label class="form-check-label" for="basic-default-checkbox">
            Option d'achat
          </label>
        </div>
        <div className="row mb-3">
          <div className="col">
            <label className="form-label mb-1">Financer par</label>
            <Field
              name="financialInfo.funded_by"
              type="text"
              className="form-control"
              placeholder="Nom"
              aria-controls="DataTables_Table_0"
            />
          </div>
          <div className="col">
            <label className="form-label mb-1">Prix D'achat</label>
            <Field
              type="text"
              name="financialInfo.soon_suite"
              className="form-control"
              placeholder="Prix"
              aria-controls="DataTables_Table_0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialInformation;
