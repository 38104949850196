import { createSlice } from "@reduxjs/toolkit";
import { invoiceListApi } from "../services/Invoice";
import moment from "moment";

const profileInviceSlice = createSlice({
  name: "profileInviceSlice",
  initialState: {
    invoiceList: [],
    tempInvoiceList: [],
  },
  reducers: {
    getInvoiceList: (state, action) => {
      state.invoiceList = action.payload;
      console.log("🚀 ~  action.payload:", action.payload);
      state.tempInvoiceList = action.payload;
    },

    searchInvoice: (state, action) => {
      const value = action.payload?.toLowerCase();
    },
    
    getInvoice: (state, action) => {
      const data = action.payload;
      console.log("🚀 ~ action123:", data);
      const sortedReservations = data?.map((item) => {
        function calculateDateGap(startDate, endDate) {
          const start = moment(startDate);
          const end = moment(endDate);
          const gapInDays = end.diff(start, "days");
          return gapInDays + 1; // Include both start and end dates
        }

        function addDays(date, days) {
          return moment(date).add(days, "days").toDate();
        }

        function formatDate(date) {
          return moment(date).format("DD-MM-YYYY");
        }

        // Convert start_date and return_date from item to moment objects
        const startDate = moment(item?.billing_date);
        const returnDate = moment(item?.due_date);
        const totalDays = calculateDateGap(startDate, returnDate); // Total duration in days
        const pricePerDay = (item?.productDetails?.[1]?.price / 30).toFixed(2);
        console.log("ok", pricePerDay);

        const periodLength = 30; // Duration of each period in days

        let periods = [];
        let currentDate = startDate;

        // Calculate the number of 30-day periods
        while (currentDate.diff(startDate, "days") < totalDays) {
          let endOfPeriod = moment(currentDate).add(periodLength - 1, "days");
          if (endOfPeriod.diff(startDate, "days") >= totalDays) {
            endOfPeriod = moment(startDate).add(totalDays - 1, "days"); // Adjust last period end date
          }
          console.log("ojk");
          const gap = calculateDateGap(currentDate, endOfPeriod);
          periods.push({
            startdate: formatDate(currentDate),
            retrundate: formatDate(endOfPeriod),
            item_name: item?.productDetails?.[1]?.item_name,
            price:
              30 <= gap
                ? item?.productDetails?.[1]?.price
                : (
                    pricePerDay *
                    (calculateDateGap(currentDate, endOfPeriod) - 1)
                  ).toFixed(2),
            status:
              moment(currentDate).month() + 1 > moment().month() + 1 ? 1 : 0,
          });
          currentDate = moment(endOfPeriod).add(1, "days"); // Start next period
        }

        // Adjust last period to ensure it fits within the total duration
        if (
          periods.length > 0 &&
          periods[periods.length - 1].retrundate !==
            formatDate(moment(startDate).add(totalDays - 1, "days"))
        ) {
          periods[periods.length - 1].retrundate = formatDate(
            moment(startDate).add(totalDays - 1, "days")
          );
        }

        // Add periods to the item
        return {
          ...item,
          periods,
        };
      });

      state.invoiceList = sortedReservations;
    },
  },
});

export const { getInvoiceList, searchInvoice, getInvoice } =
  profileInviceSlice.actions;
export default profileInviceSlice.reducer;

// api call
export const fetchInvoiceList = (id) => async (dispatch) => {
  try {
    const tempData = await invoiceListApi(id);
    console.log("🚀 ~ fetchInvoiceList ~ tempData:", tempData);
    dispatch(getInvoiceList(tempData?.data));
    dispatch(getInvoice(tempData?.data));
  } catch (error) {
    console.log("🚀 ~ fetchInvoiceList ~ error:", error);
  }
};
