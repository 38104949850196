import React, { useState, useRef } from 'react';
import Annotation from 'react-image-annotation';
import { Modal, Button } from 'react-bootstrap';
import {
    PointSelector,
    RectangleSelector,
    OvalSelector,
} from 'react-image-annotation/lib/selectors';

const Annotator = ({ url, showModal, setShowModal, setShowDamageForm }) => {
    const UploadImages = url;
    const [annotations, setAnnotations] = useState([
        {
            "geometry": {
                "type": "OVAL",
                "x": 15.913199563234404,
                "y": 11.951120734478476,
                "width": 17.405063531266,
                "height": 56.935676308077234
            },
            "data": {
                "text": "1231",
                "id": 0.9984880529262532,
                "imageId": ""
            }
        },
        {
            "geometry": {
                "type": "OVAL",
                "x": 63.3815546485053,
                "y": 12.309206748994686,
                "width": 13.562387167220251,
                "height": 65.88782667098246
            },
            "data": {
                "text": "1321",
                "id": 0.5991296608802297,
                "imageId": ""
            }
        }
    ]);
    const [annotation, setAnnotation] = useState({});
    const [selectedImage, setSelectedImage] = useState("");
    const [hoveredAnnotation, setHoveredAnnotation] = useState(null);

    const [annotatedImageUrl, setAnnotatedImageUrl] = useState('');
    const undoStackRef = useRef([]);
    const redoStackRef = useRef([]);
    const style = {
        button: "text-[#fff] bg-[#4ca3dd] py-[2px] px-2 rounded-[5px]",
    };
    // console.log("annotations: ", annotations);

    const Box = ({ children, geometry, style }) => (
        <div
            style={{
                ...style,
                position: 'absolute',
                left: `${geometry.x}%`,
                top: `${geometry.y}%`,
                height: `${geometry.height}%`,
                width: `${geometry.width}%`,
            }}
        >
            {children}
        </div>
    );

    function renderSelector({ annotation }) {
        const { geometry } = annotation;
        if (!geometry) return null;

        return (
            <Box
                geometry={geometry}
                style={{
                    border: `solid 2px #E10000`,
                    borderRadius: '50%', // Make it look like an ellipse
                }}
            />
        );
    }

    function renderHighlight({ annotation }) {
        const { geometry } = annotation;

        if (!geometry) return null;

        return (
            <Box
                key={annotation.data.id}
                geometry={geometry}
                style={{
                    border: `solid 3px ${'#E10000'}`,
                    borderRadius: '50%', // Make it look like an ellipse
                }}
                onMouseEnter={() => setHoveredAnnotation(annotation)}
                onMouseLeave={() => setHoveredAnnotation(null)}
            />
        );
    }

    function renderContent({ annotation }) {
        const { geometry } = annotation;
        return (
            <div
                key={annotation.data.id}
                style={{
                    background: `${'#C60606'}`,
                    color: 'white',
                    paddingRight: 10,
                    paddingLeft: 10,
                    fontWeight: "bolder",
                    fontSize: 15,
                    position: 'absolute',
                    left: `${geometry.x}%`,
                    top: `${geometry.y - 1}%`,
                }}
            >
                {annotation.data && annotation.data.text}
            </div>
        );
    }

    function renderEditor(props) {
        const { geometry } = props.annotation;
        if (!geometry) return null;

        return (
            <div
                style={{
                    background: 'white',
                    borderRadius: 3,
                    position: 'absolute',
                    left: `${geometry.x}%`,
                    top: `${geometry.y + geometry.height}%`,
                }}
                className="p-2 rounded-[10px] mt-[5px]"
            >
                <input
                    onChange={e => props.onChange({
                        ...props.annotation,
                        data: {
                            ...props.annotation.data,
                            text: e.target.value,
                        },
                    })}
                    placeholder="write a description"
                    className="block mt-1 p-2 focus:outline-none"
                />
                <button onClick={props.onSubmit} className={`${style.button} m-2`}>Comment</button>
            </div>
        );
    }

    const onChange = (newAnnotation) => {
        setAnnotation(newAnnotation);
        // console.log('newAnnotation on change',newAnnotation)
    };

    const onSubmit = (newAnnotation) => {
        const { geometry, data } = newAnnotation;

        redoStackRef.current = [];

        setAnnotation({});
        const newAnnotationData = {
            geometry,
            data: {
                ...data,
                id: Math.random(),
                imageId: selectedImage,
            },
        };

        setAnnotations([...annotations, newAnnotationData]);

        undoStackRef.current.push(annotations);
        setShowDamageForm(true);
    };

    const handleUndo = () => {
        if (undoStackRef.current.length > 0) {
            const lastAnnotations = undoStackRef.current.pop();
            redoStackRef.current.push([...annotations]);
            setAnnotations(lastAnnotations);
        }
    };

    const handleRedo = () => {
        if (redoStackRef.current.length > 0) {
            const nextAnnotations = redoStackRef.current.pop();
            undoStackRef.current.push([...annotations]);
            setAnnotations(nextAnnotations);
        }
    };

    const applyAnnotationsToNewImage = (previousAnnotations) => {
        return previousAnnotations
            .map(anno => ({
                geometry: anno.geometry,
                data: {
                    ...anno.data,
                    id: Math.random(),
                    imageId: selectedImage,
                },
            }));
    };

    const applyAnnotationsToSelectedImage = () => {
        const annotationsForNewImage = applyAnnotationsToNewImage(annotations);
        setAnnotations([...annotations, ...annotationsForNewImage]);
        setAnnotatedImageUrl(UploadImages); // Set the annotated image URL
        setShowModal(true); // Open the modal after applying annotations
    };

    const handleCloseModal = () => {
        setShowModal(false); // Close the modal
    };

    return (
        <div className="px-4">
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Annotated Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flex flex-wrap items-start gap-4 justify-evenly">
                        <div className="mt-6">
                            <p className="text-center text-[20px] font-[600]">Annotate images</p>
                            <div className="flex gap-4 justify-center items-center my-4">
                                <button className={style.button} onClick={handleUndo} type="button">Undo</button>
                                <button className={style.button} onClick={handleRedo} type="button">Redo</button>
                                <button className={style.button} onClick={applyAnnotationsToSelectedImage} type="button">Save</button>
                            </div>
                            <div className="w-full md:w-[400px] m-auto cursor-crosshair">
                                <Annotation
                                    src={UploadImages}
                                    alt="Annotate image"
                                    annotations={annotations.filter((anno) => anno.data.imageId === selectedImage)}
                                    value={annotation}
                                    type={OvalSelector.TYPE} // Ensure OvalSelector is used
                                    className="h-[300px]"
                                    onChange={onChange}
                                    onSubmit={onSubmit}
                                    allowTouch
                                    renderOverlay={() => null}
                                    renderSelector={renderSelector}
                                    renderHighlight={renderHighlight}
                                    renderContent={renderContent}
                                    renderEditor={renderEditor}
                                />
                            </div>
                        </div>
                    </div>


                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Annotator;
