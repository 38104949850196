import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Field, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { updateVehicle } from "../../../../services/AddVehicle";
import { fetchSingleVehicleList } from "../../../../Redux/vehicleSlice";
import { useParams } from "react-router-dom";

function OverviewModal(props) {
  const { singlevehicleData } = useSelector((state) => state.vehicleSlice);
  const { id } = useParams();
  const dispatch = useDispatch();
  const initialValues = {
    vehicle_rented: singlevehicleData?.Overview?.vehicle_rented || "",
    ave_non_time: singlevehicleData?.Overview?.ave_non_time || "",
    current_delta: singlevehicleData?.Overview?.current_delta || "",
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Color</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={async (values) => {
            const tempData = {
              ...singlevehicleData,
              Overview: { general_info: id, ...values },
            };
            const data = await updateVehicle(id, tempData);
            if (data) {
              dispatch(fetchSingleVehicleList(id));
              props.onHide();
            }
          }}
        >
          {({ values, handleChange, errors }) => (
            <Form>
              <div className="row mb-3">
                <div className="col">
                  <label
                    className="form-label mb-1 d-flex justify-content-between align-items-center"
                    htmlFor="category-org"
                  >
                    Vehicle rented
                  </label>
                  <Field
                    name="vehicle_rented"
                    type="number"
                    className="form-control"
                    placeholder="Vehicle rented"
                    aria-controls="DataTables_Table_0"
                  />
                </div>
                <div className="col">
                  <label
                    className="form-label mb-1 d-flex justify-content-between align-items-center"
                    htmlFor="category-org"
                  >
                    Average Non-used Time
                  </label>
                  <Field
                    name="ave_non_time"
                    type="number"
                    className="form-control"
                    placeholder="Average Non-used Time"
                    aria-controls="DataTables_Table_0"
                  />
                </div>
                <div className="col">
                  <label
                    className="form-label mb-1 d-flex justify-content-between align-items-center"
                    htmlFor="category-org"
                  >
                    Current Delta Value
                  </label>
                  <Field
                    name="current_delta"
                    type="number"
                    className="form-control"
                    placeholder="Current Delta Value"
                    aria-controls="DataTables_Table_0"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end gap-3">
                <button
                  type="button"
                  className="btn btn-label-danger waves-effect"
                  onClick={props.onHide}
                >
                  Annuler
                </button>
                <button type="submit" className="btn btn-primary">
                  Mise à jour
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default OverviewModal;
