// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../img/pages/header.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq-header{background:url(${___CSS_LOADER_URL_REPLACEMENT_0___});background-repeat:no-repeat;background-size:cover;min-height:300px !important}.faq-header .input-wrapper{position:relative;width:100%;max-width:55%}@media(max-width: 575.98px){.faq-header .input-wrapper{max-width:70%}}.light-style .bg-faq-section{background-color:rgba(75,75,75,.04)}.dark-style .bg-faq-section{background-color:rgba(134,146,208,.04)}
`, "",{"version":3,"sources":["webpack://./src/assets/vendor/css/pages/page-faq.css"],"names":[],"mappings":"AAAA,YAAY,kDAA+C,CAAC,2BAA2B,CAAC,qBAAqB,CAAC,2BAA2B,CAAC,2BAA2B,iBAAiB,CAAC,UAAU,CAAC,aAAa,CAAC,4BAA4B,2BAA2B,aAAa,CAAC,CAAC,6BAA6B,mCAAmC,CAAC,4BAA4B,sCAAsC","sourcesContent":[".faq-header{background:url(\"../../../img/pages/header.png\");background-repeat:no-repeat;background-size:cover;min-height:300px !important}.faq-header .input-wrapper{position:relative;width:100%;max-width:55%}@media(max-width: 575.98px){.faq-header .input-wrapper{max-width:70%}}.light-style .bg-faq-section{background-color:rgba(75,75,75,.04)}.dark-style .bg-faq-section{background-color:rgba(134,146,208,.04)}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
