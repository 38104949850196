import { Dropdown } from "primereact/dropdown";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addModal } from "../../../services/AddVehicle";
import {
  fetchManufacturerDropdown,
  fetchModalDropdown,
} from "../../../Redux/vehicleSlice";

const AddModel = (props) => {
  const { manufacturersDropdown } = useSelector((state) => state?.vehicleSlice);

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    manuFacturers: "",
    name: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const addModalData = async () => {
    const body = {
      manufacturer: formData?.manuFacturers?._id,
      name: formData?.name,
    };
    await addModal(body);
    props.onHide();
    setFormData("");
    dispatch(fetchManufacturerDropdown());
    dispatch(fetchModalDropdown(formData.manuFacturers._id));
  };

  return (
    <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Ajouter un Modéle
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col">
          <label className="form-label mb-1">Fabriquant</label>
          <Dropdown
            value={formData.manuFacturers}
            onChange={handleInputChange}
            options={manufacturersDropdown}
            name="manuFacturers"
            optionLabel="name"
            placeholder="Sélectionner"
            className="w-full"
          />
        </div>
        <div className="col">
          <label className="form-label mb-1">Nom</label>
          <input
            value={formData.name}
            onChange={handleInputChange}
            name="name"
            type="text"
            className="form-control"
            placeholder="Nom"
            aria-controls="DataTables_Table_0"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-label-secondary waves-effect"
          onClick={props.onHide}
        >
          Fermer
        </button>
        <button
          className="btn btn-primary waves-effect waves-light"
          onClick={addModalData}
        >
          Sauvegarder
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddModel;
