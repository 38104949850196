import React from "react";
import { useSelector } from "react-redux";
import { formatDate } from "../../../Common/FormatDate";

const ActivityTimeline = () => {
  const { customerData } = useSelector((state) => state?.clientsSlice);

  return (
    <>
      <div className="col-xl-12 mb-4 mb-xl-0 mt-4 ">
        <div className="card">
          <div className="d-flex justify-content-between ">
            <div className="d-flex list-details">
              <i className="ti ti-list-details my-auto"></i>
              <h5 className="card-header">Activity Timeline</h5>
            </div>
            <i className="ti ti-dots-vertical ti-sm lh-1 my-auto mx-5"></i>
          </div>
          <div className="card-body pb-0">
            <ul className="timeline mb-0">
              {customerData?.customer?.activityTimeline?.map((item, index) => (
                <li className="timeline-item timeline-item-transparent border-transparent">
                  <span className="timeline-point timeline-point-secondary" />
                  <div className="timeline-event">
                    <div className="timeline-header mb-sm-0 mb-3">
                      <h6 className="mb-0">{item?.activityType}</h6>
                      <span className="text-muted">
                        {formatDate(item?.date)}
                      </span>
                    </div>
                    <p>{item?.details}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityTimeline;
