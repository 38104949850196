// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#icons-container .icon-card{width:128px}#icons-container .icon-card i{font-size:2rem}@media(max-width: 1024px){#icons-container .icon-card{width:126px}}@media(max-width: 767.98px){#icons-container .icon-card{width:131px}}@media(max-width: 414px){#icons-container .icon-card{width:110px}}@media(max-width: 375px){#icons-container .icon-card{width:150px}}
`, "",{"version":3,"sources":["webpack://./src/assets/vendor/css/pages/page-icons.css"],"names":[],"mappings":"AAAA,4BAA4B,WAAW,CAAC,8BAA8B,cAAc,CAAC,0BAA0B,4BAA4B,WAAW,CAAC,CAAC,4BAA4B,4BAA4B,WAAW,CAAC,CAAC,yBAAyB,4BAA4B,WAAW,CAAC,CAAC,yBAAyB,4BAA4B,WAAW,CAAC","sourcesContent":["#icons-container .icon-card{width:128px}#icons-container .icon-card i{font-size:2rem}@media(max-width: 1024px){#icons-container .icon-card{width:126px}}@media(max-width: 767.98px){#icons-container .icon-card{width:131px}}@media(max-width: 414px){#icons-container .icon-card{width:110px}}@media(max-width: 375px){#icons-container .icon-card{width:150px}}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
