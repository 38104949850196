/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../Common/BreadCrumbs";
import { Outlet, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleVehicleList, loader } from "../../../Redux/vehicleSlice";
import ColorModal from "./Modal/ColorModal";
import OverviewModal from "./Modal/OverviewModal";
import GeneralModal from "./Modal/GeneralModal";
import FleetNavbar from "./Layout/FleetNavbar";
import { Loader } from "../../Common/Loader";

const EditFleet = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [modalShow, setModalShow] = useState(false);
  const [isOverView, setOverView] = useState(false);
  const [isColorModalShow, setColorModalShow] = useState(false);
  const { singlevehicleData, singlevehicleDocument, loading } = useSelector(
    (state) => state.vehicleSlice
  );
  useEffect(() => {
    dispatch(loader(true));
    dispatch(fetchSingleVehicleList(id));
  }, [id]);

  return (
    <div className="container-fluid flex-grow-1 container-p-y client">
      <BreadCrumbs title={"Flotte"} subTitle={"Véhicule"} />
      {!loading ? (
        <div className="row">
          <div className="col-xl-4 col-xxl-3">
            <div className="card mb-4">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-2">
                  {singlevehicleData?.General?.color?.image ? (
                    <img
                      className="single-color"
                      src={require(`../../../assets/img/colors/${singlevehicleData?.General?.color?.image}`)}
                      alt=""
                    />
                  ) : (
                    <div></div>
                  )}
                  <div>
                    <button
                      className="btn btn-primary waves-effect waves-light"
                      onClick={() => setColorModalShow(true)}
                    >
                      Modifier
                    </button>
                  </div>
                </div>
                <div className="single-car mb-4">
                  <div>
                    {singlevehicleDocument?.length > 0 && (
                      <img
                        src={`${process.env.REACT_APP_IMG_URL}${singlevehicleDocument?.[0]?.document?.[0]}`}
                        className="img-fluid"
                        alt=""
                      />
                    )}
                  </div>
                  <div className="mx-4 d-flex justify-content-evenly mt-4">
                    <div className="badge badge-center rounded-pill bg-label-success me-2">
                      {singlevehicleData?.General?.active === "Active" ? (
                        <span className="badge badge-center rounded-pill w-px-30 h-px-30 me-2 bg-label-success">
                          <i className="ti ti-sm ti-circle-check"></i>
                        </span>
                      ) : (
                        <span className="badge badge-center rounded-pill w-px-30 h-px-30 me-2 bg-label-danger">
                          <i className="ti ti-sm text-danger"> x</i>
                        </span>
                      )}
                    </div>
                    <div>
                      <h4 className="mb-0 text-center">
                        {singlevehicleData?.General?.manufacturer?.name}{" "}
                        {singlevehicleData?.General?.model?.name}{" "}
                        {singlevehicleData?.General?.declination}
                      </h4>

                      <div className="d-flex justify-content-between gap-2">
                        <div className="text-lighter">
                          {singlevehicleData?.GeneralInfo?.license_plate}
                        </div>
                        <div className="text-lighter">Luxembourg</div>
                      </div>
                    </div>
                    <div className="badge badge-center bg-label-danger ms-2">
                      {singlevehicleData?.General?.vehicle_category?.name}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="card-details">
                  <div className="text-lighter fs-7 py-3">DETAILS</div>
                  <div className="fw-semibold pb-2">
                    Marques :{" "}
                    <span className="fw-normal">
                      {singlevehicleData?.General?.manufacturer?.name}
                    </span>
                  </div>
                  <div className="fw-semibold pb-2">
                    Modèle :{" "}
                    <span className="fw-normal">
                      {" "}
                      {singlevehicleData?.General?.model?.name}
                    </span>
                  </div>

                  <div className="fw-semibold pb-2">
                    Déclinaison :{" "}
                    <span className="fw-normal">
                      {" "}
                      {singlevehicleData?.General?.declination}
                    </span>
                  </div>

                  <div className="fw-semibold pb-2">
                    Type :{" "}
                    <span className="fw-normal">
                      {singlevehicleData?.GeneralInfo?.vi_types}
                    </span>
                  </div>
                  <div className="fw-semibold pb-2">
                    Année :{" "}
                    <span className="fw-normal">
                      {singlevehicleData?.General?.year}
                    </span>
                  </div>
                  <div className="fw-semibold pb-2">
                    Propriétaire :{" "}
                    <span className="fw-normal">
                      {singlevehicleData?.General?.company?.name}
                    </span>
                  </div>
                  <div className="fw-semibold pb-2">
                    Statuts :{" "}
                    <span className="fw-normal">
                      {singlevehicleData?.General?.status}
                    </span>
                  </div>
                  <div className="fw-semibold pb-2">
                    Activité :{" "}
                    <span className="fw-normal">
                      {singlevehicleData?.General?.active}
                    </span>
                  </div>
                  <div className="fw-semibold pb-2">
                    Catégorie du véhicule :{" "}
                    <span className="fw-normal">
                      {singlevehicleData?.General?.vehicle_category?.name}
                    </span>
                  </div>
                  <div className="fw-semibold pb-2">
                    Age Minimum :{" "}
                    <span className="fw-normal">
                      {singlevehicleData?.General?.min_age_driver}
                    </span>
                  </div>
                  <div className="fw-semibold pb-2">
                    Classe Permit de conduire :{" "}
                    <span className="fw-normal">
                      {singlevehicleData?.General?.license_class}
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-center gap-2">
                  <button
                    className="btn btn-primary waves-effect waves-light"
                    onClick={() => setModalShow(true)}
                  >
                    Modifier
                  </button>
                  <button className="btn btn-label-danger waves-effect">
                    Supprimer
                  </button>
                </div>
              </div>
            </div>
            <div className="card mb-4 p-3 px-4">
              <div className="d-flex  align-items-center justify-content-between">
                <div className="text-lighter fs-7">OVERVIEW</div>
                <button
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => setOverView(true)}
                >
                  Modifier
                </button>
              </div>
              <div className="mt-4">
                <div className="d-flex gap-2 pb-2">
                  <i className="ti ti-check"></i>
                  <div className="fw-medium">Vehicle rented:</div>
                  <div>{singlevehicleData?.Overview?.vehicle_rented} Times</div>
                </div>
                <div className="d-flex gap-2 pb-2">
                  <i className="ti ti-brand-nytimes"></i>
                  <div className="fw-medium">Average Non-used Time:</div>
                  <div>{singlevehicleData?.Overview?.ave_non_time} Days</div>
                </div>
                <div className="d-flex gap-2 pb-2">
                  <i className="ti ti-space"></i>
                  <div className="fw-medium">Current Delta Value:</div>
                  <div className="btn btn-label-success waves-effect py-1">
                    + {singlevehicleData?.Overview?.current_delta}€
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-xxl-9">
            <FleetNavbar />

            <Outlet />
            {modalShow && (
              <GeneralModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            )}
          </div>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "75vh" }}
        >
          <Loader />
        </div>
      )}
      {isColorModalShow && (
        <ColorModal
          show={isColorModalShow}
          onHide={() => setColorModalShow(false)}
        />
      )}
      {isOverView && (
        <OverviewModal show={isOverView} onHide={() => setOverView(false)} />
      )}
    </div>
  );
};

export default EditFleet;
