import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import React from "react";
import { Offcanvas } from "react-bootstrap";
import { addPayment } from "../../../services/Invoice";

const AddPayment = (props) => {
  const paymentMethod = [
    "Cash",
    "Bank Transfer",
    "Debit Card",
    "Credit Card",
    "Paypal",
  ];
  const { paymentShow, handleClose, id, singelInvoice } = props;
  const formik = useFormik({
    initialValues: {
      invoice_id: "",
      amount: "",
      pay_date: "",
      pay_method: "",
      internal_payment_notes: "",
    },
    onSubmit: async (values, { resetForm }) => {
      const newValue = { ...values, invoice_id: id };
      const formData = new FormData();
      formData.append("invoice_id", id);
      formData.append("amount", newValue.amount);
      formData.append("pay_date", newValue.pay_date);
      formData.append("pay_method", newValue.pay_method);
      formData.append("status", 2);
      formData.append(
        "internal_payment_notes",
        newValue.internal_payment_notes
      );
      await addPayment(formData);
      await singelInvoice();
      resetForm();
      handleClose();
    },
  });
  return (
    <>
      <Offcanvas
        show={paymentShow}
        onHide={handleClose}
        placement="end"
        {...props}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Payment</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="offcanvas-body mx-0 flex-grow-0 pt-0 h-100">
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-3">
              <label className="form-label" htmlFor="invoiceAmount">
                Payment Amount
              </label>
              <div className="input-group">
                <span className="input-group-text">€</span>
                <input
                  type="number"
                  id="invoiceAmount"
                  name="amount"
                  className="form-control invoice-amount"
                  placeholder="100"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="payment-date">
                Payment Date
              </label>
              <input
                id="payment-date"
                className="form-control invoice-date flatpickr-input"
                type="date"
                name="pay_date"
                value={formik.values.pay_date}
                onChange={formik.handleChange}
              />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="payment-method">
                Payment Method
              </label>
              <Dropdown
                options={paymentMethod}
                placeholder="company"
                className={`w-full`}
                name="pay_method"
                value={formik.values.pay_method}
                onChange={formik.handleChange}
              />
            </div>
            <div className="mb-4">
              <label className="form-label" htmlFor="payment-note">
                Internal Payment Note
              </label>
              <textarea
                className="form-control"
                id="payment-note"
                rows="2"
                name="internal_payment_notes"
                value={formik.values.internal_payment_notes}
                onChange={formik.handleChange}
              ></textarea>
            </div>
            <div className="mb-3 d-flex flex-wrap">
              <button className="btn btn-primary me-3" type="submit">
                Send
              </button>
              <button
                className="btn btn-label-secondary"
                value=""
                type="button"
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
          </form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default AddPayment;
