/* eslint-disable react-hooks/exhaustive-deps */
import { Dropdown } from "primereact/dropdown";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomer } from "../../../services/client/addclientApi";
import {
  fetchSingleCompanyData,
  fetchSingleclientData,
} from "../../../Redux/invoiceSlice";
import { InvoiceContext } from "../invoiceContext";
import { addInvoice } from "../../../services/Invoice";
import { useNavigate } from "react-router-dom";
import { fetchcompanyDropdown } from "../../../Redux/commonSlice";
import { getVehicleDropDown } from "../../../services/Calendar/reservation";
import InvoiceDiscount from "./InviceDiscount";

const InvoiceActions = ({ setOtherValues, setInitialValues }) => {
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [isCustomerList, setCustomerList] = useState([]);
  console.log(
    "🚀 ~ file: InvoiceActions.js:22 ~ InvoiceActions ~ isCustomerList:",
    isCustomerList
  );
  const [isVehicleDropdown, setVehicleDropdown] = useState([]);
  const { isValue, setValue } = useContext(InvoiceContext);
  const [invoiceDiscount, setInvoiceDiscount] = useState(false);
  const [isProformate, setProformate] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { companyDropdown } = useSelector((state) => state?.commonSlice);

  const customerDropdown = async () => {
    try {
      const dropdownData = await getCustomer();

      const formattedOptions = dropdownData?.data
        .map((customer) => ({
          label: `${customer.firstname} ${customer.lastname}`,
          value: customer._id,
          role: customer?.role,
        }))
        .filter((item) => item?.role[0] !== "BASIC");

      setCustomerList(formattedOptions);
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };

  useEffect(() => {
    const updatedValue = {
      ...isValue,
      company_id: selectedCompany,
      customer_id: selectedCustomer,
      proformate: isProformate,
      general_id: selectedVehicle?._id,
    };
    setValue(updatedValue);
    customerDropdown();
    dispatch(fetchcompanyDropdown());
  }, [selectedCompany, selectedCustomer, isProformate, selectedVehicle?._id]);

  //send invoice

  // addInvoice start
  const toSafeguards = async () => {
    const data = await addInvoice(isValue);
    if (data) {
      navigate("/invoice");
    }
  };
  // addInvoice end

  const vehicleDropdown = async () => {
    try {
      const formattedOptions = await getVehicleDropDown(selectedCompany?._id);

      setVehicleDropdown(formattedOptions.data);
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };

  useEffect(() => {
    if (selectedCompany?._id) {
      vehicleDropdown();

      // setGeneralId(selectedCompany?.general_info)
    }
  }, [selectedCompany]);

  return (
    <div className="col-lg-3 col-12 invoice-actions">
      <div className="card mb-4">
        <div className="card-body">
          <button
            className="btn btn-primary d-grid w-100 mb-2 waves-effect waves-light"
            value=""
            type="button"
            onClick={() => setInvoiceDiscount(true)}
          >
            Discount
          </button>
          <button
            className="btn btn-label-success d-grid w-100 mb-2 waves-effect"
            value=""
            type="button"
          >
            Vérifier
          </button>
          <button
            className="btn btn-label-primary d-grid w-100 waves-effect mb-2"
            type="button"
            onClick={toSafeguards}
          >
            Sauvegarder
          </button>
          <button
            className="btn bg-label-warning d-grid w-100 waves-effect"
            type="button"
            onClick={() => {
              setOtherValues({
                billing_date: "",
                due_date: "",
                subtotal_ex_vat: "",
                total_vat: "",
                total_in_vat: "",
                paid: "",
                refunded: "",
                total_pay: "",
                notes: "",
              });
              setInitialValues([]);
              setSelectedCompany([]);
              setSelectedCustomer([]);
              setSelectedVehicle([]);
              dispatch(fetchSingleclientData(""));
              dispatch(fetchSingleCompanyData(""));
            }}
          >
            Recommencer
          </button>
        </div>
      </div>
      <div>
        <p className="mb-1">Société</p>
        <Dropdown
          value={selectedCompany}
          onChange={(e) => {
            setSelectedCompany(e.target.value);

            dispatch(fetchSingleCompanyData(e.value?._id));
          }}
          options={companyDropdown}
          optionLabel="name"
          filter
          placeholder="Company"
          className="w-full mb-3 "
        />
        <p className="mb-1">Client</p>
        <Dropdown
          value={selectedCustomer}
          onChange={(e) => {
            setSelectedCustomer(e.target.value);
            dispatch(fetchSingleclientData(e.value));
          }}
          options={isCustomerList}
          filter
          placeholder="Customer"
          className="w-full mb-3"
        />

        <label className="mb-1">Vehicle</label>
        <Dropdown
          value={selectedVehicle}
          onChange={(e) => {
            setSelectedVehicle(e.value);
          }}
          options={isVehicleDropdown}
          optionLabel="name"
          filter
          placeholder="Vehicle"
          className="w-full mb-3 "
        />

        <div className="d-flex justify-content-between mb-2">
          <label htmlFor="payment-terms" className="mb-0">
            Pro-Format
          </label>
          <label className="switch switch-primary me-0">
            <input
              onChange={(e) => setProformate(e.target.checked)}
              type="checkbox"
              className="switch-input"
              id="ProFormat"
              name="ProFormat"
              checked={isProformate}
            />
            <span className="switch-toggle-slider">
              <span className="switch-on"></span>
              <span className="switch-off"></span>
            </span>
            <span className="switch-label"></span>
          </label>
        </div>
      </div>

      <InvoiceDiscount
        invoiceDiscount={invoiceDiscount}
        handleClose={() => setInvoiceDiscount(false)}
      />
    </div>
  );
};

export default InvoiceActions;
