import React from "react";
import Modal from "react-bootstrap/Modal";

function DeleteModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
          {/* Supprimer le Véhicule */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{props.description}</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={props.onHide}>
          Annuler
        </button>
        <button className="btn btn-danger" onClick={props.deleteData}>
          Supprimer
        </button>
      </Modal.Footer>
    </Modal>
  );
}
export default DeleteModal;
