// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.api-key-actions{position:absolute !important;top:.75rem}html:not([dir=rtl]) .api-key-actions{right:.5rem}[dir=rtl] .api-key-actions{left:.5rem}
`, "",{"version":3,"sources":["webpack://./src/assets/vendor/css/pages/page-account-settings.css"],"names":[],"mappings":"AAAA,iBAAiB,4BAA4B,CAAC,UAAU,CAAC,qCAAqC,WAAW,CAAC,2BAA2B,UAAU","sourcesContent":[".api-key-actions{position:absolute !important;top:.75rem}html:not([dir=rtl]) .api-key-actions{right:.5rem}[dir=rtl] .api-key-actions{left:.5rem}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
