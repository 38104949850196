/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addClientApi } from "../../services/client/addclientApi";
import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/bootstrap.css";
import { CustomerType } from "../Common/DropdownValue";
import { fetchAllUserList } from "../../Redux/commonSlice";
import ChangeRole from "./Modals/ChangeRole";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import { schengenCountries } from "../Common/AddressAutocompleteInput";
const libraries = ["places"];

const AddClient = (props) => {
  const { companyDropdown } = useSelector((state) => state?.commonSlice);
  const { show, onHide } = props;
  const [isLoding, setLoding] = useState(null);
  const [isModale, setModale] = useState(false);
  const [isData, setData] = useState("");
  const [autocomplete, setAutocomplete] = useState("");

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCfC8Izlpa7eb4Rae3EtA5-WcmrHr8BHAA",
    libraries,
  });
  const dispatch = useDispatch();

  //  google setAutocomplete start
  const handleLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const handlePlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      let address = "";

      const addressComponents = place?.address_components;
      if (addressComponents) {
        const uniqueComponents = new Set(
          addressComponents
            .filter((component) => !component.types.includes("street_number"))
            .map((component) => component.long_name)
        );

        // Join the unique components to form the full address
        address = Array.from(uniqueComponents).join(", ");
        const fullAddress = address?.includes(place?.name)
          ? address
          : place?.name + ", " + address;
        formik.setFieldValue("address", fullAddress);
      }
    }
  };
  // google setAutocomplete end

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastname: "",
      invoiceEmail: "",
      email: "",
      contact: "",
      address: "",
      buildingNumber: "",
      customerType: "",
      vatNumber: "",
      business: "",
      comp_id: "",
      gender: "",
      language: "",
    },

    onSubmit: async (values, { resetForm }) => {
      setLoding(true);
      const newValues = {
        ...values,
        comp_id: values?.comp_id._id,
        subscription: values?.subscription?._id
          ? values?.subscription?._id
          : "",
      };
      const formData = new FormData();
      formData.append("firstname", newValues.firstName);
      formData.append("lastname", newValues.lastname);
      formData.append("invoice_email", newValues.invoiceEmail);
      formData.append("email", newValues.email);
      formData.append("comp_id", newValues.comp_id);
      formData.append("cust_type", newValues.customerType);
      if (formik.values.customerType === "Entreprise") {
        formData.append("business", newValues.business);
        formData.append("vat_number", newValues.vatNumber);
        formData.append("company[buildingNumber]", newValues.buildingNumber);
        formData.append("company[company_address]", newValues.address);
      } else {
        formData.append("houseno", newValues.buildingNumber);
        formData.append("address", newValues.address);
      }
      formData.append("contact", newValues.contact);
      if (formik.values.customerType === "Particulier") {
        formData.append("gender", newValues.gender);
      }
      formData.append("language", newValues.language);
      formData.append("beclient", "true");
      formData.append("role", "CLIENT");

      const data = await addClientApi(formData);
      if (
        data?.response?.data?.msg ===
        "Email already exists with a BASIC role. Do you want to upgrade it to CLIENT role?"
      ) {
        setData({
          id: data?.response?.data?.data?.existingCustomer?._id,
          msg: data?.response?.data?.msg,
          formData: formData,
        });
        onHide();
        setModale(true);
      }
      if (data?.status) {
        resetForm();
        onHide();
      }
      setLoding(false);
      dispatch(fetchAllUserList());
    },

    validate: (values) => {
      const errors = {};
      if (!values.firstName.trim()) {
        errors.firstName = "This field is required.";
      }
      if (!values.lastname.trim()) {
        errors.lastname = "This field is required.";
      }
      if (!values.email.trim()) {
        errors.email = "This field is required.";
      }
      if (!values.comp_id?._id?.toString().trim()) {
        errors.comp_id = "This field is required.";
      }
      if (!values.contact?.trim()) {
        errors.contact = "This field is required.";
      }
      if (!values.buildingNumber?.toString().trim()) {
        errors.buildingNumber = "This field is required.";
      }
      if (values?.customerType === "Entreprise") {
        if (!values.business?.toString().trim()) {
          errors.business = "This field is required.";
        }
        if (!values.vatNumber?.toString().trim()) {
          errors.vatNumber = "This field is required.";
        }
      }
      return errors;
    },
  });

  useEffect(() => {
    formik.resetForm();
  }, [show]);
  if (loadError) return <></>;
  if (!isLoaded) return <></>;
  return (
    <>
      {show && <div className="fade c-offcanvas-backdrop show"></div>}
      <div
        className={`c-offcanvas offcanvas-end ${show ? "c-show" : ""}`}
        tabIndex={-1}
        id="offcanvasEnd"
        aria-labelledby="offcanvasEndLabel"
        aria-modal="true"
        role="dialog"
      >
        <div className="c-offcanvas-header">
          <h5 id="offcanvasEndLabel" className="offcanvas-title">
            Ajouter un Client
          </h5>
          <button
            onClick={() => onHide()}
            type="button"
            className="btn-close text-reset"
            aria-label="Close"
          />
        </div>
        <div className="c-offcanvas-body my-auto mx-0 flex-grow-0">
          <div className="add-new-user pt-0" id="addNewUserForm">
            <div className="mb-3">
              <label className="form-label" htmlFor=" Type de Client">
                Type de Client
              </label>
              <Dropdown
                options={CustomerType}
                placeholder="Type"
                className="w-full"
                name="customerType"
                value={formik.values.customerType}
                onChange={formik.handleChange}
              />
            </div>

            {formik.values.customerType === "Particulier" && (
              <>
                <div className="mb-3 d-flex gap-3">
                  <div>
                    <label className="form-label" htmlFor="add-user-fullname">
                      Prénom
                    </label>
                    <input
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="text"
                      className={`form-control ${
                        formik.touched.firstName && formik.errors.firstName
                          ? "form-control-invalid"
                          : ""
                      }`}
                      id="add-user-fullname"
                      placeholder="Prénom"
                      name="firstName"
                      aria-label="John"
                    />
                    {formik.touched.firstName && formik.errors.firstName && (
                      <div className="invalid-feedback">
                        {formik.errors.firstName}
                      </div>
                    )}
                  </div>
                  <div className="">
                    <label className="form-label" htmlFor="add-user-email">
                      Nom de famille
                    </label>
                    <input
                      value={formik.values.lastname}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="text"
                      placeholder="Nom de famille"
                      aria-label="doe"
                      name="lastname"
                      className={`form-control ${
                        formik.touched.lastname && formik.errors.lastname
                          ? "form-control-invalid"
                          : ""
                      }`}
                      id="add-user-lastname"
                    />
                    {formik.touched.lastname && formik.errors.lastname && (
                      <div className="invalid-feedback">
                        {formik.errors.lastname}
                      </div>
                    )}
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label" htmlFor="add-user-email">
                    E-mail
                  </label>
                  <input
                    type="email"
                    id="add-user-email"
                    placeholder="@turismo.com"
                    name="email"
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      const lowerCaseEmail = e.target.value.toLowerCase();
                      formik.setFieldValue("email", lowerCaseEmail);
                    }}
                    className={`form-control ${
                      formik.touched.email && formik.errors.email
                        ? "form-control-invalid"
                        : ""
                    }`}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="invalid-feedback">
                      {formik.errors.email}
                    </div>
                  )}
                </div>
              </>
            )}

            {formik.values.customerType === "Entreprise" && (
              <>
                <div className="mb-3">
                  <label className="form-label" htmlFor="add-user-email">
                    Nom de la Société
                  </label>
                  <input
                    type="text"
                    id="add-user-email"
                    className={`form-control ${
                      formik.touched.business && formik.errors.business
                        ? "form-control-invalid"
                        : ""
                    }`}
                    placeholder="Nom"
                    name="business"
                    value={formik.values.business}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.business && formik.errors.business && (
                    <div className="invalid-feedback">
                      {formik.errors.business}
                    </div>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label" htmlFor="add-user-email">
                    Numéro de TVA
                  </label>
                  <input
                    type="text"
                    id="add-user-email"
                    className={`form-control ${
                      formik.touched.vatNumber && formik.errors.vatNumber
                        ? "form-control-invalid"
                        : ""
                    }`}
                    placeholder="LU000"
                    name="vatNumber"
                    value={formik.values.vatNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.vatNumber && formik.errors.vatNumber && (
                    <div className="invalid-feedback">
                      {formik.errors.vatNumber}
                    </div>
                  )}
                </div>
              </>
            )}
            <div className="mb-3">
              <label className="form-label" htmlFor="add-user-invoiceEmail">
                Invoicing Email
              </label>
              <input
                type="email"
                id="add-user-invoiceEmail"
                placeholder="Email"
                aria-label="john.doe@example.com"
                name="invoiceEmail"
                value={formik.values.invoiceEmail}
                onChange={(e) => {
                  const lowerCaseEmail = e.target.value.toLowerCase();
                  formik.setFieldValue("invoiceEmail", lowerCaseEmail);
                }}
                onBlur={formik.handleBlur}
                className={`form-control ${
                  formik.touched.invoiceEmail && formik.errors.invoiceEmail
                    ? "form-control-invalid"
                    : ""
                }`}
              />
              {formik.touched.invoiceEmail && formik.errors.invoiceEmail && (
                <div className="invalid-feedback">
                  {formik.errors.invoiceEmail}
                </div>
              )}
            </div>
            {formik.values.customerType === "Particulier" && (
              <>
                <div className="mb-3">
                  <label className="form-label" htmlFor="add-user-email">
                    Contact
                  </label>
                  <PhoneInput
                    country={"Luxembourg"}
                    enableSearch={true}
                    className={`form-control ${
                      formik.touched.contact && formik.errors.contact
                        ? "form-control-invalid"
                        : ""
                    }`}
                    placeholder="+352 369 852 472"
                    value={"+352" || formik.values.contact}
                    onChange={(value) => formik.setFieldValue("contact", value)}
                  />

                  {formik.touched.contact && formik.errors.contact && (
                    <div className="invalid-feedback">
                      {formik.errors.contact}
                    </div>
                  )}
                </div>
              </>
            )}
            <div className="mb-3">
              <label className="form-label" htmlFor="add-user-email">
                Membre
              </label>
              <Dropdown
                options={companyDropdown}
                placeholder="Société"
                className={`w-full ${
                  formik.touched.comp_id && formik.errors.comp_id
                    ? "dropdown-invalid"
                    : ""
                }`}
                name="comp_id"
                optionLabel="name"
                value={formik.values.comp_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <ErrorMessage formik={formik} name="comp_id" />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="add-user-contact">
                Address
              </label>
              <Autocomplete
                onLoad={handleLoad}
                onPlaceChanged={handlePlaceChanged}
                options={{
                  componentRestrictions: { country: schengenCountries },
                }}
              >
                <input
                  type="text"
                  className="form-control mt-0"
                  placeholder="Address"
                  name="address"
                  autoComplete="off"
                  onChange={formik.handleChange}
                  defaultValue={formik.values.address}
                  style={{ width: "100%" }}
                />
              </Autocomplete>
            </div>

            <div className="mb-3">
              <label className="form-label" htmlFor="add-user-contact">
                Street/Building Number
              </label>
              <input
                type="text"
                id="add-user-contact"
                autoComplete="off"
                className={`form-control ${
                  formik.touched.buildingNumber && formik.errors.buildingNumber
                    ? "form-control-invalid"
                    : ""
                }`}
                placeholder="Street/Building Number"
                name="buildingNumber"
                value={formik.values.buildingNumber}
                onChange={formik.handleChange}
              />
              <ErrorMessage formik={formik} name="buildingNumber" />
            </div>

            <div className="mb-3">
              <label className="form-label" htmlFor="add-user-email">
                Language
              </label>
              <Dropdown
                options={["FR", "EN"]}
                placeholder="Language"
                className="w-full"
                name="language"
                value={formik.values.language}
                onChange={formik.handleChange}
              />
            </div>

            {formik.values.customerType === "Particulier" && (
              <div className="mb-3">
                <label className="form-label" htmlFor="add-user-email">
                  Gender
                </label>
                <Dropdown
                  options={["Male", "Female", "Other"]}
                  placeholder="Gender"
                  className="w-full"
                  name="gender"
                  value={formik.values.gender}
                  onChange={formik.handleChange}
                />
              </div>
            )}

            {(formik.values.customerType === "Entreprise" ||
              formik.values.customerType === "") && (
              <>
                <hr className="mb-2" />

                <div className="mb-3 d-flex gap-3">
                  <div>
                    <label className="form-label" htmlFor="add-user-fullname">
                      Prénom
                    </label>
                    <input
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="text"
                      className={`form-control ${
                        formik.touched.firstName && formik.errors.firstName
                          ? "form-control-invalid"
                          : ""
                      }`}
                      id="add-user-fullname"
                      placeholder="Prénom"
                      name="firstName"
                      aria-label="John"
                    />

                    <ErrorMessage formik={formik} name="firstName" />
                  </div>
                  <div className="">
                    <label className="form-label" htmlFor="add-user-email">
                      Nom de famille
                    </label>
                    <input
                      value={formik.values.lastname}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="text"
                      placeholder="Nom de famille"
                      aria-label="doe"
                      name="lastname"
                      className={`form-control ${
                        formik.touched.lastname && formik.errors.lastname
                          ? "form-control-invalid"
                          : ""
                      }`}
                      id="add-user-lastname"
                    />
                    <ErrorMessage formik={formik} name="lastname" />
                  </div>
                </div>

                <div className="mb-3">
                  <label className="form-label" htmlFor="add-user-email">
                    E-mail
                  </label>
                  <input
                    type="email"
                    id="add-user-email"
                    placeholder="@turismo.com"
                    aria-label="john.doe@example.com"
                    name="email"
                    value={formik.values.email}
                    onChange={(e) => {
                      const lowerCaseEmail = e.target.value.toLowerCase();
                      formik.setFieldValue("email", lowerCaseEmail);
                    }}
                    onBlur={formik.handleBlur}
                    className={`form-control ${
                      formik.touched.email && formik.errors.email
                        ? "form-control-invalid"
                        : ""
                    }`}
                  />
                  <ErrorMessage formik={formik} name="email" />
                </div>
                <div className="mb-3">
                  <label className="form-label" htmlFor="add-user-email">
                    Contact
                  </label>
                  <PhoneInput
                    country={"Luxembourg"}
                    enableSearch={true}
                    className={`form-control ${
                      formik.touched.contact && formik.errors.contact
                        ? "form-control-invalid"
                        : ""
                    }`}
                    placeholder="+352 369 852 472"
                    value={"+352" || formik.values.contact}
                    onChange={(value) => formik.setFieldValue("contact", value)}
                  />
                  <ErrorMessage formik={formik} name="contact" />
                </div>
              </>
            )}

            <button
              type="button"
              onClick={formik.handleSubmit}
              className="btn btn-primary me-sm-3 me-1 data-submit mb-4"
            >
              {isLoding && (
                <span
                  class="spinner-border text-white me-2 "
                  role="status"
                ></span>
              )}
              Ajouter
            </button>
            <button
              type="reset"
              className="btn btn-label-secondary mb-4"
              onClick={() => onHide()}
            >
              Annuler
            </button>
            <div className="mb-5" />
          </div>
        </div>
      </div>
      {show && (
        <div
          className={`c-offcanvas-backdrop fade show ${show ? "c-width" : ""}`}
          onClick={() => onHide()}
        ></div>
      )}
      <ChangeRole
        isData={isData}
        show={isModale}
        onHide={() => setModale(false)}
      />
    </>
  );
};

export default AddClient;

const ErrorMessage = ({ formik, name }) => (
  <>
    {formik.touched[name] && formik.errors[name] && (
      <div className="invalid-feedback">{formik.errors[name]}</div>
    )}
  </>
);
