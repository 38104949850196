import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { updateVehicle } from "../../../../../services/AddVehicle";
import { useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { SmallLoader } from "../../../../Common/Loader";
import { useParams } from "react-router-dom";

const FinancialInformation = () => {
  const [isEdit, setEdit] = useState(true);
  const [isLoding, setLoding] = useState(false);
  const { singlevehicleData } = useSelector((state) => state.vehicleSlice);
  const { financetypeDropdownList } = useSelector((state) => state.commonSlice);
  const { id } = useParams();
  const financialInfo = {
    registered_by: singlevehicleData?.FinancialInfo?.registered_by || "",
    finance_type: singlevehicleData?.FinancialInfo?.finance_type || "",
    purchase_option: singlevehicleData?.FinancialInfo?.purchase_option,
    funded_by: singlevehicleData?.FinancialInfo?.funded_by || "",
    soon_suite: singlevehicleData?.FinancialInfo?.soon_suite || "",
  };

  return (
    <Formik
      initialValues={financialInfo}
      enableReinitialize={true}
      onSubmit={async (values) => {
        setLoding(true);
        const tempData = { ...singlevehicleData, FinancialInfo: values };
        const data = await updateVehicle(id, tempData);
        if (data) {
          setEdit(true);
          setLoding(false);
        }
      }}
    >
      {({ values, errors, handleChange }) => (
        <Form className="card mb-4">
          <div className="card-header d-flex justify-content-between">
            <h5 className="card-tile mb-0">Information financière</h5>
            {isEdit ? (
              <div
                onClick={() => setEdit(false)}
                className="btn btn-primary waves-effect waves-light"
              >
                Modifier
              </div>
            ) : (
              <button
                type="submit"
                className="btn btn-primary waves-effect waves-light"
              >
                Save {isLoding && <SmallLoader />}
              </button>
            )}
          </div>
          <div className="card-body">
            <div className="row mb-4">
              <div className="col">
                <h6>Enregistrer Par</h6>
                <Field
                  readOnly={isEdit}
                  name="registered_by"
                  placeholder="Type"
                  className={` ${isEdit ? "hide-input" : "form-control"} ${
                    errors.FinancialInfo && "dropdown-invalid"
                  }`}
                />
              </div>
              <div className="col">
                <h6>Type of Financing</h6>
                <Dropdown
                  name="finance_type"
                  value={values.finance_type}
                  onChange={handleChange}
                  options={financetypeDropdownList}
                  optionLabel="name"
                  filter
                  placeholder="Type"
                  className={`w-full ${
                    isEdit ? "p-dropdown-hide" : ".p-dropdown"
                  } ${errors?.main_fuel && "dropdown-invalid"}`}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h6>Financed By</h6>
                <Field
                  readOnly={isEdit}
                  name="funded_by"
                  placeholder="Type"
                  className={` ${isEdit ? "hide-input" : "form-control"} ${
                    errors.funded_by && "dropdown-invalid"
                  }`}
                />
              </div>
              <div className="col">
                <h6>Prix D’achat</h6>
                <Field
                  readOnly={isEdit}
                  name="soon_suite"
                  placeholder="Type"
                  className={` ${isEdit ? "hide-input" : "form-control"} ${
                    errors.soon_suite && "dropdown-invalid"
                  }`}
                />
              </div>
              <div className="col">
                <h6>Option D’achat</h6>
                <Dropdown
                  name="purchase_option"
                  value={values.purchase_option}
                  onChange={handleChange}
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                  placeholder=""
                  className={`w-full ${
                    isEdit ? "p-dropdown-hide" : ".p-dropdown"
                  } ${errors?.purchase_option && "dropdown-invalid"}`}
                />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FinancialInformation;
