/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Bill from "./Bill";
import { Form, Formik } from "formik";
import { invoiceNumber } from "../../../services/Invoice";
import InvoiceActions from "./InvoiceActions";

const AddInvoice = () => {
  const [isInitialValues, setInitialValues] = useState([
    {
      item_name: "",
      item_desc: "",
      price: "",
      length: "",
      qty: "",
      p_vat: "",
      vat: "",
      total_ex_vat: "",
      total: "",
      period:"",
    },
  ]);

  const [otherValues, setOtherValues] = useState({
    invoice_id: "",
    billing_date: "",
    due_date: "",
    subtotal_ex_vat: "",
    total_vat: "",
    total_in_vat: "",
    paid: "",
    refunded: "",
    total_pay: "",
    notes: "",
    proformate: "",
    total: "",
  });

  const getInvoiceNumber = async () => {
    const number = await invoiceNumber();
    setOtherValues({ ...otherValues, invoice_id: number?.data });
  };

  useEffect(() => {
    getInvoiceNumber();
  }, []);
  return (
    <div className="content-wrapper">
      <div className="container-fluid flex-grow-1 container-p-y">
        <Formik enableReinitialize={true}>
          {(props) => {
            return (
              <Form>
                <div className="row invoice-edit">
                  <Bill
                    isInitialValues={isInitialValues}
                    setInitialValues={setInitialValues}
                    otherValues={otherValues}
                    setOtherValues={setOtherValues}
                  />
                  <InvoiceActions
                    setInitialValues={setInitialValues}
                    setOtherValues={setOtherValues}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddInvoice;
