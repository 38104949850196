// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setting .active-btn {
  background-color: var(--bs-primary);
  color: white !important;
}
.setting td {
  border: transparent !important;
}
.setting .thead {
  border-bottom: 1px solid rgb(231, 227, 227) !important;
}
.setting .input-group-merge .form-control:not(:last-child) {
  border-radius: 10px !important;
}
#template-customizer .template-customizer-open-btn {
  /* opacity: 0 !important; */
  display: none !important;
}
.avatar-img {
  width: 60px;
  border-radius: 50%;
}
.btn-company {
  border-radius: 6px 0 0 6px !important;
}
.btn-subscription {
  border-radius: 0 6px 6px 0 !important;
}
.setting a {
  border: 0 !important;
  box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3) !important;
  color: var(--bs-primary) !important;
}
.active .btn-active {
  background-color: var(--bs-primary) !important;
  color: white;
}

.a {
  width: 0;
  transition: all 0.5s ease-out;
}
.a .aactive {
  width: 200px;
}
`, "",{"version":3,"sources":["webpack://./src/css/setting.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,uBAAuB;AACzB;AACA;EACE,8BAA8B;AAChC;AACA;EACE,sDAAsD;AACxD;AACA;EACE,8BAA8B;AAChC;AACA;EACE,2BAA2B;EAC3B,wBAAwB;AAC1B;AACA;EACE,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,qCAAqC;AACvC;AACA;EACE,qCAAqC;AACvC;AACA;EACE,oBAAoB;EACpB,kEAAkE;EAClE,mCAAmC;AACrC;AACA;EACE,8CAA8C;EAC9C,YAAY;AACd;;AAEA;EACE,QAAQ;EACR,6BAA6B;AAC/B;AACA;EACE,YAAY;AACd","sourcesContent":[".setting .active-btn {\n  background-color: var(--bs-primary);\n  color: white !important;\n}\n.setting td {\n  border: transparent !important;\n}\n.setting .thead {\n  border-bottom: 1px solid rgb(231, 227, 227) !important;\n}\n.setting .input-group-merge .form-control:not(:last-child) {\n  border-radius: 10px !important;\n}\n#template-customizer .template-customizer-open-btn {\n  /* opacity: 0 !important; */\n  display: none !important;\n}\n.avatar-img {\n  width: 60px;\n  border-radius: 50%;\n}\n.btn-company {\n  border-radius: 6px 0 0 6px !important;\n}\n.btn-subscription {\n  border-radius: 0 6px 6px 0 !important;\n}\n.setting a {\n  border: 0 !important;\n  box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3) !important;\n  color: var(--bs-primary) !important;\n}\n.active .btn-active {\n  background-color: var(--bs-primary) !important;\n  color: white;\n}\n\n.a {\n  width: 0;\n  transition: all 0.5s ease-out;\n}\n.a .aactive {\n  width: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
