import { toast } from "react-toastify";
import axiosInstanceAuth from "../../apiInstances/axiosInstanceAuth";
import axios from "axios";

export const getChecklist = async () => {
  try {
    const response = await axiosInstanceAuth.get(`check`);
    // console.log("SDasdasdasdasdasd", response.data);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
// export const getDeliverylist = async () => {
//   try {
//     const response = await axiosInstanceAuth.get(`/reservation/filter/reservationByDeliveryStatus`);
//     console.log("response.data delivery", response.data);
//     return response.data;
//   } catch (error) {
//     toast.error(error?.response?.data?.msg, {
//       position: toast.POSITION.TOP_RIGHT,
//     });
//   }
// };

export const getnewChecklist = async () => {
  try {
    const response = await axiosInstanceAuth.get(`/reservation/filter/reservationByCheckStatus`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const getListCheckin = async () => {
  try {
    const response = await axiosInstanceAuth.get("check/listdata/checkin");
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const getSingleCheck = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(`check/${id}`);
    return response.data;
  } catch (error) {
    console.log("Error in getSingleCheck", error?.response?.data?.msg);
  }
};

export const listdataExteriorParts = async () => {
  try {
    const response = await axiosInstanceAuth.get(
      `check/listdata/exterior-parts`
    );
    return response.data;
  } catch (error) {
    console.log("Error in getSingleCheck", error?.response?.data?.msg);
  }
};

export const listDataExteriorStripe = async () => {
  try {
    const response = await axiosInstanceAuth.get(
      `check/listdata/exterior-stripe`
    );
    return response.data;
  } catch (error) {
    console.log("Error in getSingleCheck", error?.response?.data?.msg);
  }
};
export const listDataExteriorElement = async () => {
  try {
    const response = await axiosInstanceAuth.get(
      `check/listdata/exterior-element`
    );
    return response.data;
  } catch (error) {
    console.log("Error in getSingleCheck", error?.response?.data?.msg);
  }
};
export const listDataInteriorParts = async () => {
  try {
    const response = await axiosInstanceAuth.get(
      `check/listdata/interior-parts`
    );
    return response.data;
  } catch (error) {
    console.log("Error in getSingleCheck", error?.response?.data?.msg);
  }
};
export const listDataInteriorStripe = async () => {
  try {
    const response = await axiosInstanceAuth.get(
      `check/listdata/interior-stripe`
    );
    return response.data;
  } catch (error) {
    console.log("Error in getSingleCheck", error?.response?.data?.msg);
  }
};

export const checkAdd = async (body) => {
  try {
    const response = await axiosInstanceAuth.post(`check/add`, body);
    toast.success(response?.data?.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response.data;
  } catch (error) {
    console.log("checkAdd", error?.response?.data?.msg);
  }
};
export const checkInAdd = async (body) => {
  try {
    const response = await axiosInstanceAuth.post(`check/addcheckin`, body);
    toast.success(response?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response.data;
  } catch (error) {
    console.log("checkInAdd", error?.response?.data?.msg);
  }
};

export const checkOutAddFinal = async (body) => {
  try {
    const response = await axiosInstanceAuth.post(`finalCheck/createFinalCheck`, body);
    // toast.success(response?.msg, {
    //   position: toast.POSITION.TOP_RIGHT,
    // });
    return response.data;
  } catch (error) {
    console.log("checkOutAdd", error?.response?.data?.msg);
  }
};

export const checkCreateImage = async (body) => {
  try {
    const response = await axiosInstanceAuth.post(`check/createCheckImage`, body);
    // toast.success(response?.data?.message, {
    //   position: toast.POSITION.TOP_RIGHT,
    // });
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    console.log("checkAdd", error?.response?.data?.msg);
  }
};

export const getSingleCheckData = async (body) => {
  try {
    const response = await axiosInstanceAuth.post(`check/listCheckImage`, body);
    return response.data;
  } catch (error) {
    console.log("Error in getSingleCheck", error?.response?.data?.msg);
  }
};

export const checkCreateAnnotate = async (body) => {
  try {
    const response = await axiosInstanceAuth.post(`annotate/createAnnotate`, body);
    return response.data;
  } catch (error) {
    console.log("checkAdd", error?.response?.data?.msg);
  }
};

export const getImageById = async (body) => {
  try {
    const response = await axiosInstanceAuth.post(`check/getCheckImageById`, body);
    return response.data;
  } catch (error) {
    console.log("Error in getSingleCheck", error?.response?.data?.msg);
    return null
  }
};

export const createDamgeImage = async (body) => {
  try {
    const response = await axiosInstanceAuth.post(`check/add/damage-images`, body);
    toast.success(response?.data?.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response.data;
  } catch (error) {
    console.log("checkAdd", error?.response?.data?.msg);
  }
};

export const getDamageById = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(
      `check/listdemage-images/${id}`
    );
    return response.data;
  } catch (error) {
    console.log("Error in getSingleCheck", error?.response?.data?.msg);
  }
};

export const repairDamageById = async (id,body) => {
  try {
    const response = await axiosInstanceAuth.put(`check/update/damage-images/${id}`, body);
    toast.success(response?.data?.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response.data;
  } catch (error) {
    console.log("Error in getSingleCheck", error?.response?.data?.msg);
  }
};

export const getCheckFinal = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(
      `finalCheck/getFinalCheckById/${id}`
    );
    return response.data;
  } catch (error) {
    console.log("Error in getSingleCheck", error?.response?.data?.msg);
  }
};

export const checkStatusUpdate = async (body) => {
  try {
    const response = await axiosInstanceAuth.put(`reservation/updateReservationStatus`, body);
    toast.success(response?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response.data;
  } catch (error) {
    console.log("checkOutAdd", error?.response?.data?.msg);
  }
};
