import React, { useEffect, useRef, useState } from "react";
import logo from "../../../assets/img/icons/logo/Turismo.svg";
import { invoiceById } from "../../../services/Invoice";
import { NavLink, useParams } from "react-router-dom";
import { fetchSingleCompanyData } from "../../../Redux/invoiceSlice";
import { useDispatch, useSelector } from "react-redux";
import SendInvoice from "./SendInvoice";
import AddPayment from "./AddPayment";
import { CenterLoader } from "../../Common/Loader";
import { numberWithEuroFormat } from "../../Common/Currency";
import { formatDate } from "./../../Common/FormatDate";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { MyInvoicePDFDoc } from "./InvoicePDF";

const ReviewInvoice = () => {
  const { id } = useParams();
  const pdfRef = useRef();
  const dispatch = useDispatch();
  const [isSingleInvoice, setSingleInvoice] = useState("");
  const [invoiceShow, setInvoiceShow] = useState(false);
  const [paymentShow, setPaymentShow] = useState(false);
  const { singleCompanyData } = useSelector((state) => state?.invoiceSlice);

  const singelInvoice = async () => {
    const data = await invoiceById(id);
    setSingleInvoice(data?.data);
  };

  useEffect(() => {
    singelInvoice();
  }, []);

  useEffect(() => {
    if (isSingleInvoice) {
      dispatch(
        fetchSingleCompanyData(isSingleInvoice?.invoice?.company_id?._id)
      );
    }
  }, [isSingleInvoice]);

  const total = (
    isSingleInvoice?.invoice?.subtotal_ex_vat +
    isSingleInvoice?.invoice?.total_vat -
    // - isSingleInvoice?.invoice?.paid -
    isSingleInvoice?.invoice?.discount
  )?.toFixed(2);

  return (
    <div className="content-wrapper">
      {!isSingleInvoice ? (
        <CenterLoader />
      ) : (
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="row">
            <div className=" col-xl-9 col-12 mb-md-0 mb-4">
              <div className="card invoice-preview-card">
                <div
                  className="card-body hide-from-printer"
                  ref={pdfRef}
                  id="template-content"
                >
                  <div className="d-md-flex justify-content-md-between my-sm-4 m-0 mx-0 invoice-header">
                    <div className="mb-md-0 mb-4 ps-0">
                      <div className="d-flex svg-illustration mb-md-5 align-items-center">
                        <img
                          width={150}
                          className="img-fluid"
                          src={logo}
                          alt="logo-not-found"
                        />
                      </div>
                    </div>
                    <div className=" text-md-end p-0 m-0 mb-3">
                      <span
                        id="FactureProForma"
                        className="h2 text-capitalize mb-0 text-nowrap m-0"
                      >
                        {/* {isValue?.proformate ? "Facture Pro-format" : "Facture"} */}
                        {isSingleInvoice?.invoice?.proformate
                          ? "Facture Pro-format"
                          : "Facture"}
                      </span>
                    </div>
                  </div>

                  <div className="row m-sm-4 m-0 invoice-info">
                    <div className="col-md-5 mb-md-0 mb-4 ps-0">
                      <p className="mb-3 fw-bold">
                        {singleCompanyData?.company?.name}
                      </p>
                      <p className="mb-2">
                        {singleCompanyData?.billing?.number}
                        {singleCompanyData?.billing?.number && ","}
                        {singleCompanyData?.billing?.street}
                      </p>
                      <p className="mb-2">
                        {singleCompanyData?.billing?.province}
                        {singleCompanyData?.billing?.province && ","}

                        {singleCompanyData?.billing?.postal}
                        {singleCompanyData?.billing?.postal && ","}
                        {isSingleInvoice?.invoice.customer_id?.city}
                      </p>
                      <p className="mb-4">
                        {isSingleInvoice?.invoice.customer_id?.country}
                      </p>
                    </div>
                    <div className="col-md-5 col-sm-5 col-12 mb-sm-0 mb-4">
                      <p className="mb-3 fw-bold">
                        {isSingleInvoice?.invoice?.customer_id?.firstname}{" "}
                        {isSingleInvoice?.invoice?.customer_id?.lastname}
                      </p>
                      <p className="mb-2">
                        {isSingleInvoice?.invoice.customer_id?.address}
                      </p>
                      <p className="mb-2">
                        {singleCompanyData?.billing?.city}{" "}
                        {singleCompanyData?.billing?.city && ","}
                        {singleCompanyData?.billing?.country}
                      </p>

                      <p className="mb-1">
                        {isSingleInvoice?.invoice?.customer_id?.email}
                        {isSingleInvoice?.invoice?.customer_id?.email && ","}
                        {isSingleInvoice?.invoice.customer_id?.contact}
                      </p>
                    </div>
                  </div>
                  <hr className="py-3 mx-n4" />

                  <div className="border py-4 row mx-0">
                    <div className="d-flex  position-relative pe-0">
                      <div className="row w-100 px-3">
                        <div className="col flex-grow-1">
                          <p className="mb-2 repeater-title">
                            Numéro de Facture
                          </p>
                          <div className="input-group disabled w-px-150 has-validation">
                            <span className="input-group-text">#</span>
                            <input
                              type="number"
                              className="form-control is-valid"
                              id="InvoiceNo"
                              name="invoice_id"
                              Value={isSingleInvoice?.invoice?.invoice_id}
                            />
                          </div>
                        </div>
                        <div className="col flex-grow-1">
                          <p className="mb-2 repeater-title">
                            Date de Facturation
                          </p>
                          <input
                            value={formatDate(
                              isSingleInvoice?.invoice?.billing_date
                            )}
                            name="billing_date"
                            type="text"
                            className="form-control w-px-150 date-picker flatpickr-input"
                            placeholder="YYYY-MM-DD"
                          />
                        </div>
                        <div className="col flex-grow-1">
                          <p className="mb-2 repeater-title">Date d'Échéance</p>
                          <input
                            value={formatDate(
                              isSingleInvoice?.invoice?.due_date
                            )}
                            type="text"
                            className="form-control w-px-150 date-picker flatpickr-input"
                            placeholder="YYYY-MM-DD"
                            name="due_date"
                          />
                        </div>
                        <div className="col flex-grow-1">
                          <p className="mb-2 repeater-title">Numéro Client</p>
                          <p>{isSingleInvoice?.invoice?.customer_id?.c_id}</p>
                        </div>
                        <div className="col flex-grow-1">
                          <p className="mb-2 repeater-title">Numéro TVA</p>
                          <p>{singleCompanyData?.company?.vatno}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive border-top">
                    <table className="table m-0">
                      <thead>
                        <tr>
                          <th>Item</th>
                          {/* <th>Description</th> */}
                          <th>Cost</th>
                          {/* <th>Length</th> */}
                          <th>Qty</th>
                          <th>Vat</th>
                          <th>Price</th>
                          <th>SELECT</th>
                          {/* <th /> */}
                        </tr>
                      </thead>
                      <tbody id="repeatItems" is="dmx-repeat">
                        {isSingleInvoice?.productDetails?.map((item, index) => {
                          return (
                            <tr>
                              <td className="text-nowrap">{item.item_name}</td>
                              {/* <td className="text-nowrap">{item.item_desc}</td> */}
                              <td>{item.price}</td>
                              {/* <td>{item.length}</td> */}
                              <td>{item.qty}</td>
                              <td>{item.vat}</td>
                              <td>{numberWithEuroFormat(item.total_ex_vat)}</td>
                              <td>{item.period}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="row p-0 p-sm-4 total-info">
                    <div className="col-md-6 mb-md-0 mb-3 border rounded">
                      <h6 className="mt-2 mb-4 fw-bold">Salesperson : </h6>
                      <table>
                        <tbody>
                          <tr>
                            <td className="pe-4">Total Due:</td>
                            <td>
                              <span className="fw-medium">
                                {numberWithEuroFormat(
                                  isSingleInvoice?.invoice?.total_pay?.toFixed(
                                    2
                                  )
                                )}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="pe-4">Bank name:</td>
                            <td>{singleCompanyData?.banking?.bankname}</td>
                          </tr>
                          <tr>
                            <td className="pe-4">Country:</td>
                            <td>{singleCompanyData?.billing?.country}</td>
                          </tr>
                          <tr>
                            <td className="pe-4">IBAN:</td>
                            <td>{singleCompanyData?.banking?.vatno}</td>
                          </tr>
                          <tr>
                            <td className="pe-4">BIC code:</td>
                            <td>{singleCompanyData?.banking?.bic}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end">
                      <div className="invoice-calculations">
                        <div className="d-flex justify-content-between mb-2">
                          <span className="w-px-200">Subtotal excl. VAT:</span>
                          <span className="fw-medium">
                            {numberWithEuroFormat(
                              isSingleInvoice?.invoice?.subtotal_ex_vat
                            )}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <span className="w-px-200">VAT:</span>
                          <span className="fw-medium">
                            {numberWithEuroFormat(
                              isSingleInvoice?.invoice?.total_vat?.toFixed(2)
                            )}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <span className="w-px-200">Total Incl. VAT:</span>
                          <span className="fw-medium">
                            {numberWithEuroFormat(total)}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <span className="w-px-200">Paid:</span>
                          <span className="fw-medium">
                            €{isSingleInvoice?.invoice?.paid?.toFixed(2)}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <span className="w-px-200">Discount:</span>
                          <span className="fw-medium">
                            {numberWithEuroFormat(
                              isSingleInvoice?.invoice?.discount
                            )}
                          </span>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                          <span className="w-px-200">Total To Pay:</span>
                          {/* <span className="fw-medium">{(total - isSingleInvoice?.invoice?.discount).toFixed(2)}</span> */}
                          <span className="fw-medium">
                            {numberWithEuroFormat(total)}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <span className="w-px-200">Remaining To Pay:</span>
                          <span className="fw-medium">
                            {numberWithEuroFormat(
                              (total - isSingleInvoice?.invoice?.paid)?.toFixed(
                                2
                              )
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <h6 className="p-sm-4 p-0">
                    Note : {isSingleInvoice?.invoice?.notes}
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-xl-3  col-12 invoice-actions">
              <div className="card">
                <div className="card-body">
                  <button
                    className="btn btn-primary d-grid w-100 mb-2"
                    type="button"
                    // onClick={() => {setInvoiceShow(true) ;sedHTML()}}
                    onClick={() => {
                      setInvoiceShow(true);
                    }}
                  >
                    <span className="d-flex align-items-center justify-content-center text-nowrap">
                      <i className="ti ti-send ti-xs me-2"></i>Send Invoice
                    </span>
                  </button>
                  <PDFDownloadLink
                    document={
                      <MyInvoicePDFDoc
                        isSingleInvoice={isSingleInvoice}
                        singleCompanyData={singleCompanyData}
                        total={total}
                      />
                    }
                    fileName="invoice.pdf"
                  >
                    {({ blob, url, loading, error }) => {
                      return (
                        <button
                          className="btn btn-label-secondary d-grid w-100 mb-2"
                          type="button"
                        >
                          {loading ? "Loading document..." : "Download"}
                        </button>
                      );
                    }}
                  </PDFDownloadLink>
                  <button
                    className="btn btn-label-secondary d-grid w-100 mb-2"
                    onClick={() => window.print()}
                  >
                    Print
                  </button>
                  <NavLink
                    className="btn btn-label-secondary d-grid w-100 mb-2"
                    to={`/invoice-update/${id}`}
                  >
                    Edit Invoice
                  </NavLink>
                  <button
                    className="btn btn-primary d-grid w-100"
                    onClick={() => setPaymentShow(true)}
                    type="button"
                  >
                    <span className="d-flex align-items-center justify-content-center text-nowrap">
                      <i className="ti ti-currency-dollar ti-xs me-2"></i>Add
                      Payment
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <SendInvoice
        invoiceShow={invoiceShow}
        handleClose={() => setInvoiceShow(false)}
        pdfRef={pdfRef}
      />
      <AddPayment
        singelInvoice={singelInvoice}
        id={id}
        paymentShow={paymentShow}
        handleClose={() => setPaymentShow(false)}
      />
    </div>
  );
};

export default ReviewInvoice;
