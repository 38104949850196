import { createSlice } from "@reduxjs/toolkit";
import { getReservationList } from "../services/Calendar/reservation";



const calanderSlice = createSlice({
  name: "calanderSlice",
  initialState: {
    reservationData: [],
    modelList: [],
  },
  reducers: {
    getReservationData: (state, action) => {
      state.reservationData = action.payload;
    },
  },
});

export const { getReservationData } = calanderSlice.actions;
export default calanderSlice.reducer;

// api call
export const reservationList = () => async (dispatch) => {
  try {
    const tempData = await getReservationList();
    dispatch(getReservationData(tempData?.data));
  } catch (error) {
    console.error("Error fetching manufacturer dropdown:", error);
  }
};
