import React from "react";

import RoleListPagenation from "../components/RoleList/RoleListPagenation";
const RoleList = () => {
  return (
    <div className="content-wrapper">
      {/* Content */}
      <div className="container-fluid flex-grow-1 container-p-y">
        <h4 className="mb-4">Roles List</h4>
        <p className="mb-4">
          A role provided access to predefined menus and features so that
          depending on <br /> assigned role an administrator can have access to
          what user needs.
        </p>
        {/* Role cards */}
        <div className="row g-4">
          <div className="col-xl-4 col-lg-6 col-md-6">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h6 className="fw-normal mb-2">Total 4 users</h6>
                  <ul className="list-unstyled d-flex align-items-center avatar-group mb-0">
                    <li
                      data-bs-toggle="tooltip"
                      data-popup="tooltip-custom"
                      data-bs-placement="top"
                      className="avatar avatar-sm pull-up"
                      aria-label="Vinnie Mostowy"
                      data-bs-original-title="Vinnie Mostowy"
                    >
                      <img
                        className="rounded-circle"
                        src="assets/img/avatars/5.png"
                        alt="Avatar"
                      />
                    </li>
                    <li
                      data-bs-toggle="tooltip"
                      data-popup="tooltip-custom"
                      data-bs-placement="top"
                      className="avatar avatar-sm pull-up"
                      aria-label="Allen Rieske"
                      data-bs-original-title="Allen Rieske"
                    >
                      <img
                        className="rounded-circle"
                        src="assets/img/avatars/12.png"
                        alt="Avatar"
                      />
                    </li>
                    <li
                      data-bs-toggle="tooltip"
                      data-popup="tooltip-custom"
                      data-bs-placement="top"
                      className="avatar avatar-sm pull-up"
                      aria-label="Julee Rossignol"
                      data-bs-original-title="Julee Rossignol"
                    >
                      <img
                        className="rounded-circle"
                        src="assets/img/avatars/6.png"
                        alt="Avatar"
                      />
                    </li>
                    <li
                      data-bs-toggle="tooltip"
                      data-popup="tooltip-custom"
                      data-bs-placement="top"
                      className="avatar avatar-sm pull-up"
                      aria-label="Kaith D'souza"
                      data-bs-original-title="Kaith D'souza"
                    >
                      <img
                        className="rounded-circle"
                        src="assets/img/avatars/3.png"
                        alt="Avatar"
                      />
                    </li>
                    <li
                      data-bs-toggle="tooltip"
                      data-popup="tooltip-custom"
                      data-bs-placement="top"
                      className="avatar avatar-sm pull-up"
                      aria-label="John Doe"
                      data-bs-original-title="John Doe"
                    >
                      <img
                        className="rounded-circle"
                        src="assets/img/avatars/1.png"
                        alt="Avatar"
                      />
                    </li>
                  </ul>
                </div>
                <div className="d-flex justify-content-between align-items-end mt-1">
                  <div className="role-heading">
                    <h4 className="mb-1">Administrator</h4>
                    <a
                      href="javascript:;"
                      data-bs-toggle="modal"
                      data-bs-target="#addRoleModal"
                      className="role-edit-modal"
                    >
                      <span className="roleEdit">Edit Role</span>
                    </a>
                  </div>
                  <a href="javascript:void(0);" className="text-muted">
                    <i className="ti ti-copy ti-md" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h6 className="fw-normal mb-2">Total 7 users</h6>
                  <ul className="list-unstyled d-flex align-items-center avatar-group mb-0">
                    <li
                      data-bs-toggle="tooltip"
                      data-popup="tooltip-custom"
                      data-bs-placement="top"
                      className="avatar avatar-sm pull-up"
                      aria-label="Jimmy Ressula"
                      data-bs-original-title="Jimmy Ressula"
                    >
                      <img
                        className="rounded-circle"
                        src="assets/img/avatars/4.png"
                        alt="Avatar"
                      />
                    </li>
                    <li
                      data-bs-toggle="tooltip"
                      data-popup="tooltip-custom"
                      data-bs-placement="top"
                      className="avatar avatar-sm pull-up"
                      aria-label="John Doe"
                      data-bs-original-title="John Doe"
                    >
                      <img
                        className="rounded-circle"
                        src="assets/img/avatars/1.png"
                        alt="Avatar"
                      />
                    </li>
                    <li
                      data-bs-toggle="tooltip"
                      data-popup="tooltip-custom"
                      data-bs-placement="top"
                      className="avatar avatar-sm pull-up"
                      aria-label="Kristi Lawker"
                      data-bs-original-title="Kristi Lawker"
                    >
                      <img
                        className="rounded-circle"
                        src="assets/img/avatars/2.png"
                        alt="Avatar"
                      />
                    </li>
                    <li
                      data-bs-toggle="tooltip"
                      data-popup="tooltip-custom"
                      data-bs-placement="top"
                      className="avatar avatar-sm pull-up"
                      aria-label="Kaith D'souza"
                      data-bs-original-title="Kaith D'souza"
                    >
                      <img
                        className="rounded-circle"
                        src="assets/img/avatars/3.png"
                        alt="Avatar"
                      />
                    </li>
                    <li
                      data-bs-toggle="tooltip"
                      data-popup="tooltip-custom"
                      data-bs-placement="top"
                      className="avatar avatar-sm pull-up"
                      aria-label="Danny Paul"
                      data-bs-original-title="Danny Paul"
                    >
                      <img
                        className="rounded-circle"
                        src="assets/img/avatars/7.png"
                        alt="Avatar"
                      />
                    </li>
                  </ul>
                </div>
                <div className="d-flex justify-content-between align-items-end mt-1">
                  <div className="role-heading">
                    <h4 className="mb-1">Manager</h4>
                    <a
                      href="javascript:;"
                      data-bs-toggle="modal"
                      data-bs-target="#addRoleModal"
                      className="role-edit-modal"
                    >
                      <span className="roleEdit">Edit Role</span>
                    </a>
                  </div>
                  <a href="javascript:void(0);" className="text-muted">
                    <i className="ti ti-copy ti-md" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h6 className="fw-normal mb-2">Total 5 users</h6>
                  <ul className="list-unstyled d-flex align-items-center avatar-group mb-0">
                    <li
                      className="avatar avatar-sm pull-up"
                      aria-label="Andrew Tye"
                      data-bs-original-title="Andrew Tye"
                    >
                      <img
                        className="rounded-circle"
                        src="assets/img/avatars/6.png"
                        alt="Avatar"
                      />
                    </li>
                    <li
                      className="avatar avatar-sm pull-up"
                      aria-label="Rishi Swaat"
                      data-bs-original-title="Rishi Swaat"
                    >
                      <img
                        className="rounded-circle"
                        src="assets/img/avatars/9.png"
                        alt="Avatar"
                      />
                    </li>
                    <li
                      className="avatar avatar-sm pull-up"
                      aria-label="Rossie Kim"
                      data-bs-original-title="Rossie Kim"
                    >
                      <img
                        className="rounded-circle"
                        src="assets/img/avatars/12.png"
                        alt="Avatar"
                      />
                    </li>
                    <li
                      className="avatar avatar-sm pull-up"
                      aria-label="Kim Merchent"
                      data-bs-original-title="Kim Merchent"
                    >
                      <img
                        className="rounded-circle"
                        src="assets/img/avatars/10.png"
                        alt="Avatar"
                      />
                    </li>
                    <li
                      className="avatar avatar-sm pull-up"
                      aria-label="Sam D'souza"
                      data-bs-original-title="Sam D'souza"
                    >
                      <img
                        className="rounded-circle"
                        src="assets/img/avatars/13.png"
                        alt="Avatar"
                      />
                    </li>
                  </ul>
                </div>
                <div className="d-flex justify-content-between align-items-end mt-1">
                  <div className="role-heading">
                    <h4 className="mb-1">Users</h4>
                    <a
                      href="javascript:;"
                      data-bs-toggle="modal"
                      data-bs-target="#addRoleModal"
                      className="role-edit-modal"
                    >
                      <span className="roleEdit">Edit Role</span>
                    </a>
                  </div>
                  <a href="javascript:void(0);" className="text-muted">
                    <i className="ti ti-copy ti-md" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h6 className="fw-normal mb-2">Total 3 users</h6>
                  <ul className="list-unstyled d-flex align-items-center avatar-group mb-0">
                    <li
                      className="avatar avatar-sm pull-up"
                      aria-label="Kim Karlos"
                      data-bs-original-title="Kim Karlos"
                    >
                      <img
                        className="rounded-circle"
                        src="assets/img/avatars/3.png"
                        alt="Avatar"
                      />
                    </li>
                    <li
                      className="avatar avatar-sm pull-up"
                      aria-label="Katy Turner"
                      data-bs-original-title="Katy Turner"
                    >
                      <img
                        className="rounded-circle"
                        src="assets/img/avatars/9.png"
                        alt="Avatar"
                      />
                    </li>
                    <li
                      className="avatar avatar-sm pull-up"
                      aria-label="Peter Adward"
                      data-bs-original-title="Peter Adward"
                    >
                      <img
                        className="rounded-circle"
                        src="assets/img/avatars/4.png"
                        alt="Avatar"
                      />
                    </li>
                    <li
                      className="avatar avatar-sm pull-up"
                      aria-label="Kaith D'souza"
                      data-bs-original-title="Kaith D'souza"
                    >
                      <img
                        className="rounded-circle"
                        src="assets/img/avatars/10.png"
                        alt="Avatar"
                      />
                    </li>
                    <li
                      className="avatar avatar-sm pull-up"
                      aria-label="John Parker"
                      data-bs-original-title="John Parker"
                    >
                      <img
                        className="rounded-circle"
                        src="assets/img/avatars/11.png"
                        alt="Avatar"
                      />
                    </li>
                  </ul>
                </div>
                <div className="d-flex justify-content-between align-items-end mt-1">
                  <div className="role-heading">
                    <h4 className="mb-1">Support</h4>
                    <a
                      href="javascript:;"
                      data-bs-toggle="modal"
                      data-bs-target="#addRoleModal"
                      className="role-edit-modal"
                    >
                      <span className="roleEdit">Edit Role</span>
                    </a>
                  </div>
                  <a href="javascript:void(0);" className="text-muted">
                    <i className="ti ti-copy ti-md" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h6 className="fw-normal mb-2">Total 2 users</h6>
                  <ul className="list-unstyled d-flex align-items-center avatar-group mb-0">
                    <li
                      className="avatar avatar-sm pull-up"
                      aria-label="Kim Merchent"
                      data-bs-original-title="Kim Merchent"
                    >
                      <img
                        className="rounded-circle"
                        src="assets/img/avatars/10.png"
                        alt="Avatar"
                      />
                    </li>
                    <li
                      className="avatar avatar-sm pull-up"
                      aria-label="Sam D'souza"
                      data-bs-original-title="Sam D'souza"
                    >
                      <img
                        className="rounded-circle"
                        src="assets/img/avatars/13.png"
                        alt="Avatar"
                      />
                    </li>
                    <li
                      className="avatar avatar-sm pull-up"
                      aria-label="Nurvi Karlos"
                      data-bs-original-title="Nurvi Karlos"
                    >
                      <img
                        className="rounded-circle"
                        src="assets/img/avatars/5.png"
                        alt="Avatar"
                      />
                    </li>
                    <li
                      className="avatar avatar-sm pull-up"
                      aria-label="Andrew Tye"
                      data-bs-original-title="Andrew Tye"
                    >
                      <img
                        className="rounded-circle"
                        src="assets/img/avatars/8.png"
                        alt="Avatar"
                      />
                    </li>
                    <li
                      className="avatar avatar-sm pull-up"
                      aria-label="Rossie Kim"
                      data-bs-original-title="Rossie Kim"
                    >
                      <img
                        className="rounded-circle"
                        src="assets/img/avatars/9.png"
                        alt="Avatar"
                      />
                    </li>
                  </ul>
                </div>
                <div className="d-flex justify-content-between align-items-end mt-1">
                  <div className="role-heading">
                    <h4 className="mb-1">Restricted User</h4>
                    <a
                      href="javascript:;"
                      data-bs-toggle="modal"
                      data-bs-target="#addRoleModal"
                      className="role-edit-modal"
                    >
                      <span className="roleEdit">Edit Role</span>
                    </a>
                  </div>
                  <a href="javascript:void(0);" className="text-muted">
                    <i className="ti ti-copy ti-md" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6">
            <div className="card h-100">
              <div className="row h-100">
                <div className="col-sm-5">
                  <div className="d-flex align-items-end h-100 justify-content-center mt-sm-0 mt-3">
                    <img
                      src="assets/img/illustrations/add-new-roles.png"
                      className="img-fluid mt-sm-4 mt-md-0"
                      alt="add-new-roles"
                      width={83}
                    />
                  </div>
                </div>
                <div className="col-sm-7">
                  <div className="card-body text-sm-end text-center ps-sm-0">
                    <button
                      data-bs-target="#addRoleModal"
                      data-bs-toggle="modal"
                      className="btn btn-primary mb-2 text-nowrap add-new-role waves-effect waves-light"
                    >
                      Add New Role
                    </button>
                    <p className="mb-0 mt-1">Add role, if it does not exist</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <RoleListPagenation />
        </div>
      </div>
      <div className="content-backdrop fade" />
    </div>
  );
};
export default RoleList;
