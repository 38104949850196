import { Field, FieldArray } from "formik";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { Damage } from "../../../Common/DropdownValue";

const DamageAndMaintenance = ({ handleChange, values, isinitialValues }) => {
  const [nextDate, setNextDate] = useState();
  console.log(nextDate, "nextDate");
  const [prevDate, setPrevDate] = useState();
  useEffect(() => {
    if (isinitialValues?.DamageMaintenance) {
      const nextDate = new Date(isinitialValues?.DamageMaintenance?.next_date)
        .toISOString()
        .split("T")[0];
      const prevDate = new Date(isinitialValues?.DamageMaintenance?.pre_date)
        .toISOString()
        .split("T")[0];
      setNextDate(isinitialValues?.DamageMaintenance?.next_date && nextDate);
      setPrevDate(isinitialValues?.DamageMaintenance?.pre_date && prevDate);
    }
  }, [isinitialValues]);
  return (
    <div className="card mb-4">
      <div className="card-header">
        <h5 className="card-tile mb-0">Dommages et entretien</h5>
      </div>
      <div className="card-body">
        <div className="row mb-3">
          <label className="form-label mb-1">Entretien suivant</label>
          <div className="col">
            <Field
              name="damageMaintenance.next_mileage"
              type="number"
              className="form-control"
              placeholder="Km"
              aria-controls="DataTables_Table_0"
            />
          </div>
          <div className="col">
            <input
              defaultValue={nextDate}
              onChange={handleChange}
              name="damageMaintenance.next_date"
              type="date"
              className="form-control"
              placeholder="Km"
              aria-controls="DataTables_Table_0"
            />
          </div>
        </div>
        <div className="row mb-3">
          <label className="form-label mb-1">Entretien Précédent</label>
          <div className="col">
            <Field
              name="damageMaintenance.pre_mileage"
              type="number"
              className="form-control"
              placeholder="Km"
              aria-controls="DataTables_Table_0"
            />
          </div>
          <div className="col">
            <input
              defaultValue={prevDate}
              onChange={handleChange}
              name="damageMaintenance.pre_date"
              type="date"
              className="form-control"
              placeholder="Next Later"
              aria-controls="DataTables_Table_0"
            />
          </div>
        </div>
        <div className="mb-3 col border-top pt-3">
          <FieldArray
            name="damage"
            render={(arrayHelpers) => (
              <>
                {values?.damage?.map((variable, index) => {
                  return (
                    <div className="row mb-3" key={index}>
                      <div className="col">
                        <Dropdown
                          options={Damage}
                          value={values?.damage?.[index].damage_type}
                          onChange={handleChange}
                          name={`damage.${index}.damage_type`}
                          placeholder="Types"
                          className="w-full"
                        />
                      </div>
                      <div className="col">
                        <Field
                          className="form-control col"
                          name={`damage.${index}.damage_cost`}
                          id={`damage.${index}.damage_cost`}
                          type="number"
                          placeholder="Prix"
                        />
                      </div>
                    </div>
                  );
                })}
                <div>
                  <button
                    onClick={() =>
                      arrayHelpers.push(values?.damage?.length + 1, {
                        damage_type: "",
                        damage_cost: "",
                      })
                    }
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                  >
                    Ajouter un type de dommage
                  </button>
                </div>
              </>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default DamageAndMaintenance;
