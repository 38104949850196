import React, { useRef, useState } from "react";

const CustomFileUploader = ({ label, handleChange, name }) => {
  const fileInputRef = useRef(null);
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleChange(file);
      fileInputRef.current.value = "";
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = () => {
    setIsDraggingOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      handleChange(file);
      fileInputRef.current.value = "";
    }
    setIsDraggingOver(false);
  };

  return (
    <div
      className={`customFileUploader cursor-pointer p-0 position-relative  ${
        isDraggingOver ? "customFileUploader-dragging" : ""
      }`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop} // Ensure onDrop is active
    >
      <input
        type="file"
        name={name}
        accept="image/*,application/pdf"
        id={name}
        hidden
        className="position-absolute top-0 w-100 h-100 "
        onChange={handleFileChange}
        ref={fileInputRef}
      />
      <label className="p-3 cursor-pointer" htmlFor={name}>
        {label}
      </label>
    </div>
  );
};

export default CustomFileUploader;
