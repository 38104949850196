// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.misc-wrapper{display:flex;flex-direction:column;justify-content:center;align-items:center;min-height:100vh;text-align:center}.misc-bg-wrapper{position:relative}.misc-bg-wrapper img{position:absolute;bottom:0;left:0;right:0;width:100%;z-index:-1}@media(max-width: 1499.98px){.misc-bg-wrapper img{height:250px}.misc-under-maintenance-bg-wrapper img{height:270px !important}}
`, "",{"version":3,"sources":["webpack://./src/assets/vendor/css/pages/page-misc.css"],"names":[],"mappings":"AAAA,cAAc,YAAY,CAAC,qBAAqB,CAAC,sBAAsB,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,CAAC,iBAAiB,iBAAiB,CAAC,qBAAqB,iBAAiB,CAAC,QAAQ,CAAC,MAAM,CAAC,OAAO,CAAC,UAAU,CAAC,UAAU,CAAC,6BAA6B,qBAAqB,YAAY,CAAC,uCAAuC,uBAAuB,CAAC","sourcesContent":[".misc-wrapper{display:flex;flex-direction:column;justify-content:center;align-items:center;min-height:100vh;text-align:center}.misc-bg-wrapper{position:relative}.misc-bg-wrapper img{position:absolute;bottom:0;left:0;right:0;width:100%;z-index:-1}@media(max-width: 1499.98px){.misc-bg-wrapper img{height:250px}.misc-under-maintenance-bg-wrapper img{height:270px !important}}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
