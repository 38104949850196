import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { updateVehicle } from "../../../../../services/AddVehicle";
import { SmallLoader } from "../../../../Common/Loader";
import * as Yup from "yup";
import { useParams } from "react-router-dom";

const InformationD = () => {
  const [isEdit, setEdit] = useState(true);
  const { singlevehicleData } = useSelector((state) => state.vehicleSlice);
  const [isLoding, setLoding] = useState(false);
  const { id } = useParams();

  const validationSchema = Yup.object().shape({
    date_issue: Yup.date().required("required *"),
    issuer: Yup.string().required("required *"),
  });

  const insuranceInfo = {
    issuer: singlevehicleData?.InsuranceInfo?.issuer || "",
    date_issue: singlevehicleData?.InsuranceInfo?.date_issue
      ? new Date(singlevehicleData?.InsuranceInfo?.date_issue)
          ?.toISOString()
          ?.split("T")[0]
      : "",
    support_contact: singlevehicleData?.InsuranceInfo?.support_contact || "",
    flight_franchise: singlevehicleData?.InsuranceInfo?.flight_franchise || "",
    franchise_crash: singlevehicleData?.InsuranceInfo?.franchise_crash || "",
    total_claims: singlevehicleData?.InsuranceInfo?.total_claims || "",
    info: singlevehicleData?.InsuranceInfo?.info || "",
  };

  return (
    <Formik
      initialValues={insuranceInfo}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={async (values) => {
        setLoding(true);
        const tempData = { ...singlevehicleData, InsuranceInfo: values };
        const data = await updateVehicle(id, tempData);
        if (data) {
          setEdit(true);
          setLoding(false);
        }
      }}
    >
      {({ values, errors, handleChange }) => (
        <Form className="card mb-4">
          <div className="card-header d-flex justify-content-between">
            <h5 className="card-tile mb-0">Information D’assurance</h5>
            {isEdit ? (
              <div
                onClick={() => setEdit(false)}
                className="btn btn-primary waves-effect waves-light"
              >
                Modifier
                {isLoding && <SmallLoader />}
              </div>
            ) : (
              <button
                type="submit"
                className="btn btn-primary waves-effect waves-light"
              >
                Save
                {isLoding && <SmallLoader />}
              </button>
            )}
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-4">
                  <h6>Courtier D’assurance</h6>
                  <Field
                    readOnly={isEdit}
                    name="issuer"
                    value={values.issuer}
                    placeholder="Type"
                    className={` ${isEdit ? "hide-input" : "form-control"} ${
                      errors.issuer && "dropdown-invalid"
                    }`}
                  />
                </div>
                <div className="mb-4">
                  <h6>Contact de l'assistance</h6>
                  <Field
                    readOnly={isEdit}
                    name="support_contact"
                    value={values.support_contact}
                    placeholder="Type"
                    className={`${isEdit ? "hide-input" : "form-control"}`}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-4">
                  <h6>Debut De L’assurance</h6>
                  <Field
                    type="date"
                    readOnly={isEdit}
                    name="date_issue"
                    defaultValue={values.date_issue}
                    placeholder="Type"
                    className={` ${isEdit ? "hide-input" : "form-control"} ${
                      errors.date_issue && "dropdown-invalid"
                    }`}
                  />
                </div>
                <div className="mb-4">
                  <h6>Client Memo</h6>
                  <Field
                    readOnly={isEdit}
                    name="info"
                    value={values.info}
                    placeholder="Type"
                    className={`${isEdit ? "hide-input" : "form-control"}`}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h6>Franchise Vol</h6>
                  <Field
                    readOnly={isEdit}
                    name="flight_franchise"
                    value={values.flight_franchise}
                    placeholder="Type"
                    className={` ${isEdit ? "hide-input" : "form-control"}`}
                  />
                </div>
                <div className="col">
                  <h6>Franchise accident</h6>
                  <Field
                    readOnly={isEdit}
                    name="franchise_crash"
                    value={values.franchise_crash}
                    placeholder="Type"
                    className={` ${isEdit ? "hide-input" : "form-control"}`}
                  />
                </div>
                <div className="col">
                  <h6>Franchise Perte Totale</h6>
                  <Field
                    type="number"
                    readOnly={isEdit}
                    name="total_claims"
                    value={values.total_claims}
                    placeholder="Type"
                    className={` ${isEdit ? "hide-input" : "form-control"}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default InformationD;
