/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import { Dropdown } from "primereact/dropdown";
import {
  deleteClient,
  getSubscriptionByCompanylist,
} from "../services/client/addclientApi";
import BreadCrumbs from "../components/Common/BreadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import {
  alphabeticalDriver,
  fetchAllUserList,
  fetchcompanyDropdown,
  searchDriverUserList,
} from "../Redux/commonSlice";
import { Loader } from "../components/Common/Loader";
import tagifyMin from "@yaireo/tagify";
import { BsChevronUp } from "react-icons/bs";
import { BsChevronDown } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import AddDriver from "../components/Driver/AddDriver";
import { pagination } from "../components/Common/Pagination";
import {
  roleClasses,
  subscriptionStatusClass,
} from "../components/Common/roles";
import TableFooter from "../components/Common/TableFooter";
import DeleteModal from "../components/Common/DeleteModal";

const Driver = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = React.useState(false);
  const [clientId, setclientId] = useState(null);
  const [isSubscriptionValue, setSubscriptionValue] = useState([]);
  const inputRef = useRef();
  const [isTags, setTags] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setitemsPerPage] = useState("25");
  const { companyDropdown, driverList, loading } = useSelector(
    (state) => state?.commonSlice
  );
  const [initialValue, setInitialValue] = useState({
    search: "",
    company: "",
    subscription: "",
  });

  // pagination start
  const driverListData = pagination(driverList, currentPage, itemsPerPage);
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  // pagination end

  useEffect(() => {
    const tagify = new tagifyMin(inputRef.current, {
      dropdown: {
        enabled: 0,
      },
      whitelist: ["AUX", "MAIN"],
    });
    tagify.on("add", (e) => {
      setTags(tagify.value.map((tag) => tag.value));
    });

    tagify.on("remove", (e) => {
      setTags(tagify.value.map((tag) => tag.value));
    });

    return () => {
      tagify.destroy();
    };
  }, []);

  // subscriptionDropdownValue start
  const subscriptionDropdownValue = async (id) => {
    const subscriptionValue = await getSubscriptionByCompanylist(id);
    if (subscriptionValue !== undefined) {
      setSubscriptionValue(subscriptionValue?.data);
    } else {
      setSubscriptionValue([]);
    }
  };
  // subscriptionDropdownValue end

  const aTozSorting = (value) => {
    const tempData = driverList.map((item) => ({
      ...item,
      member: item?.comp_id?.name,
    }));
    const data = tempData
      ?.slice()
      .sort((a, b) => a?.[value]?.localeCompare(b?.[value]));
    dispatch(alphabeticalDriver(data));
  };

  const zToaSorting = (value) => {
    const tempData = driverList.map((item) => ({
      ...item,
      member: item?.comp_id?.name,
    }));
    const data = tempData
      ?.slice()
      .sort((a, b) => b?.[value]?.localeCompare(a?.[value]));
    dispatch(alphabeticalDriver(data));
  };

  const handleEditClick = (id) => {
    localStorage.setItem("customer_id", id);
    navigate(`/driver/profile/${id}`);
  };

  const handleSelectChange = (e) => {
    setitemsPerPage(e.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentPage(0);
    setInitialValue((prev) => {
      const updatedFilters = { ...prev, [name]: value };
      return updatedFilters;
    });
  };

  const handleDelete = async () => {
    const form = new FormData();
    form.append("role", ["MAIN", "AUX"]);
    await deleteClient(clientId);
    dispatch(searchDriverUserList(form));
    setModalShow(false);
  };

  useEffect(() => {
    const form = new FormData();
    if (initialValue?.search) form.append("searchname", initialValue?.search);
    if (initialValue?.company?._id)
      form.append("comp_id", initialValue?.company?._id);
    if (initialValue?.subscription?._id)
      form.append("sub_id", initialValue?.subscription?._id);
    if (isTags?.length > 0) {
      form.append("role", isTags);
    } else {
      form.append("role", ["MAIN", "AUX"]);
    }
    const debounceTimeout = setTimeout(() => {
      dispatch(searchDriverUserList(form));
    }, 300);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [
    initialValue?.search,
    initialValue?.company,
    initialValue?.subscription,
    isTags?.length,
  ]);

  useEffect(() => {
    dispatch(fetchcompanyDropdown());
  }, []);

  return (
    <div className="container-fluid flex-grow-1 container-p-y client">
      {/* Users List Table */}
      <BreadCrumbs title={"Operationel"} subTitle={"Abonnés"} />
      <div className="card">
        <div className="card-header">
          <div className="d-flex align-items-center gap-3">
            <div className="flex-grow-1">
              <input
                type="search"
                className="form-control"
                placeholder="Rechercher.."
                name="search"
                onChange={handleChange}
                value={initialValue?.search}
              />
            </div>

            <div className="col-12 col-md-3 col-lg-2 d-none d-lg-inline">
              <Dropdown
                value={initialValue?.company}
                options={companyDropdown}
                optionLabel="name"
                filter
                name="company"
                placeholder="Société"
                className="w-full"
                onChange={(e) => {
                  handleChange(e);
                  subscriptionDropdownValue(e.target.value?._id);
                }}
                {...(initialValue?.company && { showClear: true })}
              />
            </div>
            <div className="col-12 col-md-3 col-lg-2 d-none d-lg-inline">
              <Dropdown
                value={initialValue?.subscription}
                onChange={handleChange}
                options={isSubscriptionValue}
                optionLabel="subscription_name"
                placeholder="Abonnement"
                className="w-full"
                name="subscription"
                {...(initialValue?.subscription && { showClear: true })}
              />
            </div>

            <div className="col-12 col-md-3 col-lg-2 d-none d-lg-inline">
              <input
                onChange={(e) => {
                  setTags(e.target.value);
                }}
                value={isTags}
                className="form-control"
                ref={inputRef}
                placeholder="Type Of Client"
              />
            </div>
          </div>
        </div>
        <div className=" table-responsive">
          <table className="table  table-hover">
            <thead className="border-top">
              <tr>
                <th># </th>
                <th>
                  <span className="position-relative top-4">STATUS</span>
                  <div className="d-inline-block item-center">
                    <span className="d-flex flex-column ps-1"></span>
                  </div>
                </th>
                <th>
                  <span className="position-relative top-4">NOM</span>
                  <div className="d-inline-block item-center">
                    <span className="d-flex flex-column ps-1">
                      <BsChevronUp
                        fontSize={12}
                        onClick={() => aTozSorting("firstname")}
                      />
                      <BsChevronDown
                        fontSize={12}
                        onClick={() => zToaSorting("firstname")}
                      />
                    </span>
                  </div>
                </th>
                <th>
                  <span className="position-relative top-4"> E-MAIL</span>
                  <div className="d-inline-block item-center">
                    <span className="d-flex flex-column ps-1">
                      <BsChevronUp
                        fontSize={12}
                        onClick={() => aTozSorting("email")}
                      />
                      <BsChevronDown
                        fontSize={12}
                        onClick={() => zToaSorting("email")}
                      />
                    </span>
                  </div>
                </th>
                <th>
                  <span className="position-relative top-4">MEMBRE</span>
                  <div className="d-inline-block item-center">
                    <span className="d-flex flex-column ps-1">
                      <BsChevronUp
                        fontSize={12}
                        onClick={() => aTozSorting("member")}
                      />
                      <BsChevronDown
                        fontSize={12}
                        onClick={() => zToaSorting("member")}
                      />
                    </span>
                  </div>
                </th>

                <th>ACTIONS </th>
              </tr>
            </thead>
            <tbody>
              {driverListData?.length > 0 &&
                driverListData?.map((customer, index) => {
                  return (
                    <tr
                      key={customer?.id}
                      className="cursor-pointer"
                      onClick={() => {
                        handleEditClick(customer._id);
                      }}
                    >
                      <td>{customer?.index}</td>
                      <td className="text-nowrap d-flex">
                        <div className="d-inline-block">
                          {customer?.role.map((role, i) => {
                            return (
                              <React.Fragment key={i}>
                                {role !== "MAIN" && role !== "CLIENT" && (
                                  <span className={`me-2 ${roleClasses[role]}`}>
                                    {role}
                                  </span>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </div>

                        {customer?.sub_id?.subscription_name && (
                          <span
                            className={`${
                              subscriptionStatusClass[
                                customer?.sub_id?.subscription_name
                              ]
                            }`}
                          >
                            {customer?.sub_id?.subscription_name}
                          </span>
                        )}
                      </td>
                      <td
                        className="text-nowrap cursor-pointer"
                        onClick={() => {
                          handleEditClick(customer._id);
                        }}
                      >
                        {customer?.firstname} &nbsp;{customer?.lastname}
                      </td>
                      <td>{customer?.email}</td>
                      <td className="text-nowrap">{customer?.comp_id?.name}</td>
                      <td>
                        <i
                          onClick={() => {
                            handleEditClick(customer._id);
                          }}
                          className="menu-icon tf-icons ti ti-edit"
                          type="button"
                        />

                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            setclientId(customer?._id);
                            setModalShow(true);
                          }}
                          className="menu-icon tf-icons  ti ti-sm ti-trash"
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          {loading && (
            <div className="card-body">
              <Loader />
            </div>
          )}

          {driverListData.length > 0 && (
            <TableFooter
              itemsPerPage={itemsPerPage}
              handleSelectChange={handleSelectChange}
              listData={driverList}
              handlePageChange={handlePageChange}
            />
          )}
        </div>

        {!loading && (driverList?.length === 0 || !driverList) && (
          <div className="text-center my-4 mx-auto">No Data Found</div>
        )}

        {/* Modal start */}

        <DeleteModal
          title={"Supprimer le Abonné"}
          description={"Êtes-vous sûr de supprimer le Abonné ?"}
          show={modalShow}
          onHide={() => setModalShow(false)}
          deleteData={() => handleDelete(false)}
        />
        {/* Modal end */}
      </div>
    </div>
  );
};

export default Driver;
