/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import BreadCrumbs from "../components/Common/BreadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import {
  listDataExteriorElementDropdown,
  listDataExteriorPartsDropdown,
  listDataExteriorStripeDropdown,
  listDataInteriorPartsDropdown,
  listDataInteriorStripeDropdown,
} from "./../Redux/checkSlice";
import { Outlet, useLocation } from "react-router-dom";
import "../css/checks.css";

const Checks = () => {
  const dispatch = useDispatch();
  const { checkListData } = useSelector((state) => state?.checkSlice);
  const location = useLocation();

  useEffect(() => {
    dispatch(listDataExteriorPartsDropdown());
    dispatch(listDataExteriorStripeDropdown());
    dispatch(listDataExteriorElementDropdown());
    dispatch(listDataInteriorPartsDropdown());
    dispatch(listDataInteriorStripeDropdown());
  }, []);

  const newCheckListData = checkListData?.map((item) => {
    const today = new Date();
    const returnDays = new Date(item?.return_date);
    var timeDifference = returnDays?.getTime() - today.getTime();
    var dayDifference = timeDifference / (1000 * 3600 * 24);
    dayDifference = Math.round(dayDifference);
    return { ...item, leftDays: dayDifference };
  });
  newCheckListData?.sort((a, b) => a.leftDays - b.leftDays);

  const hidebc = ["annotate"];
  const bcCondition = location.pathname.includes(hidebc);

  return (
    <>
      <div className="container-fluid flex-grow-1 client">
        <div className="content-wrapper delivers">
          {/* Users List Table */}
          <div className={`flex-grow-1 "container-p-y"`}>
            {!bcCondition && (
              <BreadCrumbs title={"Flotte"} subTitle={"Checks"} />
            )}

            {/* <div className="step d-flex gap-3 pb-3 mb-1">
              <NavLink to={"check-out"} className="rounded-2 border p-1 px-2">
                <span className="bs-stepper-label">
                  <span className="bs-stepper-title}">Check Out</span>
                </span>
              </NavLink>
              <NavLink to={"check-in"} className="rounded-2 border p-1 px-2">
                <span className="bs-stepper-label ">
                  <span className="bs-stepper-title">Check In</span>
                </span>
              </NavLink>
            </div> */}
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default Checks;
