export const colorTemplate = (option) => {
  if (!option?.image) {
    return null; // Or handle the case in another way
  }
  return (
    <div className="color-option">
      <img
        className="color-square"
        src={require(`../../assets/img/colors/${option.image}`)}
        alt={option.name}
      />
      <div className="color-label">{option.name}</div>
    </div>
  );
};


