import React from "react";

const SelectedVehicle = ({ selected_vehicle, customerData }) => {
  return (
    <div className="card p-3 mt-4">
      {selected_vehicle?.map((item, index) => {
        return (
          <div className="cursor-pointer" key={index}>
            <div className="text-nowrap cursor-pointer fw-bold">
              {index + 1}&nbsp;. {item?.vehicle_name}&nbsp;{item?.model_name}
              &nbsp;
              {item?.declination}
            </div>
            <hr />
          </div>
        );
      })}
      <div className="text-nowrap cursor-pointer fw-bold">
        Subscription&nbsp;:&nbsp;
        {customerData?.customer?.sub_id?.subscription_name}
      </div>
    </div>
  );
};

export default SelectedVehicle;
