/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../components/Common/BreadCrumbs";
import { Dropdown } from "primereact/dropdown";
import DeleteModal from "../components/Common/DeleteModal";
import {
  changeSearchText,
  fetchManufacturerDropdown,
  fetchVehicleList,
  searchVehicleData,
  setFleetCurrentPage, 
  sortingAtoZ,
  sortingZtoA,
} from "../Redux/vehicleSlice";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import {
  deleteDocumentGerenalId,
  deleteVehiclet,
} from "../services/AddVehicle";
import { Status, locationOptions } from "../components/Common/DropdownValue";
import { Loader } from "../components/Common/Loader";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { pagination } from "../components/Common/Pagination";
import TableFooter from "../components/Common/TableFooter";

const Fleet = () => {
  const [modalShow, setModalShow] = useState(false);
  const [isStatus, setStatus] = useState("");
  const [istId, setId] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [itemsPerPage, setitemsPerPage] = useState("25");
  const [isPageNumberArray, setPageNumberArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const { manufacturersDropdown } = useSelector((state) => state?.vehicleSlice);
  const {
    vehicleList,
    vehicleSearchList,
    searchText,
    loading,
    currentFleetPage,
  } = useSelector((state) => state.vehicleSlice);

  const [initialValue, setInitialValue] = useState({
    search: "",
    company: "",
    status: "",
    owner: "",
    vehicle_location: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentPage(0);
    setInitialValue((prev) => {
      const updatedFilters = { ...prev, [name]: value };
      return updatedFilters;
    });
  };

  const editFleet = (id) => {
    navigate(`/fleet/info/${id}`);
  };

  const deleteData = async () => {
    await deleteVehiclet(istId);
    await deleteDocumentGerenalId(istId);
    setModalShow(false);
    dispatch(fetchVehicleList());
  };

  const aTozSorting = (value) => {
    dispatch(sortingAtoZ(value));
  };

  const zToaSorting = (value) => {
    dispatch(sortingZtoA(value));
  };
  // pagination start

  const vehicleListData = pagination(
    vehicleSearchList,
    currentPage,
    itemsPerPage
  );

  const handlePageChange = ({ selected }) => {
    selected !== 0 && dispatch(setFleetCurrentPage(selected));
    setCurrentPage(selected);
  };

  const handleSelectChange = (e) => {
    setitemsPerPage(e.value);
  };

  useEffect(() => {
    setPageNumberArray(vehicleSearchList);
  }, [vehicleSearchList]);
  // pagination end

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(changeSearchText(initialValue));
      dispatch(searchVehicleData(initialValue));
    }, 200);

    return () => clearTimeout(timeoutId);
  }, [
    initialValue.search,
    initialValue.vehicle_location,
    initialValue.status,
    initialValue.company,
  ]);

  const handleActive = () => {
    setCurrentPage(currentFleetPage);
  };

  useEffect(() => {
    handleActive();
    vehicleList?.length === 0 && dispatch(fetchVehicleList());
    dispatch(fetchManufacturerDropdown());
  }, []);

  useEffect(() => {
    setInitialValue((prev) => ({
      ...prev,
      search: searchText.search,
      company: searchText.company,
      status: searchText.status,
      vehicle_location: searchText.vehicle_location,
    }));
  }, [searchText]);

  return (
    <div className="content-wrapper">
      <div className="container-fluid flex-grow-1 container-p-y">
        {/* page-titel start */}
        <BreadCrumbs title={"Flotte"} subTitle={"Flotte"} />
        {/* page-titel end */}

        {/* main containe start */}
        <div className="card">
          <div className="card-header border-bottom">
            <div className="d-flex align-items-center justify-content-end gap-3">
              <div className="flex-grow-1">
                <input
                  value={initialValue?.search}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="search"
                  filter
                  type="search"
                  placeholder="Rechercher.."
                  className="form-control"
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2 d-none d-lg-inline">
                <Dropdown
                  value={initialValue?.company}
                  options={manufacturersDropdown}
                  optionLabel="name"
                  filter
                  name="company"
                  // showClear
                  placeholder="Marques"
                  className="w-full"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  {...(initialValue?.company && { showClear: true })}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2 d-none d-lg-inline">
                <Dropdown
                  // showClear
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  options={Status}
                  name="status"
                  value={initialValue?.status}
                  placeholder="Statuts"
                  className="w-full"
                  {...(initialValue?.status && { showClear: true })}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2 d-none d-lg-inline">
                <Dropdown
                  // showClear
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  options={locationOptions}
                  name="vehicle_location"
                  value={initialValue?.vehicle_location}
                  placeholder="Localisation"
                  className="w-full"
                  {...(initialValue?.vehicle_location && { showClear: true })}
                />
              </div>
              <div className="d-flex align-items-center flex-grow-0">
                <NavLink
                  to={"/add-vehicle"}
                  className="btn btn-primary waves-effect waves-light"
                  type="button"
                >
                  <i className="ti ti-plus me-0 me-sm-1 ti-xs" />
                  <span className="d-none d-sm-inline-block">Voiture</span>
                </NavLink>
              </div>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <span className="position-relative top-4">VOITURE</span>
                    <div className="d-inline-block item-center">
                      <span className="d-flex flex-column ps-1">
                        <BsChevronUp
                          fontSize={12}
                          onClick={() => aTozSorting("manufacturer")}
                        />
                        <BsChevronDown
                          fontSize={12}
                          onClick={() => zToaSorting("manufacturer")}
                        />
                      </span>
                    </div>
                  </th>
                  <th>
                    <span className="position-relative top-4">STATUS </span>
                    <div className="d-inline-block item-center">
                      <span className="d-flex flex-column ps-1">
                        <BsChevronUp
                          fontSize={12}
                          onClick={() => aTozSorting("status")}
                        />
                        <BsChevronDown
                          fontSize={12}
                          onClick={() => zToaSorting("status")}
                        />
                      </span>
                    </div>
                  </th>
                  <th>
                    {" "}
                    <span className="position-relative top-4">
                      LOCALISATION
                    </span>
                    <div className="d-inline-block item-center">
                      <span className="d-flex flex-column ps-1">
                        <BsChevronUp
                          fontSize={12}
                          onClick={() => aTozSorting("vehicle_location")}
                        />
                        <BsChevronDown
                          fontSize={12}
                          onClick={() => zToaSorting("vehicle_location")}
                        />
                      </span>
                    </div>
                  </th>
                  <th className="text-center">KILOMÉTRAGE</th>
                  <th className="text-center ">ACTIVITÉ</th>
                  <th className="cell-fit">ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {vehicleListData.length > 0 &&
                  vehicleListData.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <i className="ti ti-info-octagon text-secondary"></i>
                        </td>
                        <td>
                          <div
                            className="cursor-pointer"
                            style={{ fontWeight: "600" }}
                            onClick={() => {
                              editFleet(item.general_id);
                            }}
                          >
                            {item.manufacturer} {item.model} {item.declination}
                          </div>
                          <small className="text-muted">
                            {item.license_plate}
                          </small>
                        </td>
                        <td>
                          <Dropdown
                            onClick={() => setId(item.general_id)}
                            value={
                              istId === item.general_id && isStatus
                                ? isStatus
                                : item.status
                            }
                            onChange={(e) => setStatus(e.target.value)}
                            options={Status}
                            filter
                            placeholder="Status"
                            className="w-full"
                          />
                        </td>
                        <td className="Fleet">
                          <div>{item?.vehicle_location}</div>
                        </td>
                        <td className="text-center">
                          {item?.km_counter} KM <br />
                          <small>{item?.updated_date}</small>
                        </td>
                        <td className="text-center">
                          {item.active === "Active" ? (
                            <span className="badge badge-center rounded-pill w-px-30 h-px-30 me-2 bg-label-success">
                              <i className="ti ti-sm ti-circle-check"></i>
                            </span>
                          ) : (
                            <span className="badge badge-center rounded-pill w-px-30 h-px-30 me-2 bg-label-danger">
                              <i className="ti ti-sm text-danger">x</i>
                            </span>
                          )}
                        </td>
                        <td>
                          <div className="d-flex align-items-center cursor-pointer">
                            <i
                              className="mx-2 ti ti-edit ti-sm "
                              onClick={() => {
                                editFleet(item.general_id);
                              }}
                            ></i>
                            <i
                              className="mx-2 ti ti-sm ti-trash"
                              onClick={() => {
                                setModalShow(true);
                                setId(item.general_id);
                              }}
                            ></i>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>

          {loading && (
            <div className="card-header my-4 mx-auto">
              <Loader />
            </div>
          )}

          {!loading && isPageNumberArray?.length === 0 && (
            <div className="text-center my-4 mx-auto">No Data Found</div>
          )}

          {vehicleListData !== false && (
            <TableFooter
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              handleSelectChange={handleSelectChange}
              listData={vehicleSearchList}
              handlePageChange={handlePageChange}
              handleActive={handleActive}
            />
          )}
        </div>

        <DeleteModal
          title={"Supprimer Cette véhicule"}
          description={"Êtes-vous sûr de supprimer le véhicule ?"}
          show={modalShow}
          onHide={() => setModalShow(false)}
          deleteData={() => deleteData()}
        />
      </div>
    </div>
  );
};

export default Fleet;
